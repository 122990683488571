import axios from 'axios'

const getSearchSynonyms = (url) => {
  return new Promise((resolve, reject) => {
    return axios({
      url: url,
      method: 'get'
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  })
}

export default getSearchSynonyms
