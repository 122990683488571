<template>
  <div>
    <FormFilter :config="config" @filtered="displayResults" @has-results="displayPagination" />

    <div class="columns is-multiline">
      <div
        v-for="(result, index) in displayedResults"
        :key="index"
        :class="
          config.withExternalData
            ? 'column is-half-tablet is-half-desktop'
            : 'column is-half-tablet is-one-third-desktop'
        "
      >
        <DownloadListTeaser
          :key="`downloadlistteaser-${result.identifier}`"
          :result="result"
          :labels="labels"
          :wt-params="config.wtParams"
        />
      </div>
    </div>
    <Pagination
      v-show="config.loadInitialData || hasResults"
      :config="config.pagination"
      :results="results?.length"
      @update="displayPaginatedResults"
    />
  </div>
</template>

<script setup>
import FormFilter from '../FormFilter/FormFilter.vue'
import DownloadListTeaser from '../DownloadListTeaser/DownloadListTeaser.vue'
import Pagination from '../Pagination/Pagination.vue'
import { ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const hasResults = ref(false)
const results = ref([])
const displayedResults = ref([])
const displayRange = ref([0, props.config.pagination.resultsPerPage - 1])
const labels = ref(props.config.texts)

const displayPagination = (hasResultsRaw) => {
  hasResults.value = hasResultsRaw
}

const displayResults = (newResults) => {
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < results.value?.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }
  return displayedResults.value
}

const displayPaginatedResults = (displayRangeRaw) => {
  displayRange.value = displayRangeRaw
  displayResults(results.value)
}
</script>
