export const parseExternalProps = (object) => {
  try {
    if (typeof object === 'undefined') {
      return undefined
    }
    if (typeof object === 'string' && object === '') {
      return undefined
    }

    return JSON.parse(object)
  } catch (err) {
    return console.error(err.message)
  }
}
