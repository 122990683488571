<template>
  <span>
    <Button
      colour="orange"
      :text="waitingForResponse ? addButtonLoadingText : item.text"
      :is-disabled="waitingForResponse"
      @on-simple-click="addItem"
    />
    <p
      v-if="addItemFailed"
      :class="[$style.errorText, { [$style.darkBackground]: isDarkBackground }]"
    >
      {{ addItemFailedMsg }}
    </p>
    <p
      v-if="addItemAmountMessage"
      :class="[$style.warningText, { [$style.darkBackground]: isDarkBackground }]"
    >
      {{ addItemAmountMsgTxtRef }}
    </p>
  </span>
</template>

<script setup>
import getQuoteIdFromUrl from '../../xhr/shopCart/getQuoteIdFromUrl'
import addItemToUrl from '../../xhr/shopCart/addItemToUrl'
import CustomEvents from '../../globals/constants/customEvents'
import Button from '../Button/Button.vue'
import { ref } from 'vue'
import axios from 'axios'

const emit = defineEmits(['closed', 'error'])

const props = defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  addButtonLoadingText: {
    type: String,
    default: ''
  },
  addItemFailedMsg: {
    type: String,
    default: ''
  },
  addItemAmountMsgTxt: {
    type: String,
    default: ''
  },
  addItemHighestAmountMsg: {
    type: String,
    default: ''
  },
  addItemLowestAmountMsg: {
    type: String,
    default: ''
  },
  addItemMultipleAmountMsg: {
    type: String,
    default: ''
  },
  isDarkBackground: {
    type: Boolean,
    default: false
  }
})

const addItemFailed = ref(false)
const addItemAmountMessage = ref(false)
const waitingForResponse = ref(false)
const addItemAmountMsgTxtRef = ref(null)

const addItem = async () => {
  addItemFailed.value = false
  addItemAmountMessage.value = false
  addItemAmountMsgTxtRef.value = props.addItemAmountMsgTxt
  let addItemHighestAmountMsg = props.addItemHighestAmountMsg
  let addItemLowestAmountMsg = props.addItemLowestAmountMsg
  let addItemMultipleAmountMsg = props.addItemMultipleAmountMsg
  waitingForResponse.value = true

  const productType = (
    await axios({
      url: window.getItemInfoUrl,
      method: 'post',
      data: {
        itemid: props.item.id,
        country: window.currentCountry,
        language: window.currentLanguage.replace('EN-US', 'EN')
      }
    }).catch(() => {
      // ? no catch needed
      return
    })
  )?.data?.ProductType

  getQuoteIdFromUrl(window.getQuoteIdUrl)
    .then((quoteId) => {
      addItemToUrl(window.addItemUrl, {
        itemid: props.item.id,
        quantity: props.item.quantity,
        country: window.currentCountry,
        language: window.currentLanguage,
        quoteid: quoteId,
        headline: props.item.headline,
        subheadline: props.item.subheadline,
        pictureurl: props.item.pictureurl,
        thumbnailurl: props.item.thumbnailurl,
        producturl: props.item.producturl,
        // ? individualPrice extra
        isLoggedIn: Boolean(window.logIn),
        discount: window.logIn?.discount,
        typesExcludedDiscount: window.logIn?.typesExcludedDiscount,
        productType
      })
        .then((response) => {
          waitingForResponse.value = false
          //We will add amountMessage handling
          if (
            typeof response.data.amountMessage !== 'undefined' &&
            response.data.amountMessage != null &&
            response.data.amountMessage != ''
          ) {
            //Try to get message and value which is separated by semicolon in json response
            let strAmountMessage = ''
            let partsOfAmountMessage = response.data.amountMessage.split(';')
            if (partsOfAmountMessage[0].includes('highestAmount')) {
              strAmountMessage = addItemHighestAmountMsg.replace(
                '###amountValue###',
                partsOfAmountMessage[1]
              )
            }
            if (partsOfAmountMessage[0].includes('lowestAmount')) {
              strAmountMessage = addItemLowestAmountMsg.replace(
                '###amountValue###',
                partsOfAmountMessage[1]
              )
            }
            if (partsOfAmountMessage[0].includes('multipleAmount')) {
              strAmountMessage = addItemMultipleAmountMsg.replace(
                '###amountValue###',
                partsOfAmountMessage[1]
              )
            }
            addItemAmountMsgTxtRef.value = strAmountMessage
            addItemAmountMessage.value = true
          }
          const event = new CustomEvent(CustomEvents.ADD_TO_CART, {
            detail: response.data
          })
          window.dispatchEvent(event)
          emit('closed')
        })
        .catch((error) => {
          addItemFailed.value = true
          waitingForResponse.value = false
          console.error(error)

          if (error.data) {
            emit('error', error.data)
          }
        })
    })
    .catch((error) => {
      addItemFailed.value = true
      waitingForResponse.value = false
      console.error(error)
    })
}
</script>

<style module lang="scss">
@import './ShopCartLink.scss';
</style>
