<template>
  <div
    :class="$style.container"
    @mouseenter="noHover = true"
    @mouseleave="(noHover = false), (open = false)"
    @click="open = true"
  >
    <a
      :class="[
        { NaviSelect__blue: colour === 'blue' },
        { NaviSelect__white: colour === 'white' },
        { NaviSelect__orange: colour === 'orange' },
        { NaviSelect__noHover: noHover },
        { NaviSelect__open: open },
        $style.select
      ]"
    >
      <span>Hydraulikaggregate</span>
      <span :class="$style.triangle" />
    </a>
    <ul>
      <li>
        <a href="#">Hydraulikaggregate</a>
      </li>
      <li>
        <a href="#">Schmiersysteme</a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  colour: {
    type: String,
    default: 'blue'
  }
})

const open = ref(false)
const noHover = ref(false)
</script>

<style module lang="scss">
@import './NaviSelect.scss';
</style>
