<template>
  <div :class="$style.search">
    <input
      ref="inputFieldRef"
      v-model="inputField"
      type="text"
      :placeholder="searchPlaceholder"
      maxlength="50"
      :class="$style.globalSearch"
      aria-label="searchphrase"
      @input.prevent="handleChange"
      @keyup.delete="handleReset"
      @keyup.up="handleKeyUpUp"
      @keyup.down="handleKeyUpDown"
      @keyup.enter="handleKeyUpEnter"
    />
    <div :class="$style.label" @click.prevent="handleSubmit">
      <input
        type="submit"
        :title="searchPlaceholder"
        value=""
        :class="$style.submitButton"
        aria-label="submit searchphrase"
      />
      <i class="fas fa-search" />
    </div>
    <div v-if="isLoading" :class="$style.loadingSpinner">
      <Loading :size="20" color="#ccc" />
    </div>
    <div v-if="didYouMean" :class="$style.didYouMean">
      {{ didYouMeanLabel }}
      <span :class="$style.didYouMeanPhrase" @click="handleDidYouMeanClick" v-text="didYouMean" />
    </div>
  </div>
</template>

<script setup>
import Loading from '../Loading/Loading.vue'
import { ref, computed, onMounted, watch } from 'vue'

const props = defineProps({
  searchPlaceholder: {
    type: String
  },
  selectedSuggestion: {
    type: String
  },
  model: {
    type: Array,
    default: () => []
  },
  showDidYouMean: {
    type: Boolean,
    default: false
  },
  searchSynonymSuggest: {
    type: Array,
    default: () => []
  },
  searchSynonym: {
    type: String,
    default: null
  },
  isLoading: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'active',
  'change',
  'inactive',
  'initialize',
  'keyUpDown',
  'keyUpEnter',
  'keyUpUp',
  'reset',
  'submitted'
])

const inputField = ref('')
const didYouMeanLabel = window.dictionary.didYouMean

const didYouMean = computed(() => {
  if (!props.showDidYouMean) {
    return false
  } else if (props.searchSynonym) {
    return props.searchSynonym
  } else if (props.searchSynonymSuggest.length > 0) {
    return props.searchSynonymSuggest.join(' ')
  }
  return false
})

const inputFieldRef = ref(null)

const handleDidYouMeanClick = () => {
  inputField.value = didYouMean
  inputFieldRef.value.focus()
  handleSubmit()
}

const handleSubmit = () => emit('submitted', prepareTerms())
const handleKeyUpEnter = () => emit('keyUpEnter', prepareTerms())
const prepareTerms = () => inputField.value.trim().split(' ')
const handleKeyUpUp = () => emit('keyUpUp')
const handleKeyUpDown = () => emit('keyUpDown')
const handleReset = () => emit('reset', inputField.value)

const handleChange = () => {
  emit('change', inputField.value)

  if (inputField.value !== '') {
    emit('active', inputField.value)
  } else {
    emit('inactive', inputField.value)
  }
}

watch(
  () => props.selectedSuggestion,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      inputField.value = newValue
    }
  }
)

onMounted(() => {
  const parsedValue = props.model.toString().replace(/,/g, ' ')
  emit('initialize', parsedValue, true)
  inputField.value = parsedValue
})
</script>

<style module lang="scss">
@import './Search.scss';
</style>
