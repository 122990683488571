import axios from 'axios'
import getQuoteIdFromUrl from './getQuoteIdFromUrl'

const getCartFromUrl = (url, requestData) => {
  return new Promise((resolve, reject) => {
    getQuoteIdFromUrl(window.getQuoteIdUrl)
      .then((quoteId) => {
        axios({
          url: url,
          method: 'post',
          responseType: 'json',
          data: { ...requestData, quoteid: quoteId }
        })
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => reject(error))
      })
      .catch((error) => reject(error))
  })
}

export default getCartFromUrl
