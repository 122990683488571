const convertISODateTimeToLocaleDate = (isoString, lang, emptyExpDateText) => {
  const shortIsoString =
    isoString.length > 10 && isoString.includes(' ') ? isoString.split(' ')[0] : isoString
  if (isoString) {
    const dateObj = new Date(shortIsoString)
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
    return new Intl.DateTimeFormat(lang, options).format(dateObj)
  } else {
    return emptyExpDateText
  }
}

const convertISODateTimeToISODate = (isoString) => {
  const shortIsoString =
    isoString.length > 10 && isoString.includes(' ') ? isoString.split(' ')[0] : isoString
  const dateObj = new Date(shortIsoString)
  const fullYear = dateObj.getFullYear()
  const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
  const day = ('0' + dateObj.getDate()).slice(-2)
  return `${fullYear}-${month}-${day}`
}

export { convertISODateTimeToISODate, convertISODateTimeToLocaleDate }
