<template>
  <div :class="$style.container">
    <img
      ref="img"
      :width="width"
      :height="height"
      :src="src"
      :alt="alt"
      @mouseenter="handleOver"
      @mousemove="handleMove"
      @mouseleave="handleOut"
    />
    <div ref="mask" :class="[$style.cursorMask, maskClass]" :style="maskStyle" />
    <div v-show="zoomShow" :class="[$style.zoomMask, zoomClass]" :style="zoomStyle">
      <img :id="id" :class="$style.zoomImg" :alt="alt" :src="zoomSrc" :style="zoomImgStyle" />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const props = defineProps({
  alt: {
    type: String,
    default: ''
  },
  width: {
    default: 'auto'
  },
  height: {
    default: 'auto'
  },
  id: {
    type: String,
    default: ''
  },
  orientation: {
    validator: function (value) {
      return ['right', 'left', 'top', 'bottom'].indexOf(value) !== -1
    },
    default: 'right'
  },
  src: {},
  zoomSrc: {},
  zoomClass: {},
  maskWidth: {
    default: 100
  },
  maskHeight: {
    default: 100
  },
  maskBgColor: {
    type: String,
    default: '#409eff'
  },
  maskOpacity: {
    default: 0.5
  },
  maskClass: {},
  delayIn: {
    type: Number,
    default: 0
  },
  delayOut: {
    type: Number,
    default: 0
  },
  scale: {
    type: Number,
    default: 3
  }
})

const zoomShow = ref(false)
const imgRect = ref('')
const maskRect = ref('')
const maskX = ref(0)
const maskY = ref(0)
const zoomLeft = ref('')
const zoomTop = ref('')
const zoomImgWidth = ref(0)
const zoomImgHeight = ref(0)
const zoomPosition = ref({
  x: 0,
  y: 0
})
const zoomInTimeoutId = ref(null)
const zoomOutTimeoutId = ref(null)
const img = ref(null)
const mask = ref(null)

const maskStyle = computed(() => {
  return {
    width: `${props.maskWidth}px`,
    height: `${props.maskHeight}px`,
    opacity: props.maskOpacity,
    backgroundColor: props.maskBgColor,
    left: `${maskX.value}px`,
    top: `${maskY.value}px`,
    visibility: zoomShow.value ? 'visible' : 'hidden'
  }
})

const zoomStyle = computed(() => {
  const currentImgRect = imgRect.value
    ? imgRect.value
    : img.value && img.value.getBoundingClientRect()
  const scaledMaskWidth = props.scale && currentImgRect ? props.maskWidth * props.scale : null
  const scaledMaskHeight = props.scale && currentImgRect ? props.maskHeight * props.scale : null
  const zoomProperties = {
    width: scaledMaskWidth ? `${scaledMaskWidth}px` : 'auto',
    height: scaledMaskHeight ? `${scaledMaskHeight}px` : 'auto',
    bottom: props.orientation === 'bottom' ? `-${scaledMaskHeight + 10}px` : 'auto',
    left:
      props.orientation === 'left'
        ? `-${scaledMaskWidth + 10}px`
        : props.orientation === 'top' || props.orientation === 'bottom'
          ? `-${(scaledMaskWidth - zoomLeft.value) / 2}px`
          : 'auto',
    right:
      props.orientation === 'right' || !props.orientation ? `-${scaledMaskWidth + 10}px` : 'auto',
    top:
      props.orientation === 'top'
        ? `-${scaledMaskHeight + 10}px`
        : props.orientation === 'left' || props.orientation === 'right'
          ? `-${(scaledMaskHeight - zoomTop.value) / 2}px`
          : 'auto'
  }
  Object.keys(zoomProperties).forEach((zoomKey) => {
    zoomProperties[zoomKey] = zoomProperties[zoomKey].replace('--', '-')
  })

  return zoomProperties
})

const zoomImgStyle = computed(() => {
  return {
    width: `${zoomImgWidth.value}px`,
    height: `${zoomImgHeight.value}px`,
    left: `-${zoomPosition.value.x}px`,
    top: `-${zoomPosition.value.y}px`
  }
})

const handleOver = () => {
  clearTimeout(zoomOutTimeoutId.value)
  calcZoomSize()
  if (props.delayIn === 0) {
    zoomShow.value = true
  } else {
    zoomInTimeoutId.value = setTimeout(() => {
      zoomShow.value = true
    }, props.delayIn)
  }
}

const calcZoomSize = () => {
  imgRect.value = img.value && img.value.getBoundingClientRect()
  maskRect.value = mask.value && mask.value.getBoundingClientRect()
  if (imgRect.value && maskRect.value) {
    zoomLeft.value = imgRect.value.width + 10
    zoomTop.value = imgRect.value.height + 10
    zoomImgWidth.value = imgRect.value.width * props.scale
    zoomImgHeight.value = imgRect.value.height * props.scale
  }
}

const handleMove = (e) => {
  if (!imgRect.value || !maskRect.value) {
    return
  }
  maskX.value = outXCheck(e.clientX - imgRect.value.left)
  maskY.value = outYCheck(e.clientY - imgRect.value.top)
  zoomPosition.value.x = maskX.value * (zoomImgWidth.value / imgRect.value.width)
  zoomPosition.value.y = maskY.value * (zoomImgHeight.value / imgRect.value.height)
}

const handleOut = () => {
  clearTimeout(zoomInTimeoutId.value)
  if (props.delayOut === 0) {
    zoomShow.value = false
  } else {
    zoomOutTimeoutId.value = setTimeout(() => {
      zoomShow.value = false
    }, props.delayOut)
  }
}

const outXCheck = (x) => {
  x = x - maskRect.value.width / 2
  if (x < 0) {
    return 0
  }
  if (x + maskRect.value.width > imgRect.value.width) {
    return imgRect.value.width - maskRect.value.width
  }
  return x
}

const outYCheck = (y) => {
  y = y - maskRect.value.height / 2
  if (y < 0) {
    return 0
  }
  if (y + maskRect.value.height > imgRect.value.height) {
    return imgRect.value.height - maskRect.value.height
  }
  return y
}
</script>

<style module lang="scss">
@import './ImageMagnifier.scss';
</style>
