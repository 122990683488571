<template>
  <div
    :class="[{ Tabs__vertical: isVertical }, { clearfix: isVertical }, $style.container]"
    :style="{ padding: padding ? padding : '' }"
  >
    <div :class="$style.tabs">
      <a
        v-for="tab in tabCount"
        :key="tab"
        :href="navigateOnClick ? tabTitles && tabTitles[tab - 1].url : undefined"
        :class="[
          activeTab === tab ? 'Tabs__active' : '',
          disabledTabs.indexOf(tab) >= 0 ? 'Tabs__disabled' : '',
          dividerBeforeTab === tab ? 'Tabs__divider' : ''
        ]"
        @click="(event) => preventTabClick(event, tab)"
        @mouseenter="isVertical === true && disabledTabs.indexOf(tab) < 0 && (activeTab = tab)"
      >
        <slot v-if="!tabTitles" :name="`${tab}-title`" />
        <span v-if="tabTitles" v-html="filteredTitles[tab - 1]" />
      </a>
    </div>
    <div :class="$style.tabContent">
      <div v-for="tab in tabCount" :key="tab">
        <slot v-if="activeTab === tab" :name="`${tab}-content`" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue'

const props = defineProps({
  disabledTabs: {
    type: Array,
    default: () => []
  },
  isVertical: {
    type: Boolean,
    default: false
  },
  navigateOnClick: {
    type: Boolean,
    default: false
  },
  tabCount: {
    type: Number,
    default: 1
  },
  padding: {
    type: String,
    default: ''
  },
  dividerBeforeTab: {
    type: Number,
    default: null
  },
  tabTitles: {
    type: Array
  }
})

const activeTab = ref(1)
const filteredTitles = ref([])

onBeforeMount(() => {
  if (props.tabTitles && props.tabTitles.length > 0) {
    for (let subElm of props.tabTitles) {
      filteredTitles.value.push(subElm.title)
    }
  }
})

const preventTabClick = (event, tab) => {
  if (
    !props.navigateOnClick &&
    !props.isVertical &&
    props.disabledTabs.indexOf(tab) < 0 &&
    (activeTab.value = tab)
  ) {
    event.preventDefault()
  }
}
</script>

<style module lang="scss">
@import './Tabs.scss';
</style>
