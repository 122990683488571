<template>
  <div :class="[$style.container, 'column is-half-tablet is-one-third-desktop']">
    <div v-if="product?.image" :class="$style.image">
      <a v-if="product?.primaryLink" :href="product.primaryLink">
        <img :src="product.image" :alt="product?.title + ' ' + product?.type" />
      </a>
      <img v-else :src="product.image" :alt="product?.title + ' ' + product?.type" />
    </div>
    <div :class="$style.content">
      <a v-if="product?.primaryLink" :href="product?.primaryLink">
        <span v-if="product?.title" :class="$style.markup5">{{ product.title }}</span>
        <span v-if="product?.additionalText">{{ product.additionalText }}</span>
        <p v-if="product?.type" :class="$style.typ">{{ productTypeRef() }}</p>
      </a>
      <span v-else>
        <span v-if="product?.title" :class="$style.markup5">{{ product.title }}</span>
        <span v-if="product?.additionalText">{{ product.additionalText }}</span>
        <p v-if="product?.type" :class="$style.typ">{{ productTypeRef() }}</p>
      </span>
    </div>
    <div :class="$style.productProperties">
      <div :class="$style.productPropertiesLeft">
        <span v-if="product?.price" :class="$style.shopPrice">
          <strong>
            {{ texts?.from }}
            <IndividualPrice
              :value="Number(product.price)"
              :product-type="product?.type"
              :quantity="Number(1)"
              :btype-list="product?.btypeList"
            />
          </strong>
          <span>{{ plusVatRef + ' & ' }}</span>
          <a v-if="shippingURLRef" :href="shippingURLRef">{{ shippingRef }}</a>
        </span>
      </div>
      <div :class="$style.productPropertiesRight">
        <a :class="!product.primaryLink && $style.isDisabled" :href="product?.primaryLink">
          <span>{{ texts?.toProduct }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const props = defineProps({
  texts: {
    type: Object,
    default: () => {}
  },
  product: {
    type: Object,
    default: () => {}
  }
})

const plusVatRef = computed(() => window?.dictionary?.plusVat)
const shippingURLRef = computed(() => window?.shippingURL)
const shippingRef = computed(() => window?.dictionary.shipping)
const typeRef = computed(() => window?.dictionary.productType)
const typePluralRef = computed(() => window?.dictionary.productTypes)

const productTypeRef = () => {
  const typeRaw = JSON.parse(props.product.type)

  if (typeRaw.length < 2) {
    return `${typeRef.value} ${typeRaw}`
  } else {
    return `${typePluralRef.value} ${typeRaw.join(', ')}`
  }
}
</script>

<style module lang="scss">
@import './ShopProductTeaser.scss';
</style>
