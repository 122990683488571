<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <h1 class="SubPage__title">Anfahrt</h1>
          <GoogleMaps :config="mapsConfig[0]" />
        </div>
        <SocialSharing :social-sharing-links="socialSharingLinks" />
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <div class="columns">
        <div class="column is-three-quarters">
          <hr />
        </div>
        <div class="column is-one-quarter SubPage__sideBar">
          <div class="column" />
        </div>
      </div>
    </div>
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
    <Footer />
  </div>
</template>

<script setup>
import PageHeader from '../PageHeader/PageHeader.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import Footer from '../Footer/Footer.vue'
import SocialSharing from '../SocialSharing/SocialSharing.vue'
import GoogleMaps from '../GoogleMaps/GoogleMaps.vue'

const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  },
  button: {
    text: 'Zustimmen',
    colour: 'blue'
  }
}

const mapsConfig = [
  {
    logo: 'https://de-de.wika.de/templates/images/gmap_wikalogo.gif',
    marker: 'https://de-de.wika.de/templates/images/mapmarker_blue.png',
    company: 'WIKA Alexander Wiegand SE & Co. KG',
    addInfo: '',
    street: 'Alexander-Wiegand-Straße 30',
    zip: '63911',
    city: 'Klingenberg/Main',
    country: 'Germany',
    language: 'de',
    ccTLD: '.de',
    directionsText: 'Zum Routenplaner',
    latitude: '49.782327',
    longitude: '9.169936'
  },
  {
    logo: 'https://de-de.wika.de/templates/images/gmap_wikalogo.gif',
    marker: 'https://de-de.wika.de/templates/images/mapmarker_blue.png',
    company: 'WIKA Instruments Limited',
    addInfo: '4 Gatton Park Business Centre',
    street: 'Wells Place',
    zip: 'RH1 3LG',
    city: 'Merstham, Redhill',
    country: 'United Kingdom',
    language: 'en',
    ccTLD: '.co.uk',
    directionsText: 'Get directions',
    latitude: '51.25742',
    longitude: '-0.155348'
  }
]

const socialSharingLinks = [
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-facebook-f',
    color: '#3c5598'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-twitter',
    color: '#1da1f2'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-xing',
    color: '#007e7e'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-linkedin-in',
    color: '#0072b1'
  },
  {
    url: 'mailto:?subject=Ihr angeforderter Link von der WIKA-Internetseite&body=Guten Tag,%0D%0A%0D%0ASie haben auf unserer Internetseite einen Link angefordert:%0D%0A%0D%0Ahttps://de-de.wika.de/products_de_de.WIKA%0D%0A%0D%0AVielen Dank für Ihr Interesse.%0D%0AIhr WIKA-Team',
    title: 'Seite zusenden',
    newWindow: 'true',
    icon: 'fas fa-envelope',
    color: '#3480cf'
  }
]
</script>
