<template>
  <div :class="$style.container">
    <div
      :class="[
        $style.card,
        { FlipCard__flipped: flipped === true },
        { FlipCard__orange: config.colour === 'orange' },
        { FlipCard__blue: config.colour === 'blue' },
        { FlipCard__lightBlue: config.colour === 'lightBlue' },
        { FlipCard__darkGrey: config.colour === 'darkGrey' },
        { FlipCard__grey: config.colour === 'grey' },
        { FlipCard__green: config.colour === 'green' }
      ]"
      @mouseenter="flipped = true"
      @mouseleave="flipped = false"
      @touchstart="flipped = !flipped"
    >
      <div :class="$style.content">
        <div :class="$style.front">
          <img v-if="config.image" :src="config.image" :alt="config.title" />
          <i v-if="config.icon" :class="config.icon" />
          <h4>{{ config.title }}</h4>
        </div>
        <div :class="$style.back">
          <p>{{ config.title }}</p>
          <div v-if="config.text" v-html="config.text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const flipped = ref(false)
</script>

<style module lang="scss">
@import './FlipCard.scss';
</style>
