import { createApp } from 'vue'

export default function init() {
  const element = document.getElementById('socialSharing')

  if (element) {
    const app = createApp({
      methods: {
        addCover(e) {
          e.currentTarget.classList.add('noHover')
        },
        removeCover(e) {
          e.currentTarget.classList.remove('noHover')
        }
      }
    })

    app.mount('#socialSharing')
  }
}
