<template>
  <div :class="$style.container">
    <div :class="$style.navMeta">
      <div class="columns is-gapless is-mobile is-vcentered">
        <div :class="[$style.homeTextLink, 'column is-one-quarter-tablet is-one-fifth-desktop']">
          <a href="#">WIKA Online-Shop</a>
        </div>
        <div class="column is-three-quarters-tablet is-four-fifths-desktop">
          <div class="columns is-gapless is-mobile is-vcentered">
            <div
              :class="[
                $style.switcher,
                'column is-one-third-mobile is-one-third-tablet is-one-quarter-desktop'
              ]"
            >
              <div :class="$style.countrySwitcher">
                <ShopCountrySwitcher :config="shopCountrySwitcherConfig" />
              </div>
            </div>
            <div :class="[$style.search, 'column is-one-quarter-desktop']">
              <div :class="$style.desktopSearch">
                <ElasticSearch :config="searchConfig" />
              </div>
            </div>
            <div
              :class="[
                $style.loginLink,
                'column is-one-third-mobile is-one-third-tablet is-one-quarter-desktop'
              ]"
            >
              <ShopLogin :config="loginConfig" />
            </div>
            <div
              :class="[
                $style.cartLink,
                'column is-one-third-mobile is-one-third-tablet is-one-quarter-desktop'
              ]"
            >
              <ShopCart :config="cartConfig" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[{ ShopPageHeader__sticky: sticky }, $style.navMain]">
      <div class="columns is-mobile is-multiline is-vcentered">
        <div :class="[$style.homeLink, 'column']">
          <a title="WIKA" href="#">
            <img src="../../assets/logo_wika.svg" alt="WIKA" />
          </a>
        </div>
        <div :class="[$style.desktopNav, 'column']">
          <MainMenu :is-shop="true" :primary-nav="primaryNav" :secondary-nav="secondaryNav" />
        </div>
        <div :class="[$style.mobileSearch, 'column']">
          <ElasticSearch :config="searchConfig" />
        </div>
        <div :class="[$style.mobileNav, 'column']">
          <MobileMenu :is-shop="true" :primary-nav="primaryNav" :secondary-nav="secondaryNav" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ShopCountrySwitcher from '../ShopCountrySwitcher/ShopCountrySwitcher.vue'
import MainMenu from '../MainMenu/MainMenu.vue'
import MobileMenu from '../MobileMenu/MobileMenu.vue'
import ElasticSearch from '../ElasticSearch/ElasticSearch.vue'
import ShopCart from '../ShopCart/ShopCart.vue'
import ShopLogin from '../ShopLogin/ShopLogin.vue'
import { onBeforeMount, onUnmounted, ref } from 'vue'

const {
  shopCountrySwitcherConfig,
  searchConfig,
  loginConfig,
  cartConfig,
  primaryNav,
  secondaryNav
} = defineProps({
  shopCountrySwitcherConfig: {
    type: Object,
    default: () => ({})
  },
  searchConfig: {
    type: Object,
    default: () => ({})
  },
  loginConfig: {
    type: Object,
    default: () => ({})
  },
  cartConfig: {
    type: Object,
    default: () => ({})
  },
  primaryNav: {
    type: Array,
    default: () => []
  },
  secondaryNav: {
    type: Array,
    default: () => []
  }
})

const sticky = ref(false)
const stickyPosition = 40

const initScroll = () => {
  window.addEventListener('scroll', handleScroll)
}
const handleScroll = () => {
  sticky.value = window.scrollY > stickyPosition.value
}

onBeforeMount(() => {
  initScroll()
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style module lang="scss">
@import './ShopPageHeader.scss';
</style>
