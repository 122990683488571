const mobileWidth = 780

export const checkIsMobile = () => {
  let containerWidth = window.self.innerWidth

  if (containerWidth === 0) {
    if (window.top.de_espirit_firstspirit_webedit_Main?.()) {
      containerWidth = window.top.innerWidth - 40 - 13 // minus content creator sidebar and scrollbar
    } else {
      containerWidth = window.top.innerWidth
    }
  }

  return containerWidth < mobileWidth
}
