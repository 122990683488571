<!-- eslint-disable-next-line vue/valid-template-root -->
<template />
<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  // eslint-disable-next-line no-undef
  var markInstance = new Mark(document.querySelector('.MicrositeHR__container'))
  const queries = window.location.search.substring(1).split('&')
  let queryKeywordString = null
  if (queries) {
    for (const query of queries) {
      if (query.includes('highlightedText')) {
        queryKeywordString = query.split('=')[1]

        markInstance.unmark({
          done: function () {
            markInstance.mark(queryKeywordString, {
              className: 'SearchHighlighter__highlightKeyword',
              done: function (count) {
                if (count > 0) {
                  let keywordResult = document.getElementsByTagName('mark')[0]
                  keywordResult.scrollIntoView({ behavior: 'smooth', block: 'center' })
                }
              }
            })
          }
        })
      }
    }
  }
})
</script>

<style module lang="scss">
@import './SearchHighlighter.scss';
</style>
