<template>
  <div :class="$style.container">
    <div
      v-for="accordionItem in itemCollection"
      :key="getIdentifier(accordionItem)"
      :class="$style.accordionItem"
      :id="`${itemIdPrefix ?? ''}${accordionItem.id}`"
    >
      <a
        @click.prevent="
          multipleActive
            ? toggleMultipleMode(getIdentifier(accordionItem))
            : toggleSingleMode(getIdentifier(accordionItem))
        "
        :class="[
          $style.title,
          {
            [$style.active]: openItems.includes(getIdentifier(accordionItem))
          }
        ]"
        v-html="accordionItem.title || accordionItem.question"
      />
      <transition
        name="accordionToggle"
        appear
        @before-appear="closeAccordion"
        @appear="appear"
        @before-enter="closeAccordion"
        @enter="openAccordion"
        @before-leave="openAccordion"
        @leave="closeAccordion"
      >
        <div
          v-show="openItems.includes(getIdentifier(accordionItem))"
          :class="$style.content"
          v-html="accordionItem.content || accordionItem.answer"
        />
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
type AccordionItem = {
  id?: number
  title: string
  question: string
  content: string
  answer: string
}

type Props = {
  initialOpenAccordionItems: Array<number | string>
  itemCollection: AccordionItem[]
  multipleActive: boolean
  itemIdPrefix?: string
}
</script>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'

const props = withDefaults(defineProps<Props>(), {
  itemCollection: () => [],
  initialOpenAccordionItems: () => [],
  multipleActive: false
})

const CONTENT_PADDING = 15
const openItems = ref([...props.initialOpenAccordionItems])

const reset = () => {
  openItems.value = []
}

defineExpose({ reset })

const getIdentifier = (accordionItem: AccordionItem) =>
  accordionItem.id ? accordionItem.id : `accordion_${JSON.stringify(accordionItem)}`

const toggleSingleMode = (identifier: number | string) => {
  openItems.value = openItems.value.includes(identifier) ? [] : [identifier]
}

const toggleMultipleMode = (identifier: number | string) => {
  if (openItems.value.includes(identifier)) {
    openItems.value = openItems.value.filter((item) => item !== identifier)
  } else {
    openItems.value.push(identifier)
  }
}

const setElementHeight = (el: Element, height: number) => {
  ;(el as HTMLElement).style.height = `${height}px`
}

const openAccordion = (el: Element) => setElementHeight(el, el.scrollHeight + CONTENT_PADDING)

const closeAccordion = (el: Element) => setElementHeight(el, 0)

const appear = (el: Element) => {
  const timeOut = setTimeout(() => {
    openAccordion(el)
    clearTimeout(timeOut)
  }, 1000)
}

onBeforeMount(() => {
  if (props.initialOpenAccordionItems.length) {
    openItems.value = [...props.initialOpenAccordionItems]
  }
})
</script>

<style module lang="scss">
@import './Accordion.scss';
</style>
