<template>
  <div :class="[$style.contentGrid, 'SubPage__contentGrid']">
    <ElasticSearch :config="config" :model="elasticSearchModel" @submitted="startNewSearch" />
    <p v-if="hint !== hintEnum.EMPTY" :class="$style.info">
      <em class="hlt1">{{ config.textPrefix }}: </em>
      <span v-html="computedHint" />
    </p>
    <p v-if="!!articleNumberSearchResult.articleNumber" :class="$style.info">
      <em class="hlt1">{{ config.hintPrefix }}: </em>
      <span>{{
        `"${articleNumberSearchResult.articleNumber}" ${config.hintArticle} "${articleNumberSearchResult.productType}"`
      }}</span>
    </p>
    <p v-if="!!articleNumberSearchResult.productType" :class="$style.info">
      <em class="hlt1">{{ config.hintPrefix }}: </em>
      <span v-html="config.hintArticleInShop" />
    </p>
    <p v-if="totalHits > 0" :class="$style.info" v-html="resultsText" />
    <div class="columns">
      <div class="column is-one-quarter">
        <ResultFilter
          :range-size="rangeSize"
          :filterResetText="config.filterResetText"
          :filters="filters"
          :terms="terms"
          :results="results"
          @filtered="displayResults"
        />
      </div>
      <div class="column is-three-quarters">
        <div class="columns is-multiline">
          <ResultTeaserArticle
            v-if="!!articleNumberSearchResult.articleNumber"
            :article-number="articleNumberSearchResult.articleNumber"
            :result="displayedResults"
            :bg-img-url="config.bgImgUrl"
            class="column is-full"
          />
          <div v-for="(result, index) in displayedResults" :key="index" class="column is-full">
            <ResultTeaser
              :key="`resultTeaserKey_${JSON.stringify(result)}`"
              :result="result"
              :web-base-url="config.webBaseUrl"
              :shop-base-url="config.shopBaseUrl"
              :textfield-of-activity="config.textfieldOfActivity"
              :text-location="config.textLocation"
              :text-country="config.textCountry"
              :text-target-group="config.textTargetGroup"
              :text-employment="config.textEmployment"
              :search-microsite="config.searchMicrosite"
              :terms="terms"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ElasticSearch from '../ElasticSearch/ElasticSearch.vue'
import ResultTeaser from '../ResultTeaser/ResultTeaser.vue'
import ResultTeaserArticle from '../ResultTeaserArticle/ResultTeaserArticle.vue'
import ResultFilter from '../ResultFilter/ResultFilter.vue'
import * as Storage from '../../shared/Storage'
import getSearchResultFromUrl from '../../xhr/search/getSearchResultFromUrl'
import VueSecureHTML from 'vue-html-secure'

import { computed, onMounted, ref } from 'vue'

const PATTERN_ARTICLE_NUMBER = '^[0-9]{6,8}$'
const hintEnum = {
  MIN_LENGTH: 'MIN_LENGTH',
  NO_RESULT: 'NO_RESULT',
  NONE: 'NONE',
  EMPTY: 'EMPTY'
}

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  rangeSize: {
    type: Number,
    default: 25
  }
})

const terms = ref([])
const displayedResults = ref([])
const totalHits = ref(0)
const results = ref([])
const filters = ref([])
const failedSearchRequests = ref({
  terms: null
})
const range = ref({
  from: 0
})
const loading = ref(false)
const useCategorySearch = ref(false)
const articleNumberSearchResult = ref({
  articleNumber: null,
  productType: null
})
const memorizedLabels = ref({}) // in case of scolling result has no labels, so we need this cache
const elasticSearchModel = ref([])
const hint = ref(hintEnum.EMPTY)

const searchContainer = window.searchContainer
const disableExternalDocuments = window.disableExternalDocuments

const resultsText = computed(() => {
  return props.config.resultsText.replace(/{{result}}/g, `<strong>${totalHits.value}</strong>`)
})

const computedHint = computed(() => {
  return hint.value === hintEnum.MIN_LENGTH
    ? props.config.textMinLength
    : hint.value === hintEnum.NO_RESULT
      ? props.config.textNoResult.replace(
          /{{searchPhrase}}/g,
          `<strong>${VueSecureHTML.escapeHTML(terms.value.join(' '))}</strong>`
        )
      : ''
})

const checkFailedSearches = (onMount) => {
  let storedFailedSearchRequests = Storage.getLocalStorage('failedSearchRequests', true)
  if (storedFailedSearchRequests && storedFailedSearchRequests.terms) {
    if (storedFailedSearchRequests.terms.length > 1 && !onMount && props.config.emptySearchUrl) {
      window.setTimeout(() => {
        window.location = props.config.emptySearchUrl
      }, 200)
    } else {
      failedSearchRequests.value.terms = storedFailedSearchRequests.terms
    }
  }
}

const getPotentialArticleNumber = () => {
  const articleNumberRegEx = new RegExp(PATTERN_ARTICLE_NUMBER, 'gm')
  return terms.value.find((term) => !!articleNumberRegEx.exec(term))
}

const initSearch = () => {
  const queries = window.location.search.substring(1).split('&')
  let queryString = []
  let decodedTerms = []
  if (queries) {
    for (const query of queries) {
      if (query.includes('wika-search')) {
        queryString = query.split('=')[1]
        terms.value = queryString.split('+')
        for (const term of terms.value) {
          decodedTerms.push(decodeURI(term))
        }

        elasticSearchModel.value = decodedTerms

        if (decodedTerms.length === 1 && decodedTerms[0].length < 2) {
          hint.value = hintEnum.MIN_LENGTH
          return
        }

        getSearchResults(elasticSearchModel.value)
      }
    }
  }
}

const initScroll = () => {
  const distanceToStartLoad = 300
  window.onscroll = () => {
    let bottomOfWindow =
      document.documentElement.clientHeight +
        document.documentElement.scrollTop +
        distanceToStartLoad >=
      document.documentElement.scrollHeight

    if (bottomOfWindow && !loading.value && !useCategorySearch.value) {
      range.value.from = range.value.from + props.rangeSize
      getSearchResults(terms.value, true)
    }
  }
}

const processSearch = (data) => {
  totalHits.value = data.totalhits
  if (window.dataLayer) {
    trackSearchResult()
  }

  if (data.totalhits === 0) {
    hint.value = hintEnum.NO_RESULT

    if (failedSearchRequests.value.terms) {
      failedSearchRequests.value.terms.push(terms.value)
    } else {
      failedSearchRequests.value.terms = [terms.value]
    }
    Storage.setLocalStorage('failedSearchRequests', failedSearchRequests.value, true)
    checkFailedSearches()
  } else {
    hint.value = hintEnum.EMPTY
  }

  displayedResults.value = []
  setDisplayResults(data.categoryhits)
  setFilters(data.categoryhits)

  results.value = displayedResults.value
  loading.value = false
  initScroll()
}

const trackSearchResult = () => {
  const params = {
    'WT.oss': terms.value.join(' '),
    'WT.oss_r': totalHits.value.toString()
  }

  window.wtTrackPage(params)
}

const updateSearch = (data) => {
  setDisplayResults(data.categoryhits)
  setFilters(data.categoryhits)
  results.value = displayedResults.value
  loading.value = false
}

const setDisplayResults = (categoryhits) => {
  for (const category of categoryhits) {
    displayedResults.value.push(...category.result.hits.hits)

    if (getPotentialArticleNumber() && category.name === 'products') {
      setArticleNumberSearchResult([...category.result.hits.hits])
    }
  }
  displayedResults.value.sort((a, b) => b._score - a._score)
}

const setFilters = (categoryhits) => {
  filters.value = []

  for (const category of categoryhits) {
    if (category.label) {
      memorizedLabels.value[category.name] = category.label
    }

    filters.value.push({
      name: category.name,
      label: memorizedLabels.value[category.name] || category.name,
      hits: category.result.hits.total.value
    })
  }
}

const emptyResultList = () => {
  terms.value = []
  displayedResults.value = []
  totalHits.value = 0
  results.value = []
  filters.value = []
  useCategorySearch.value = false
  range.value.from = 0
}

const startNewSearch = (newTerms) => {
  if (newTerms.length === 1 && newTerms[0].length < 2) {
    emptyResultList()
    hint.value = hintEnum.MIN_LENGTH
    return
  }
  range.value.from = 0
  useCategorySearch.value = false
  articleNumberSearchResult.value = {
    articleNumber: null,
    productType: null
  }
  getSearchResults(newTerms)
}

const getSearchResults = (newTerms, onScroll) => {
  loading.value = true
  terms.value = newTerms

  getSearchResultFromUrl(window.combinedSearchUrl, {
    word: newTerms,
    from: range.value.from,
    size: props.rangeSize,
    container: searchContainer,
    disableExternalDocuments
  })
    .then((responseData) => {
      if (onScroll) {
        updateSearch(responseData)
      } else {
        processSearch(responseData)
      }
    })
    .catch((error) => console.error(error))
}

const displayResults = (newDisplayedResults) => {
  results.value.length === newDisplayedResults.length
    ? ((useCategorySearch.value = false), initScroll())
    : (useCategorySearch.value = true)
  displayedResults.value = newDisplayedResults
}

const setArticleNumberSearchResult = (products) => {
  for (const product of products) {
    if (product._source.standardarticle) {
      articleNumberSearchResult.value = {
        articleNumber: getPotentialArticleNumber(),
        productType: product._source.producttype
      }
      break
    }
  }
}

onMounted(() => {
  checkFailedSearches(true)
  initSearch()
})
</script>

<style module lang="scss">
@import './ResultList.scss';
</style>
