<template>
  <div>
    <div v-if="!stateRef.contentLoaded" class="preloadFilter">
      <Loading />
    </div>
    <form v-if="stateRef.contentLoaded && !config.worldwide && stateRef.data.zip">
      <div :class="[$style.filter, 'columns is-gapless', { ['is-mobile']: stateRef.isMobile }]">
        <div class="column">
          <FormInput
            v-model="stateRef.zipValue"
            :error-message="stateRef.data.zip.errorMessage"
            :error="!isZip && stateRef.zipValue !== null && stateRef.zipValue !== ''"
            :placeholder="stateRef.data.zip.placeholderText"
            aria-label="enter zip"
            @input="zipActive = false"
          />
        </div>
        <div class="column">
          <ButtonVue
            colour="blue"
            type="submit"
            :is-form="true"
            :class="$style.searchBtn"
            @close="handleSubmit"
          />
        </div>
      </div>
    </form>
    <div
      v-if="stateRef.contentLoaded && !config.worldwide && stateRef.data.zip && zipActive"
      :class="$style.zipResultText"
      v-html="
        stateRef.data.zip.resultMessage.replace(/\{.*?\}/g, `<strong>${stateRef.zipValue}</strong>`)
      "
    />
    <div
      v-if="
        stateRef.contentLoaded &&
        !config.worldwide &&
        stateRef.data.group &&
        stateRef.data.group.useFilter
      "
      :class="[$style.filter, $style.groupFilter]"
    >
      <FormSelect
        v-model="stateRef.filter.initialSelectOption"
        :has-filter="false"
        :colour="stateRef.filter.colour"
        :options="stateRef.selectOptions"
        :initial-option="stateRef.filter.initialSelectOption"
        @update="updateFilter"
      />
    </div>
    <div v-if="stateRef.activeTab === tab && stateRef.data.zip">
      <div v-for="(range, ind) in stateRef.content" :key="ind">
        <div
          v-if="
            zipActive &&
            isZip &&
            stateRef.zipValue >= range.from &&
            stateRef.zipValue <= range.to &&
            range.departments &&
            range.departments.length === 0
          "
          :class="['columns', 'is-multiline', { ['is-mobile']: stateRef.isMobile }]"
        >
          <div class="column is-full">
            <br />
          </div>
          <template v-for="(contact, index) in range.contacts" :key="index">
            <div
              :class="[
                { [$style.mobileColumn]: stateRef.isMobile },
                'column',
                { ['is-half-mobile']: stateRef.isMobile },
                { ['is-one-third-tablet']: !stateRef.isMobile },
                { ['is-one-quarter-desktop']: !stateRef.isMobile }
              ]"
            >
              <ContactBox
                :id="contact.id"
                :image="contact.image"
                :firstname="contact.firstname"
                :lastname="contact.lastname"
                :position="contact.position"
                :company="contact.company"
                :address="contact.address"
                :phone="contact.phone"
                :mobile="contact.mobile"
                :fax="contact.fax"
                :email="contact.email"
                :form-email="contact.formEmail"
                :website="contact.website"
                :website_text="contact.website_text"
                :vcard="contact.vcard"
                :use-form="config.useForm"
              />
            </div>
          </template>
        </div>
        <div
          v-if="
            zipActive &&
            isZip &&
            stateRef.zipValue >= range.from &&
            stateRef.zipValue <= range.to &&
            range.departments &&
            range.departments.length !== 0
          "
          :class="['columns', 'is-multiline', { ['is-mobile']: stateRef.isMobile }]"
        >
          <h2 :class="[$style.subHeadline, 'column is-full']">
            <hr />
            {{ range.departments[0] }}
          </h2>
          <template v-for="(contact, index) in range.contacts">
            <div
              v-if="contact.departments[0] === range.departments[0]"
              :key="index"
              :class="[
                { [$style.mobileColumn]: stateRef.isMobile },
                'column',
                { ['is-half-mobile']: stateRef.isMobile },
                { ['is-one-third-tablet']: !stateRef.isMobile },
                { ['is-one-quarter-desktop']: !stateRef.isMobile }
              ]"
            >
              <ContactBox
                :id="contact.id"
                :image="contact.image"
                :firstname="contact.firstname"
                :lastname="contact.lastname"
                :position="contact.position"
                :company="contact.company"
                :address="contact.address"
                :phone="contact.phone"
                :mobile="contact.mobile"
                :fax="contact.fax"
                :email="contact.email"
                :form-email="contact.formEmail"
                :website="contact.website"
                :website_text="contact.website_text"
                :vcard="contact.vcard"
                :use-form="config.useForm"
              />
            </div>
          </template>
        </div>
        <div
          v-if="
            zipActive &&
            isZip &&
            stateRef.zipValue >= range.from &&
            stateRef.zipValue <= range.to &&
            range.departments &&
            range.departments.length !== 0
          "
          :class="['columns', 'is-multiline', { ['is-mobile']: stateRef.isMobile }]"
        >
          <h2 :class="[$style.subHeadline, 'column is-full']">
            <hr />
            {{ range.departments[1] }}
          </h2>
          <template v-for="(contact, index) in range.contacts">
            <div
              v-if="contact.departments[0] === range.departments[1]"
              :key="index"
              :class="[
                { [$style.mobileColumn]: stateRef.isMobile },
                'column',
                { ['is-half-mobile']: stateRef.isMobile },
                { ['is-one-third-tablet']: !stateRef.isMobile },
                { ['is-one-quarter-desktop']: !stateRef.isMobile }
              ]"
            >
              <ContactBox
                :id="contact.id"
                :image="contact.image"
                :firstname="contact.firstname"
                :lastname="contact.lastname"
                :position="contact.position"
                :company="contact.company"
                :address="contact.address"
                :phone="contact.phone"
                :mobile="contact.mobile"
                :fax="contact.fax"
                :email="contact.email"
                :form-email="contact.formEmail"
                :website="contact.website"
                :website_text="contact.website_text"
                :vcard="contact.vcard"
                :use-form="config.useForm"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="stateRef.activeTab === tab && stateRef.data.group && stateRef.data.group.useFilter">
      <div
        v-for="(group, ind) in stateRef.content"
        :key="ind"
        :ref="(el) => (formRefs[ind] = [group.name, el])"
        :class="[$style.group, 'columns is-multiline', { ['is-mobile']: stateRef.isMobile }]"
      >
        <template v-if="group.contacts && group.contacts.length > 0">
          <div
            v-for="(contact, contactIndex) in group.contacts"
            :key="`${ind}-${contactIndex}`"
            :class="[
              { [$style.mobileColumn]: stateRef.isMobile },
              'column',
              { ['is-half-mobile']: stateRef.isMobile },
              { ['is-one-third-tablet']: !stateRef.isMobile },
              { ['is-one-quarter-desktop']: !stateRef.isMobile }
            ]"
          >
            <ContactBox
              :id="contact.id"
              :image="contact.image"
              :firstname="contact.firstname"
              :lastname="contact.lastname"
              :position="contact.position"
              :company="contact.company"
              :address="contact.address"
              :phone="contact.phone"
              :mobile="contact.mobile"
              :fax="contact.fax"
              :email="contact.email"
              :form-email="contact.formEmail"
              :website="contact.website"
              :website_text="contact.website_text"
              :vcard="contact.vcard"
              :use-form="config.useForm"
            />
          </div>
        </template>
        <template v-for="(department, departmentIndex) in group.departments">
          <h2
            v-if="stateRef.data.group.splitByGroups"
            :key="`${ind}-${departmentIndex}`"
            :class="[$style.subHeadline, 'column is-full']"
          >
            <hr />
            {{ department.name }}
          </h2>
          <div
            v-for="(contact, contactIndex) in department.contacts"
            :key="`${ind}-${departmentIndex}-${contactIndex}`"
            :class="[
              { [$style.mobileColumn]: stateRef.isMobile },
              'column',
              { ['is-half-mobile']: stateRef.isMobile },
              { ['is-one-third-tablet']: !stateRef.isMobile },
              { ['is-one-quarter-desktop']: !stateRef.isMobile }
            ]"
          >
            <ContactBox
              :id="contact.id"
              :image="contact.image"
              :firstname="contact.firstname"
              :lastname="contact.lastname"
              :position="contact.position"
              :company="contact.company"
              :address="contact.address"
              :phone="contact.phone"
              :mobile="contact.mobile"
              :fax="contact.fax"
              :email="contact.email"
              :form-email="contact.formEmail"
              :website="contact.website"
              :website_text="contact.website_text"
              :vcard="contact.vcard"
              :use-form="config.useForm"
            />
          </div>
        </template>
      </div>
    </div>
    <div v-if="stateRef.activeTab === tab && stateRef.data.group && !stateRef.data.group.useFilter">
      <div
        :class="[
          $style.group,
          $style.active,
          'columns is-multiline',
          { ['is-mobile']: stateRef.isMobile }
        ]"
      >
        <template v-for="(group, ind) in stateRef.content">
          <h2
            v-if="stateRef.data.group.splitByGroups"
            :key="ind"
            :class="[$style.subHeadline, 'column is-full']"
          >
            <hr />
            {{ group.name }}
          </h2>
          <div
            v-for="(contact, index) in group.contacts"
            :key="`${ind}-${index}`"
            :class="[
              { [$style.mobileColumn]: stateRef.isMobile },
              'column',
              { ['is-half-mobile']: stateRef.isMobile },
              { ['is-one-third-tablet']: !stateRef.isMobile },
              { ['is-one-quarter-desktop']: !stateRef.isMobile }
            ]"
          >
            <ContactBox
              :id="contact.id"
              :image="contact.image"
              :firstname="contact.firstname"
              :lastname="contact.lastname"
              :position="contact.position"
              :company="contact.company"
              :address="contact.address"
              :phone="contact.phone"
              :mobile="contact.mobile"
              :fax="contact.fax"
              :email="contact.email"
              :form-email="contact.formEmail"
              :website="contact.website"
              :website_text="contact.website_text"
              :vcard="contact.vcard"
              :use-form="config.useForm"
            />
          </div>
        </template>
      </div>
    </div>
    <div
      v-if="stateRef.activeTab === tab && stateRef.data.contact"
      :class="['columns is-multiline', { ['is-mobile']: stateRef.isMobile }]"
    >
      <div
        v-for="(contact, index) in stateRef.content"
        :key="index"
        :class="[
          { [$style.mobileColumn]: stateRef.isMobile },
          'column',
          { ['is-half-mobile']: stateRef.isMobile },
          { ['is-one-third-tablet']: !stateRef.isMobile },
          { ['is-one-quarter-desktop']: !stateRef.isMobile }
        ]"
      >
        <ContactBox
          :id="contact.id"
          :image="contact.image"
          :firstname="contact.firstname"
          :lastname="contact.lastname"
          :position="contact.position"
          :company="contact.company"
          :address="contact.address"
          :phone="contact.phone"
          :mobile="contact.mobile"
          :fax="contact.fax"
          :email="contact.email"
          :form-email="contact.formEmail"
          :website="contact.website"
          :website_text="contact.website_text"
          :vcard="contact.vcard"
          :use-form="config.useForm"
        />
      </div>
    </div>
    <div v-if="stateRef.activeTab === tab && config.worldwide">
      <WorldWideList :config="worldwideConfig" :use-form="config.useForm" />
    </div>
  </div>
</template>

<script setup>
import FormSelect from '../FormSelect/FormSelect.vue'
import FormInput from '../FormInput/FormInput.vue'
import ButtonVue from '../Button/Button.vue'
import ContactBox from '../ContactBox/ContactBox.vue'
import WorldWideList from '../WorldWideList/WorldWideList.vue'
import Loading from '../Loading/Loading.vue'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  state: {
    type: Object,
    default: () => {}
  },
  worldwideConfig: {
    type: Object,
    default: () => {}
  },
  tab: {
    type: Number,
    default: 0
  }
})

const stateRef = ref(props.state)

watch(
  () => props.state,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      stateRef.value = newValue
    }
  }
)

const zipActive = ref(false)
const isZip = ref(false)
const wtTrack = window.wtTrack
const formRefs = ref([])

const handleSubmit = () => {
  wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: stateRef.value.zipValue })
  isZip.value = new RegExp(stateRef.value.data.zip.regex).test(stateRef.value.zipValue)
  zipActive.value = isZip.value || false
}

const updateFilter = (values) => {
  const [value] = values

  wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: value })

  for (let ref in formRefs.value) {
    const [refValue, element] = formRefs.value[ref]

    if (!element) {
      return
    }

    const { classList } = element

    if (value === refValue) {
      classList.remove('ContactList__inactive')
      classList.add('ContactList__active')
    } else {
      classList.remove('ContactList__active')
      classList.add('ContactList__inactive')
    }
  }
}

onMounted(() => {
  if (stateRef.value.data.zip && stateRef.value.zipValue) {
    handleSubmit()
  }
})
</script>

<style module lang="scss">
@import './ContactList.scss';
</style>
