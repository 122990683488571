<template>
  <figure :class="$style.container">
    <img v-if="downloadOptions[0].image" :src="downloadOptions[0].image" alt="" />
    <figcaption>
      <div :class="$style.title">
        <a
          v-if="downloadOptions[0].categoryLink"
          :href="downloadOptions[0].categoryLink"
          v-html="downloadOptions[0].title"
        />
        <span v-else v-html="downloadOptions[0].title" />
      </div>
      <ul :class="$style.downloadList">
        <li v-for="(elm, index) in downloadOptions[0].downloadList" :key="index">
          <a
            v-if="elementLinks(elm).length > 1"
            :id="'downloadLinks-' + index"
            :href="elementLinks(elm)[0]"
            target="_blank"
          >
            <span :class="$style.triangle" />
            <span v-html="elm.title" />
          </a>
          <a v-else :href="elementLinks(elm)[0]" target="_blank">
            <span :class="$style.triangle" />
            <span v-html="elm.title" />
          </a>
          <tippy
            v-if="elementLinks(elm).length > 1"
            :content-class="$style.downloadLinks"
            :interactive="true"
            :to="'#downloadLinks-' + index"
            :trigger="isMobileDevice ? 'click' : 'mouseenter focus'"
            :z-index="10000"
            content-tag="ul"
            placement="top"
            theme="wika light tooltip"
          >
            <template #content>
              <li v-if="elm.pdf && !downloadOptions[0].noWishList">
                <WishListLink
                  :config="{
                    text: labels.wishlist,
                    item: {
                      downloads: [{ url: elm.pdf }],
                      title: elm.title,
                      section: downloadOptions[0].title
                    }
                  }"
                />
              </li>
              <li v-if="elm.pdf">
                <a
                  :href="elm.pdf"
                  target="_blank"
                  @click="
                    elm.wtParams &&
                      wtTrack(
                        { cat: elm.wtParams.doctype },
                        {
                          ...elm.wtParams,
                          description: elm.wtParams.description || elm.title,
                          filename: elm.pdf
                        }
                      )
                  "
                >
                  <i class="fas fa-file-pdf" />{{ labels.pdf }}
                </a>
              </li>
              <li
                v-if="elm.ebook"
                @click="
                  elm.wtParams &&
                    wtTrack(
                      { cat: 'E_BOOK' },
                      {
                        ...elm.wtParams,
                        doctype: 'E_BOOK',
                        description: elm.wtParams.description || elm.title,
                        filename: elm.ebook
                      }
                    )
                "
              >
                <a :href="elm.ebook" target="_blank">
                  <i class="fas fa-book" />{{ labels.ebook }}
                </a>
              </li>
              <li v-if="elm.docx">
                <a
                  :href="elm.docx"
                  target="_blank"
                  @click="
                    elm.wtParams &&
                      wtTrackingCategories.NEWS.indexOf(elm.wtParams.doctype) !== -1 &&
                      wtTrack(
                        { cat: elm.wtParams.doctype },
                        {
                          ...elm.wtParams,
                          filename: elm.docx,
                          type: 'DOCX'
                        }
                      )
                  "
                >
                  <i class="fas fa-file-word" />{{ labels.docx }}
                </a>
              </li>
              <li v-if="elm.doc">
                <a
                  :href="elm.doc"
                  target="_blank"
                  @click="
                    elm.wtParams &&
                      wtTrackingCategories.NEWS.indexOf(elm.wtParams.doctype) !== -1 &&
                      wtTrack(
                        { cat: elm.wtParams.doctype },
                        {
                          ...elm.wtParams,
                          filename: elm.doc,
                          type: 'DOC'
                        }
                      )
                  "
                >
                  <i class="fas fa-file-word" />{{ labels.doc }}
                </a>
              </li>
              <li v-if="elm.jpg">
                <a
                  :href="elm.jpg"
                  target="_blank"
                  @click="
                    elm.wtParams &&
                      wtTrackingCategories.NEWS.indexOf(elm.wtParams.doctype) !== -1 &&
                      wtTrack(
                        { cat: elm.wtParams.doctype },
                        {
                          ...elm.wtParams,
                          filename: elm.jpg,
                          type: 'Image'
                        }
                      )
                  "
                >
                  <i class="fas fa-file-image" />{{ labels.jpg }}
                </a>
              </li>
            </template>
          </tippy>
        </li>
      </ul>
    </figcaption>
  </figure>
</template>

<script setup>
import { isMobile } from 'mobile-device-detect'
import WishListLink from '../WishListLink/WishListLink.vue'
import { ref } from 'vue'

const props = defineProps({
  downloadOptions: {
    type: Array,
    default: () => []
  },
  labels: {
    type: Object,
    default: () => {}
  }
})

const isMobileDevice = ref(isMobile)
const wtTrack = window.wtTrack
const wtTrackingCategories = window.wtTrackingCategories

const elementLinks = (elm) => {
  return [
    elm.pdf,
    elm.docx,
    elm.doc,
    elm.jpg,
    elm.ebook,
    elm.pdf && !props.downloadOptions[0].noWishList
  ].filter(Boolean)
}
</script>

<style module lang="scss">
@import './DownloadBox.scss';
</style>
