<template>
  <div :class="$style.container" typeof="schema:ExhibitionEvent">
    <GeoIPOverlay
      :config="{
        websiteCountry: 'DE',
        title: 'Sind Sie hier richtig?',
        text: 'Wir bieten eine lokale Webseite für',
        countriesJSON: baseUrl + '/de-de/laender_json.json',
        changeButtonText: 'Jetzt wechseln',
        stayButtonText: 'Hier bleiben'
      }"
    />
    <PageHeader />
    <div :class="[$style.contentGrid, $style.firstGrid]">
      <BreadCrumbs :crumbs="crumbs" />
      <div :class="[$style.header, 'columns is-mobile']">
        <div class="column is-three-quarters-tablet">
          <!-- with optional 75/25 up tablet -->
          <h1 :class="$style.title">
            Ein super langer Seitentitel mit einer zweiten Zeile, der bei 75% umbricht
          </h1>
        </div>
        <SocialSharing :social-sharing-links="socialSharingLinks" />
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <TextTeaser
            head="Hochwertige Digitalmanometer von WIKA"
            text="WIKA Präzisions-Digitalmanometer wie beispielsweise der Typ CPG1500 eignen sich sowohl
              für die stationäre als auch die mobile Messung und Anzeige von Drücken. Ein weiterer
              Vorteil ist, dass Digitalmanometer zudem als Druckreferenz verwendet werden können.
              Dies ermöglicht das einfache Überprüfen, Einstellen und Kalibrieren von anderen
              Druckmesseinrichtungen direkt vor Ort. Durch leistungsfähige Messzellen wird eine hohe
              Genauigkeit von 0,05 % FS erreicht. Neben zahlreichen Konfigurationsmöglichkeiten stehen
              Ihnen in unserem Online-Shop ergänzend komplette Service-Kits, bestehend aus
              Digitalmanometer, Handprüfpumpe und verschiedensten Anschlussadaptern mit geringen
              Lieferzeiten zur Verfügung. WIKA Präzisions-Digitalmanometer wie beispielsweise der Typ
              CPG1500 eignen sich sowohl für die stationäre als auch die mobile Messung und Anzeige von
              Drücken. Ein weiterer Vorteil ist, dass Digitalmanometer zudem als Druckreferenz
              verwendet werden können."
            :links="[
              'Schutzrohrberechnung',
              'Bauweise von Schutzrohren in Sonderlänge',
              'Fragebogen für Schutzrohre'
            ]"
            :is-static="false"
          />
        </div>
        <div class="column column-equal-height">
          <figure class="ContentOverviewTeaser__container">
            <a
              class="ContentOverviewTeaser__mediaLink"
              href="https://shop.wika.com/de-de/manometer.WIKA?_ga=2.212259220.1810900541.1624458659-1532738384.1620159766"
              target="_blank"
            >
              <img
                src="https://www.wika.com/media/10__images/contentteaser/service_1/ct/calibrationservices_rs_w738_h415_image.jpg"
                alt="Manometer kaufen?"
                width="738"
                height="415"
              />
            </a>
            <figcaption>
              <a href="https://shop.wika.com/de-de/manometer.WIKA" target="_blank">
                <h3>Manometer kaufen?</h3>
                <div>
                  Bestellen Sie Ihre Standard-Geräte einfach in unserem Online-Shop - in gewohnter
                  WIKA-Qualität.
                </div>
              </a>
            </figcaption>
          </figure>
        </div>
        <div class="column column-equal-height">
          <SalesContainer :config="salesContainerConfig" />
        </div>
      </div>
    </div>
    <div :class="$style.contentGrid">
      <div class="columns">
        <div :class="['column is-three-quarters', $style.contentArea]">
          <NaviSelect colour="blue" style="width: 300px; margin-bottom: 15px" />
          <NaviSelectCascading :config="naviConfig" />
          <div id="detailSection" style="display: none">
            <ul>
              <li>Unordered List</li>
              <li>Für gasförmige, flüssige und aggressive Messstoffe</li>
              <li>Pumpenüberwachung und -steuerung</li>
              <li>Filterüberwachung</li>
              <li>Füllstandsmessung an geschlossenen Behältern</li>
            </ul>
            <h1>H1 Element</h1>
            <h2>H2 Element</h2>
            <h3>H3 Element</h3>
            <h4>H4 Element</h4>
            <h5>H5 Element</h5>
            <h6>H6 Element</h6>
            <p>
              Paragraph mit Line Breaks und Links<br /><br />Lorem ipsum dolor
              <a href="#" target="_blank">Extern link</a> sit amet, consectetur adipiscing elit,
              <a href="#" target="_self">Intern link</a> sed do eiusmod tempor incididunt ut labore
              et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
              laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
              mollit anim id est laborum.
            </p>
          </div>
          <div id="faqSection" style="display: none">
            <h2 style="margin-bottom: 20px; border: 5px solid red; padding: 10px">
              Content before FAQList
            </h2>
            <h2 style="margin-bottom: 20px; border: 5px solid green; padding: 10px">
              More content before FAQList
            </h2>
            <div class="FAQList__container">FAQList placeholder</div>
            <h2
              style="margin-bottom: 20px; margin-top: 20px; border: 5px solid blue; padding: 10px"
            >
              Content after FAQList
            </h2>
          </div>
          <div id="downloadSection" style="display: none">
            <div class="Wysiwyg__container">
              <h1 class="Wysiwyg__h1">H1 Element</h1>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <h2 class="Wysiwyg__h2">H2 Element</h2>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <h3 class="Wysiwyg__h3">H3 Element</h3>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <h4 class="Wysiwyg__h4">H4 Element</h4>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <h5 class="Wysiwyg__h5">H5 Element</h5>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <h6 class="Wysiwyg__h6">H6 Element</h6>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, accusantium
                laboriosam ut numquam quis rerum nam tempora maiores perspiciatis natus commodi est
                ipsam. Debitis provident nemo delectus ipsa totam minima.
              </p>
              <ul>
                <li>Unordered List</li>
                <li>Für gasförmige, flüssige, verunreinigte, viskose und aggressive Messstoffe</li>
                <li>Pumpenüberwachung und -steuerung</li>
                <li>Filterüberwachung</li>
                <li>Füllstandsmessung an geschlossenen Behältern</li>
              </ul>
              <hr />
              <ol>
                <li>Ordered List</li>
                <li>Für gasförmige, flüssige, verunreinigte, viskose und aggressive Messstoffe</li>
                <li>Pumpenüberwachung und -steuerung</li>
                <li>Filterüberwachung</li>
                <li>Füllstandsmessung an geschlossenen Behältern</li>
              </ol>
              <hr />
              <table>
                <thead>
                  <th colspan="4">Table</th>
                </thead>
                <tbody>
                  <tr>
                    <td>Cell 1</td>
                    <td>Cell 2</td>
                    <td>Cell 3</td>
                    <td>Cell 4</td>
                  </tr>
                  <tr>
                    <td>Cell 5</td>
                    <td>Cell 6</td>
                    <td>Cell 7</td>
                    <td>Cell 8</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <ContentTabs :config="tabsConfig" />
          <div class="columns is-multiline">
            <div class="column is-half-tablet">
              <TextImageElement
                link-title="Portabler Prozesskalibrator"
                image="https://picsum.photos/300/169?random=1"
              >
                <template #default>
                  Der portable Prozesskalibrator CPH7000 von WIKA hat die ATEX-Zulassung erhalten.
                </template>
              </TextImageElement>
            </div>
            <div class="column is-half-tablet">
              <TextImageElement
                :image-right="true"
                link-title="Portabler Prozesskalibrator"
                image="https://picsum.photos/300/169?random=2"
              >
                <template #default>
                  Der portable Prozesskalibrator CPH7000 von WIKA hat die ATEX-Zulassung erhalten.
                </template>
              </TextImageElement>
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=3"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=4"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=5"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=6"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=7"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=8"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=9"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=10"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=11"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=12"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=13"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=14"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=15"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=16"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=17"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=18"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=19"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=20"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
            <div class="column is-half-tablet is-one-third-desktop">
              <ContentOverviewTeaser
                image="https://picsum.photos/220/121?random=21"
                title="Kalibrierservice"
                copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
                primary-link="#"
              />
            </div>
          </div>
        </div>
        <div :class="['column is-one-quarter', $style.sideBar]">
          <div class="column">
            <SidebarShopTeaser />
          </div>
          <div class="column">
            <ContentBox
              image="https://picsum.photos/300/160"
              icon="https://picsum.photos/20/20"
              title="Umwelt-/Energiepolitik"
              text="Was versteht WIKA unter dem Umweltschutz? Erfahren Sie mehr."
              :link-list="[
                {
                  title: 'Zum Anfrageformular',
                  url: '#'
                },
                {
                  title: 'Zum Warenrücksendeformular',
                  url: '#'
                }
              ]"
            />
          </div>
          <div class="column">
            <DownloadBox :download-options="downloadOptions" :labels="labels" />
          </div>
          <div class="column">
            <ContactBox
              head="Ihr Kontakt"
              name="Max Mustermann"
              phone="+49 1234567890"
              email="max@mustermann.net"
              vcard="#"
            />
          </div>
          <div class="column">
            <ContactBox
              image="https://picsum.photos/110/180"
              head="Ihr Kontakt"
              description="Ihre Beschreibung"
              position="HR Manager"
              street="Musterstraße 12"
              city="123456 Musterstadt"
              name="Max Mustermann"
              phone="+49 1234567890"
              fax="+49 9123456780"
              mobile="+49 8123456790"
              website="http://mustermann.net"
              email="max@mustermann.net"
              vcard="#"
              additional-text="Ihr weiterer Text"
            />
          </div>
          <div class="column">
            <CloudWordBox :cloud-options="cloudOptions" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
  </div>
</template>

<script setup>
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import CloudWordBox from '../CloudWordBox/CloudWordBox.vue'
import DownloadBox from '../DownloadBox/DownloadBox.vue'
import Footer from '../Footer/Footer.vue'
import PageHeader from '../PageHeader/PageHeader.vue'
import ContentOverviewTeaser from '../ContentOverviewTeaser/ContentOverviewTeaser.vue'
import TextTeaser from '../TextTeaser/TextTeaser.vue'
import ContactBox from '../ContactBox/ContactBox.vue'
import ContentBox from '../ContentBox/ContentBox.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import SidebarShopTeaser from '../SidebarShopTeaser/SidebarShopTeaser.vue'
import SocialSharing from '../SocialSharing/SocialSharing.vue'
import TextImageElement from '../TextImageElement/TextImageElement.vue'
import ContentTabs from '../ContentTabs/ContentTabs.vue'
import NaviSelectCascading from '../NaviSelectCascading/NaviSelectCascading.vue'
import NaviSelect from '../NaviSelect/NaviSelect.vue'
import GeoIPOverlay from '../GeoIPOverlay/GeoIPOverlay.vue'
import SalesContainer from '../SalesContainer/SalesContainer.vue'

const baseUrl = window.baseUrl

const salesContainerConfig = {
  title: 'Vertriebs&shy;kontakt',
  icon: 'fas fa-user-tie',
  input: {
    placeholderText: 'Postleitzahl eingeben',
    submitUrl: 'contact_de_at.WIKA?cnType=1&cnEntry='
  },
  buttons: [
    {
      icon: 'fas fa-comments',
      text: 'Jetzt chatten',
      offlineText: 'Nachricht senden'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Kontakt&shy;formular',
      linkUrl: '#'
    }
  ]
}

const naviConfig = {
  colour: 'blue',
  cascading: true,
  selectedEntry: '2345',
  selectedChild: null,
  defaultLabel: 'Bitte wählen...',
  entries: [
    {
      id: '1234',
      label: 'Navi 1',
      url: '',
      children: [
        {
          id: 'asd',
          label: 'Child 1-1',
          url: '#'
        },
        {
          id: 'sdf',
          label: 'Child 1-2',
          url: '#'
        },
        {
          id: 'egb',
          label: 'Child 1-3',
          url: '#'
        }
      ]
    },
    {
      id: '2345',
      label: 'Navi 2 <sub>3</sub>',
      url: '',
      children: [
        {
          id: 'trzj',
          label: 'Child <sub>2-1</sub>',
          url: '#'
        },
        {
          id: 'fsgn',
          label: 'Child 2-2',
          url: ''
        }
      ]
    },
    {
      id: '3456',
      label: 'Navi <sup>3</sup>',
      url: '',
      children: [
        {
          id: 'dnsfgn',
          label: 'Child 3-1',
          url: '#'
        },
        {
          id: 'qegeg',
          label: 'Child 3-2',
          url: '#'
        },
        {
          id: 'egntn',
          label: 'Child 3-3',
          url: '#'
        },
        {
          id: 'dfngn',
          label: 'Child 3-3',
          url: '#'
        }
      ]
    },
    {
      id: '4567',
      label: 'Navi 4 - direkter Link',
      url: '#',
      children: []
    }
  ]
}

const cloudOptions = [
  {
    title: 'Weitere Themen',
    links: [
      ['Kraftsensor', 3, 'https://google.com'],
      ['tecsis', 4, 'https://google.com'],
      ['Kraftmesstechnik', 7, 'https://google.com'],
      ['Wägetechnik', 1, 'https://google.com'],
      ['Kraftmessgerät', 3, 'https://google.com'],
      ['Druckprüfung', 6, 'https://google.com'],
      ['Druckanzeige', 3, 'https://google.com'],
      ['Füllstandsüberwachung', 2, 'https://google.com'],
      ['Differenzdruck', 1, 'https://google.com'],
      ['Drucksensor', 5, 'https://google.com'],
      ['Pt100', 4, 'https://google.com'],
      ['Kalibrierlabor', 2, 'https://google.com']
    ]
  }
]

const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  },
  button: {
    text: 'Zustimmen',
    colour: 'blue'
  }
}

const crumbs = [
  {
    title: 'Startseite',
    url: '#'
  },
  {
    title: 'Produkte',
    url: '#'
  },
  {
    title: 'Manometer',
    url: '#'
  }
]

const labels = {
  pdf: 'PDF',
  ebook: 'E-Book',
  wishlist: 'Merkzettel'
}

const downloadOptions = [
  {
    image: '//placehold.it/250x134&text=Placeholder',
    title: 'Broschüren und Flyer',
    categoryLink: 'http://www.google.com',
    downloadList: [
      {
        title: 'Sterile Verfahrenstechnik',
        ebook: 'https://de-de.wika.de/apps/ebooks/brochures/BR_Chemical_dede/index.html',
        pdf: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
      },
      {
        title: 'EPC Contractor Business',
        ebook: 'https://de-de.wika.de/apps/ebooks/brochures/BR_Chemical_dede/index.html',
        pdf: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
      }
    ]
  }
]

const socialSharingLinks = [
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-facebook-f',
    color: '#3c5598'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-twitter',
    color: '#1da1f2'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-xing',
    color: '#007e7e'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-linkedin-in',
    color: '#0072b1'
  },
  {
    url: 'mailto:?subject=Ihr angeforderter Link von der WIKA-Internetseite&body=Guten Tag,%0D%0A%0D%0ASie haben auf unserer Internetseite einen Link angefordert:%0D%0A%0D%0Ahttps://de-de.wika.de/products_de_de.WIKA%0D%0A%0D%0AVielen Dank für Ihr Interesse.%0D%0AIhr WIKA-Team',
    title: 'Seite zusenden',
    newWindow: 'true',
    icon: 'fas fa-envelope',
    color: '#3480cf'
  }
]

const tabsConfig = [
  {
    title: 'Übersicht',
    id: 'detailSection'
  },
  /* {
          title: "FAQs",
          id: "faqSection",
          FAQListConfig: {
            listTitle: "FAQs",
            hasSearch: true,
            texts: {
              searchPlaceholder: "Suchen...",
              resultText: "Anzahl der gefundenen Einträge:",
              resetText: "Zurücksetzen",
              helpText: "Bitte wählen Sie eine oder mehrerer Filteroptionen.",
              pdf: "PDF",
              ebook: "E-Book",
              wishlist: "Merkzettel",
              validto: "Gültig bis",
              productDetail: "Details",
              productDatasheet: "Datenblatt"
            },
            resetSelectOption: "Alle",
            loadInitialData: true,
            url: window.baseUrl + "/de-de/faqs-json.json?_c=null",
            withExternalData: false,
            mappingUrl: "",
            searchService: window.baseUrl + "/de-de/rest-services/faq/search",
            filters: [
              {
                hasFilter: false,
                key: "measured_range",
                label: "Messgröße",
                sort: "asc",
                initialSelectOption: "Bitte wählen..."
              },
              {
                hasFilter: false,
                key: "productline",
                label: "Produktlinie",
                sort: "asc",
                initialSelectOption: "Bitte wählen..."
              },
              {
                hasFilter: false,
                key: "branch",
                label: "Branche",
                sort: "asc",
                initialSelectOption: "Bitte wählen..."
              },
              {
                hasFilter: false,
                key: "type",
                label: "Typ",
                sort: "asc",
                initialSelectOption: "Bitte wählen..."
              }
            ],
            pagination: {
              texts: {
                page: "Seite",
                of: "von"
              },
              resultsPerPage: 12,
              resultsSelect: {
                initialSelectOption: "12 Einträge pro Seite",
                selectOptions: [
                  "12 Einträge pro Seite",
                  "24 Einträge pro Seite",
                  "Alle"
                ]
              }
            }
          }
        }, */
  {
    title: 'Downloads',
    id: 'downloadSection'
  },
  /* {
          title: "Produkte",
          url: window.baseUrl + "/iframe/standard-artikel.html"
        }, */
  {
    title: 'Messen',
    disabled: true
  }
]
</script>

<style module lang="scss">
@import './SubPage.scss';
</style>
