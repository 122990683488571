const Viewports = {
  SMALL: '768px',
  SMALL_MAX: '767px',
  MEDIUM: '1024px',
  MEDIUM_MAX: '1023px',
  LARGE: '1216px',
  LARGE_MAX: '1215px'
}

export default Viewports
