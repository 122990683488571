const getValueByParameterCaseInsensitive = (object, key) => {
  return object[
    Object.keys(object).filter(function (k) {
      return k.toLowerCase() === key.toLowerCase()
    })[0]
  ]
}

const replaceNewLine = (value) => value.replace(/(?:\r\n|\r|\n)/g, '<br />')

const withTrailingSlash = (url) => url.replace(/\/?$/, '/')

export { getValueByParameterCaseInsensitive, replaceNewLine, withTrailingSlash }

// polyfill for IE11
export function endsWith(str, suffix) {
  return str.indexOf(suffix, str.length - suffix.length) !== -1
}

// filter link attributes
export function linkAttributes(json) {
  const contentKey = 'content'
  if (contentKey in json) {
    const j = { ...json }
    delete j[contentKey]
    return j
  }
  return json
}
