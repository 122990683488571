<template>
  <label
    :class="[$style.item, { [$style.itemActive]: active }, { [$style.itemDisabled]: disabled }]"
  >
    <input
      type="checkbox"
      :class="$style.input"
      :checked="active"
      :disabled="disabled"
      @click="emit('change', !active)"
    />
    <slot />
  </label>
</template>

<script setup>
const emit = defineEmits(['change'])

defineProps({
  active: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  }
})
</script>

<style module lang="scss">
@import './PanelGroupCheckbox';
</style>
