export const ContactFormEnum = {
  contactEmail: 'contactHash',
  contactTitle: 'ContactFormTitle',
  contactBack: 'ContactFormBack'
}

export const prepareContactFormUrl = (contact) => {
  const { dynamicFormUrl } = window
  const params = new URLSearchParams()

  params.set(ContactFormEnum.contactEmail, contact.formEmail)
  params.set(ContactFormEnum.contactBack, true)
  params.set(
    ContactFormEnum.contactTitle,
    contact.company ? contact.company : `${contact.firstname} ${contact.lastname}`
  )

  const contactFormUrl = `${dynamicFormUrl}${
    dynamicFormUrl.indexOf('?') !== -1 ? '&' : '?'
  }${params}`

  return contactFormUrl
}
