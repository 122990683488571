import { DOWNLOADMATRIX_LANGUAGES } from '../globals/constants/download'

export const sortCollectionInGivenArray = (array, direction = 'asc', key = '') => {
  return array.sort((a, b) => {
    let nameA = key ? a[key] : a
    let nameB = key ? b[key] : b

    nameA = typeof nameA === 'string' ? nameA.toLowerCase() : nameA
    nameB = typeof nameB === 'string' ? nameB.toLowerCase() : nameB

    if (nameA < nameB) {
      return direction === 'desc' ? 1 : -1
    }

    if (nameA > nameB) {
      return direction === 'desc' ? -1 : 1
    }

    return 0
  })
}

export const sortInGivenArray = (array, direction = 'asc') => {
  return array.sort((a, b) => {
    let nameA = a
    let nameB = b

    nameA = typeof nameA === 'string' ? nameA.toLowerCase() : nameA
    nameB = typeof nameB === 'string' ? nameB.toLowerCase() : nameB

    if (nameA < nameB) {
      return direction === 'desc' ? 1 : -1
    }

    if (nameA > nameB) {
      return direction === 'desc' ? -1 : 1
    }

    return 0
  })
}

export const sortMonths = (array, direction = 'asc') => {
  return array.sort((a, b) => {
    let nameA = a
    let nameB = b

    nameA = typeof nameA === 'string' ? nameA.toLowerCase() : nameA
    nameB = typeof nameB === 'string' ? nameB.toLowerCase() : nameB

    if (nameA < nameB) {
      return direction === 'desc' ? 1 : -1
    }

    if (nameA > nameB) {
      return direction === 'desc' ? -1 : 1
    }

    return 0
  })
}

export const sortCollectionAccordingDOWNLOADMATRIX_LANGUAGES = (
  array,
  key = '',
  direction = 'asc'
) => {
  return array.sort((a, b) => {
    let nameA = key ? a[key] : a
    let nameB = key ? b[key] : b

    const indexA = DOWNLOADMATRIX_LANGUAGES.indexOf(nameA.toUpperCase())
    const indexB = DOWNLOADMATRIX_LANGUAGES.indexOf(nameB.toUpperCase())

    if (indexA < indexB) {
      return direction === 'desc' ? 1 : -1
    }

    if (indexA > indexB) {
      return direction === 'desc' ? -1 : 1
    }

    return 0
  })
}
