<template>
  <figure :class="$style.container">
    <div v-if="image" :class="$style.imageContainer">
      <img :src="image" :alt="`${firstname} ${lastname}`" />
    </div>
    <figcaption>
      <section v-if="head || name || (firstname && lastname) || description || position">
        <div v-if="head" :class="$style.title">
          {{ head }}
        </div>
        <div v-if="firstname && lastname" :class="$style.title">{{ firstname }} {{ lastname }}</div>
        <div v-if="name">
          {{ name }}
        </div>
        <div v-if="description">
          {{ description }}
        </div>
        <div v-if="position" v-html="position" />
      </section>
      <section v-if="street || city || company || address">
        <div v-if="company" :class="$style.title">
          {{ company }}
        </div>
        <div v-if="address" v-html="`${address.replace(/(?:\r\n|\r|\n)/g, '<br>')}`" />
        <div v-if="street">
          {{ street }}
        </div>
        <div v-if="city">
          {{ city }}
        </div>
      </section>
      <section v-if="phone || mobile || fax || website || email || vcard || useForm">
        <a v-if="phone" :href="`tel:${phone}`"> <i class="fas fa-phone-alt" />{{ phone }} </a>
        <a v-if="mobile" :href="`tel:${mobile}`"> <i class="fas fa-mobile-alt" />{{ mobile }} </a>
        <a v-if="fax"> <i class="fas fa-fax" />{{ fax }} </a>
        <a v-if="website" :href="website" target="_blank">
          <i class="fas fa-globe-americas" />{{
            website_text.trim() !== '' ? website_text : website
          }}
        </a>
        <a v-if="email" :href="`mailto:${email}`"> <i class="fas fa-envelope" />{{ email }} </a>
        <a v-if="vcard" :href="vcard" download> <i class="fas fa-id-card" />vCard </a>
        <a v-if="useForm && formEmail" :href="computedFormUrl">
          <i class="fas fa-inbox" /> {{ dynamicFormLabel }}
        </a>
      </section>
      <div v-if="additionalText">
        {{ additionalText }}
      </div>
    </figcaption>
  </figure>
</template>

<script setup>
import { prepareContactFormUrl } from '../../utils/form'

defineProps({
  id: {
    type: Number,
    default: undefined
  },
  image: {
    type: String,
    default: ''
  },
  head: {
    type: String,
    default: ''
  },
  description: {
    type: String,
    default: ''
  },
  position: {
    type: String,
    default: ''
  },
  company: {
    type: String,
    default: ''
  },
  address: {
    type: String,
    default: ''
  },
  street: {
    type: String,
    default: ''
  },
  city: {
    type: String,
    default: ''
  },
  firstname: {
    type: String,
    default: ''
  },
  lastname: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  phone: {
    type: String,
    default: ''
  },
  fax: {
    type: String,
    default: ''
  },
  mobile: {
    type: String,
    default: ''
  },
  website: {
    type: String,
    default: ''
  },
  // eslint-disable-next-line vue/prop-name-casing
  website_text: {
    type: String,
    default: ''
  },
  email: {
    type: String,
    default: ''
  },
  formEmail: {
    type: String,
    default: ''
  },
  vcard: {
    type: String,
    default: ''
  },
  additionalText: {
    type: String,
    default: ''
  },
  useForm: {
    type: Boolean,
    default: false
  }
})

const dynamicFormLabel = window.dictionary.dynamicForm

const computedFormUrl = () => {
  return prepareContactFormUrl(this)
}
</script>

<style module lang="scss">
@import './ContactBox.scss';
</style>
