<template>
  <a :class="[$style.link, { WishListLink__disabled: isDisabled }]" @click.prevent="handleClick()">
    <i class="fas fa-file-medical" />{{ config.text }}
  </a>
</template>

<script setup>
import * as Storage from '../../shared/Storage'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const isDisabled = ref(false)
const customEvent = ref(null)
const wishList = ref(null)
const badge = ref(null)
const wishListItem = ref(null)
const willUpdateBadge = ref(null)

const handleClick = () => {
  wishList.value = Storage.getLocalStorage('wishList', true)
  willUpdateBadge.value = true
  if (!isDisabled.value) {
    wishListItem.value = props.config.item
    if (wishListItem.value.flag) {
      wishListItem.value.flag = wishListItem.value.flag.replace('big', 'small')
    }
    if (wishList.value) {
      const wishListItemIdx = wishList.value.findIndex(
        (listItem) => listItem.title === wishListItem.value.title
      )

      if (wishListItemIdx > -1) {
        wishList.value[wishListItemIdx].downloads.push(...getDownloadsArray())
        willUpdateBadge.value = false
      } else {
        wishList.value.push({
          section: wishListItem.value.section,
          title: wishListItem.value.title,
          subTitle: wishListItem.value.subTitle,
          types: wishListItem.value.types,
          downloads: getDownloadsArray()
        })
      }
      Storage.setLocalStorage('wishList', wishList.value, true)
    } else {
      Storage.setLocalStorage(
        'wishList',
        [
          {
            title: wishListItem.value.title,
            subTitle: wishListItem.value.subTitle,
            section: wishListItem.value.section,
            types: wishListItem.value.types,
            downloads: getDownloadsArray()
          }
        ],
        true
      )
    }
    isDisabled.value = true
    if (willUpdateBadge.value) {
      updateBadge()
    }
    window.dispatchEvent(customEvent.value)
  }
}

const getDownloadsArray = () => {
  return wishListItem.value.downloads.map((downloadItem) => {
    return {
      url: downloadItem.url,
      flag: downloadItem.flag,
      languageCodes: Array.isArray(downloadItem.language)
        ? downloadItem.language
        : [downloadItem.language]
    }
  })
}

const checkLocalStorage = () => {
  wishList.value = Storage.getLocalStorage('wishList', true)
  if (wishList.value) {
    const wishListItemIdx = wishList.value.findIndex(
      (listItem) => listItem.title === props.config.item.title
    )
    const isInStorage =
      !!wishList.value[wishListItemIdx] &&
      !!wishList.value[wishListItemIdx].downloads?.find(
        (downloadItem) =>
          !!props.config.item.downloads &&
          !!props.config.item.downloads?.find(
            (configDownloadItem) => downloadItem.url === configDownloadItem.url
          )
      )

    isDisabled.value = wishListItemIdx > -1 && isInStorage
  }
}

const updateBadge = () => {
  if (badge.value) {
    badge.value.style.transition = 'none'
    badge.value.style.opacity = 1
  }
}

onMounted(() => {
  badge.value = document.getElementById('wishListBadge')
  customEvent.value = new CustomEvent('addToWishList')
  window.addEventListener('removeFromWishList', checkLocalStorage)
  checkLocalStorage()
})

onUnmounted(() => {
  window.removeEventListener('removeFromWishList', checkLocalStorage)
})
</script>

<style module lang="scss">
@import './WishListLink.scss';
</style>
