<template>
  <div
    v-if="!props.isLoaded"
    :class="$style.loading"
    :style="{
      fontSize: formattedSize ? `${formattedSize}px` : null,
      backgroundColor: backgroundColor,
      ['--loading-color']: color
    }"
  >
    <div :class="$style.spinner">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  size: {
    type: String,
    default: null // default is set in scss
  },
  backgroundColor: {
    type: String,
    default: null // default is set in scss
  },
  color: {
    type: String,
    default: null // default is set in scss
  },
  isLoaded: {
    type: Boolean,
    default: false
  }
})

const formattedSize = ref(Number(props.size))

watch(
  () => props.size,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      formattedSize.value = Number(props.size)
    }
  }
)
</script>

<style module lang="scss">
@import './Loading.scss';
</style>
