export const ACCEPTED_IMAGES = ['jpg', 'jpeg', 'png', 'gif']
export const ACCEPTED_FILES = ['txt', 'doc', 'rtf', 'pdf']
export const ACCEPTED_DOCS = ACCEPTED_IMAGES.concat(ACCEPTED_FILES)
export const MAX_FILES = 10
export const MAX_FILE_SIZE_MB = 5

export const attachedFile = {
  fullName: String,
  name: String,
  size: Number,
  type: String,
  fileExtension: String,
  url: String,
  isImage: Boolean,
  isUploaded: Boolean
}

export const attachmentDefinition = {
  filename: String,
  base64content: String
}

export const getFileInitialData = () => ({
  fullName: '',
  name: '',
  size: 0,
  type: '',
  data: '',
  fileExtention: '',
  url: '',
  isImage: false,
  isUploaded: false
})

export const getFileSizeMB = (file) => {
  return Math.round((file.size / 1024 / 1024) * 100) / 100
}

export const getFileExtension = (file) => {
  return file.name.split('.').pop()
}

export const isMaxFilesReached = (selectedFiles) => {
  return selectedFiles.length >= MAX_FILES
}

export const isImageFile = (file) => {
  return ACCEPTED_IMAGES.includes(getFileExtension(file).toLowerCase())
}

const isFileSizeValid = (file) => {
  return getFileSizeMB(file) < MAX_FILE_SIZE_MB
}

const isFileTypeValid = (file) => {
  return ACCEPTED_DOCS.includes(file.name.split('.').pop().toLowerCase())
}

export const isFileValid = (file) => {
  return isFileSizeValid(file) && isFileTypeValid(file)
}
