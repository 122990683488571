<template>
  <transition name="scroll">
    <div v-if="show" :class="$style.container">
      <div :class="$style.inner">
        <span v-if="config.text" :class="$style.text">{{ config.text }}</span>
        <span :class="$style.right">
          <a v-if="config.link" :class="$style.link" :href="config.link.url" target="_self">{{
            config.link.text
          }}</a>
          <Button
            v-if="config.button"
            :text="config.button.text"
            :colour="config.button.colour"
            no-border
            is-form
            @close="handleClick"
          />
        </span>
      </div>
    </div>
  </transition>
</template>

<script setup>
import Button from '../Button/Button.vue'
import * as Storage from '../../shared/Storage'
import { onMounted, ref } from 'vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const show = ref(false)
const cookieName = ref('hideCookieMessage')

onMounted(() => {
  if (Storage.getCookie(cookieName.value) === '') {
    setTimeout(() => {
      show.value = true
    }, 1200)
  }
})

const handleClick = () => {
  Storage.setCookie(cookieName.value, true, 365)
  show.value = false
}
</script>

<style module lang="scss">
@import './CookieDisclaimer.scss';
</style>
