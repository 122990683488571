<template>
  <tippy
    v-tippy="{
      theme: 'wika tooltip',
      interactive: true,
      placement: 'top',
      animation: 'fade',
      duration: [200, 600],
      animateFill: false,
      arrow: true,
      zIndex: 10000,
      trigger: isMobileDevice ? 'click' : 'mouseenter focus'
    }"
  >
    <Button
      :colour="colour"
      :href="config.urls.url"
      :is-link="config.hideTooltip ? true : false"
      :target-blank="true"
      :text="
        config.metadata.fileType && config.metadata.fileSize
          ? `${config.metadata.fileType} (${config.metadata.fileSize})`
          : config.title
      "
    />

    <template v-if="config.urls.url && !config.hideTooltip" #content>
      <ul :class="$style.downloadLinks">
        <li>
          <WishListLink
            :config="{
              text: labels.wishlist,
              item: {
                downloads: [
                  {
                    url: config.urls.url,
                    flag: config.urls.flag,
                    language: config.urls.language
                  }
                ],
                section: section,
                title: config.wishlistTitle,
                types: config.types
              }
            }"
          />
        </li>
        <li>
          <a
            :href="config.urls.url"
            target="_blank"
            @click="
              wtParams &&
                wtTrack(
                  { cat: wtParams.doctype },
                  {
                    ...wtParams,
                    filename: config.urls.url
                  }
                )
            "
          >
            <i class="fas fa-file-alt" />
            <span>{{ config.urls.url.split(/\.(?=[^\.]+$)/)[1] }}</span>
          </a>
        </li>
      </ul>
    </template>
  </tippy>
</template>

<script setup>
import Button from '../Button/Button.vue'
import { isMobile } from 'mobile-device-detect'
import WishListLink from '../WishListLink/WishListLink.vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  section: String,
  id: {
    type: String,
    default: ''
  },
  labels: {
    type: Object,
    default: () => {}
  },
  colour: {
    type: String,
    default: 'blue'
  },
  wtParams: Object
})

const isMobileDevice = isMobile
const { wtTrack } = window
</script>

<style module lang="scss">
@import './DownloadButton.scss';
</style>
