const getCMPData = () => {
  if (window.__cmp) {
    return window.__cmp('getCMPData')
  }
}

const getVendorConsent = (vendorId) => {
  const CMPData = getCMPData()

  if (!CMPData) {
    return false
  }

  if ('vendorConsents' in CMPData) {
    return !!(vendorId in CMPData.vendorConsents && CMPData.vendorConsents[vendorId])
  } else {
    return false
  }
}

// function to register consent listeners
const registerConsentListeners = (checkVendorConsent) => {
  if (window.cmpConsentType === 'consentmanager') {
    if (typeof window.__cmp === 'function') {
      window.__cmp("addEventListener", ["consentrejected", checkVendorConsent, false], null);
      window.__cmp("addEventListener", ["consentapproved", checkVendorConsent, false], null);
      window.__cmp("addEventListener", ["consentcustom", checkVendorConsent, false], null);
    }
  }
}


export { getVendorConsent, registerConsentListeners }
