<template>
  <div class="ContentTabs__container">
    <div v-if="!isMobile" class="ContentTabs__tabs">
      <a
        v-for="(tab, index) in config.length"
        :key="`${index}-aKey`"
        :class="[ 'ContentTabs__tab', { ContentTabs__active: activeTab === tab }, { ContentTabs__disabled: config[index].disabled } ]"
        @click="(event) => updateTabs(event, tab)"
      >
        {{ config[index].title }}
      </a>
      <div
        v-for="(tab, index) in config.length"
        :key="`${index}-bKey`"
        :class="['ContentTabs__content', { ContentTabs__activeContent: activeTab === tab }]"
      >
        <div v-if="data.introText" :class="$style.intro" v-html="data.introText" />
        <ContactListEntries
          v-if="activeTab === tab && !config[index].isMap && !config[index].isLocations"
          ref="contactListDesktop"
          :config="config[index]"
          :state="computedState"
          :worldwide-config="worldwideConfig"
          :tab="tab"
        />
        <MapFilterApp
          v-else-if="activeTab === tab && config[index].isMap && content !== null"
          :config="config[index]"
          :state="content.contact"
        />
        <MapLocations
          v-else-if="activeTab === tab && config[index].isLocations && content !== null"
          :config="config[index]"
          :state="content.contact"
        />
        <GoogleMaps
          v-if="activeTab === tab && config[index].isSimpleMap && config[index].mapConfig"
          :config="config[index].mapConfig"
        />
      </div>
    </div>
    <div v-if="isMobile" class="ContentTabs__tabs ContentTabs__mobile">
      <div v-for="(tab, index) in config.length" :key="`${index}-cKey`">
        <a
          v-if="!config[index].disabled"
          :class="[ 'ContentTabs__tab', { ContentTabs__active: activeTab === tab && mobileHidden !== tab } ]"
          @click="(event) => updateTabs(event, tab)"
        >
          {{ config[index].title }}
        </a>
        <div
          :class="[ 'ContentTabs__content', { ContentTabs__activeContent: activeTab === tab && !isMobile }, { ContentTabs__activeContent: activeTab === tab && isMobile && mobileHidden !== tab } ]"
        >
          <div v-if="data.introText" :class="$style.intro" v-html="data.introText" />
          <ContactListEntries
            v-if="activeTab === tab && !config[index].isMap && !config[index].isLocations"
            ref="contactListMobile"
            :config="config[index]"
            :state="computedState"
            :worldwide-config="worldwideConfig"
            :tab="tab"
          />
          <MapFilterApp
            v-else-if="activeTab === tab && config[index].isMap && content !== null"
            :config="config[index]"
            :state="content.contact"
          />
          <MapLocations
            v-else-if="activeTab === tab && config[index].isLocations && content !== null"
            :config="config[index]"
            :state="content.contact"
          />
          <GoogleMaps
            v-if="activeTab === tab && config[index].isSimpleMap && config[index].mapConfig"
            :config="config[index].mapConfig"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MapFilterApp from '../MapFilterApp/MapFilterApp.vue'
import ContactListEntries from './ContactListEntries.vue'
import MapLocations from '../MapLocations/MapLocations.vue'
import GoogleMaps from '../GoogleMaps/GoogleMaps.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { checkIsMobile } from '../../utils/media'

const props = defineProps({
  config: {
    type: Array,
    default: () => []
  }
})

const contactListDesktop = ref(null)
const contactListMobile = ref(null)
const zipValue = ref(null)
const isMobile = ref(false)
const activeTab = ref(null)
const mobileHidden = ref(null)
const content = ref(null)
const contentLoaded = ref(false)
const data = ref({})
const activeTabPreselected = ref(false)
const filter = ref({
  colour: 'blue',
  initialSelectOption: ''
})
const selectOptions = ref([])
const selectOptionsIds = ref([])
const queryStringValue = ref(null)
const queryStringValueTwo = ref(null)
const worldwideConfig = ref({})

const computedState = computed(() => {
  return {
    contentLoaded: contentLoaded.value,
    isMobile: isMobile.value,
    activeTab: activeTab.value,
    mobileHidden: mobileHidden.value,
    content: content.value,
    zipValue: zipValue.value,
    data: data.value,
    filter: filter.value,
    selectOptions: selectOptions.value
  }
})

const checkScreenSize = () => {
  isMobile.value = checkIsMobile()
}

const initTabs = () => {

  const queries = window.location.search.substring(1).split('&');
  let queryStringTabId = null;

  if (queries) {
    for (const query of queries) {
      if (query.includes('tab')) {
        queryStringTabId = parseInt(query.split('=')[1], 10);
      }
      if (query.includes('cnEntry')) {
        queryStringValue.value = decodeURI(query.split('=')[1]);
      }
      if (query.includes('country')) {
        queryStringValueTwo.value = decodeURI(query.split('=')[1]);
      }
    }
  }

  if (queryStringTabId !== null && props.config[queryStringTabId - 1] && !props.config[queryStringTabId - 1].disabled) {
    activeTab.value = queryStringTabId;
    activeTabPreselected.value = true;

    const selectedTab = props.config[queryStringTabId - 1];

    if (selectedTab.id && !selectedTab.worldwide) {
      getContentFromId(selectedTab.id);
    } else if (selectedTab.worldwide) {
      worldwideConfig.value = selectedTab.config;
      const { introText } = worldwideConfig.value;
      data.value = { introText };
      content.value = null;
      contentLoaded.value = true;
    }
    return;
  }

  for (const [index, tab] of props.config.entries()) {
    if (tab.active && !tab.disabled) {
      activeTab.value = index + 1;
      activeTabPreselected.value = true;

      if (tab.id && !tab.worldwide) {
        if (tab.initialSelectOption) {
          filter.value.initialSelectOption = tab.initialSelectOption;
        }
        getContentFromId(tab.id);
      } else if (tab.worldwide) {
        worldwideConfig.value = tab.config;
        const { introText } = worldwideConfig.value;
        data.value = { introText };
        content.value = null;
        contentLoaded.value = true;
      }
      return;
    }
  }

  if (!activeTabPreselected.value) {
    activeTab.value = 1;
    if (props.config[0].id && !props.config[0].worldwide) {
      if (props.config[0].initialSelectOption) {
        filter.value.initialSelectOption = props.config[0].initialSelectOption;
      }
      getContentFromId(props.config[0].id);
    } else if (props.config[0].worldwide) {
      worldwideConfig.value = props.config[0].config;
      const { introText } = worldwideConfig.value;
      data.value = { introText };
      content.value = null;
      contentLoaded.value = true;
    }
  }
};

const updateTabs = (event, tab) => {
  const elm = event.currentTarget
  if (!elm.classList.contains('ContentTabs__disabled')) {
    contentLoaded.value = false
    if (props.config[tab - 1].initialSelectOption) {
      selectOptions.value = []
      filter.value.initialSelectOption = props.config[tab - 1].initialSelectOption
    }
    if (props.config[tab - 1].id && !props.config[tab - 1].worldwide) {
      getContentFromId(props.config[tab - 1].id)
    } else if (props.config[tab - 1].worldwide) {
      worldwideConfig.value = props.config[tab - 1].config
      const { introText } = worldwideConfig.value
      data.value = { introText }
      content.value = null
      contentLoaded.value = true
    } else {
      content.value = null
    }
    if (isMobile.value && activeTab.value === tab && activeTab.value !== mobileHidden.value) {
      mobileHidden.value = tab
    } else if (
      isMobile.value &&
      activeTab.value === tab &&
      activeTab.value === mobileHidden.value
    ) {
      mobileHidden.value = null
    } else if (isMobile.value && activeTab.value !== tab) {
      mobileHidden.value = null
      activeTab.value = tab
    } else {
      activeTab.value = tab
    }
  }
}

const getContentFromId = (id) => {
  const elm = document.getElementById(id)
  data.value = elm ? JSON.parse(elm.dataset.config) : {}

  if (data.value.isSimpleMap) {
    content.value = null
    contentLoaded.value = true
    return
  }

  if (data.value.contact && (data.value.contact.isMap || data.value.contact.isLocations)) {
    content.value = data.value
    contentLoaded.value = true
  } else if (data.value.contact) {
    content.value = data.value.contact.contacts
    contentLoaded.value = true
  } else if (data.value.group) {
    for (const group of data.value.group.groups) {
      selectOptions.value.push(group.name)
      if (queryStringValue.value) {
        selectOptionsIds.value.push(group.id.toString())
      }
    }
    content.value = data.value.group.groups
    if (queryStringValue.value) {
      const nameIndex = selectOptionsIds.value.indexOf(queryStringValue.value)
      if (nameIndex !== -1) {
        filter.value.initialSelectOption = selectOptions.value[nameIndex]
      } else {
        filter.value.initialSelectOption = selectOptions.value[0]
      }
      contentLoaded.value = true
    } else {
      contentLoaded.value = true
    }
  } else if (data.value.zip) {
    content.value = data.value.zip.ranges
    if (queryStringValue.value) {
      zipValue.value = queryStringValue.value
    }
    contentLoaded.value = true
  } else {
    content.value = null
    contentLoaded.value = false
  }
  queryStringValue.value = null
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  checkScreenSize()
  initTabs()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style module lang="scss">
@import './ContactList.scss';
</style>
