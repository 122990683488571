<template>
  <div
    ref="refKpiBlock"
    class="columns"
    :class="[$style.container, { KPIS__blue: config.colour === 'blue' }]"
  >
    <div
      v-for="(kpi, index) in kpis"
      :key="index"
      class="column is-full-mobile"
      :class="[$style.section]"
    >
      <div :class="$style.number">
        <span ref="numberRefs" :data-target="kpi.number">0</span>
      </div>
      <div :class="$style.description">
        {{ kpi.description }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, onMounted, watch, onBeforeUnmount, reactive, computed } from 'vue'
import * as Storage from '../../shared/Storage'
import gsap from 'gsap'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const duration = ref(3)
const easing = ref('Power0.easeNone')
const kpis = ref([])
const kpiPosition = ref(null)
const refKpiBlock = ref(null)
const numberRefs = ref([])
const kpiState = reactive({
  visible: false,
  played: false
})

const getLanguageString = () => {
  if (props.config.locale) {
    return props.config.locale
  }

  const languageCookie = Storage.getCookie('cookieCountry', true)
  if (languageCookie.length) {
    return languageCookie
  }

  const languages = navigator.languages
  if (languages && languages.length > 0) {
    return languages[0]
  }

  return 'de-DE'
}

const localeString = getLanguageString()

const kpiInitialPosition = computed(() => {
  return refKpiBlock.value.getBoundingClientRect().top
})

const formatNumberToLocale = (number) => {
  const formattedNumber = Number(number).toLocaleString(localeString, {
    maximumFractionDigits: 0
  })
  return formattedNumber
}

const generateKpis = () => {
  let maxKPIElements = props.config.kpis.length < 4 ? props.config.kpis.length : 4
  do {
    const randomNum = Math.floor(Math.random() * props.config.kpis.length)
    if (!kpis.value.includes(props.config.kpis[randomNum])) {
      kpis.value.push(props.config.kpis[randomNum])
    }
  } while (kpis.value.length < maxKPIElements)
}

const runNumberAnimation = () => {
  numberRefs.value.forEach((numberObject) => {
    const value = {
      val: parseInt(numberObject.dataset.target)
    }
    gsap.from(value, {
      val: 0,
      duration: duration.value,
      ease: easing.value,
      onUpdate: function () {
        numberObject.innerText = formatNumberToLocale(value.val)
      }
    })
  })
  kpiState.played = true
}

watch(
  () => kpiState.visible,
  (newValue, oldValue) => {
    if (newValue !== oldValue && newValue && !kpiState.played) {
      return runNumberAnimation()
    }
  }
)

onBeforeMount(() => {
  generateKpis()
})

onMounted(() => {
  kpiState.visible = 0 <= kpiInitialPosition.value && window.innerHeight > kpiInitialPosition.value

  kpiState.visible && !kpiState.played && runNumberAnimation()

  document.addEventListener('scroll', () => {
    kpiPosition.value = refKpiBlock.value ? refKpiBlock.value.getBoundingClientRect().top : null
    return (kpiState.visible = 0 <= kpiPosition.value && window.innerHeight > kpiPosition.value)
  })
})

onBeforeUnmount(() => {
  document.onscroll = null
})
</script>

<style module lang="scss">
@import './KPIS.scss';
</style>
