// CSS Modules - component class name prefix: CountrySwitcher__

<template>
  <div
    v-show="!loading"
    :class="[{ CountrySwitcher__container__active: countrySwitcherActive }, $style.container]"
  >
    <tippy
      ref="tippyRef"
      :content-class="`${$style.layer} countrySwitcherPopper`"
      :interactive="true"
      :to="'#countrySwitcherTrigger'"
      :z-index="10000"
      :arrow="true"
      :on-show="
        () => {
          countrySwitcherActive = true
        }
      "
      :on-hide="
        () => {
          countrySwitcherActive = false
        }
      "
      max-width="none"
      content-tag="div"
      placement="bottom-start"
      theme="wika light tooltip"
      trigger="click"
    >
      <template #content>
        <Button :class="$style.close" is-close colour="white" @close="closeCountrySwitcher" />
        <div :class="$style.content">
          <Tabs :tab-count="2">
            <template #1-title>
              {{ config.websitesTitle }}
            </template>
            <template #1-content>
              <div :class="$style.websites">
                <div :class="[$style.websitesControls, 'columns']">
                  <div class="column">
                    <Select
                      v-if="regionSelectValue"
                      :key="`sitesKey-${componentKey}`"
                      :class="$style.regionSelect"
                      colour="blue"
                      :select-options="siteSelectOptions"
                      :select-value="regionSelectValue || siteSelectValue"
                      @on-change="updateCountryButtons"
                    />
                  </div>
                  <div
                    class="column is-offset-one-third-tablet is-offset-half-desktop is-offset-three-fifths-widescreen"
                  >
                    <Button
                      :class="$style.corporate"
                      is-link
                      colour="orange"
                      :text="config.corporateTitle"
                      :href="config.corporateUrl"
                    />
                  </div>
                </div>
                <div :class="$style.countries">
                  <div
                    v-for="(country, index) in countryButtons"
                    :key="`CountryButton${country.name}${index}`"
                  >
                    <CountryButton
                      :label="countryButtonLabel"
                      :country="country.name"
                      :flag="country.flag"
                      :languages="country.languages"
                    />
                  </div>
                </div>
              </div>
            </template>
            <template #2-title>
              {{ config.locationsTitle }}
            </template>
            <template #2-content>
              <div :class="$style.locations">
                <div :class="[$style.locationsControls, 'columns is-multiline']">
                  <div class="column is-one-third-tablet is-one-quarter-desktop">
                    <Select
                      v-if="regionSelectValue"
                      :class="$style.regionSelect"
                      colour="blue"
                      :select-options="regionSelectOptions"
                      :select-value="regionSelectValue"
                      @on-change="updateCountries"
                    />
                  </div>
                  <div class="column is-one-third-tablet is-one-quarter-desktop">
                    <Select
                      v-if="countrySelectValue"
                      :key="`countryKey-${componentKey}`"
                      :class="$style.countrySelect"
                      colour="white"
                      :select-options="countrySelectOptions"
                      :select-value="
                        [...countrySelectOptions].includes(countrySelectValue)
                          ? countrySelectValue
                          : countrySelectPlaceholderRef
                      "
                      :placeholder="countrySelectPlaceholderRef"
                      @on-change="updateContacts"
                    />
                  </div>
                  <div
                    class="column is-one-third-tablet is-one-quarter-desktop is-offset-one-quarter-desktop"
                  >
                    <Button
                      :class="$style.headQuarters"
                      is-link
                      colour="orange"
                      :text="config.headquarterTitle"
                      :href="config.corporateUrl"
                    />
                  </div>
                </div>
                <div
                  :class="[
                    $style.contacts,
                    {
                      [$style.contactsWithSpace]: contacts && contacts.length > contactsToShow
                    }
                  ]"
                >
                  <div v-if="contacts">
                    <VueAgile
                      ref="slider"
                      :dots="false"
                      :slides-to-show="1"
                      :infinite="false"
                      :nav-buttons="contacts && contacts.length > 1"
                      :responsive="[
                        {
                          breakpoint: 480,
                          settings: {
                            slidesToShow: 2,
                            navButtons: contacts && contacts.length > 2
                          }
                        },
                        {
                          breakpoint: 768,
                          settings: {
                            slidesToShow: 3,
                            navButtons: contacts && contacts.length > 3
                          }
                        },
                        {
                          breakpoint: 1024,
                          settings: {
                            slidesToShow: 4,
                            navButtons: contacts && contacts.length > 4
                          }
                        }
                      ]"
                      @breakpoint="resetSlider"
                    >
                      <div v-for="(contact, index) in contacts" :key="index">
                        <h6 v-if="contact.company">
                          {{ contact.company }}
                        </h6>
                        <address
                          v-if="contact.address"
                          v-html="
                            `${contact.address.replace(/(?:\r\n|\r|\n)/g, '<br>')}<br>${
                              contact.country
                            }`
                          "
                        />
                        <dl v-if="contact.phone || contact.fax">
                          <dt v-if="contact.phone">Tel.:</dt>
                          <dd v-if="contact.phone">
                            {{ contact.phone }}
                          </dd>
                          <br />
                          <dt v-if="contact.fax">Fax:</dt>
                          <dd v-if="contact.fax">
                            {{ contact.fax }}
                          </dd>
                        </dl>
                        <a v-if="contact.email" :href="`mailto:${contact.email}`">{{
                          contact.email
                        }}</a>
                        <br />
                        <a v-if="contact.website" target="_blank" :href="contact.website">{{
                          contact.website_text !== '' ? contact.website_text : contact.website
                        }}</a>
                      </div>
                    </VueAgile>
                  </div>
                </div>
              </div>
            </template>
          </Tabs>
        </div>
      </template>
    </tippy>
    <div :id="'countrySwitcherTrigger'" :class="$style.toggle">
      <i class="fas fa-globe-americas" />
      <span>{{ config.currentCountry }}</span>
    </div>
    <span :class="$style.caret" />
  </div>
</template>

<script setup>
import axios from 'axios'
import { VueAgile } from 'vue-agile'
import Button from '../Button/Button.vue'
import CountryButton from '../CountryButton/CountryButton.vue'
import Select from '../Select/Select.vue'
import Tabs from '../Tabs/Tabs.vue'
import { regionDataUrl } from '../../shared/globals'
import * as Storage from '../../shared/Storage'
import { onMounted, onUnmounted, ref, computed } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const loading = ref(true)
const componentKey = ref(0)
const apiData = ref(null)
const contacts = ref([])
const siteSelectOptions = ref([])
const siteSelectValue = ref('')
const countryButtons = ref([])
const regionSelectOptions = ref([])
const regionSelectValue = ref(props.config.currentRegion)
const countrySelectOptions = ref([])
const countrySelectValue = ref(props.config.currentCountry)
const countrySelectPlaceholderRef = ref('')
const countrySwitcherActive = ref(false)
const contactsToShow = ref(1)
const cookieName = ref('cookieCountry')
const slider = ref(null)
const tippyRef = ref(null)

const countryButtonLabel = computed(() => {
  return props.config.languageTitle || window.dictionary?.countrySwitcher?.language
})

const calcContactsToShow = () => {
  contactsToShow.value =
    window.innerWidth >= 1024 ? 4 : window.innerWidth >= 768 ? 3 : window.innerWidth >= 480 ? 2 : 1
}

const resetSlider = () => {
  slider.value.goTo(0)
}

const closeCountrySwitcher = () => {
  tippyRef.value.hide()
  countrySwitcherActive.value = false
}

const getContacts = (url) => {
  contacts.value = null
  axios
    .get(url)
    .then((response) => {
      contacts.value = response.data.contacts
    })
    .catch((error) => {
      console.error(error)
    })
}

const updateContacts = (value) => {
  countrySelectValue.value = value

  const region = apiData.value.regions.find((region) => region.name === regionSelectValue.value)

  if (region) {
    const country = region.countries.find((country) => country.name === value)

    if (country) {
      getContacts(country.contacts)
    }
  }
}

const initApiData = (data) => {
  apiData.value = data
  countrySelectPlaceholderRef.value = props.config.countrySelectPlaceholder
  let countrySelectOptionsRaw = []

  for (let region of apiData.value.regions) {
    siteSelectOptions.value.push(region.name)
    regionSelectOptions.value.push(region.name)

    if (region.name === props.config.currentRegion) {
      for (let country of region.countries) {
        countrySelectOptionsRaw.push(country.name)

        if (country.flag && country.languages && country.languages.length !== 0) {
          countryButtons.value.push(country)
        }

        if (country.name === props.config.currentCountry) {
          getContacts(country.contacts)
        }
      }
    }
  }
  countrySelectOptions.value = countrySelectOptionsRaw
  loading.value = false
}

const updateCountryButtons = (value) => {
  siteSelectValue.value = value
  const region = apiData.value?.regions.find((region) => region.name === value)

  if (region) {
    countryButtons.value.length = 0

    for (let country of region.countries) {
      if (country.flag && country.languages && country.languages.length !== 0) {
        countryButtons.value.push(country)
      }
    }
  }
}

const updateCountries = (value) => {
  regionSelectValue.value = value
  countrySelectOptions.value.length = 0
  contacts.value = []

  const region = apiData.value.regions.find((region) => region.name === value)

  if (region) {
    for (let country of region.countries) {
      countrySelectOptions.value.push(country.name)
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', calcContactsToShow.value)
  if (window.currentCountry) {
    Storage.setSessionCookie(cookieName.value, window.currentCountry)
  }
  calcContactsToShow()
  let dataUrl = ''
  window.regionDataUrl ? (dataUrl = window.regionDataUrl) : (dataUrl = regionDataUrl)
  axios
    .get(dataUrl)
    .then((response) => initApiData(response.data))
    .catch((error) => {
      loading.value = false
      console.error(error)
    })
})

onUnmounted(() => {
  window.removeEventListener('resize', calcContactsToShow.value)
})
</script>

<style module lang="scss">
@import './CountrySwitcher.scss';
</style>
