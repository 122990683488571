<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <h1 class="SubPage__title">Seitentitel</h1>
        </div>
        <SocialSharing :social-sharing-links="socialSharingLinks" />
      </div>
      <div class="columns is-gapless">
        <div class="column is-half">
          <TextTeaser
            head="Hochwertige Digitalmanometer von WIKA"
            text="Millionenfach bewährt sind unsere Manometer (mechanische Druckmessgeräte) für Relativdruck, Absolutdruck und Differenzdruck.
            Für die optimale Lösung verschiedenster Anwendungen stehen Messsysteme in der Rohr-, Platten- oder Kapselfeder-Technologie zur Auswahl.
            Die Manometer verfügen über Anzeigebereiche von 0 … 0,5 mbar bis 0 … 6.000 bar bei Anzeigegenauigkeiten bis zu 0,1 %.
            Für die unterschiedlichen Anforderungen in der Industrie- und Prozessinstrumentierung sind Messglieder aus."
            :links="['Dokument1', 'Dokument2', 'Dokument3']"
            :is-static="false"
          />
        </div>
        <div class="column is-half">
          <SalesContainer :config="salesContainerConfig" />
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <div class="ProductFilter__container">
        <div class="productlistselector">
          <div class="preloadFilter ProductFilter__preloadFilter" data-bind="visible: !setVisible">
            <div class="Loading__loading">
              <div class="Loading__spinner">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
          </div>
          <div
            class="columns productOverview"
            style="display: none"
            data-bind="runafter: setCategoryToggle, visible: setVisible"
          >
            <div class="column is-one-quarter productFilter">
              <div id="filterContent" class="productlistselector">
                <div class="filterSelection twoColumns" data-bind="foreach: categories">
                  <div class="panel checkboxes">
                    <h3
                      id
                      data-class="0"
                      data-toggle
                      class
                      data-bind="text: Name, attr: { id: Id }, css: { toggled: IsOpened }"
                    />
                    <div data-checkboxes class data-bind="foreach: Options">
                      <label data-bind="css: { 'checkbox-hidden': $index() >= 5 }">
                        <!-- BDKS 20170427 WEBTRENDS START (PS) -->
                        <input
                          type="checkbox"
                          onclick
                          name="filter[]"
                          value
                          data-name
                          data-bind="attr: { 'data-name': PlainName, value: Id, 'onclick': 'if(this.checked) {window.wtTrack({cat: \'PRODUCTSELECTOR\'}, {selLevel1: \'' + wtParams[0] + '\',selLevel2: \'' + wtParams[1] + '\',selItem: \'' + wtParams[2] + '\',selValue: \'' + wtParams[3] + '\'})}' }, checkedValue: $parent.Id + '|' + Id, checked: $root.selectedCategories"
                        />
                        <span>
                          <!--
                      <input type="checkbox" name="filter[]" value="" data-name="" data-bind="attr: { 'data-name': PlainName, value: Id }, checkedValue: $parent.Id + '|' + Id, checked: $root.selectedCategories"><span>
                          -->
                          <!-- BDKS 20170427 WEBTRENDS STOP -->
                          <span data-bind="html: PlainName" />
                          <span
                            class="productCountText"
                            data-bind="text: '(' + ProductCount + ')'"
                          />
                        </span>
                      </label>
                      <!-- ko if: $index() == ($parent.Options.length-1) -->
                      <a
                        class="checkbox-trigger remove"
                        href="#"
                        data-bind="click: $root.clearCategorySelection.bind($data, $parent), visible: $root.clearCategorySelectionVisible($parent)"
                        >Reset selection</a
                      >
                      <a
                        class="checkbox-trigger show"
                        href="#"
                        data-bind="visible: $parent.Options.length > 5, click: $root.ShowHideFilters.bind($data, 'show', $parent)"
                        >More</a
                      >
                      <a
                        class="checkbox-trigger hide"
                        style="display: none"
                        href="#"
                        data-bind="click: $root.ShowHideFilters.bind($data, 'hide', $parent)"
                        >Less</a
                      >
                      <!-- /ko -->
                    </div>
                  </div>
                </div>
                <div class="bottom-panel">
                  <a
                    href="#"
                    class="checkbox-trigger checkbox-trigger-global remove"
                    data-bind="visible: selectedCategories().length > 0, click: clearSelection"
                    >Reset all selections</a
                  >
                </div>
                <div class="filterControls" />
              </div>
            </div>
            <div
              id="filterResultContainer"
              class="column is-three-quarters"
              style="height: auto"
              data-bind="visible: filteredProducts().length > 0, runafter: updateCategoryProductCounts"
            >
              <div class="productResultContainer" style="display: none">
                <h2>
                  The following&nbsp;
                  <span
                    class="productCount"
                    data-bind="text: filteredProducts().length"
                  />&nbsp;products correspond to your selection:
                </h2>
                <div id="productResult" data-bind="foreach: grouped">
                  <div
                    class="columns is-mobile productRow"
                    style="display: flex"
                    data-bind="foreach: $data"
                  >
                    <div
                      class="column is-half-tablet productContainer newSelector"
                      data-bind="attr: { 'data-productId': productId }, css: { 'inactiveProduct': showAsInactive }"
                    >
                      <div class="image">
                        <a
                          data-bind="attr: { href: !showAsInactive ? productDetailUrl : 'javascript:void(0)' }, css: { 'disabledLink': showAsInactive }"
                        >
                          <span data-bind="html: newProductMarker" />
                          <span data-bind="html: specialMarker" />
                          <img
                            data-bind="attr: { alt: productImageAlt, src: 'https://www.wika.de/'+productImageUrl }"
                          />
                        </a>
                      </div>
                      <div class="content">
                        <a
                          data-bind="attr: { href: !showAsInactive ? productDetailUrl : 'javascript:void(0)' }, css: { 'disabledLink': showAsInactive }"
                        >
                          <p data-bind="text: productType" />
                          <span class="markup5" data-bind="html: productHeader" />
                          <p data-bind="html: productDescription" />
                          <span data-bind="html: SortValue" />
                        </a>
                      </div>
                      <div class="productProperties">
                        <div class="productPropertiesRight">
                          <a
                            class="button-ps blue right"
                            data-bind="attr: { href: !showAsInactive ? productDetailUrl : 'javascript:void(0)', target: productDetailUrlTarget }, css: { 'disabledLink': showAsInactive }"
                          >
                            <span data-bind="text: productDetailUrlDetails" />
                          </a>
                          <span data-bind="html: productDocuments" />
                        </div>
                      </div>
                      <!-- ko if: showAsInactive -->
                      <div class="inactiveProductOverlay" style="display: none">
                        <div class="vertical-center">
                          <strong>This product is online not available.</strong>
                          <p>For more informations contact your WIKA contact person.</p>
                        </div>
                      </div>
                      <!-- /ko -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="ProductFilter__pagination" style="display: none">
                <div class="columns is-mobile">
                  <div class="column left">
                    <div class="ProductFilter__selectWrapper">
                      <select
                        data-bind="options: availableItemsPerPage, optionsText: 'description', optionsValue: 'count', value: nbPerPage"
                      />
                    </div>
                  </div>
                  <div class="column noBorder">
                    <label class="button orange refreshPage">
                      <input id="refreshView" type="submit" name value="Aktualisieren" />
                    </label>
                  </div>
                  <div class="column right">
                    <div class="paginationContainer left">
                      <p>Page</p>
                      <div class="ProductFilter__selectWrapper">
                        <select
                          data-bind="options: pages, optionsText: 'description', optionsValue: 'count', value: pageNumber"
                        />
                      </div>
                      <p class="totalPageNr">
                        <span id="lblOf">of</span>
                        <!--ko text: totalPages() + 1-->
                        <!--/ko-->
                      </p>
                    </div>
                    <div class="noBorder left">
                      <label for="submitProductCtrl" class="button orange">
                        <input
                          id="submitProductCtrl"
                          type="submit"
                          name="submitProductCtrl"
                          value
                        />
                      </label>
                    </div>
                    <div class="paginationNav">
                      <input
                        type="submit"
                        class="prev prevJs"
                        data-bind="click: previous, css: { disabled: !hasPrevious() }"
                        aria-label="previous"
                      />
                      <input
                        type="submit"
                        class="next nextJs"
                        data-bind="click: next, css: { disabled: !hasNext() }"
                        aria-label="next"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductFilter />
      <div class="columns">
        <div class="column is-one-quarter">
          <ContentTeaserS
            :is-video="true"
            primary-link="https://www.youtube.com/embed/QbPEuTQvaiw?enablejsapi=1"
            :primary-link-new-window="false"
            secondary-link="#"
            :secondary-link-new-window="false"
            category="Video"
            link-title="Industrielle Kraftsensoren | Welche Sensortechnologien zur Kraftmessung gibt..."
            image="https://de-de.wika.de/upload/WIKA_Thumbnails/Info-Box-Single-Thumb/PIC_NE_CPH7000Ex_de_de_99747.jpg.png"
          />
        </div>
        <div class="column is-one-quarter">
          <ContentOverviewTeaser
            image="https://picsum.photos/738/410"
            title="Kalibrierservice"
            copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
            primary-link="#"
          />
        </div>
        <div class="column is-half">
          <FAQTeaser
            :faqs="faqs"
            secondary-link="#"
            :secondary-link-new-window="false"
            category="FAQ"
            image="https://picsum.photos/738/199"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-one-quarter">
          <ContentOverviewTeaser
            image="https://picsum.photos/738/410"
            title="Kalibrierservice"
            copy="Kalibrierung von verschiedensten Messgeräten in unserem Kalibrierlabor."
            primary-link="#"
          />
        </div>
        <div class="column is-three-quarters">
          <FAQTeaser
            :faqs="faqs"
            secondary-link="#"
            :secondary-link-new-window="false"
            category="FAQ"
            image="https://picsum.photos/738/199"
          />
        </div>
      </div>
    </div>
    <Footer />
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
  </div>
</template>

<script setup>
import FAQTeaser from '../FAQTeaser/FAQTeaser.vue'
import Footer from '../Footer/Footer.vue'
import PageHeader from '../PageHeader/PageHeader.vue'
import SalesContainer from '../SalesContainer/SalesContainer.vue'
import TextTeaser from '../TextTeaser/TextTeaser.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import SocialSharing from '../SocialSharing/SocialSharing.vue'
import ContentOverviewTeaser from '../ContentOverviewTeaser/ContentOverviewTeaser.vue'
import ContentTeaserS from '../ContentTeaserS/ContentTeaserS.vue'
import ProductFilter from '../ProductFilter/ProductFilter.vue'

const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  }
}

const salesContainerConfig = {
  title: 'Vertriebs&shy;kontakt',
  icon: 'fas fa-user-tie',
  input: {
    placeholderText: 'Postleitzahl eingeben',
    submitUrl: 'contact_de_at.WIKA?cnType=1&cnEntry='
  },
  buttons: [
    {
      icon: 'fas fa-comments',
      text: 'Jetzt chatten',
      offlineText: 'Nachricht senden'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Kontaktformular',
      linkUrl: '#'
    }
  ]
}

const socialSharingLinks = [
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-facebook-f',
    color: '#3c5598'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-twitter',
    color: '#1da1f2'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-xing',
    color: '#007e7e'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-linkedin-in',
    color: '#0072b1'
  },
  {
    url: 'mailto:?subject=Ihr angeforderter Link von der WIKA-Internetseite&body=Guten Tag,%0D%0A%0D%0ASie haben auf unserer Internetseite einen Link angefordert:%0D%0A%0D%0Ahttps://de-de.wika.de/products_de_de.WIKA%0D%0A%0D%0AVielen Dank für Ihr Interesse.%0D%0AIhr WIKA-Team',
    title: 'Seite zusenden',
    newWindow: 'true',
    icon: 'fas fa-envelope',
    color: '#3480cf'
  }
]

const faqs = [
  {
    primaryLink: '#',
    primaryLinkNewWindow: 'true',
    title: 'Welche Lösung gibt es bei Druckspitzen?',
    text: 'Hier empfehlen wir die Verwendung eines Prozessanschlusses mit reduzierter Kanalbohrung von z. B. 0,6 mm oder 0,3 mm Durchmesser.',
    moreLink: 'Mehr'
  },
  {
    primaryLink: '#',
    primaryLinkNewWindow: 'true',
    title: 'Wodurch entsteht Nullpunktdrift?',
    text: 'Durch Alterung der elektronischen Bauteile, durch Temperatur und Lastwechsel.',
    moreLink: 'Mehr'
  }
]
</script>
