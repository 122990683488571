<template>
  <div v-if="dataLoaded" :class="$style.container">
    <div :class="$style.select">
      <FormSelect
        :key="`force${dataLoaded}`"
        v-model="filter.initialSelectOption"
        :has-filter="false"
        :colour="filter.colour"
        :options="selectOptions"
        :initial-option="filter.initialSelectOption"
        @update="updateFilter"
      />
    </div>
    <section
      v-for="(category, categoryIndex) of downloadsRef"
      :key="`category${categoryIndex}`"
      :set="(currentCategory = category.viewType)"
      :class="[
        $style.section,
        {
          'columns is-mobile is-multiline':
            category.viewType === '3d_data' || category.viewType === 'software'
        },
        category.label === selectedFilterOption || !selectedFilterOption
          ? $style.active
          : $style.inactive
      ]"
    >
      <h3 v-if="currentCategory === '3d_data' || currentCategory === 'software'">
        {{ category.label }}
      </h3>
      <h2 v-else>
        {{ category.label }}
      </h2>
      <template
        v-if="
          currentCategory !== '3d_data' &&
          currentCategory !== 'software' &&
          currentCategory !== 'declarations'
        "
      >
        <section
          v-for="(section, sectionIndex) in getFilesSortByCategory(category.files, currentCategory)"
          :key="`category${categoryIndex}section${sectionIndex}`"
        >
          <div v-if="currentCategory !== 'approvals'" class="columns">
            <div class="column is-half">
              <p v-html="section.title" />
            </div>
            <div class="column is-half">
              <CountryDownloadBox
                v-if="section.urls.length && downloadItemHasFlagOrLanguage(section.urls)"
                :key="`category${categoryIndex}section${sectionIndex}countryDownloadBox`"
                :countries="[...section.urls]"
                :labels="configRef.labels"
                :section="category.label"
                :title="section.title || section.subHeading"
                :types="configRef.productTypes"
                :wt-params="
                  section.wtParams
                    ? section.wtParams
                    : currentCategory !== 'default'
                      ? {
                          doctype: currentCategory
                        }
                      : undefined
                "
                :hide-tooltip="configRef.hideTooltip"
              />
            </div>
          </div>
          <div v-if="currentCategory === 'approvals'" class="columns">
            <div class="column is-two-thirds">
              <div class="columns is-mobile">
                <div class="column is-2">
                  <img v-if="section.logo" :class="$style.image" :src="section.logo" />
                </div>
                <div class="column">
                  <dl class="columns is-multiline is-mobile is-gapless">
                    <dt class="column is-5">{{ configRef.labels.scheme }}:</dt>
                    <dd class="column is-7">
                      <strong
                        v-for="(scheme, scIndex) in uniqueValues('scheme', section.productcodeList)"
                        :key="`category${categoryIndex}section${sectionIndex}scheme${scIndex}`"
                        :class="$style.break"
                        v-text="scheme"
                      />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.region }}:</dt>
                    <dd class="column is-7">
                      <span
                        v-for="(region, rIndex) in uniqueValues('region', section.productcodeList)"
                        :key="`category${categoryIndex}section${sectionIndex}region${rIndex}`"
                        :class="$style.break"
                        v-text="region"
                      />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.application }}:</dt>
                    <dd class="column is-7">
                      <span
                        v-for="(application, aIndex) in uniqueValues(
                          'application',
                          section.productcodeList
                        )"
                        :key="`category${categoryIndex}section${sectionIndex}application${aIndex}`"
                        :class="$style.break"
                        v-text="application"
                      />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.scope }}:</dt>
                    <dd class="column is-7">
                      <span
                        v-for="(scope, sIndex) in uniqueValues('scope', section.productcodeList)"
                        :key="`category${categoryIndex}section${sectionIndex}scope${sIndex}`"
                        :class="$style.break"
                        v-text="scope"
                      />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.detail }}:</dt>
                    <dd class="column is-7">
                      <span
                        v-for="(detail, dIndex) in uniqueValues('detail', section.productcodeList)"
                        :key="`category${categoryIndex}section${sectionIndex}detail${dIndex}`"
                        :class="$style.break"
                        v-text="detail"
                      />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.approvalauthority }}:</dt>
                    <dd class="column is-7">
                      <span v-html="section.approvalauthority" />
                    </dd>
                    <dt class="column is-5">{{ configRef.labels.certificateId }}:</dt>
                    <dd class="column is-7">
                      <span v-html="section.certificateId" />
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
            <div class="column is-one-third">
              <CountryDownloadBox
                v-if="section.urls.length && downloadItemHasFlagOrLanguage(section.urls)"
                :key="`category${categoryIndex}section${sectionIndex}countryDownloadBox`"
                :countries="[...section.urls]"
                :labels="configRef.labels"
                :section="category.label"
                :title="`${section.title}`"
                :sub-title="getApprovalSubTitle(section)"
                :types="configRef.productTypes"
                :wt-params="
                  mergeWtParams(
                    {
                      doctype: 'approvals'
                    },
                    section.wtParams,
                    section
                  )
                "
                :hide-tooltip="configRef.hideTooltip"
              />
            </div>
          </div>
        </section>
      </template>
      <template v-if="currentCategory === 'declarations'">
        <section
          v-for="(section, sectionIndex) in category.files"
          :key="`category${categoryIndex}section${sectionIndex}`"
        >
          <div class="columns">
            <div class="column is-half">
              <p v-html="section.title" />
            </div>
            <div class="column is-half">
              <CountryDownloadBox
                v-if="section.urls.length && downloadItemHasFlagOrLanguage(section.urls)"
                :key="`category${categoryIndex}section${sectionIndex}countryDownloadBox`"
                :countries="[...section.urls]"
                :labels="configRef.labels"
                :section="category.label"
                :title="section.title || section.subHeading"
                :types="configRef.productTypes"
                :wt-params="{
                  doctype: 'declarations',
                  description: section.title || section.subHeading
                }"
                :hide-tooltip="configRef.hideTooltip"
              />
            </div>
          </div>
        </section>
      </template>
      <template v-if="currentCategory === '3d_data'">
        <section
          v-for="(section, sectionIndex) in category.files"
          :key="`category${categoryIndex}section${sectionIndex}`"
          class="column is-half"
        >
          <p v-html="section.title" />
          <DownloadButton
            v-if="section.metadata && section.urls[0]"
            :id="id"
            :config="{
              metadata: section.metadata,
              title: section.title,
              wishlistTitle: section.title,
              types: configRef.productTypes,
              urls: section.urls[0],
              hideTooltip: configRef.hideTooltip
            }"
            :section="currentCategory"
            :labels="configRef.labels"
            :wt-params="{
              ...section.wtParams,
              ...section.urls[0].wtParams
            }"
          />
        </section>
      </template>
      <template v-if="currentCategory === 'software'">
        <section
          v-for="(section, sectionIndex) in category.files"
          :key="`category${categoryIndex}section${sectionIndex}`"
          class="column is-half"
        >
          <p v-html="section.title" />
          <DownloadButton
            v-if="section.metadata[0] && section.urls[0]"
            :id="id"
            :config="{
              metadata: section.metadata[0],
              title: section.title,
              wishlistTitle: section.title,
              types: configRef.productTypes,
              urls: section.urls[0],
              hideTooltip: configRef.hideTooltip
            }"
            :section="currentCategory"
            :labels="configRef.labels"
            :wt-params="{
              ...section.wtParams,
              ...section.urls[0].wtParams
            }"
          />
          <DownloadButton
            v-if="section.urls[1] && section.metadata[1]"
            :id="id"
            colour="white"
            :config="{
              metadata: section.metadata[1],
              title: section.title,
              wishlistTitle: section.title,
              types: configRef.productTypes,
              urls: section.urls[1],
              hideTooltip: configRef.hideTooltip
            }"
            :section="currentCategory"
            :labels="configRef.labels"
            :wt-params="{
              ...section.wtParams,
              ...section.urls[1].wtParams
            }"
          />
        </section>
      </template>
    </section>
  </div>
</template>

<script setup>
import CountryDownloadBox from '../CountryDownloadBox/CountryDownloadBox.vue'
import DownloadButton from '../DownloadButton/DownloadButton.vue'
import FormSelect from '../FormSelect/FormSelect.vue'
import axios from 'axios'
import { externalDocumentPrefixes } from '../../shared/globals'
import { getValueByParameterCaseInsensitive } from '../../utils/helper'
import { mergeSwitchWtParams } from '../../utils/tracking'
import { getJsonObject } from '../../utils/getJsonObject'
import { computed, onMounted, onUpdated, ref, watch } from 'vue'

const props = defineProps({
  downloads: {
    type: [Array, String],
    default: () => {
      return []
    }
  },
  config: {
    type: [Object, String],
    default: () => {
      return {}
    }
  },
  initialSelectOption: {
    type: String,
    default: ''
  },
  selectAllOption: {
    type: String,
    default: ''
  }
})

/*
 * * refs
 */

const filter = ref({
  colour: 'blue',
  initialSelectOption: props.initialSelectOption
})

const selectOptions = ref(props.selectAllOption)
const selectedFilterOption = ref(null)
const externalCategories = ref(['approvals', 'projectdocuments', 'declarations'])
const dataLoaded = ref(false)
const files = ref(0)
const currentLanguage = ref('')

/*
 * * computed values
 */

const id = computed(() => Math.random().toString(36).substring(2, 9))
const configRef = computed(() => getJsonObject(props.config))
const downloadsRef = computed(() => getJsonObject(props.downloads))

/*
 * * watchers
 */

watch(files, (newFiles, oldFiles) => {
  if (oldFiles === 0 && newFiles > 0) {
    if (typeof selectOptions.value === 'string') {
      selectOptions.value = [selectOptions.value]
    }
    for (let section of downloadsRef.value) {
      selectOptions.value.push(section.label)
    }
    updateExternalItems()
  }
})

/*
 * * Lifecycle methods
 */

onUpdated(() => {
  files.value = downloadsRef.value.length
})

onMounted(() => {
  files.value = downloadsRef.value.length
  currentLanguage.value = window.currentLanguage.toLowerCase()
})

/*
 * * methods
 */

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}

const getFilesSortByCategory = (newFiles, currentCategory) => {
  return currentCategory === 'approvals'
    ? [...newFiles].sort((a, b) => (a.title > b.title ? 1 : -1))
    : newFiles
}

const getApprovalSubTitle = (section) => {
  return section.keywords.length > 0
    ? section.keywords.map((keyword) => keyword.keyword).join(', ')
    : undefined
}

const updateFilter = (value) => {
  if (value[0] === props.initialSelectOption) {
    return selectedFilterOption.value === null
  }

  selectedFilterOption.value = props.selectAllOption === value[0] ? null : value[0]
}

const downloadItemHasFlagOrLanguage = (urls) => {
  const urlItemsWithFlag = urls.filter((urlItem) => urlItem.flag || urlItem.language)
  return urlItemsWithFlag.length > 0
}

const updateExternalItems = () => {
  const externalDownloads = downloadsRef.value.filter((x) =>
    externalCategories.value.includes(x.viewType)
  )
  if (externalDownloads.length > 0) {
    // Get mapping-data for external data
    axios
      .get(configRef.value.mappingUrl)
      .then((response) => {
        const mappings = response.data

        externalDownloads.forEach((x) => {
          const category = x.viewType
          const urlPrefix = window.externalDocumentPrefixes
            ? window.externalDocumentPrefixes[category] || ''
            : externalDocumentPrefixes[category] || ''

          x.label = configRef.value.labels[category] || category
          const optionIndex = selectOptions.value.indexOf(category)
          selectOptions.value[optionIndex] = x.label

          for (const item of x.files) {
            // Get the logo for the approval
            let certLogos
            const langItem = item.urls.find(
              (langItem) => langItem.language === window.currentLanguage.toLowerCase()
            )
            item.title = langItem && langItem.title ? langItem.title : item.title

            if (category === 'approvals') {
              certLogos = mappings.logos.filter((x) => x.certificate == item.certificateBodyId)

              // Use mapped-values for the keywords
              item.keywords = item.keywords.map((x) => ({
                id: x.id,
                keyword: mappings.keywords[x.id] || x.keyword
              }))
            }

            if (
              category === 'approvals' ||
              category === 'declarations' ||
              category === 'projectdocuments'
            ) {
              // Use mapped values for economicAreas
              item.economicArea = item.economicArea
                ? item.economicArea.map(
                    (x) =>
                      getValueByParameterCaseInsensitive(mappings.economicAreas, x) ||
                      getValueByParameterCaseInsensitive(mappings.countries, x) ||
                      x
                  )
                : null

              // Use mapped values for the languages
              item.urls.forEach((x) => {
                x.flag = getValueByParameterCaseInsensitive(mappings.languages, x.language) || ''
                x.url = `${urlPrefix}${x.url}`

                if (x.language.length > 2) {
                  x.language = mappings.languageCodes[x.language.toLowerCase()] || x.language
                }

                if (category === 'approvals') {
                  const logo =
                    certLogos.find((mapping) => {
                      return item.keywords.map((keyword) => {
                        return mapping.keyword == keyword.id && mapping.type === x.type
                      })
                    }) || ''

                  item.logo = item.logo || logo.url
                }
              })
            }
          }
        })
        dataLoaded.value = true
      })

      .catch((error) => console.log(error))
  } else {
    return new Promise(() => {
      dataLoaded.value = true
      return true
    })
  }
}

const uniqueValues = (categoryKey, productcodeList) => {
  const itemList = []
  productcodeList.forEach((listItem) =>
    listItem[categoryKey].forEach((dataItem) => {
      if (!itemList.includes(dataItem)) {
        itemList.push(dataItem)
      }
    })
  )
  return itemList
}
</script>

<style module lang="scss">
@import './ProductDownloadsSection.scss';
</style>
