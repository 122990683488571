<template>
  <div :class="$style.container">
    <div :class="$style.navMeta">
      <div :class="$style.countrySwitcher">
        <CountrySwitcher
          :config="{
            currentRegion: 'Europa',
            currentCountry: 'Deutschland',
            corporateTitle: 'Corporate Website',
            headquarterTitle: 'Hauptstandort',
            corporateUrl: '#',
            countrySelectPlaceholder: 'Land wählen...',
            websitesTitle: 'Webseiten',
            locationsTitle: 'Standorte'
          }"
        />
      </div>
      <div :class="$style.langSwitcher">
        <LanguageSwitcher :options="config?.langSwitcherOptions" />
      </div>
      <div :class="$style.quickLinks">
        <QuickLinks :quick-links="config?.quickLinks" />
      </div>
    </div>
    <div ref="mainNavConatiner" :class="[{ PageHeader__sticky: config?.sticky }, $style.navMain]">
      <a :class="$style.homeLink" title="WIKA" href="#">
        <img src="../../assets/logo_wika.svg" alt="WIKA" />
      </a>
      <div :class="$style.desktopNav">
        <MainMenu
          :primary-nav="config?.primaryNav"
          :secondary-nav="config?.secondaryNav"
          :parent-node="mainNavConatiner"
        />
      </div>
      <div :class="$style.search">
        <ElasticSearch :config="config?.searchConfig" />
      </div>
      <div :class="$style.mobileNav">
        <MobileMenu :primary-nav="config?.primaryNav" :secondary-nav="config?.secondaryNav" />
      </div>
    </div>
  </div>
</template>

<script setup>
import CountrySwitcher from '../CountrySwitcher/CountrySwitcher.vue'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher.vue'
import MainMenu from '../MainMenu/MainMenu.vue'
import MobileMenu from '../MobileMenu/MobileMenu.vue'
import QuickLinks from '../QuickLinks/QuickLinks.vue'
import ElasticSearch from '../ElasticSearch/ElasticSearch.vue'
import { ref, onBeforeMount, onBeforeUnmount } from 'vue'

const props = defineProps({
  config: {
    Type: Object,
    default: () => {}
  }
})

const mainNavConatiner = ref(null)
const sticky = ref(null)

const initScroll = () => {
  window.addEventListener('scroll', handleScroll)
}

const handleScroll = () => {
  sticky.value = window.scrollY > props.config?.stickyPosition ? props.config.stickyPosition : '40'
}

onBeforeMount(() => {
  initScroll()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style module lang="scss">
@import './PageHeader.scss';
</style>
