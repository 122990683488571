const markerIcon = (
  iconColor
) => `<svg xmlns="http://www.w3.org/2000/svg" height="32" width="24" viewBox="0 0 38 50">
  <g transform="translate(-5, 2)">
    <circle transform="translate(0, -1)" cx="24" cy="16" r="17" fill="black" />
    <path
      d="M24 0c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z"
      fill="${iconColor}"
      stroke="white"
      stroke-width="1px"
      stroke-alignment="inner"
    />
    <circle cx="24" cy="17.7" r="7" fill="white" />
  </g>
</svg>`

const positionIcon = (
  iconColor
) => `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
  <circle cx="16" cy="16" r="16" fill="${iconColor}" opacity="0.25"/>
  <circle cx="16" cy="16" r="7" fill="${iconColor}" stroke="#fff" stroke-width="2"/>
</svg>`

export const getPositionIcon = (iconColor = '#4385f4') =>
  `data:image/svg+xml;base64,${window.btoa(positionIcon(iconColor))}`

export const getMarkerIcon = (iconColor = 'red') =>
  `data:image/svg+xml;base64,${window.btoa(markerIcon(iconColor))}`
