<template>
  <figure :class="[$style.container, { ContentBox__hover: primaryLink }]">
    <a v-if="primaryLink && image" :href="primaryLink">
      <img :src="image" />
    </a>
    <img v-else-if="image" :src="image" />
    <figcaption v-if="title || text || linkList.length > 0">
      <a v-if="primaryLink" :href="primaryLink">
        <h3 v-if="title">
          <img v-if="icon && icon !== 'warning'" :src="icon" />
          <i v-if="icon && icon === 'warning'" class="fas fa-exclamation-triangle" />
          <span v-html="title" />
        </h3>
        <div v-if="text" v-html="text" />
        <ul v-if="linkList.length > 0">
          <li v-for="(link, index) in linkList" :key="index">
            <a :href="link.url"> <span :class="$style.triangle" />{{ link.title }} </a>
          </li>
        </ul>
      </a>
      <div v-else>
        <h3 v-if="title">
          <img v-if="icon && icon !== 'warning'" :src="icon" />
          <i v-if="icon && icon === 'warning'" class="fas fa-exclamation-triangle" />
          <span v-html="title" />
        </h3>
        <div v-if="text" v-html="text" />
        <ul v-if="linkList.length > 0">
          <li v-for="(link, index) in linkList" :key="index">
            <a :href="link.url"> <span :class="$style.triangle" />{{ link.title }} </a>
          </li>
        </ul>
      </div>
    </figcaption>
  </figure>
</template>

<script setup>
import { useCssModule } from 'vue'
const $style = useCssModule()

defineProps({
  primaryLink: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  },
  icon: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  linkList: {
    type: Array,
    default: () => []
  }
})
</script>

<style module lang="scss">
@import './ContentBox.scss';
</style>
