let baseUrl = ''
let shopBaseUrl = ''
let loginName = ''
let websessionId = ''
let reqVer = ''
let reqApp = ''
let reqFormat = ''

if (import.meta.VUE_APP_FS_ENV === 'prod') {
  baseUrl = 'https://www.wika.com'
  shopBaseUrl = 'https://shop-system.wika.de'
  loginName = 'C0BE934F-E4E6-45F8-830B-EE478F2D1FDF'
  websessionId = '2090878525-6f036612a2cc474aa48519bb63feaa03'
  reqApp = 'web.WebCode.wika.com'
  reqVer = '0.1a'
  reqFormat = 'json'
} else if (import.meta.VUE_APP_FS_ENV === 'test') {
  baseUrl = 'https://www-test.wika.com'
  shopBaseUrl = 'https://shop-system-qa.wika.de'
  loginName = 'C0BE934F-E4E6-45F8-830B-EE478F2D1FDF'
  websessionId = '2090878525-6f036612a2cc474aa48519bb63feaa03'
  reqApp = 'web.WebCode.wika.com'
  reqVer = '0.1a'
  reqFormat = 'json'
} else if (import.meta.VUE_APP_FS_ENV === 'qa') {
  baseUrl = 'http://www-qa2.wika.dev.intern.etecture.de'
  shopBaseUrl = 'http://shop-qa2.wika.dev.intern.etecture.de'
  loginName = 'C0BE934F-E4E6-45F8-830B-EE478F2D1FDF'
  websessionId = '2090878525-6f036612a2cc474aa48519bb63feaa03'
  reqApp = 'web.WebCode.wika.com'
  reqVer = '0.1a'
  reqFormat = 'json'
} else {
  baseUrl = 'http://www-qa.wika.dev.intern.etecture.de'
  shopBaseUrl = 'https://shop-system-qa.wika.de'
  loginName = 'C0BE934F-E4E6-45F8-830B-EE478F2D1FDF'
  websessionId = '2090878525-6f036612a2cc474aa48519bb63feaa03'
  reqApp = 'web.WebCode.wika.com'
  reqVer = '0.1a'
  reqFormat = 'json'
}

// Session login URL needs to be extended with lang' when consume it
export const sessionLoginUrl = `https://portal.wika.com/app3/de11/portal-prod/?reqapp=${reqApp}&reqver=${reqVer}&format=${reqFormat}&loginname=${loginName}&websessionid=${websessionId}&obj=login`
// Session login URL needs to be extended with 'session' and 'lang' when consume it
export const sessionLogoutUrl = `https://portal.wika.com/app3/de11/portal-prod/?reqapp=${reqApp}&reqver=${reqVer}&format=${reqFormat}&obj=logout`
// Create data sheet URL needs to be extended with 'itemid', 'session' and 'lang' when consume it
export const createDataSheetUrl = `https://portal.wika.com/app3/de11/portal-prod/?reqapp=${reqApp}&reqver=${reqVer}&format=${reqFormat}&loginname=${loginName}&websessionid=${websessionId}&obj=getitembaseddatasheet&docformat=pdf`

export const contactFormUrl = `${baseUrl}/rest-services/forms/contact/save`
export const feedbackFormUrl = `${baseUrl}/rest-services/forms/feedback/save`
export const exhibitionFormUrl = `${baseUrl}/rest-services/forms/exhibitionticket/save`
// export const regionDataUrl = `${baseUrl}/de-de/regionen_json.json`
export const regionDataUrl = '/dist/regions_json.json' // local test data
export const searchSynonymUrl = '/dist/synonyme_json.json' // local test data
// export const geoIPUrl = `${baseUrl}/rest-services/geoip/getcountry`
// export const geoIPUrl = '/dist/getcountry.json'
export const geoIPUrl = `${baseUrl}/rest-services/geoip/getcountrytest/91.33.211.30` // Test data
export const getQuoteIdUrl = `${shopBaseUrl}/eu/l_de/rest/V1/customer/getQuoteId`
export const addItemUrl = `${baseUrl}/rest-services/shop/addItem`
export const getCartUrl = `${baseUrl}/rest-services/shop/getCart`
export const removeItemUrl = `${baseUrl}/rest-services/shop/removeItem`
export const updateItemQuantityUrl = `${baseUrl}/rest-services/shop/updateItemQuantity`
export const externalDocumentPrefixes = {
  approvals: 'http://apps.wika.com/upload',
  certificates: 'http://apps.wika.com/upload',
  conformities: 'http://apps.wika.com/upload'
}
