<template>
  <div :class="$style.container">
    <div :class="$style.inner">
      <div v-if="config.boxTitle" :class="$style.textContainer">
        <i class="fas fa-cart-plus" />
        <span :class="$style.title">{{ config.boxTitle }}</span>
      </div>
      <div v-if="config.boxText" :class="$style.text">
        {{ config.boxText }}
      </div>
      <div id="infoCircle" :class="$style.quickAddPopper">
        <i class="fas fa-info-circle" />
      </div>
      <tippy
        :arrow="true"
        :distance="7"
        :interactive="true"
        :trigger="isMobile ? 'click' : 'mouseenter focus'"
        :z-index="10000"
        theme="wika light tooltip"
        to="#infoCircle"
      >
        <template #content>
          <div v-html="`${config.infoPopperContent}<img src='${config.infoPopperImage}'>`" />
        </template>
      </tippy>
      <form @submit.prevent="">
        <div :class="$style.inputContainer">
          <div :class="$style.selectContainer">
            <input
              v-model.trim="articleNumber"
              :class="$style.inputField"
              type="text"
              :placeholder="config.inputPlaceholder"
              aria-label="article number"
              @input="forceRerender"
              @keyup.enter="addItem"
            />
            <Select
              :select-options="selectOptions"
              :select-value="quantity"
              colour="white"
              @on-change="(quantity) => updateQuantity(quantity, item)"
            />
          </div>
          <div :class="[$style.buttons, { QuickAddBox__buttonsHidden: checkingAvailability }]">
            <div :class="['Button__button', 'Button__blue', $style.infoBtn]">
              <a @click="checkAvailability">{{ config.infoButtonText }}</a>
            </div>
            <ShopCartLink
              :key="`cartLink1-${componentKey}`"
              ref="addButton"
              :item="{
                text: config.addButtonText,
                quantity: quantity,
                id: articleNumber,
                btype: bType,
                codestring: codestring,
                headline: config.headline,
                subheadline: config.subheadline,
                pictureurl: config.pictureurl,
                thumbnailurl: config.thumbnailurl,
                producturl: config.producturl
              }"
              :add-item-failed-msg="config.addArticleFailedMessage"
              :add-item-amount-msg-txt="config.addItemAmountMsgTxt"
              :add-item-highest-amount-msg="config.addItemHighestAmountMessage"
              :add-item-lowest-amount-msg="config.addItemLowestAmountMessage"
              :add-item-multiple-amount-msg="config.addItemMultipleAmountMessage"
              :add-button-loading-text="config.addButtonLoadingText"
              :is-dark-background="true"
              @error="handleShopCartLinkError"
            />
            <div
              :class="[
                $style.loadingBasket,
                { QuickAddBox__loadingBasketVisible: checkingAvailability }
              ]"
            >
              <div :class="$style.loading">
                <Loading :size="20" color="#fff" />
              </div>
              {{ config.loadingText }}
            </div>
          </div>
        </div>
      </form>
      <Modal :is-modal-open="isModalOpen" @close-modal="closeModal">
        <div v-if="searchSuccess" :class="$style.modal">
          <p :class="$style.heading">{{ config.inputPlaceholder }}: {{ articleNumber }}</p>
          <div class="columns is-gapless">
            <div class="column is-two-thirds-tablet">
              <p :class="$style.subHeading">
                {{ config.articleDetails }}
              </p>
              <p
                v-if="data.Longtext"
                v-html="`${data.Longtext.replace(/(?:\r\n|\r|\n)/g, '<br>')}`"
              />
              <p v-else v-html="`${data.Shorttext.replace(/(?:\r\n|\r|\n)/g, '<br>')}`" />
            </div>
            <div class="column is-one-third-tablet">
              <div :class="$style.addToCart">
                <div :class="[$style.singlePrice, 'clearfix']">
                  <span :class="$style.label"> {{ config.pricePerItem }}: </span>
                  <span :class="$style.label">
                    <IndividualPrice
                      :value="Number(price)"
                      :product-type="data?.ProductType"
                      :btype-list="
                        data
                          ? [
                              {
                                btype: data.ProductType,
                                minimalPrice: price
                              }
                            ]
                          : []
                      "
                    />
                  </span>
                </div>
                <div :class="$style.quantity">
                  <span :class="$style.label">{{ config.quantity }}:</span>
                  <div :class="$style.quantitySelect">
                    <Select
                      :select-options="selectOptions"
                      :select-value="quantity"
                      colour="white"
                      @on-change="(quantity) => updateQuantity(quantity, item)"
                    />
                  </div>
                </div>
                <hr />
                <div :class="$style.sum">
                  <IndividualPrice
                    :value="Number(price)"
                    :quantity="Number(quantity)"
                    :product-type="data?.ProductType"
                    :btype-list="
                      data
                        ? [
                            {
                              btype: data.ProductType,
                              minimalPrice: price
                            }
                          ]
                        : []
                    "
                  />
                </div>
                <div :class="$style.tax">
                  {{ config.tax }} &amp;
                  <a :href="config.shippingUrl">
                    {{ config.shippingText }}
                  </a>
                </div>
                <div
                  :class="[
                    {
                      QuickAddBox__deliveryStatusGreen: data.DeliveryCategory === 1
                    },
                    {
                      QuickAddBox__deliveryStatusOrange:
                        data.DeliveryCategory === 2 || data.DeliveryCategoryValue === 3
                    }
                  ]"
                >
                  <span :class="$style.deliveryStatus" />
                  {{ deliveryCategory }},
                  {{ config.deliveryTime }}
                  {{ deliveryDays }}
                  {{ workDays }}
                </div>
                <div
                  :class="[$style.buttons, { QuickAddBox__buttonsHidden: checkingAvailability }]"
                >
                  <ShopCartLink
                    :key="`cartLink2-${componentKey}`"
                    :item="{
                      text: config.addButtonText,
                      quantity: quantity,
                      id: articleNumber,
                      headline: config.headline,
                      subheadline: config.subheadline,
                      pictureurl: config.pictureurl,
                      thumbnailurl: config.thumbnailurl,
                      producturl: config.producturl
                    }"
                    :add-item-failed-msg="config.addArticleFailedMessage"
                    :add-item-highest-amount-msg="config.addItemHighestAmountMessage"
                    :add-item-lowest-amount-msg="config.addItemLowestAmountMessage"
                    :add-item-multiple-amount-msg="config.addItemMultipleAmountMessage"
                    :add-button-loading-text="config.addButtonLoadingText"
                    :is-dark-background="true"
                    @closed="closeModal"
                  />
                  <div
                    :class="[
                      $style.loadingBasket,
                      {
                        QuickAddBox__loadingBasketVisible: checkingAvailability
                      }
                    ]"
                  >
                    <div :class="$style.loading">
                      <Loading :size="30" />
                    </div>
                    {{ config.addButtonLoadingText }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else :class="$style.modal">
          <h4>{{ config.warningHeadline }}</h4>
          <p :class="$style.warningMessage" v-html="warningText" />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import { isMobile } from 'mobile-device-detect'
import Modal from '../Modal/Modal.vue'
import Select from '../Select/Select.vue'
import ShopCartLink from '../ShopCartLink/ShopCartLink.vue'
import Loading from '../Loading/Loading.vue'
import { computed, onMounted, ref } from 'vue'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const componentKey = ref(0)
const isModalOpen = ref(false)
const searchSuccess = ref(false)
const checkingAvailability = ref(false)
const articleNumber = ref('')
const buildCode = ref('')
const quantity = ref('1')
const data = ref({})
const price = ref('')
const deliveryDays = ref('')
const deliveryCategory = ref('')
const workDays = ref('')
const warningText = ref('')
const addButton = ref(null)
const bType = ref('')
const codestring = ref('')

const selectOptions = computed(() => {
  const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i).map(String)
  return range(1, props.config.selectOptionMax)
})

const addItem = () => {
  addButton.value && addButton.value.addItem()
}

const forceRerender = () => {
  componentKey.value += 1
}

const closeModal = () => {
  isModalOpen.value = false
}

const updateQuantity = (newQuantity) => {
  quantity.value = newQuantity.toString()
}

const checkAvailability = () => {
  checkingAvailability.value = true
  const itemid = typeof articleNumber.value !== 'undefined' ? articleNumber.value : ''
  const buildid = typeof buildCode.value !== 'undefined' ? buildCode.value : ''
  const output = typeof buildCode.value !== 'undefined' && buildCode.value != '' ? 'false' : 'true'

  if ((buildid || itemid) && window.currentCountry && window.currentLanguage) {
    axios({
      url: window.getItemInfoUrl,
      method: 'post',
      data: {
        itemid,
        buildid,
        output,
        country: window.currentCountry,
        language: window.currentLanguage.replace('EN-US', 'EN')
      }
    })
      .then((response) => displaySuccessModal(response.data))

      .catch((error) => console.log(error))
  } else {
    warningText.value = props.config.warningNoValueSubmitted
    searchSuccess.value = false
    isModalOpen.value = true
    checkingAvailability.value = false
  }
}

const handleShopCartLinkError = (newData) => {
  let { isArticleType, url } = newData

  if (isArticleType && url) {
    showArticleTypeUrl(url)
  }
}

const showArticleTypeUrl = (url) => {
  warningText.value = props.config.warningIsArticleType.replace(
    /{(\w+( +\w+)*)}/,
    `<a href="${url}">$1</a>`
  )
  isModalOpen.value = true
}

const displaySuccessModal = (newData) => {
  if (newData.Success) {
    data.value = newData
    price.value = newData.PositionPrice
    deliveryDays.value = parseInt(newData.DeliveryTime) + parseInt(props.config.externalTransport)

    if (newData.DeliveryCategory === 1) {
      deliveryCategory.value = props.config.readyForShipping
    } else if (newData.DeliveryCategory > 1 && newData.DeliveryCategory < 6) {
      deliveryCategory.value = props.config.deliveryAfterProduction
    }

    newData.DeliveryTime === 0
      ? (workDays.value = props.config.singularWorkDayStr)
      : (workDays.value = props.config.pluralWorkDayStr)
    searchSuccess.value = true
    isModalOpen.value = true
  } else if (newData.IsArticleType && newData.Url) {
    searchSuccess.value = false

    showArticleTypeUrl(newData.Url)
  } else {
    warningText.value = props.config.warningInvalidArticle
    searchSuccess.value = false
    isModalOpen.value = true
  }

  checkingAvailability.value = false
  bType.value = newData.MainType
  codestring.value = newData.CodeString
}

const checkUrlForItemId = () => {
  const query = window.location.search.substring(1)
  const params = new URLSearchParams(query)
  const itemId = params.get('itemid')

  const buildId = params.get('buildid')
  const buildIdUpper = params.get('buildId')

  if (buildId || buildIdUpper) {
    buildCode.value = buildId != null ? buildId : buildIdUpper
    checkAvailability()
  }

  if (itemId) {
    articleNumber.value = itemId
    checkAvailability()
  }
}

onMounted(() => {
  checkUrlForItemId()
})
</script>

<style module lang="scss">
@import './QuickAddBox.scss';
</style>
