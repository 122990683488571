<template>
  <div :class="[{ HeroSlider__heightFirst: heightFirst }, $style.container]">
    <VueAgile
      v-if="isActive"
      :dots="slides && slides.length > 1"
      :nav-buttons="slides && slides.length > 1"
      :fade="true"
      :speed="500"
      :autoplay="true"
      :autoplay-speed="7000"
    >
      <figure
        v-for="(slide, index) in slides"
        :key="`${slide.text}_${index}`"
        :class="$style.slide"
      >
        <HeroSlideProduct
          v-if="slide.product"
          :slide="slide"
          :height-first="heightFirst"
          :index="index"
        />
        <HeroSlide v-else :slide="slide" :height-first="heightFirst" :index="index" />
      </figure>
      <template #prevButton>
        <ChevronLeft />
      </template>
      <template #nextButton>
        <ChevronRight />
      </template>
    </VueAgile>
  </div>
</template>

<script setup>
import { VueAgile } from 'vue-agile'
import { isIE } from 'mobile-device-detect'
import ChevronLeft from '../../assets/svg/ChevronLeft.vue'
import ChevronRight from '../../assets/svg/ChevronRight.vue'
import HeroSlide from './HeroSlide.vue'
import HeroSlideProduct from './HeroSlideProduct.vue'
import { ref, onMounted } from 'vue'

defineProps({
  slides: {
    type: Array,
    default: () => []
  },
  heightFirst: {
    type: Boolean,
    default: false
  }
})

const isActive = ref(false)

onMounted(() => {
  if (isIE) {
    setTimeout(() => {
      isActive.value = true
    }, 500)
  } else {
    isActive.value = true
  }
})
</script>

<style module lang="scss">
@import './HeroSlider.scss';
</style>
