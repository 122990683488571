<template>
  <div :class="[$style.container, 'columns is-multiline is-mobile']">
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
    <div class="column is-half">
      <a :class="$style.bigNavButton" href="#" target="_self">
        <figure>
          <div :class="$style.image" :style="{ backgroundImage: 'url(//placehold.it/180x97)' }">
            <img src="//placehold.it/180x97" />
          </div>
          <figcaption>
            <h3>Zeiger-<br />thermometer</h3>
          </figcaption>
        </figure>
      </a>
    </div>
  </div>
</template>

<script setup></script>

<style module lang="scss">
@import './BigNavButtons.scss';
</style>
