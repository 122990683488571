export const CountryDownloadBoxContext = {
  WISHLIST: 'WISHLIST',
  DOWNLOAD_LIST_TEASER: 'DOWNLOAD_LIST_TEASER'
}

export const DownloadCategory = {
  APPROVALS: 'approvals',
  CERTIFICATES: 'certificates',
  DECLARATIONS: 'declarations'
}

export const DOWNLOADMATRIX_LANGUAGES = [
  'EN',
  'DE',
  'ES',
  'FR',
  'IT',
  'BG',
  'BR',
  'CZ',
  'DK',
  'EL',
  'FI',
  'UK',
  'HR',
  'HU',
  'JA',
  'KR',
  'KO',
  'KZ',
  'LT',
  'NL',
  'NO',
  'PT',
  'PL',
  'RO',
  'RU',
  'SE',
  'SK',
  'SR',
  'TR',
  'UA',
  'US',
  'UQ',
  'ZH'
]
