<template>
  <nav :id="stickyNavId" ref="stickyNav" :class="$style.sticky">
    <h2 v-if="title" :class="$style.title">
      {{ title }}
    </h2>
    <ul :class="[$style.links, { ScrollNavigation__big: JSON.parse(bigButtons) === true }]">
      <li
        v-for="(navItem, index) in config"
        :key="index"
        @click.prevent="scrollTo(navItem.anchorName)"
      >
        <a :href="`#${navItem.anchorName}`">
          <img v-if="navItem.image" :src="navItem.image" :alt="navItem.title" />
          <i v-if="navItem.icon" :class="navItem.icon" />
          <span>{{ navItem.title }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  scrollContainerId: {
    type: String,
    default: ''
  },
  stickyNavId: {
    type: String,
    default: ''
  },
  bigButtons: {
    type: Boolean,
    default: false
  },
  config: {
    type: Object,
    default: () => {}
  }
})

const isWebsite = window.instance === 'website'

const buffer = 5
const mobileOffset = 245 + (isWebsite ? -35 : 0)
const tabletOffset = 345 + (isWebsite ? -35 : 0)
const desktopOffset = (JSON.parse(props.bigButtons) === true ? 415 : 275) + (isWebsite ? 65 : 0)

const stickyNav = ref(null)
const pageHeader = ref(null)
const sectionsArray = ref(null)
const offset = ref(null)
const spyOffset = ref(null)

const scrollTo = (id) => {
  const element = document.getElementById(id)
  element.style.scrollMargin = spyOffset.value + 'px'
  element.scrollIntoView({ behavior: 'smooth' })
}

const setOffsets = () => {
  offset.value = pageHeader.value.offsetHeight
  stickyNav.value.style.top = offset.value + 'px'

  if (window.innerWidth < 1024) {
    if (window.innerWidth < 768) {
      spyOffset.value = mobileOffset
    } else {
      spyOffset.value = tabletOffset
    }
  } else {
    spyOffset.value = desktopOffset
  }
}

const initScrollSpy = () => {
  for (const section in sectionsArray.value) {
    if (
      sectionsArray.value[section].getBoundingClientRect().top <= spyOffset.value + buffer &&
      sectionsArray.value[section].getBoundingClientRect().bottom >= spyOffset.value + buffer
    ) {
      stickyNav.value
        .querySelector('a[href*=' + sectionsArray.value[section].id + ']')
        .parentNode.classList.add('active')
    } else {
      stickyNav.value
        .querySelector('a[href*=' + sectionsArray.value[section].id + ']')
        .parentNode.classList.remove('active')
    }
  }
}

onMounted(() => {
  const scrollContainer = document.getElementById(props.scrollContainerId)
  const sectionsList = scrollContainer.querySelectorAll('section')

  sectionsArray.value = Array.from(sectionsList)
  pageHeader.value = document.querySelector('[class*="__navMain"]')

  window.addEventListener('resize', setOffsets)
  window.addEventListener('scroll', initScrollSpy)

  setOffsets()
})

onUnmounted(() => {
  window.removeEventListener('resize', setOffsets)
  window.removeEventListener('scroll', initScrollSpy)
})
</script>

<style module lang="scss">
@import './ScrollNavigation.scss';
</style>
