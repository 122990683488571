import { getSessionStorage, removeSessionStorage, setSessionStorage } from '../shared/Storage'
import StorageKeys from '../globals/constants/storageKeys'

const setQuoteIdToSession = (quoteId) => {
  setSessionStorage(StorageKeys.QUOTE_ID, quoteId, false)
}

const getQuoteIdFromSession = () => {
  return getSessionStorage(StorageKeys.QUOTE_ID, false)
}

const removeQuoteIdFromSession = () => {
  removeSessionStorage(StorageKeys.QUOTE_ID)
}

export { getQuoteIdFromSession, removeQuoteIdFromSession, setQuoteIdToSession }
