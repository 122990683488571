<template>
  <a
    v-if="wrapInLink"
    :href="slide.imageLink"
    :target="slide.newWindow ? '_blank' : '_self'"
    @click="handleClick"
  >
    <slot />
  </a>
  <div v-if="!wrapInLink">
    <slot />
  </div>
</template>

<script setup>
const emit = defineEmits(['openModal'])

const props = defineProps({
  slide: {
    type: Object,
    default: () => {}
  }
})

const wrapInLink =
  (props.slide.imageLink && props.slide.imageLink !== '' && props.slide.imageLink !== '#') ||
  props.slide.video

const handleClick = (e) => {
  if (props.slide.video) {
    e.preventDefault()
    emit('openModal')
  }
}
</script>
