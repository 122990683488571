import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  function mountApp(element) {
    const app = createApp(App, { ...element.dataset })

    app.mount(`#${element.id}`)
  }

  const elements = document.querySelectorAll('.rotatingCarousel')

  if (elements.length) {
    elements.forEach((element) => {
      if (element.closest('div[id^="tab-content-"]')) {
        return
      }

      mountApp(element)
    })
  }

  window.initRotatingCarouselInContentTabs = () => {
    const containers = document.querySelectorAll('.ContentTabs__container')

    containers.forEach((container) => {
      const subApp = container.querySelectorAll('.rotatingCarousel')

      subApp.forEach((map) => {
        const modifiedId = `${map.id}-in-tab`
        map.id = modifiedId
        mountApp(map)
      })
    })
  }
}
// bugfix-WIKA-3560
setTimeout(() => {
  document.querySelectorAll('.rotatingCarousel').forEach((carousel, carouselIndex) => {
      const slidesData = carousel.getAttribute('data-slides');
      if (slidesData) {
          try {
              const slides = JSON.parse(slidesData);
              const slideElements = carousel.querySelectorAll('.swiper-slide');
              slides.forEach((slide, slideIndex) => {
                  const matchingSlide = Array.from(slideElements).find(slideElement => {
                      const img = slideElement.querySelector('img');
                      return img && img.src === slide.image;
                  });
                  if (matchingSlide) {
                      matchingSlide.setAttribute('data-video', slide.video);
                  } else {
                      console.warn(
                          `No matching slide found for slide data (Carousel: ${carouselIndex + 1}, Slide Index: ${slideIndex + 1})`
                      );
                  }
              });
          } catch (error) {
              console.error(`JSON parse failed (Carousel: ${carouselIndex + 1}):`, error);
          }
      } else {
          console.error(`'data-slides' attribute is missing or empty (Carousel: ${carouselIndex + 1}).`);
      }
      const slides = carousel.querySelectorAll('.swiper-slide');
      slides.forEach((slide, slideIndex) => {
          slide.addEventListener('click', function () {
              const videoUrl = slide.getAttribute('data-video');
              const modal = carousel.querySelector('.Modal__container');
              if (!modal) {
                  console.error(`Modal not found (Carousel: ${carouselIndex + 1})!`);
                  return;
              }
              const iframeContainer = modal.querySelector('.Modal__content.Modal__video');
              if (!iframeContainer) {
                  console.error('Video container not found in modal!');
                  return;
              }
              const iframe = iframeContainer.querySelector('iframe');
              if (!iframe) {
                  console.error('Iframe not found in modal!');
                  return;
              }
              const consentDiv = modal.querySelector('.Modal__consent');
              if (videoUrl) {
                  if (consentDiv) {
                      consentDiv.style.display = 'block';
                      iframe.style.display = 'none';
                      iframe.setAttribute('data-src', videoUrl);
                  } else {
                      iframe.style.display = 'block';
                      iframe.setAttribute('src', videoUrl);
                  }
                  modal.classList.add('is-active');
              } else {
                  console.error(`Video URL not found (Slide: ${slideIndex + 1}, Carousel: ${carouselIndex + 1})`);
              }
          });
      });
      const closeModalButton = carousel.querySelector('.modal-close');
      if (closeModalButton) {
          closeModalButton.addEventListener('click', function () {
              const modal = carousel.querySelector('.Modal__container');
              if (modal) {
                  const iframe = modal.querySelector('iframe');
                  if (iframe) {
                      iframe.removeAttribute('src');
                  }
                  modal.classList.remove('is-active');
              }
          });
      } else {
          console.warn(`Modal close button not found (Carousel: ${carouselIndex + 1}).`);
      }
  });
}, 1000);
