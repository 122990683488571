<template>
  <div :class="$style.container">
    <GoogleMap
      ref="map"
      api-key="AIzaSyCdM1m57HfyyDYNneMFZ_e8spr1PeSOpjM"
      :style="mapStyle"
      :center="mapCenter"
      :zoom="15"
      :street-view-control="false"
      :fullscreen-control="false"
      :map-type-control="false"
    >
      <!-- Map Markers from props -->
      <GmapMarker
        v-for="marker in markers"
        :key="marker.id"
        :options="{
          position: marker.geolocation,
          icon: {
            url: getMarkerIcon(marker.iconColor),
            scaledSize: { width: 24, height: 32 }
          }
        }"
        @click="toggleInfoWindow(marker)"
      />
      <!-- InfoWindow for selected Marker -->
      <MapInfoWindow
        v-if="infoWindow.details !== null"
        :key="infoWindow.details.id"
        :details="infoWindow.details"
        :options="infoWindow.options"
        :position="infoWindow.geolocation"
        :opened="infoWindow.opened"
        :use-form="useForm"
        :job-offer-j-s-o-n-u-r-l="jobOfferJSONURL"
        :filter-key-value="filterKeyValue"
        @closeclick="handleInfoWindowClose"
        @display-details="displayDetails"
      />
      <!-- Marker for current position -->
      <GmapMarker
        v-if="currentPosition"
        :options="{
          position: currentPosition,
          icon: {
            url: getPositionIcon(),
            scaledSize: { width: 32, height: 32 }
          }
        }"
      />
      <!-- Control to reset position to current position -->
      <CustomControl position="RIGHT_BOTTOM">
        <div v-if="!initializing" :class="$style.positionControl">
          <button
            ref="positionBtn"
            :disabled="loadingPosition"
            :class="{
              [$style.disabled]: currentPosition === null
            }"
            @click="handlePositionIconClick"
          >
            <Loading
              v-if="loadingPosition"
              :class="$style.spinner"
              :size="20"
              background-color="transparent"
            />
            <PositionIcon v-else />
          </button>
        </div>
      </CustomControl>
    </GoogleMap>
    <MapLegend :items="legendItems" />
  </div>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue'
import { GoogleMap, Marker as GmapMarker, CustomControl } from 'vue3-google-map'
import MapInfoWindow from '../MapInfoWindow/MapInfoWindow.vue'
import MapLegend from '../MapLegend/MapLegend.vue'
import Loading from '../Loading/Loading.vue'
import PositionIcon from '../../assets/svg/Position.vue'
import { getCurrentPosition, getMarkerIcon, getPositionIcon } from './helpers'

const zoomDirectionEnum = {
  UNSET: 'UNSET',
  OUT: 'OUT',
  IN: 'IN',
  PREVENT: 'PREVENT'
}

const props = defineProps({
  height: {
    type: String,
    default: '100%'
  },
  width: {
    type: String,
    default: '100%'
  },
  mapTypeId: {
    type: String,
    default: 'roadmap'
  },
  mapZoom: {
    type: Number,
    default: 10
  },
  markers: {
    type: Array,
    default: () => []
  },
  legendItems: {
    type: Array,
    default: () => []
  },
  useForm: {
    type: Boolean,
    default: false
  },
  jobOfferJSONURL: {
    type: String,
    default: ''
  },
  filterKeyValue: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['loadingChange', 'displayDetails'])

// refs
const boundsChangedListener = ref(null)
const currentPosition = ref(null)
const dirty = ref(false)
const infoWindow = ref({
  opened: false,
  geolocation: null,
  details: null,
  options: {
    pixelOffset: {
      width: 0,
      height: -35
    }
  }
})
const initializing = ref(true)
const loadingPosition = ref(false)
const map = ref(null)
const mapCenter = ref({
  lat: -1,
  lng: -1
})
const zoom = ref(props.mapZoom)
const zoomDirection = ref(zoomDirectionEnum.UNSET)

/*
 * * methods
 */

const checkUserPosition = () => {
  dirty.value = false
  loadingPosition.value = true
  getCurrentPosition()
    .then((currentPos) => {
      currentPosition.value = currentPos
      map.value.map.setCenter(currentPos)
      mapCenter.value = currentPos

      checkAvailableMarker()
    })
    .catch((/* err */) => {
      getAllMarkerBounds()
      emit('loadingChange', false)
      initializing.value = false
    })
    .finally(() => {
      loadingPosition.value = false
    })
}

const toggleInfoWindow = (marker) => {
  if (infoWindow.value.details?.id == marker.id) {
    handleInfoWindowClose()
  } else {
    const { geolocation } = marker

    infoWindow.value = {
      geolocation: geolocation,
      details: marker,
      opened: true,
      options: { ...infoWindow.value.options }
    }

    // delay for ipad
    window.setTimeout(() => {
      infoWindow.value.opened = false

      window.setTimeout(() => {
        infoWindow.value.opened = true
      }, 10)
    }, 10)
  }
}

const handleInfoWindowClose = () => {
  infoWindow.value = {
    geolocation: null,
    details: null,
    options: {
      pixelOffset: {
        width: 0,
        height: -35
      }
    }
  }
}

const fakePosition = () => {
  const currentPos = {
    lat: 49.97318248358994,
    lng: 9.185904966660072
  }
  dirty.value = false
  loadingPosition.value = true

  window.setTimeout(() => {
    currentPosition.value = currentPos
    map.value.setCenter({ ...currentPos })
    checkAvailableMarker()
    loadingPosition.value = false
  }, 2000)
}

const handlePositionIconClick = (event) => {
  event.shiftKey ? fakePosition() : checkUserPosition()
}

const displayDetails = (url) => {
  emit('displayDetails', url)
}

const getAllMarkerBounds = () => {
  if (!map.value) {
    return
  }

  if (props.markers.length > 1) {
    const bounds = new map.value.api.LatLngBounds()

    props.markers.forEach((marker) => {
      bounds.extend(marker.geolocation)
    })

    map.value.map.fitBounds(bounds)
  } else if (props.markers.length === 1) {
    const {
      geolocation: { lat, lng }
    } = props.markers[0]

    map.value.map.setCenter({
      lat,
      lng,
      zoom: props.mapZoom
    })
  }
}

const checkAvailableMarker = () => {
  const btnWidthPercent = 60 /* button + margin */ / map.value.$el.offsetWidth
  const bounds = map.value.map.getBounds()
  const north = bounds.getNorthEast().lng()
  const east = bounds.getNorthEast().lat()
  const south = bounds.getSouthWest().lng()
  const west = bounds.getSouthWest().lat()
  const verticalPadding = (north - south) / 10
  const horizontalPadding = (east - west) / 10

  let countMarkerInView = 0

  props.markers.forEach((marker) => {
    const { lat, lng } = marker.geolocation

    if (
      lng <= north - verticalPadding &&
      lng >= south + verticalPadding &&
      lat <= east - (east - west) * btnWidthPercent &&
      lat >= west + horizontalPadding
    ) {
      countMarkerInView++
    }
  })

  const gmap = map.value.map
  if (!boundsChangedListener.value) {
    boundsChangedListener.value = gmap.addListener('bounds_changed', () => {
      checkAvailableMarker()
    })
  }

  if (zoomDirection.value !== zoomDirectionEnum.PREVENT) {
    if (countMarkerInView === 0 && zoom.value > 0 && zoomDirection.value !== zoomDirectionEnum.IN) {
      zoomDirection.value = zoomDirectionEnum.OUT
      zoom.value--
      return
    } else if (
      countMarkerInView > 0 &&
      zoom.value < props.mapZoom &&
      zoomDirection.value !== zoomDirectionEnum.OUT
    ) {
      zoomDirection.value = zoomDirectionEnum.IN
      zoom.value++
      return
    } else if (countMarkerInView === 0 && zoomDirection.value === zoomDirectionEnum.IN) {
      zoomDirection.value = zoomDirectionEnum.PREVENT
      zoom.value--
      return
    }
  }

  map.value.api.event.removeListener(boundsChangedListener.value)
  zoomDirection.value = zoomDirectionEnum.UNSET
  emit('loadingChange', false)
  initializing.value = false
  boundsChangedListener.value = null
}

const initDragListener = () => {
  const gmap = map.value.map
  gmap.addListener('dragstart', () => {
    dirty.value = true
  })
}

const initZoomChangeListener = () => {
  const gmap = map.value.map
  gmap.addListener('zoom_changed', () => {
    zoom.value = gmap.getZoom()
  })
}

/*
 * * computed values
 */

const mapStyle = computed(() => {
  return `height: ${props.height};width: ${props.width};`
})

/*
 * * watchers
 */

watchEffect(() => {
  if (!map?.value?.ready || dirty.value) return

  infoWindow.value.opened = false

  if (currentPosition.value) {
    checkAvailableMarker()
    map.value.map.setCenter({
      ...currentPosition.value
    })
  } else {
    getAllMarkerBounds()
  }
})

watch(
  () => map.value?.ready,
  (ready) => {
    if (ready) {
      const gmap = map.value.map
      initDragListener()
      initZoomChangeListener()
      boundsChangedListener.value = gmap.addListener('bounds_changed', () => {
        map.value.api.event.removeListener(boundsChangedListener.value)
        boundsChangedListener.value = null
        checkUserPosition()
      })
    }
  }
)
</script>

<style module lang="scss">
@import './MapVue3.scss';
</style>
