<template>
  <div>
    <div v-if="config.headerImageRef" class="MicrositeHR__contentGrid MicrositeHR__firstGrid">
      <VideoImage :config="{ imageSrc: config.headerImageRef }" />
    </div>
    <!-- <div class="MicrositeHR__contentGrid"> -->
    <div class="MicrositeHR__contentGrid">
      <div class="MicrositePlaceholder__container MicrositeHR__jobDetailsIntro">
        <div
          v-if="config.division || config.city || config.employeeStatus.length || config.workplace"
          class="MicrositeHR__infoBar"
        >
          <ul>
            <li v-if="config.fieldOfActivity">
              <i class="fas fa-home" />{{ config.fieldOfActivity }}
            </li>
            <li v-if="config.zip || config.city">
              <i class="fas fa-globe-europe" />{{ config.zip }}
              <span v-if="config.zip && config.city" class="MicrositeHR__comma">,</span
              >{{ config.city }}
            </li>
            <li v-if="config.careerLevel"><i class="fas fa-rocket" />{{ config.careerLevel }}</li>
            <li
              v-if="
                config.employeeStatus.length ||
                (config.publishInJobAd === 'true' && config.workplace)
              "
            >
              <i class="fas fa-clock" />
              <span
                v-for="(emplStatus, index) in config.employeeStatus"
                :key="`${index}-${emplStatus}`"
                >{{ emplStatus
                }}<span
                  v-if="
                    config.employeeStatus.length > 1 && index !== config.employeeStatus.length - 1
                  "
                  class="MicrositeHR__comma"
                  >,</span
                ></span
              >
              <span v-if="config.publishInJobAd === 'true' && config.workplace"
                ><span v-if="config.employeeStatus.length" class="MicrositeHR__comma">,</span
                >{{ config.workplace }}</span
              >
            </li>
          </ul>
        </div>
        <div class="MicrositeHR__mainInfoBar">
          <h1 v-if="config.title" class="MicrositePlaceholder__startElementTitle">
            {{ config.title }}
          </h1>
          <a
            v-if="config.createPdf === 'true' && config.pdfLink"
            :href="config.pdfLink"
            target="_blank"
            ><i class="fa fa-file-pdf mr-1" aria-hidden="true" /> {{ config.pdfLinkText }}</a
          >
        </div>
      </div>
    </div>
    <div class="MicrositeHR__contentGrid">
      <div class="MicrositePlaceholder__contentArea">
        <div class="Wysiwyg__container">
          <h2 v-if="config.externalDescriptionTitle">
            {{ config.externalDescriptionTitle }}
          </h2>
          <ul v-if="config.externalDescription.length">
            <li
              v-for="(externalDescription, index) in config.externalDescription"
              :key="`${index}-${externalDescription}`"
              v-html="externalDescription"
            />
          </ul>
        </div>
      </div>
      <hr style="margin: 0" />
      <div class="MicrositePlaceholder__contentArea">
        <div class="Wysiwyg__container">
          <h2 v-if="config.minimumQualificationTitle">
            {{ config.minimumQualificationTitle }}
          </h2>
          <ul v-if="config.minimumQualification.length">
            <li
              v-for="(minimumQualification, index) in config.minimumQualification"
              :key="`${index}-${minimumQualification}`"
              v-html="minimumQualification"
            />
          </ul>
        </div>
      </div>
      <hr style="margin: 0" />
    </div>
    <div v-if="config.benefits.length" class="MicrositeHR__contentGrid">
      <div class="MicrositePlaceholder__contentArea">
        <h2 v-if="config.benefitsTitle">
          {{ config.benefitsTitle }}
        </h2>
        <div class="MicrositeHR__benefits">
          <div
            v-for="(benefit, index) in config.benefits"
            :key="`${index}-${benefit.label}`"
            class="MicrositeHR__benefitsItem"
          >
            <i v-if="benefit.icon" :class="benefit.icon" />
            <img
              v-else-if="
                screenSize === 'mobile' &&
                benefit.imageDark &&
                [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31].indexOf(index + 1) > -1
              "
              :src="benefit.imageDark"
              :alt="benefit.label"
            />
            <img
              v-else-if="screenSize === 'mobile' && benefit.imageLight"
              :src="benefit.imageLight"
              :alt="benefit.label"
            />
            <img
              v-else-if="
                screenSize === 'desktop' &&
                benefit.imageDark &&
                [2, 4, 5, 7, 10, 12, 13, 15, 18, 20, 21, 23, 26, 28, 29, 31].indexOf(index + 1) > -1
              "
              :src="benefit.imageDark"
              :alt="benefit.label"
            />
            <img
              v-else-if="screenSize === 'desktop' && benefit.imageLight"
              :src="benefit.imageLight"
              :alt="benefit.label"
            />
            <h4 v-if="benefit.label">
              {{ benefit.label }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="MicrositeHR__contentGrid">
      <div class="MicrositePlaceholder__container">
        <div class="MicrositePlaceholder__contentArea">
          <div
            v-if="config.disclaimerText"
            class="MicrositeHR__disclaimer"
            v-html="config.disclaimerText"
          />
          <hr />
          <Button
            colour="blue"
            :text="config.applyButtonText"
            no-border
            is-link
            target-blank
            :href="config.applicationLink"
            class="MicrositeHR__applyBtn Button__right"
          />
          <hr />
          <div v-if="config.owner" class="MicrositeHR__contact">
            <h2 v-if="config.contactTitle">
              {{ config.contactTitle }}
            </h2>
            <div class="MicrositeHR__contactContent">
              <ul>
                <li v-if="config.owner.name">
                  {{ config.owner.name }}
                </li>
                <li v-if="config.owner.jobTitle">
                  {{ config.owner.jobTitle }}
                </li>
                <li v-if="config.owner.phone">
                  {{ config.owner.phone }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="config.footerImageRef" class="MicrositeHR__contentGrid">
      <img :src="config.footerImageRef" :alt="config.title" class="MicrositeHR__footerImg" />
    </div>
  </div>
</template>

<script setup>
import VideoImage from '../VideoImage/VideoImage.vue'
import Button from '../Button/Button.vue'
import Viewports from '../../globals/constants/viewports'
import { onMounted, ref } from 'vue'

defineProps({
  videoImage: {
    default: 'jobad_fallback_header.png'
  },
  config: {
    type: Object,
    default: () => {}
  }
})

const screenSize = ref(null)

const initScreenSize = () => {
  screenSize.value = 'desktop'
  if (window.matchMedia(`(max-width: ${Viewports.SMALL_MAX})`).matches) {
    screenSize.value = 'mobile'
  }
}

onMounted(() => {
  initScreenSize()
})
</script>
