<template>
  <div class="ContentTabs__container">
    <div v-if="!isMobile" class="ContentTabs__tabs">
      <a
        v-for="(tab, index) in config.length"
        :key="`${index}-aKey`"
        :class="[
          'ContentTabs__tab',
          { ContentTabs__active: activeTab === tab },
          { ContentTabs__disabled: config[index].disabled }
        ]"
        @click="updateTabs(tab)"
      >
        {{ config[index].title }}
      </a>
      <div
        v-for="(tab, index) in config.length"
        :key="`${index}-bKey`"
        :class="['ContentTabs__content', { ContentTabs__activeContent: activeTab === tab }]"
      >
        <div v-if="contentLoaded === false" class="ContentTabs__preloadFilter" />
        <div v-if="activeTab === tab && !config[index].withFilter" v-html="content" />
        <div v-if="activeTab === tab && config[index].withFilter">
          <BranchList :config="listConfig" />
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="ContentTabs__tabs ContentTabs__mobile">
      <div v-for="(tab, index) in config.length" :key="`${index}-cKey`">
        <a
          v-if="!config[index].disabled"
          :class="[
            'ContentTabs__tab',
            { ContentTabs__active: activeTab === tab && mobileHidden !== tab }
          ]"
          @click="updateTabs(tab)"
        >
          {{ config[index].title }}
        </a>
        <div
          :class="[
            'ContentTabs__content',
            { ContentTabs__activeContent: activeTab === tab && !isMobile },
            {
              ContentTabs__activeContent: activeTab === tab && isMobile && mobileHidden !== tab
            }
          ]"
        >
          <div v-if="contentLoaded === false" class="preloadFilter">
            <Loading />
          </div>
          <div v-if="activeTab === tab && !config[index].withFilter" v-html="content" />
          <div v-if="activeTab === tab && config[index].withFilter">
            <BranchList :config="listConfig" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import BranchList from '../BranchList/BranchList.vue'
import Loading from '../Loading/Loading.vue'

import { onMounted, onUnmounted, ref } from 'vue'
import { checkIsMobile } from '../../utils/media'

const props = defineProps({
  config: {
    type: Array,
    default: () => []
  }
})

const isMobile = ref(false)
const activeTab = ref(null)
const mobileHidden = ref(null)
const content = ref(null)
const contentLoaded = ref(false)
const activeTabPreselected = ref(false)
const listConfig = ref('')

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const updateTabs = (tab) => {
  const elm = event.currentTarget
  if (!elm.classList.contains('ContentTabs__disabled')) {
    contentLoaded.value = false
    if (props.config[tab - 1].url) {
      getContentFromUrl(props.config[tab - 1].url)
      content.value = ''
    } else if (props.config[tab - 1].id) {
      getContentFromId(props.config[tab - 1].id)
    } else if (props.config[tab - 1].withFilter) {
      listConfig.value = props.config[tab - 1].listConfig
      contentLoaded.value = true
    } else {
      content.value = null
    }
    if (isMobile.value && activeTab.value === tab && activeTab.value !== mobileHidden.value) {
      mobileHidden.value = tab
    } else if (
      isMobile.value &&
      activeTab.value === tab &&
      activeTab.value === mobileHidden.value
    ) {
      mobileHidden.value = null
    } else if (isMobile.value && activeTab.value !== tab) {
      mobileHidden.value = null
      activeTab.value = tab
    } else {
      activeTab.value = tab
    }
  }
}

const getContentFromId = (id) => {
  const html = document.getElementById(id)
  if (html) {
    content.value = html.innerHTML
    contentLoaded.value = true
  } else {
    content.value = null
    contentLoaded.value = false
  }
}

const getContentFromUrl = (url) => {
  axios
    .get(url)
    .then((response) => ((content.value = response.data), (contentLoaded.value = true)))
    .catch((error) => (console.log(error), (content.value = null), (contentLoaded.value = false)))
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize.value)
  checkScreenSize()
  const queries = window.location.search.substring(1).split('&')
  let queryStringTabId = null
  if (queries) {
    for (const query of queries) {
      if (query.includes('tab')) {
        queryStringTabId = query.split('=')[1]
      }
    }
  }

  for (const [index, tab] of props.config.entries()) {
    if (
      (queryStringTabId && queryStringTabId == index + 1 && !tab.disabled) ||
      (tab.active && !tab.disabled)
    ) {
      activeTab.value = index + 1
      activeTabPreselected.value = true
      if (tab.url) {
        getContentFromUrl(tab.url)
      } else if (tab.id) {
        getContentFromId(tab.id)
      } else if (tab.withFilter) {
        listConfig.value = tab.listConfig
        contentLoaded.value = true
      }
    }
  }
  if (!activeTabPreselected.value) {
    activeTab.value = 1
    if (props.config[0].url) {
      this.getContentFromUrl(props.config[0].url)
    } else if (props.config[0].id) {
      this.getContentFromId(props.config[0].id)
    } else if (props.config[0].withFilter) {
      listConfig.value = props.config[0].listConfig
      contentLoaded.value = true
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize.value)
})
</script>
