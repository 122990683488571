<template>
  <figure :class="$style.container">
    <a
      v-if="isVideo"
      :class="[$style.mediaLink, $style.videoLink]"
      aria-label="goto detail"
      @click="
        (isModalOpen = true),
          wtParams &&
            wtTrack(
              { cat: wtParams.doctype },
              mergeWtParams(
                wtParams,
                {
                  filename: primaryLink
                },
                {}
              )
            )
      "
    >
      <i class="fab fa-youtube" />
      <img :src="image" :alt="linkTitle" />
    </a>
    <a
      v-if="!isVideo"
      :class="$style.mediaLink"
      :href="primaryLink"
      :target="primaryLinkNewWindow ? '_blank' : '_self'"
      @click="
        wtParams &&
          wtTrack(
            { cat: wtParams.doctype },
            mergeWtParams(
              wtParams,
              {
                filename: primaryLink
              },
              {}
            )
          )
      "
    >
      <img :src="image" alt="" />
    </a>
    <figcaption>
      <a
        v-if="category"
        :class="$style.secondaryLink"
        :href="secondaryLink"
        :target="secondaryLinkNewWindow ? '_blank' : '_self'"
      >
        {{ category }}
      </a>
      <a
        v-if="isVideo && linkTitle"
        :class="$style.primaryLink"
        @click="
          (isModalOpen = true),
            wtParams &&
              wtTrack(
                { cat: wtParams.doctype },
                mergeWtParams(
                  wtParams,
                  {
                    filename: primaryLink
                  },
                  {}
                )
              )
        "
      >
        {{ linkTitle }}
      </a>
      <a
        v-if="!isVideo && linkTitle"
        :class="$style.primaryLink"
        :href="primaryLink"
        :target="primaryLinkNewWindow ? '_blank' : '_self'"
        @click="
          wtParams &&
            wtTrack(
              { cat: wtParams.doctype },
              mergeWtParams(
                wtParams,
                {
                  filename: primaryLink
                },
                {}
              )
            )
        "
      >
        {{ linkTitle }}
      </a>
    </figcaption>
    <Modal
      v-if="isVideo"
      :is-modal-open="isModalOpen"
      :is-video="isVideo"
      @close-modal="isModalOpen = false"
    >
      <iframe
        :data-src="primaryLink"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </Modal>
  </figure>
</template>

<script setup>
import Modal from '../Modal/Modal.vue'
import { mergeSwitchWtParams } from '../../utils/tracking'
import { ref } from 'vue'

defineProps({
  isVideo: {
    type: Boolean,
    default: false
  },
  primaryLink: {
    type: String,
    default: ''
  },
  primaryLinkNewWindow: {
    type: Boolean,
    default: true
  },
  secondaryLink: {
    type: String,
    default: ''
  },
  secondaryLinkNewWindow: {
    type: Boolean,
    default: true
  },
  category: {
    type: String,
    default: ''
  },
  linkTitle: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  },
  wtParams: {
    type: Object,
    default: () => {}
  }
})

const isModalOpen = ref(false)
const wtTrack = window.wtTrack

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}
</script>

<style module lang="scss">
@import './ContentTeaserS.scss';
</style>
