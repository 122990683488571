<template>
  <div
    v-show="!loading"
    :class="$style.select"
    @mouseenter="options.length > 1 && !isMobile && switcher.activate()"
    @mouseleave="options.length > 1 && !isMobile && switcher.deactivate()"
  >
    <VueMultiselect
      v-if="options.length > 1"
      ref="switcher"
      v-model="value"
      track-by="title"
      :allow-empty="false"
      :hide-selected="true"
      :options="options"
      :preselect-first="true"
      :searchable="false"
      :show-labels="false"
      @select="switchLanguage"
    >
      <template #singleLabel="selectProps">
        <Flag :flag="selectProps.option.img" class="option__image" />
        <span class="option__title">{{ selectProps.option.title }}</span>
      </template>
      <template #option="selectProps">
        <Flag :flag="selectProps.option.img" class="option__image" />
        <div class="option__desc">
          <span class="option__title">
            {{ selectProps.option.title }}
          </span>
        </div>
      </template>
    </VueMultiselect>
    <div v-else :class="$style.noSelect">
      <Flag :flag="options.at(0).img" class="option__image" />
      <span class="option__title">{{ options.at(0).title }}</span>
    </div>
  </div>
</template>

<script setup>
import { isMobile } from 'mobile-device-detect'
import VueMultiselect from 'vue-multiselect'
import Flag from '../Flag/Flag.vue'
import { onMounted, ref } from 'vue'

defineProps({
  options: {
    type: Array,
    default: () => [{ title: '', img: '', url: '' }]
  }
})

const switcher = ref(null)
const value = ref('')
let loading = ref(true)
let event = ref(null)

const switchLanguage = (option) => {
  if (loading.value) {
    return
  }

  window.dispatchEvent(event.value)
  window.location.href = option.url
}

onMounted(() => {
  event.value = new CustomEvent('externalFilterReset')
  loading.value = false
})
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style module lang="scss">
@import './LanguageSwitcher.scss';
</style>
