import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  const elements = document.querySelectorAll('.HRTeaser')

  if (elements.length) {
    elements.forEach((element) => {
      const app = createApp(App, {
        ...element.dataset
      })

      app.mount(`#${element.id}`)
    })
  }
}
