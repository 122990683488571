<template>
  <div
    :class="[
      { Select__blue: colour === 'blue' },
      { Select__white: colour === 'white' },
      { Select__filter: colour === 'filter' },
      $style.select
    ]"
  >
    <VueMultiselect
      v-if="isMulti"
      ref="multiSelectRef"
      v-model="multiValue"
      :options="optionsRef"
      :searchable="false"
      :show-labels="false"
      :track-by="trackBy"
      :label="label"
      :placeholder="placeholder || label"
      :allow-empty="allowEmpty"
      :index="index"
      :multiple="true"
      @remove="handleRemoveLastValue(multiValue.length)"
    >
      <template #tag="selectProps">
        <span class="multiselect__tag">
          <span v-html="selectProps.option" />
          <i class="multiselect__tag-icon" @click="selectProps.remove(selectProps.option)" />
        </span>
      </template>
      <template #singleLabel="selectProps">
        <span v-html="getOptionLabel(selectProps.option)" />
      </template>
      <template #option="selectProps">
        <span v-html="getOptionLabel(selectProps.option)" />
      </template>
    </VueMultiselect>
    <VueMultiselect
      v-else
      ref="singleSelectRef"
      v-model="value"
      :options="optionsRef"
      :searchable="searchable"
      :show-labels="false"
      :track-by="trackBy"
      :label="label"
      :placeholder="placeholder"
      :allow-empty="allowEmpty"
      :index="index"
      :disabled="disabled"
      class="single"
      @close="onClose()"
    >
      <template #singleLabel="selectProps">
        <span v-html="getOptionLabel(selectProps.option)" />
      </template>
      <template #option="selectProps">
        <span v-html="getOptionLabel(selectProps.option)" />
      </template>
    </VueMultiselect>
  </div>
</template>

<script setup>
import VueMultiselect from 'vue-multiselect'
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  colour: {
    type: String,
    default: 'blue'
  },
  selectValue: {
    type: String,
    default: ''
  },
  selectMultiValue: {
    type: [String, Array],
    default: () => []
  },
  selectOptions: {
    type: Array,
    default: () => []
  },
  selectIndex: {
    type: String,
    default: ''
  },
  trackBy: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  placeholder: {
    type: String,
    default: undefined
  },
  isMulti: {
    type: Boolean,
    default: false
  },
  category: {
    type: String,
    default: undefined
  },
  activeSelect: {
    type: String,
    default: undefined
  },
  resetMultiSelects: {
    Type: Boolean,
    default: false
  },
  searchable: {
    Type: Boolean,
    default: false
  },
  allowEmpty: {
    Type: Boolean,
    default: true
  },
  disabled: {
    Type: Boolean,
    default: false
  }
})

const emit = defineEmits(['onChange', 'multiChanged', 'isEmpty'])

const value = ref('')
const multiValue = ref([])
const optionsRef = ref([])
const index = ref(props.selectIndex)
const singleSelectRef = ref(null)
const multiSelectRef = ref(null)
const isClosed = ref(false)

const getOptionLabel = (option) => {
  return typeof option === 'object' ? option[props.label] : option
}

const handleRemoveLastValue = (length) => {
  if (length === 0) {
    multiValue.value = []
    multiValue.value.push(optionsRef.value[0] || props.selectOptions[0])
  }
}

const onClose = () => {
  return (isClosed.value = true)
}

watch(value, (newValue, oldValue) => {
  if (newValue !== oldValue && !props.isMulti) {
    if (!newValue) {
      if (props.resetMultiSelects) {
        value.value = ''
      } else {
        value.value = oldValue
      }
    } else {
      emit('onChange', newValue)
    }
  }
})

watch(multiValue, (newValue, oldValue) => {
  if (newValue !== oldValue && props.isMulti) {
    if (newValue.length === 0) {
      multiValue.value.push(optionsRef.value[0])
    } else if (newValue.length > 1) {
      if (newValue[0] === optionsRef.value[0]) {
        multiValue.value.shift()
      } else if (newValue.includes(optionsRef.value[0])) {
        multiValue.value = [optionsRef.value[0]]
      }
    }
    emit('multiChanged', multiValue.value)
  }
})

watch(
  () => props.selectValue,
  (newValue, oldValue) => {
    if (!props.isMulti) {
      if (newValue !== oldValue && newValue) {
        return (value.value = Array.isArray(props.selectValue)
          ? props.selectValue[0]
          : props.selectValue)
      }
      emit('onChange', value.value)
    }
  }
)

watch(
  () => props.resetMultiSelects,
  (newValue, oldValue) => {
    if (newValue !== oldValue && newValue) {
      if (props.isMulti) {
        multiValue.value = []
      } else if (value.value) {
        value.value = ''
      }
    }
  }
)

watch(isClosed, () => {
  if (isClosed.value && !props.allowEmpty) {
    value.value ? emit('isEmpty', false) : emit('isEmpty', true)
    isClosed.value = false
  }
})

watch(
  () => props.selectOptions,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      optionsRef.value = props.selectOptions
    }
  }
)

onMounted(() => {
  optionsRef.value = props.selectOptions
  if (props.isMulti) {
    multiValue.value = props.selectMultiValue
  } else {
    value.value = props.selectValue
  }
})

onBeforeUnmount(() => {
  optionsRef.value = []
  value.value = ''
  multiValue.value = []
})
</script>

<style module lang="scss">
@import './Select.scss';
</style>
