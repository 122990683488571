import axios from 'axios'

const checkLoginWithUrl = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'get',
      responseType: 'json',
      withCredentials: true
    })
      .then((response) => {
        const jsonResponse = JSON.parse(response.data)
        if (jsonResponse.message) {
          resolve({
            isLoggedIn: jsonResponse.message.isLoggedIn,
            discount: jsonResponse.message.discount,
            typesExcludedDiscount: jsonResponse.message.typesExcludedDiscount
          })
        } else {
          resolve({
            isLoggedIn: response.data,
            discount: 0,
            typesExcludedDiscount: ''
          })
        }
      })
      .catch((error) => reject(error))
  })
}

export default checkLoginWithUrl
