<template>
  <div :class="$style.container">
    <div v-if="config?.isBestSellers" :class="[$style.bestSellers, 'columns is-multiline']">
      <ShopProductTeaser
        v-for="(product, index) in config?.products"
        :key="index"
        :product="product"
        :texts="config?.texts"
      />
    </div>
    <div v-else :class="[$style.newProducts, 'columns is-multiline']">
      <div
        v-for="(product, index) in newProducts"
        :key="index"
        class="column is-half-tablet is-one-third-desktop productContainer"
      >
        <div class="image">
          <a href="#">
            <img
              src="https://shop.wika.de/upload/WIKA_Thumbnails/Product-MicrositeEntry-Thumb/PIC_PR_A_10_de_de_2942.jpg.png"
              alt="Typ A-10"
            />
          </a>
        </div>
        <div class="content">
          <a href="#">
            <span class="markup5">Drucksensor</span>
            <span>Für allgemeine industrielle Anwendungen</span>
            <p class="typ">Typ A-10</p>
          </a>
        </div>
        <div class="productProperties">
          <div class="productPropertiesLeft">
            <span class="shop-price">
              <strong>ab 107,64 €</strong>
              zzgl. MwSt. &amp;
              <a href="#">Versand</a>
            </span>
          </div>
          <div class="productPropertiesRight">
            <a href="#">
              <span>Zum Produkt</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ShopProductTeaser from '../ShopProductTeaser/ShopProductTeaser.vue'

defineProps({
  config: {
    isBestSellers: {
      type: Boolean,
      default: true
    },
    texts: {
      type: Object,
      default: () => {}
    },
    products: {
      type: Array,
      default: []
    }
  }
})

const newProducts = 3
</script>

<style module lang="scss">
@import './ShopProductList.scss';
</style>
