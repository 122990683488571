<template>
  <div :class="$style.container">
    <div :class="$style.title">Direkt bestellen</div>
    <div :class="$style.priceTag">
      <strong>ab 19,58 EUR</strong>
      <span>
        zzgl. MwSt. &amp;
        <a href="https://shop.wika.de/service_info_shipping_shop_de_de.WIKA" target="_blank"
          >Versand</a
        >
      </span>
    </div>
    <a href="#">Zum Online-Shop</a>
  </div>
</template>

<script setup>
import { useCssModule } from 'vue'

const $style = useCssModule()
</script>

<style module lang="scss">
@import './SidebarShopTeaser.scss';
</style>
