<template>
  <div>
    <form v-if="!stepOneComplete" :class="$style.container">
      <p :class="$style.text">
        {{ config.text }}
      </p>
      <div class="columns">
        <div class="column is-half-tablet">
          <FormInput
            v-model.trim="v$.formData.email.$model"
            :label="config.labels.email.label"
            :bold-label="true"
            :error-message="config.labels.email.errorMessage"
            :error="v$.formData.email.$error && v$.$dirty && isStepOneSubmitted"
            :original-value="previousEmail && previousEmail !== '' ? previousEmail : null"
            :form-submit-switch="formSubmitSwitch"
          />
        </div>
      </div>
      <div v-if="serverError" :class="$style.serverError">
        {{ config.serverErrorMessage }}
      </div>
      <div v-if="noSubscriptionsError" :class="$style.serverError">
        {{ config.noSubscriptionsErrorMessage }}
      </div>
      <div class="columns">
        <div class="column has-text-right-tablet">
          <Button
            colour="blue"
            :text="config.firstButtonText"
            type="submit"
            :is-form="true"
            @close="handleStepOneSubmit"
          />
        </div>
      </div>
      <ReCaptcha :execute-switch="executeSwitch" :submit="stepOneSubmit" />
    </form>
    <form v-else :class="$style.container">
      <p :class="$style.stepTwoTitle">
        {{ config.optionalSectionTitle }}
      </p>
      <p :class="$style.text">
        <span v-if="checkboxes.length > 1">{{ config.optionalSectionTextPartOne }}</span>
        <span>{{ config.optionalSectionTextPartTwo }}</span>
      </p>
      <div :class="[$style.checkboxes, 'columns is-mobile is-multiline']">
        <div
          v-for="(checkbox, index) in checkboxes"
          :key="`checkbox-${index}`"
          class="column is-half"
        >
          <input
            :id="`checkbox-${index}`"
            v-model="selectedIds"
            type="checkbox"
            :name="`checkbox-${index}`"
            :value="checkbox.newsletterId"
          />
          <label :for="`checkbox-${index}`">{{ checkbox.label }}</label>
        </div>
      </div>
      <div v-if="serverError" :class="$style.serverError">
        {{ config.serverErrorMessage }}
      </div>
      <div v-if="serverSuccess" :class="$style.serverSuccess">
        {{ config.unsubscribeSuccess }}
      </div>
      <div v-if="checkboxError" :class="$style.serverError">
        {{ config.checkboxErrorMessage }}
      </div>
      <div class="columns">
        <div class="column has-text-right-tablet">
          <Button
            colour="blue"
            :text="config.secondButtonText"
            type="submit"
            :is-form="true"
            @close="handleStepTwoSubmit"
          />
        </div>
      </div>
      <ReCaptcha :execute-switch="executeSwitch" :submit="stepTwoSubmit" />
    </form>
  </div>
</template>

<script setup>
import { required, email as emailRule } from '@vuelidate/validators'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'
import FormInput from '../FormInput/FormInput.vue'
import Button from '../Button/Button.vue'
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

// form refs
const formData = ref({
  newsletter: '',
  email: '',
  domain: '',
  pageUrl: '',
  token: ''
})

// regular refs
const checkboxes = ref([])
const newsletterIds = ref([])
const selectedIds = ref([])
const previousEmail = ref('')
const isStepOneSubmitted = ref(false)
const formSubmitSwitch = ref(false)
const executeSwitch = ref(false)
const stepOneComplete = ref(false)
const serverError = ref(false)
const serverSuccess = ref(false)
const noSubscriptionsError = ref(false)
const checkboxError = ref(false)

const rules = {
  formData: {
    email: {
      required,
      emailRule
    }
  }
}

const v$ = useVuelidate(rules, { formData })

onMounted(() => {
  if (props.config.newsletters) {
    for (const newsletter of props.config.newsletters) {
      newsletterIds.value.push(parseFloat(newsletter.newsletterId))
    }
  }
})

const handleStepOneSubmit = () => {
  serverError.value = false
  serverSuccess.value = false
  v$.value.$touch()
  isStepOneSubmitted.value = true
  formSubmitSwitch.value = !formSubmitSwitch.value
  if (!v$.$invalid) {
    executeSwitch.value = !executeSwitch.value
  } else {
    previousEmail.value = formData.value.email
  }
}

const initStepTwo = (data) => {
  checkboxes.value = []
  for (const id of data) {
    const item = {
      newsletterId: id.toString(),
      label: props.config.newsletters[newsletterIds.value.indexOf(id)].label
    }
    checkboxes.value.push(item)
  }
  stepOneComplete.value = true
}

const handleStepTwoSubmit = () => {
  serverError.value = false
  serverSuccess.value = false
  noSubscriptionsError.value = false
  checkboxError.value = false
  v$.value.formData.email.$touch()
  if (!v$.value.$invalid) {
    if (selectedIds.value.length !== 0) {
      executeSwitch.value = !executeSwitch.value
    } else {
      checkboxError.value = true
    }
  } else {
    previousEmail.value = formData.value.email
  }
}

const stepOneSubmit = (newToken) => {
  formData.value.token = newToken
  formData.value.domain = window.location.origin
  formData.value.pageUrl = window.location.pathname
  axios({
    url: window.newsletterGetSubscriptionsUrl,
    method: 'get',
    responseType: 'json',
    params: {
      email: formData.value.email,
      listIds: newsletterIds.value.toString()
    }
  })
    .then((response) => {
      if (response.status === 200 && response.data.length !== 0) {
        initStepTwo(response.data)
      } else {
        serverError.value = true
      }
    })
    .catch((error) => {
      if (error.response.status === 500) {
        noSubscriptionsError.value = true
      } else {
        serverError.value = true
      }
      console.error(error)
    })
}

const stepTwoSubmit = (token) => {
  formData.value.token = token
  formData.value.domain = window.location.origin
  formData.value.pageUrl = window.location.pathname
  let combinedRequests = []
  for (const id of selectedIds.value) {
    formData.value.newsletter = id
    combinedRequests.push(
      axios({
        url: window.newsletterUnsubscribeUrl,
        method: 'post',
        data: { ...formData },
        responseType: 'json'
      })
    )
  }
  axios
    .all(combinedRequests)
    .then(
      axios.spread((...responses) => {
        if (responses.every((response) => response.status === 200)) {
          serverSuccess.value = true
        } else {
          serverError.value = true
          serverSuccess.value = false
        }
      })
    )
    .catch((errors) => {
      serverError.value = true
      serverSuccess.value = false
      console.error(errors)
    })
}
</script>

<style module lang="scss">
@import './NewsletterForm.scss';
</style>
