<template>
  <form :class="$style.container">
    <Button
      v-if="contactBack"
      :text="config.labels.backBtn"
      :class="$style.backBtn"
      @on-simple-click="handleBackClick"
    />
    <h1 v-if="contactTitle">{{ config.labels.contactTitle }} {{ contactTitle }}</h1>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.company.$model"
          :label="config.labels.company.label"
          :bold-label="true"
          :error-message="config.labels.company.errorMessage"
          :error="v$.formData.company.$error && v$.$dirty && isSubmitted"
          :original-value="previousData.company ? previousData.company : null"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.name.$model"
          :label="config.labels.name.label"
          :bold-label="true"
          :error-message="config.labels.name.errorMessage"
          :error="v$.formData.name.$error && v$.$dirty && isSubmitted"
          :original-value="previousData.name ? previousData.name : null"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.streetNr.$model"
          :label="config.labels.streetNr.label"
          :bold-label="true"
          :error-message="config.labels.streetNr.errorMessage"
          :error="v$.formData.streetNr.$error && v$.$dirty && isSubmitted"
          :original-value="previousData.streetNr"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.zipCity.$model"
          :label="config.labels.zipCity.label"
          :bold-label="true"
          :error-message="config.labels.zipCity.errorMessage"
          :error="v$.formData.zipCity.$error && v$.$dirty && isSubmitted"
          :original-value="previousData.zipCity"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <!--
      Use in FormInput
      :reset="true"
      to create a reset button at the right edge of any form filed.
      Example:
      <FormInput
      v-model.trim="v$.formData.phone.$model"
      :label="config.labels.phone.label"
      :optionalLabel="config.labels.phone.optionLabel"
      :error="false"
      :originalValue="previousData.phone"
      :formSubmitSwitch="formSubmitSwitch"
      :reset="true"
      /-->
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.phone.$model"
          :label="config.labels.phone.label"
          :optional-label="config.labels.phone.optionLabel"
          :error="false"
          :original-value="previousData.phone"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.fax.$model"
          :label="config.labels.fax.label"
          :optional-label="config.labels.fax.optionLabel"
          :error="false"
          :original-value="previousData.fax"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.email.$model"
          :label="config.labels.email.label"
          :bold-label="true"
          :error-message="config.labels.email.errorMessage"
          :error="v$.formData.email.$error && v$.$dirty && isSubmitted"
          :original-value="previousData.email"
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column" />
    </div>
    <div class="columns">
      <div class="column">
        <FormTextarea
          v-model.trim="v$.formData.message.$model"
          :label="config.labels.message.label"
          :error-message="config.labels.message.errorMessage"
          :error="v$.formData.message.$error && v$.$dirty && isSubmitted"
          :rows="5"
          :columns="20"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model.trim="v$.formData.fileList.$model"
          type="file"
          :label="config.labels.fileList.label"
          :optional-label="config.labels.fileList.optionLabel"
          :no-files-label="config.labels.fileList.noFilesLabel"
          :max-files-reached-label="config.labels.fileList.maxFilesReachedLabel"
          :error-message="config.labels.fileList.errorMessage"
          :error="false"
          :original-value="previousData.fileList"
          :form-submit-switch="formSubmitSwitch"
          @on-file-list-changed="handleFileUpload"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column has-text-right-tablet">
        <Button type="submit" :text="config.labels.sendBtn" :is-form="true" @close="handleSubmit" />
      </div>
    </div>
    <div v-if="serverError" :class="$style.serverError">
      {{ config.serverErrorMessage }}
    </div>
    <ReCaptcha :execute-switch="executeSwitch" :submit="submit" />
  </form>
</template>

<script setup>
import axios from 'axios'

import Button from '../Button/Button.vue'
import FormInput from '../FormInput/FormInput.vue'
import FormTextarea from '../FormTextarea/FormTextarea.vue'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'

import { ContactFormEnum } from '../../utils/form'
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const formData = ref({
  company: '',
  name: '',
  streetNr: '',
  zipCity: '',
  phone: '',
  fax: '',
  email: '',
  message: '',
  fileList: [],
  attachments: {
    name: 'Attachments',
    attachedFiles: []
  }
})

const rules = {
  formData: {
    company: {
      required
    },
    name: {
      required
    },
    streetNr: {
      required
    },
    zipCity: {
      required
    },
    phone: {},
    fax: {},
    email: {
      required,
      email
    },
    message: {
      required
    },
    fileList: {}
  }
}

const previousData = ref({})
const isSubmitted = ref(false)
const formSubmitSwitch = ref(false)
const executeSwitch = ref(false)
const serverError = ref(false)
const contactTitle = ref(undefined)
const contactBack = ref(false)

const v$ = useVuelidate(rules, { formData })

onMounted(() => {
  const { search } = window.location
  const params = new URLSearchParams(search)

  contactTitle.value = params.get(ContactFormEnum.contactTitle)
  formData.value.formEmail = params.get(ContactFormEnum.contactEmail)
  formData.value.distributorContact = params.get(ContactFormEnum.contactTitle)
  contactBack.value = params.get(ContactFormEnum.contactBack)
})

const handleFileUpload = (files) => {
  formData.value.attachments.attachedFiles = files
}

const handleBackClick = () => {
  window.history.back()
}

const handleSubmit = () => {
  serverError.value = false
  v$.value.$touch()
  isSubmitted.value = true
  formSubmitSwitch.value = !formSubmitSwitch.value

  if (!v$.value.$invalid) {
    executeSwitch.value = !executeSwitch.value
  } else {
    previousData.value = JSON.parse(JSON.stringify(formData.value))
  }
}

const submit = (token) => {
  const { thankyouUrl } = props.config
  const url = window.contactFormUrl
  const {
    location: { origin: domain, pathname: pageUrl },
    currentLanguage: language,
    currentCountry: country
  } = window

  axios({
    url,
    method: 'post',
    responseType: 'json',
    data: {
      ...formData.value,
      token,
      domain,
      pageUrl,
      language,
      country
    }
  })
    .then((response) => {
      if (response.status === 200) {
        window.open(thankyouUrl, '_self')
        return
      }

      throw response.statusText
    })
    .catch((error) => {
      serverError.value = true
      console.error(error)
    })
}
</script>

<style module lang="scss">
@import './ContactForm.scss';
</style>
