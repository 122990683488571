<template>
  <div
    :class="[
      { Button__blue: colour === 'blue' },
      { Button__orange: colour === 'orange' },
      { Button__white: colour === 'white' },
      { Button__darkGrey: colour === 'darkGrey' },
      { Button__close: isClose },
      { Button__noBorder: noBorder },
      { Button__roundedCorners: roundedCorners },
      { Button__backArrow: backArrow },
      $style.button
    ]"
  >
    <a
      v-if="isLink"
      :href="href"
      :aria-label="ariaLabel"
      :target="targetBlank ? '_blank' : '_self'"
    >
      <span>{{ text }}</span>
    </a>
    <a
      v-else-if="isTabLink"
      :href="href"
      :aria-label="ariaLabel"
      @click="handleTabLinkClick(tabID)"
    >
      {{ text }}
    </a>
    <button v-else-if="isForm" :type="type" :aria-label="ariaLabel" @click.prevent="handleClick()">
      {{ text }}
    </button>
    <button
      v-else-if="!isLink && !isClose"
      :type="type"
      :disabled="isDisabled"
      :aria-label="ariaLabel"
      @click="handleSimpleClick"
    >
      {{ text }}
    </button>
    <button
      v-else-if="!isLink && isClose && !text"
      :class="$style.closeX"
      :type="type"
      :aria-label="ariaLabel"
      @click="handleClick"
      v-html="rawHtml"
    />
    <button
      v-else-if="!isLink && isClose && text"
      :type="type"
      :aria-label="ariaLabel"
      @click="handleClick"
    >
      {{ text }}
    </button>
  </div>
</template>

<script setup>
import CustomEvents from '../../globals/constants/customEvents'

const emit = defineEmits(['close', 'onSimpleClick'])

const props = defineProps({
  colour: {
    type: String,
    default: 'blue'
  },
  href: String,
  isClose: {
    type: Boolean,
    default: false
  },
  isLink: {
    type: Boolean,
    default: false
  },
  isDisabled: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'button',
    validator: (value) => ['button', 'submit', 'reset'].indexOf(value) >= 0
  },
  isForm: {
    type: Boolean,
    default: false
  },
  noBorder: {
    type: Boolean,
    default: false
  },
  isTabLink: {
    type: Boolean,
    default: false
  },
  tabID: {
    type: String,
    default: ''
  },
  ariaLabel: {
    type: String,
    default: undefined
  },
  roundedCorners: {
    type: Boolean,
    default: false
  },
  targetBlank: {
    type: Boolean,
    default: false
  },
  backArrow: {
    type: Boolean,
    default: false
  }
})

const rawHtml = '&times;'

const handleClick = () => {
  window.dispatchEvent(
    new CustomEvent('button:close', {
      detail: { props }
    })
  )

  emit('close')
}
const handleSimpleClick = () => {
  window.dispatchEvent(
    new CustomEvent('button:onSimpleClick', {
      detail: { props }
    })
  )

  emit('onSimpleClick')
}

const handleTabLinkClick = (tabID) => {
  window.dispatchEvent(
    new CustomEvent(CustomEvents.OPEN_TAB_FROM_LINK, {
      detail: tabID
    })
  )
}
</script>

<style module lang="scss">
@import './Button.scss';
</style>
