<template>
  <div class="SubPage__container">
    <ShopPageHeader
      :shop-country-switcher-config="shopPageHeaderDefaultConfig.shopCountrySwitcherConfig"
      :search-config="shopPageHeaderDefaultConfig.searchConfig"
      :login-config="shopPageHeaderDefaultConfig.loginConfig"
      :cart-config="shopPageHeaderDefaultConfig.cartConfig"
      :primary-nav="shopPageHeaderDefaultConfig.primaryNav"
      :secondary-nav="shopPageHeaderDefaultConfig.secondaryNav"
    />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <BreadCrumbs :crumbs="crumbs" />
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <Button colour="blue" text="Zurück zur Übersicht" is-link href="#" />
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-three-fifths">
          <HeaderBox
            type="Typen 111.10, 111.12"
            title="Rohrfedermanometer, CrNi-Stahl"
            text="Für die Prozessindustrie, Standardausführung, NG 63, 100 und 160"
            :links="['Datenblatt']"
            :shop-extras="{
              linkText: 'Weitere Informationen (WIKA-Webseite)',
              url: '#',
              deliveryNotice:
                'Lieferhinweis: Lagergeräte sofort versandfertig; Fertigungsgeräte siehe Artikeldetails.'
            }"
            :warning="{
              title: 'Warning',
              text: 'This product can expose you to chemicals including Nickel and Chromium, which is known to the State of California to cause cancer and reproductive harm. For more information go to www.P65Warnings.ca.gov',
              url: '#'
            }"
          />
        </div>
        <div class="column is-two-fifths">
          <div class="SubPage__shopImage">
            <ImageMagnifier
              :alt="shopConfig.headline"
              :src="shopConfig.pictureurl"
              :zoom-src="shopConfig.imageZoom ?? shopConfig.pictureurl"
              width="100%"
              height="auto"
              mask-bg-color="#434140"
              mask-height="70"
              mask-width="70"
              :scale="5"
              :orientation="shopConfig.zoomOrientation"
            />
            <span class="SubPage__shopImageCaption">Beispielhafte Darstellung</span>
          </div>
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <ProductDetailTabs :config="tabsConfig" :shop-config="shopConfig" />
      <ProductSlider :config="slider1" />
      <ProductSlider :config="slider2" />
    </div>
    <ShopFooter />
  </div>
</template>

<script setup>
import Button from '../Button/Button.vue'
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import ShopFooter from '../ShopFooter/ShopFooter.vue'
import ShopPageHeader from '../ShopPageHeader/ShopPageHeader.vue'
import HeaderBox from '../HeaderBox/HeaderBox.vue'
import ImageMagnifier from '../ImageMagnifier/ImageMagnifier.vue'
import ProductSlider from '../ProductSlider/ProductSlider.vue'
import { onBeforeMount } from 'vue'
import ProductDetailTabs from '../../components/ProductDetailTabs/ProductDetailTabs.vue'

defineProps({
  crumbs: undefined,
  tabsConfig: undefined,
  shopConfig: undefined,
  slider1: undefined,
  slider2: undefined,
  shopPageHeaderDefaultConfig: {
    type: Object,
    default: () => ({})
  }
})

onBeforeMount(() => {
  window.instance = 'shop'
})
</script>
