<template>
  <div :class="[{ ListTeaser__linked: result.link }, $style.container]">
    <a
      v-if="result.link && !result.isVideo"
      :set="(isPdf = linkIsPDF(result.link))"
      :class="$style.teaserLink"
      :href="result.link"
      :target="isPdf ? '_blank' : result.target ? result.target : '_self'"
      aria-label="goto detail"
      @click="
        wtParams &&
          wtTrack(
            {
              cat: wtParams.doctype
            },
            mergeWtParams(wtParams, {
              ...result.wtParams,
              filename: result.link,
              ...(isPdf
                ? {
                    type: 'PDF'
                  }
                : {})
            })
          )
      "
    />
    <div v-if="result.image" :class="[$style.mediaLink, { ListTeaser__videoLink: result.isVideo }]">
      <i v-if="result.isVideo" class="fab fa-youtube" />
      <img :src="result.image" :alt="result.text?.replace(/(<([^>]+)>)/gi, '')" />
    </div>
    <div :class="$style.caption">
      <div v-if="result.subHeading" :class="$style.headingRow">
        <div :class="$style.subHeading">
          {{ result.subHeading }}
        </div>
        <Flag v-if="result.flag" :class="$style.flag" :flag="result.flag" />
      </div>
      <div v-if="result.country_name" :class="$style.subHeading">
        {{ result.country_name }}
      </div>
      <h5 v-if="result.title" :class="$style.title">
        {{ result.title }}
      </h5>
      <div
        v-if="result.company && !result.phone && !result.fax && !result.website && !result.email"
        :class="$style.text"
      >
        {{ result.company }}
      </div>
      <div
        v-if="result.company && (result.phone || result.fax || result.website || result.email)"
        :class="$style.headingRow"
      >
        <h5 :class="$style.title">
          {{ result.company }}
        </h5>
        <Flag v-if="!result.subHeading && result.flag" :class="$style.flag" :flag="result.flag" />
      </div>
      <div v-if="result.address" :class="$style.address">
        <span v-html="result.address.replace(/(?:\r\n|\r|\n)/g, '<br />')" /><br />
        {{ result.country }}
      </div>
      <div v-if="result.text" :class="$style.text" v-html="result.text" />
      <div
        v-if="result.phone || result.fax || result.website || result.email || useForm"
        :class="$style.linkBlock"
      >
        <a v-if="result.phone" :href="`tel:${result.phone}`">
          <i class="fas fa-phone-alt" />
          {{ result.phone }}
        </a>
        <a v-if="result.fax">
          <i class="fas fa-fax" />
          {{ result.fax }}
        </a>
        <a v-if="result.email" :href="`mailto:${result.email}`">
          <i class="fas fa-envelope" />
          {{ result.email }}
        </a>
        <a v-if="result.website" :href="result.website" target="_blank">
          <i class="fas fa-globe-americas" />
          {{ result.website_text !== '' ? result.website_text : result.website }}
        </a>
        <a v-if="useForm && result.fromEmail" :href="prepareContactFormUrl(result)">
          <i class="fas fa-inbox" /> {{ dynamicFormLabel }}
        </a>
      </div>
      <div v-if="result.files" :class="$style.downloads">
        <a
          v-for="(file, index) in result.files"
          :key="index"
          :href="file.url"
          target="_blank"
          @click="
            wtParams &&
              wtTrack(
                { cat: wtParams.doctype },
                mergeWtParams(
                  wtParams,
                  {
                    ...result.wtParams,
                    filename: file.url,
                    type: file.name.replace(/^JPG|JPEG|GIF|PNG$/, 'Image')
                  },
                  {}
                )
              )
          "
        >
          <i v-if="file.name === 'PDF'" class="fas fa-file-pdf" />
          <i v-else-if="file.name === 'DOC' || file.name === 'DOCX'" class="fas fa-file-word" />
          <i
            v-else-if="
              file.name === 'JPG' ||
              file.name === 'JPEG' ||
              file.name === 'GIF' ||
              file.name === 'PNG'
            "
            class="fas fa-file-image"
          />
          <i v-else class="fas fa-file" />
          {{ file.name }}
        </a>
      </div>
    </div>
    <a
      v-if="result.link && result.isVideo"
      :class="$style.teaserLink"
      @click="
        (isModalOpen = true),
          result.wtParams &&
            wtTrack(
              { cat: wtParams.doctype },
              mergeWtParams(
                wtParams,
                {
                  ...result.wtParams,
                  filename: result.link
                },
                {}
              )
            )
      "
    />
    <Modal
      v-if="result.isVideo"
      :is-modal-open="isModalOpen"
      :is-video="result.isVideo"
      @close-modal="isModalOpen = false"
    >
      <iframe
        :data-src="result.link"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
    </Modal>
  </div>
</template>

<script setup>
import Modal from '../Modal/Modal.vue'
import Flag from '../Flag/Flag.vue'
import { mergeSwitchWtParams } from '../../utils/tracking'
import { prepareContactFormUrl } from '../../utils/form'
import { ref } from 'vue'

defineProps({
  result: {
    type: Object,
    default: () => {}
  },
  wtParams: {
    type: Object,
    default: () => {}
  },
  useForm: {
    type: Boolean,
    default: false
  }
})

const isModalOpen = ref(false)
const wtTrack = window.wtTrack
const dynamicFormLabel = window.dictionary.dynamicForm

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}

const linkIsPDF = (link) => {
  return link && link.split('?')[0].match(/\.pdf$/g)
}
</script>

<style module lang="scss">
@import './ListTeaser.scss';
</style>
