import axios from 'axios'

const addItemToUrl = (url, requestData) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'post',
      data: requestData
    })
      .then((response) => {
        if (!response.data.success) {
          reject({
            message: `Response after call addItemUrl (${url}): ${response.message}`,
            data: response.data
          })
        } else {
          resolve(response)
        }
      })
      .catch((error) => reject(error))
  })
}

export default addItemToUrl
