import axios from 'axios'

const logoutWithUrl = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'get',
      responseType: 'json',
      withCredentials: true
    })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => reject(error))
  })
}

export default logoutWithUrl
