export const getCookie = (cookieName) => {
  const name = `${cookieName}=`
  const savedCookies = document.cookie.split(';')
  for (let i = 0; i < savedCookies.length; i++) {
    let current = savedCookies[i]
    while (current.charAt(0) === ' ') {
      current = current.substring(1)
    }
    if (current.indexOf(name) === 0) {
      return current.substring(name.length, current.length)
    }
  }
  return ''
}

export const setCookie = (cookieName, cookieValue, daysToExpiraton) => {
  const date = new Date()
  date.setTime(date.getTime() + daysToExpiraton * 24 * 60 * 60 * 1000)
  const expires = `expires=${date.toUTCString()}`
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`
}

export const setSessionCookie = (cookieName, cookieValue) => {
  document.cookie = `${cookieName}=${cookieValue};path=/`
}

export const setLocalStorage = (storageKey, storageValue, isJSON) => {
  let value = storageValue
  if (isJSON) {
    value = JSON.stringify(storageValue)
  }
  window.localStorage.setItem(storageKey, value)
}

export const getLocalStorage = (storageKey, isJSON) => {
  if (isJSON) {
    return JSON.parse(window.localStorage.getItem(storageKey))
  } else {
    return window.localStorage.getItem(storageKey)
  }
}

export const removeLocalStorage = (storageKey) => {
  window.localStorage.removeItem(storageKey)
}

export const setSessionStorage = (storageKey, storageValue, isJSON) => {
  let value = storageValue
  if (isJSON) {
    value = JSON.stringify(storageValue)
  }
  window.sessionStorage.setItem(storageKey, value)
}

export const getSessionStorage = (storageKey, isJSON) => {
  if (isJSON) {
    return JSON.parse(window.sessionStorage.getItem(storageKey))
  } else {
    return window.sessionStorage.getItem(storageKey)
  }
}

export const removeSessionStorage = (storageKey) => {
  window.sessionStorage.removeItem(storageKey)
}
