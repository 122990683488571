<template>
  <div>
    <div id="shopCartPopper" :class="$style.popperPosition" />
    <div class="ShopCart__cartPopper" />
    <tippy
      ref="addToCartTippy"
      theme="wika light tooltip"
      :interactive="true"
      placement="top-end"
      animation="shift-away"
      :animate-fill="false"
      :arrow="false"
      :distance="11"
      :z-index="10001"
      trigger="manuel"
      to="#shopCartPopper"
    >
      <template #content>
        <div id="addToCartPopperContent" class="ShopCart__popperContent">
          <div class="columns is-mobile is-multiline">
            <div class="ShopCart__cartItem column is-full">
              <strong class="ShopCart__headline">{{ config.headline }}:</strong>
              <Button class="ShopCart__close" is-close colour="white" @close="closePopper" />
              <div class="columns is-mobile">
                <div class="column is-one-third">
                  <img :src="item.thumbnail" :alt="item.title" />
                </div>
                <div class="ShopCart__priceBox column is-two-thirds">
                  <strong class="ShopCart__title">{{ item.title }}</strong>
                  <div>{{ item.name }}</div>
                  <p class="ShopCart__heading">
                    {{ config.itemNumber }}:
                    {{
                      typeof item !== 'undefined' &&
                      typeof item.id !== 'undefined' &&
                      !item.id.includes('none_')
                        ? item.id
                        : config.noArtNoAvailableText
                    }}
                  </p>
                  <div>
                    {{ config.pricePerItem }}:
                    <IndividualPrice
                      :value="Number(item.pricesingle)"
                      :product-type="item.productType"
                      :btype-list="[
                        {
                          btype: item.productType,
                          minimalPrice: item.pricesingle
                        }
                      ]"
                    />
                  </div>
                  <div class="ShopCart__labelBox">
                    <div class="ShopCart__label">{{ config.quantity }}: {{ item.quantity }}</div>
                    <div class="ShopCart__label">
                      <IndividualPrice
                        :value="Number(item.pricesingle)"
                        :product-type="item.productType"
                        :quantity="item.quantity"
                        :btype-list="[
                          {
                            btype: item.productType,
                            minimalPrice: item.pricesingle
                          }
                        ]"
                      />
                    </div>
                  </div>
                  <div class="ShopCart__tax">
                    {{ config.tax }} &amp;
                    <a :href="config.shippingUrl">
                      {{ config.shippingText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="ShopCart__cartTotal column is-offset-one-third is-two-thirds">
              <Button
                colour="blue"
                :text="config.buttonText"
                :is-link="true"
                :href="config.shopCartUrl"
              />
            </div>
          </div>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import Button from '../Button/Button.vue'
import { ref } from 'vue'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  item: {
    type: Object,
    default: () => {}
  },
  isPopperOpen: {
    type: Boolean,
    default: false
  }
})

const addToCartTippy = ref(null)

watch(
  () => props.isPopperOpen,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      newValue === true ? openPopper() : closePopper()
    }
  }
)

const openPopper = () => {
  addToCartTippy.value.show()
}

const closePopper = () => {
  addToCartTippy.value.hide()
}
</script>

<style module>
.popperPosition {
  top: 0;
  right: 10px;
  position: fixed;
  width: 0;
  height: 0;
}
</style>
