<template>
  <div>
    <FormFilter :config="config" @filtered="displayResults" @has-results="displayPagination" />
    <div class="columns is-multiline">
      <div
        v-for="(result, index) in displayedResults"
        :key="index"
        class="column is-half-tablet is-one-third-desktop"
      >
        <ListTeaser :result="result" :wt-params="config.wtParams" />
      </div>
    </div>
    <Pagination
      v-show="config.loadInitialData || hasResults"
      :config="config.pagination"
      :results="results.length"
      @update="displayPaginatedResults"
    />
  </div>
</template>

<script setup>
import FormFilter from '../FormFilter/FormFilter.vue'
import ListTeaser from '../ListTeaser/ListTeaser.vue'
import Pagination from '../Pagination/Pagination.vue'
import { ref } from 'vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const results = ref([])
const displayRange = ref([0, 11])
const displayedResults = ref([])
const hasResults = ref(false)

const displayPagination = (hasNewResults) => {
  hasResults.value = hasNewResults
}

const displayResults = (newResults) => {
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < results.value.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }
}

const displayPaginatedResults = (displayRange) => {
  displayRange.value = displayRange
  displayResults(results.value)
}
</script>
