<template>
  <div class="ContentTabs__container">
    <template v-if="config.tabs.length === 1">
      <FormFilter
        :config="config.tabs[0]"
        @filtered="displayResults"
        @has-results="displayPagination"
      />
      <div class="columns is-multiline">
        <div
          v-for="(result, resultIndex) in displayedResults"
          :key="`oneTab_${resultIndex}`"
          class="column is-half-tablet is-one-third-desktop"
        >
          <ListTeaser
            :key="resultIndex"
            :result="result"
            :wt-params="
              config.tabs[0].inlineData.wtParams
                ? config.tabs[0].inlineData.wtParams
                : config.wtParams || {}
            "
          />
        </div>
      </div>
      <Pagination
        :config="config.pagination"
        :results="results.length"
        @update="displayPaginatedResults"
      />
    </template>
    <div v-if="!isMobile && config.tabs.length > 1" class="ContentTabs__tabs">
      <a
        v-for="(tab, tabIndex) in config.tabs.length"
        :key="`tabLink_${tab}`"
        :class="[
          'ContentTabs__tab',
          { ContentTabs__active: activeTab === tab },
          { ContentTabs__disabled: config.tabs[tabIndex].disabled }
        ]"
        @click="(e) => updateTabs(e, tab)"
      >
        {{ config.tabs[tabIndex].title }}
      </a>
      <div
        v-for="(tab, tabIndex) in config.tabs.length"
        :key="`tab_${tab}`"
        :class="['ContentTabs__content', { ContentTabs__activeContent: activeTab === tab }]"
      >
        <template v-if="activeTab === tab">
          <FormFilter
            :config="config.tabs[tabIndex]"
            @filtered="displayResults"
            @has-results="displayPagination"
          />
          <div class="columns is-multiline">
            <div
              v-for="(result, resultIndex) in displayedResults"
              :key="'listTeaserContainer_' + resultIndex"
              class="column is-half-tablet is-one-third-desktop"
            >
              <ListTeaser
                :key="resultIndex"
                :result="result"
                :wt-params="
                  config.tabs[tabIndex].inlineData.wtParams
                    ? config.tabs[tabIndex].inlineData.wtParams
                    : config.wtParams || {}
                "
              />
            </div>
          </div>
          <Pagination
            :config="config.pagination"
            :results="results.length"
            @update="displayPaginatedResults"
          />
        </template>
      </div>
    </div>
    <div v-if="isMobile && config.tabs.length > 1" class="ContentTabs__tabs ContentTabs__mobile">
      <div v-for="(tab, tabIndex) in config.tabs.length" :key="`tabMobile_${tabIndex}`">
        <a
          v-if="!config.tabs[tabIndex].disabled"
          :class="[
            'ContentTabs__tab',
            { ContentTabs__active: activeTab === tab && mobileHidden !== tab }
          ]"
          @click="(e) => updateTabs(e, tab)"
        >
          {{ config.tabs[tabIndex].title }}
        </a>
        <div
          :class="[
            'ContentTabs__content',
            { ContentTabs__activeContent: activeTab === tab && !isMobile },
            {
              ContentTabs__activeContent: activeTab === tab && isMobile && mobileHidden !== tab
            }
          ]"
        >
          <template v-if="activeTab === tab">
            <FormFilter
              :config="config.tabs[tabIndex]"
              @filtered="displayResults"
              @has-results="displayPagination"
            />
            <div class="columns is-multiline">
              <div
                v-for="(result, resultIndex) in displayedResults"
                :key="`listTeaserContainerMobile_${tabIndex}_${resultIndex}`"
                class="column is-half-tablet is-one-third-desktop"
              >
                <ListTeaser
                  :key="resultIndex"
                  :result="result"
                  :wt-params="
                    config.tabs[tabIndex].inlineData.wtParams
                      ? config.tabs[tabIndex].inlineData.wtParams
                      : config.wtParams || {}
                  "
                />
              </div>
            </div>
            <Pagination
              :config="config.pagination"
              :results="results.length"
              @update="displayPaginatedResults"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { checkIsMobile } from '../../utils/media'
import FormFilter from '../FormFilter/FormFilter.vue'
import ListTeaser from '../ListTeaser/ListTeaser.vue'
import Pagination from '../Pagination/Pagination.vue'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const isMobile = ref(false)
const activeTab = ref(null)
const mobileHidden = ref(null)
const activeTabPreselected = ref(false)
const hasResults = ref(false)
const results = ref([])
const displayedResults = ref([])
const displayRange = ref([0, props.config.pagination.resultsPerPage - 1])

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const initTabs = () => {
  const queries = window.location.search.substring(1).split('&')
  let queryStringTabId = null
  if (queries) {
    for (const query of queries) {
      if (query.includes('tab')) {
        queryStringTabId = query.split('=')[1]
      }
    }
  }
  for (const [index, tab] of props.config.tabs.entries()) {
    if (
      (queryStringTabId && queryStringTabId == index + 1 && !tab.disabled) ||
      (tab.active && !tab.disabled)
    ) {
      activeTab.value = index + 1
      activeTabPreselected.value = true
    }
  }
  if (!activeTabPreselected.value) {
    activeTab.value = 1
  }
}

const updateTabs = (e, tab) => {
  const elm = e.currentTarget
  if (!elm.classList.contains('ContentTabs__disabled')) {
    if (isMobile.value && activeTab.value === tab && activeTab.value !== mobileHidden.value) {
      mobileHidden.value = tab
    } else if (
      isMobile.value &&
      activeTab.value === tab &&
      activeTab.value === mobileHidden.value
    ) {
      mobileHidden.value = null
    } else if (isMobile.value && activeTab.value !== tab) {
      mobileHidden.value = null
      activeTab.value = tab
    } else {
      activeTab.value = tab
    }
  }
}

const displayPagination = (hasNewResults) => {
  hasResults.value = hasNewResults
}

const displayResults = (newResults) => {
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < results.value?.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }
  return displayedResults.value
}

const displayPaginatedResults = (displayRangeRaw) => {
  displayRange.value = displayRangeRaw
  displayResults(results.value)
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  checkScreenSize()
  initTabs()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style module lang="scss">
@import './NewsList.scss';
</style>
