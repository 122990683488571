import axios from 'axios'

const submitRFQForm = (url, requestData) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'post',
      responseType: 'json',
      data: requestData
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => reject(error))
  })
}

export default submitRFQForm
