const CategoryKeys = {
  APPROVAL_AUTHORITY: 'approvalauthority',
  CERTIFICATE_ID: 'certificateId',
  ECONOMIC_AREA: 'economicArea',
  PRODUCT_CODE: 'productcode',
  PRODUCT_CODELIST: 'product',
  SCOPE: 'scope',
  STANDARD: 'standard',
  VALID_TO: 'validto',
  REGION: 'region',
  APPLICATION: 'application',
  SCHEME: 'scheme',
  DETAIL: 'detail',
  UNSOLICITED_APPLICATION: 'unsolicitedApplication',
  TALENTPOOL: 'talentpool'
}

export { CategoryKeys }
