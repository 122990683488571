export const mergeSwitchWtParams = (globalWtParams, itemWtParams, itemParams) => {
  if (window.disableTracking) {
    return {}
  }

  const globalDocType =
    itemWtParams && itemWtParams.doctype
      ? itemWtParams.doctype
      : globalWtParams && globalWtParams.doctype
        ? globalWtParams.doctype
        : undefined

  const mergedWtParams = {
    // APPROVAL
    ...(globalDocType &&
      window.wtTrackingCategories.APPROVAL.indexOf(globalDocType.toUpperCase()) !== -1 && {
        certBody: itemParams.approvalauthority,
        certType:
          itemParams.keywords.length > 0
            ? (function () {
                const [firstKeyword] = [...itemParams.keywords].sort()
                return typeof firstKeyword === 'string' ? firstKeyword : firstKeyword.keyword
              })()
            : undefined
      }),
    // NEWS
    ...(globalDocType &&
      window.wtTrackingCategories.NEWS.indexOf(globalDocType.toUpperCase()) !== -1 && {
        type: 'URL'
      }),
    ...globalWtParams,
    ...itemWtParams
  }

  return mergedWtParams
}
