<template>
  <div class="columns">
    <div
      :class="[$style.cascading, $style.container, 'column is-half-tablet is-half-desktop']"
      @mouseenter="mouseenterEvent(0)"
      @mouseleave="open[0] && mouseleaveEvent(0), open[0] && mouseleaveEvent(1)"
      @click="clickEvent(0)"
    >
      <a :class="getSelectClasses(0)">
        <span v-if="selectedEntryId && selectedEntry" v-html="selectedEntry.label" />
        <span v-else v-html="config.defaultLabel" />
        <span :class="$style.triangle" />
      </a>
      <ul>
        <li
          v-for="entry in config.entries"
          :key="`entry${entry.id}`"
          :class="{
            [$style.active]: entry.id == selectedEntryId
          }"
        >
          <a v-if="entry.url" :href="entry.url" v-html="entry.label" />
          <a v-else @click="selectEntry($event, entry.id)" v-html="entry.label" />
        </li>
      </ul>
    </div>
    <div
      v-if="config.cascading && children.length"
      :class="[$style.cascading, $style.container, 'column is-half']"
      @mouseenter="mouseenterEvent(1)"
      @mouseleave="mouseleaveEvent(1)"
      @click="clickEvent(1)"
    >
      <a :class="getSelectClasses(1)">
        <span v-if="selectedChildId && selectedChild" v-html="selectedChild.label" />
        <span v-else v-html="config.defaultLabel" />
        <span :class="$style.triangle" />
      </a>
      <ul>
        <li
          v-for="child in children"
          :key="`entry${selectedEntryId}-child${child.id}`"
          :class="{
            [$style.active]: child.id == selectedChildId
          }"
        >
          <a v-if="child.url" :href="child.url" v-html="child.label" />
          <a v-else v-html="child.label" />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, useCssModule } from 'vue'
import { checkIsMobile } from '../../utils/media'
const $style = useCssModule()
const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const open = ref([false, false])
const noHover = ref([false, false])
const selectedEntryId = ref(false)
const selectedChildId = ref(false)
const isMobile = ref(false)

const selectedEntry = computed(() => {
  return (
    (props.config.entries &&
      props.config.entries.find((entry) => entry.id == selectedEntryId.value)) ||
    null
  )
})

const children = computed(() => {
  return selectedEntry.value?.children ?? []
})

const selectedChild = computed(() => {
  return getChildById(selectedChildId.value)
})

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const getChildById = (childId) => {
  return children.value?.find((child) => child.id == childId) ?? null
}

const getSelectClasses = (level) => {
  return [
    {
      [$style.blue]: props.config.colour === 'blue',
      [$style.white]: props.config.colour === 'white',
      [$style.orange]: props.config.colour === 'orange',
      [$style.noHover]: noHover[level],
      [$style.open]: open[level]
    },
    $style.select
  ]
}

const clickEvent = (number) => {
  open.value[number] = true
}

const mouseenterEvent = (number) => {
  noHover.value[number] = true
}

const mouseleaveEvent = (number) => {
  window.setTimeout(() => {
    open.value[number] = false
    noHover.value[number] = true
  }, 1000)
}

const setInitialSelectedChild = () => {
  if (props.config.selectedChild) {
    selectedChildId.value = props.config.selectedChild
  } else {
    getSelectedChildFromUrl()
  }
}

const getSelectedChildFromUrl = () => {
  const params = window.location.search.substring(1).split('&')
  const naviSelectedParam = params.find((param) => param.includes('naviSelectedChild'))
  const naviSelectedParamValue = naviSelectedParam ? naviSelectedParam.split('=')[1] : null
  selectedChildId.value = getChildById(naviSelectedParamValue) ? naviSelectedParamValue : null
}

const selectEntry = (event, entryId) => {
  if (event) {
    event.stopPropagation()
  }

  const isClicked = !!entryId
  selectedEntryId.value = entryId || props.config.selectedEntry

  // Populate list of children in the second dropdown
  if (selectedEntryId.value) {
    if (isClicked) {
      selectedChildId.value = null
      clickEvent(1)
      mouseenterEvent(1)
    } else {
      setInitialSelectedChild()
    }
  }

  if (isMobile.value) {
    // close first select on mobile to prevent overlaying eachother
    open.value[0] = false
  }
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  checkScreenSize()
  selectEntry()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style module lang="scss">
@import './NaviSelectCascading.scss';
</style>
