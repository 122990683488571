<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <Breadcrumbs :crumbs="crumbs" />
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <h1 class="SubPage__title">Kontakt</h1>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-two-thirds">
          <HeroSlider :slides="slides" :height-first="true" />
        </div>
        <div class="column is-one-third">
          <SalesContainer :config="salesContainerConfig" />
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <h2 class="SubPage__subHeading">WIKA weltweit</h2>
      <WorldWideList
        :config="{
          currentRegion: 'Europa',
          currentCountry: 'Deutschland',
          regionLabel: 'Kontinent',
          countryLabel: 'Land'
        }"
      />
    </div>
    <Footer />
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
  </div>
</template>

<script setup>
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import Footer from '../Footer/Footer.vue'
import SalesContainer from '../SalesContainer/SalesContainer.vue'
import PageHeader from '../PageHeader/PageHeader.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import WorldWideList from '../WorldWideList/WorldWideList.vue'
import HeroSlider from '../HeroSlider/HeroSlider.vue'

const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  },
  button: {
    text: 'Zustimmen',
    colour: 'blue'
  }
}

const crumbs = [
  {
    title: 'Startseite',
    url: '#'
  },
  {
    title: 'Kontakt',
    url: '#'
  },
  {
    title: 'WIKA weltweit',
    url: '#'
  }
]

const slides = [
  {
    image: 'https://picsum.photos/662/257?random=1',
    newWindow: false,
    url: '#'
  },
  {
    image: 'https://picsum.photos/662/257?random=2',
    newWindow: false,
    url: '#'
  },
  {
    image: 'https://picsum.photos/662/257?random=3',
    newWindow: false,
    url: '#'
  }
]

const salesContainerConfig = {
  title: 'Vertriebs&shy;kontakt',
  icon: 'fas fa-user-tie',
  input: {
    placeholderText: 'Postleitzahl eingeben',
    submitUrl: 'contact_de_at.WIKA?cnType=1&cnEntry='
  },
  buttons: [
    {
      icon: 'fas fa-comments',
      text: 'Jetzt chatten',
      offlineText: 'Nachricht senden'
    },
    {
      icon: 'fas fa-envelope',
      text: 'Kontaktformular',
      linkUrl: '#'
    }
  ]
}
</script>
