<template />

<script>
// productOverview.js adapted from https://shop.wika.de/templates/scripts/productOverview.js
// import * as ko2 from "knockout";
const init = () => {
  import('knockout').then((ko2) => {
    /**
     * ViewModel used to prepare items per page dropdown
     * @param {} count
     * @param {} description
     * @returns {}
     */
    var itemsPerPageModel = function (count, description) {
      var self = this
      self.count = count
      self.description = description
    }

    /**
     * ViewModel used to pages dropdown
     * @param {} count
     * @param {} description
     * @returns {}
     */
    var availablePagesModel = function (count, description) {
      var self = this
      self.count = count
      self.description = description
    }

    /*
     * knockout extensions
     * renderAfter derivation to only be called once if every draw is done
     */
    ko2.bindingHandlers.runafter = {
      update: function (element, valueAccessor, allBindingsAccessor) {
        // add dependency on all other bindings
        ko2.toJS(allBindingsAccessor())
        setTimeout(function () {
          var value = valueAccessor()
          if (typeof value != 'function' || ko2.isObservable(value))
            throw new Error('run must be used with a function')
          value(element)
        }, 0)
      }
    }

    /**
     * Knockout Viewmodel for ProductSelector
     * @returns {}
     */
    function ProductSelectorViewModel() {
      var self = this

      /*
       * get JSON data stored in hidden field by former ProductListOptionsHandler
       */
      const categoryData = $('#selectorCategoryData').val()
      this.categories = categoryData ? ko2.observableArray(JSON.parse(categoryData)) : []

      /*
       * get JSON data stored in hidden field by former ProductResultsHandler
       */
      this.products = ko2.observableArray(JSON.parse($('#selectorData').val()))

      /*
       * observable that holds selected categories
       */
      this.selectedCategories = ko2.observableArray()

      /*
       * actual products based on selected checkboxes
       */
      this.filteredProducts = ko2.computed(function () {
        var products = self.products()
        var eligibleProductIds = []

        if (self.selectedCategories().length > 0) {
          var currentParentId = 0
          var index = 0
          // gather all product ids that match the selected categories
          self
            .selectedCategories()
            .sort()
            .forEach(function (sc) {
              // for the first iteration or when currentParentId stays the same
              if (currentParentId === 0 || currentParentId === sc.split('|')[0]) {
                self.categories().forEach(function (c) {
                  c.Options.forEach(function (o) {
                    if (o.Id === sc.split('|')[1]) {
                      if (eligibleProductIds[index] !== undefined) {
                        eligibleProductIds[index] = eligibleProductIds[index].concat(o.ProductIds)
                      } else {
                        eligibleProductIds.push(o.ProductIds)
                      }
                    }
                  })
                })
              } else {
                // when we switch to another parent grp start a new array entry
                index++

                self.categories().forEach(function (c) {
                  c.Options.forEach(function (o) {
                    if (o.Id === sc.split('|')[1]) {
                      if (eligibleProductIds[index] !== undefined) {
                        eligibleProductIds[index] = eligibleProductIds[index].concat(o.ProductIds)
                      } else {
                        eligibleProductIds.push(o.ProductIds)
                      }
                    }
                  })
                })
              }
              currentParentId = sc.split('|')[0]
            })

          // compare each array and take only numbers that exist in all arrays to actually do the AND comparison
          var result = eligibleProductIds.shift().filter(function (v) {
            return eligibleProductIds.every(function (a) {
              return a.indexOf(v) !== -1
            })
          })

          // filter product ids that don't appear in eligibleProductIds
          products = products.filter(function (p) {
            return result.indexOf(p.productId) !== -1
          })
        }

        return products
      })

      this.setVisible = ko2.computed(function () {
        return self.products().length > 0 &&
          window.location.href.indexOf('_escaped_fragment_') === -1
          ? true
          : false
      })

      /*
       * data for items per page dropdown
       */
      this.availableItemsPerPage = ko2.observableArray([
        new itemsPerPageModel(10, '10 ' + langStr['itemsPerPage']),
        new itemsPerPageModel(20, '20 ' + langStr['itemsPerPage']),
        new itemsPerPageModel(30, '30 ' + langStr['itemsPerPage']),
        new itemsPerPageModel(40, '40 ' + langStr['itemsPerPage'])
      ])

      this.pageNumber = ko2.observable(0)
      this.nbPerPage = ko2.observable(10)

      this.totalPages = ko2.computed(function () {
        var div = Math.floor(self.filteredProducts().length / self.nbPerPage())
        div += self.filteredProducts().length % self.nbPerPage() > 0 ? 1 : 0
        return div - 1
      })

      /*
       * data for pages dropdown
       */
      this.pages = ko2.pureComputed(function () {
        var temp = []

        for (var i = 0; i <= self.totalPages(); i++) {
          temp.push(new availablePagesModel(i, i + 1))
        }

        // init select2 on items per page dropdown
        $('.ProductFilter__selectWrapper select').select2({
          minimumResultsForSearch: 10000
        })

        // init select2 on pages dropdown
        $('.paginationContainer select').select2({
          minimumResultsForSearch: 10000,
          width: '45px'
        })

        return temp
      }, this)

      /*
       * actual products per page based on selected page and items per page
       */
      this.paginated = ko2.computed(function () {
        var first = self.pageNumber() * self.nbPerPage()
        return self.filteredProducts().slice(first, first + self.nbPerPage())
      })

      this.hasPrevious = ko2.computed(function () {
        return self.pageNumber() !== 0
      })

      this.hasNext = ko2.computed(function () {
        return self.pageNumber() !== self.totalPages()
      })

      this.next = function () {
        if (self.pageNumber() < self.totalPages()) {
          self.pageNumber(self.pageNumber() + 1)
        }
        $('.paginationContainer select').select2({
          minimumResultsForSearch: 10000,
          width: '45px'
        })
      }

      this.previous = function () {
        if (self.pageNumber() !== 0) {
          self.pageNumber(self.pageNumber() - 1)
        }
        $('.paginationContainer select').select2({
          minimumResultsForSearch: 10000,
          width: '45px'
        })
      }

      /*
       * paginated products are paired in to groups of two to create rows of products
       */
      this.grouped = ko2.computed(function () {
        var rows = [],
          current = []
        rows.push(current)
        for (var i = 0; i < self.paginated().length; i += 1) {
          current.push(self.paginated()[i])
          if ((i + 1) % 2 === 0) {
            current = []
            rows.push(current)
          }
        }
        return rows
      }, this)

      /*
       * called runAfter when a filter selection is done
       * used to update the numbers at each category that display how many products of this category are shown currently
       */
      this.updateCategoryProductCounts = function () {
        setTimeout(function () {
          var checkboxes = $('.panel.checkboxes input[type=checkbox]')

          $(checkboxes).each(function (i, v) {
            // get the category this checkbox is related to
            var category = self.categories().filter(function (obj) {
              return obj.Id == v.value.split('|')[0]
            })

            // get the option of the category this checkbox is related to
            var option = category[0].Options.filter(function (obj) {
              return obj.Id == v.value.split('|')[1]
            })

            var idsOfCategory = option[0].ProductIds
            var idsActual = $(self.filteredProducts()).map(function () {
              return this.productId
            })

            var count = idsOfCategory.filter(function (val) {
              return $(idsActual).toArray().indexOf(val) !== -1
            }).length

            $(v)
              .parent()
              .find('.productCountText')
              .text('(' + count + ')')
            if (count === 0) {
              $(v).attr('disabled', true)

              // remove checkbox if the selected filter has an empty result
              self.clearSingleSelection($(v).val())
            } else {
              $(v).removeAttr('disabled')
            }
          })
        }, 0)
      }

      /*
       * attach click event on category toggle function
       */
      this.setCategoryToggle = function () {
        setTimeout(function () {
          $('.filterSelection h3[data-toggle]').on('click', function () {
            $(this).toggleClass('toggled')
            $(this).parent().toggleClass('checkboxes-hidden')
          })
        }, 0)
      }

      /*
       * click binding to deselect all checkboxes
       */
      this.clearSelection = function () {
        self.selectedCategories([])
      }

      /*
       * to deselect checkbox with a specific value
       */
      this.clearSingleSelection = function (value) {
        self.selectedCategories.remove(function (player) {
          return player === value
        })
      }

      /*
       * click binding to deselect checkboxes in specific category only
       */
      this.clearCategorySelection = function (element) {
        self.selectedCategories.remove(function (player) {
          return player.split('|')[0] == element.Id
        })
      }

      /*
       * visible binding to show/hide category specific clear checkboxes button
       */
      this.clearCategorySelectionVisible = function (element) {
        var categories = self.selectedCategories().filter(function (c) {
          return c.split('|')[0] == element.Id
        })

        return categories.length > 0 ? true : false
      }

      /*
       * click binding to show/hide checkboxes > 5 per category
       */
      this.ShowHideFilters = function (message, parent) {
        if (message === 'show') {
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('.checkbox-hidden')
            .removeClass('checkbox-hidden')
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('.checkbox-trigger.show')
            .hide()
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('.checkbox-trigger.hide')
            .show()
        } else {
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('label')
            .each(function (i, v) {
              if (i >= 5) {
                $(v).addClass('checkbox-hidden')
              }
            })
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('.checkbox-trigger.show')
            .show()
          $('h3[id=' + parent.Id + ']')
            .parent()
            .find('.checkbox-trigger.hide')
            .hide()
        }

        return false
      }

      /*
       * function to check if the given value is a number
       */
      this.isNumber = function (n) {
        return !isNaN(parseFloat(n)) && isFinite(n)
      }

      /*
       * get all parameters from current url
       */
      this.getUrlParameters = function () {
        var query = location.search.substr(1)
        var result = {}
        if (query === '') {
          return result
        }

        query.split('&').forEach(function (part) {
          var item = part.split('=')
          result[item[0]] = decodeURIComponent(item[1])
        })
        return result
      }

      /*
       * initialize the filters by url parameters on page load
       */
      this.loadSelectedCategories = function () {
        var categories = this.getUrlParameters()

        $.each(categories, function (index, value) {
          if (self.isNumber(index)) {
            var values = value.split('|')
            for (var j = 0; j < values.length; j++) {
              self.selectedCategories.push(index + '|' + values[j])
            }
          }
        })
      }

      this.loadSelectedCategories()

      /*
       * sync filter to history
       */
      var prevFilters = {}

      this.syncFiltersToHistory = function (result) {
        const searchParams = new URLSearchParams(window.location.search)

        // delete old filters
        for (const prevFilter of Object.keys(prevFilters)) {
          if (!(prevFilter in result)) {
            searchParams.delete(prevFilter)
          }
        }

        // set filters
        for (const filterKey of Object.keys(result)) {
          searchParams.set(filterKey, result[filterKey])
        }

        let historyURL = window.location.pathname

        if (searchParams.size > 0) {
          historyURL += `?${searchParams}`
        }

        // sync
        window.history.replaceState({}, '', `${historyURL}`)

        // set prev filters
        prevFilters = result
      }

      /*
       * add filter parameters to product detail urls
       */
      this.filterParameters = ko2.computed(function () {
        var selCategories = self.selectedCategories()
        var result = {}

        for (var i = 0, j = selCategories.length; i < j; i++) {
          var selCategory = selCategories[i].split('|')
          if (!(selCategory[0] in result)) {
            result[selCategory[0]] = selCategory[1]
          } else {
            result[selCategory[0]] = result[selCategory[0]] + '|' + selCategory[1]
          }
        }

        var resultStr = ''
        $.each(result, function (index, value) {
          if (resultStr.indexOf('?') < 0) {
            resultStr += '?'
          } else {
            resultStr += '&'
          }
          resultStr += index + '=' + value
        })

        self.syncFiltersToHistory(result)

        return result.length === 0 ? '' : resultStr
      })
    }

    // ProductFilter functions adapted from https://de-de.wika.de/templates/Scripts/functions.js

    function getOptions(groupid) {
      var preloadOverlay = $('<div class="preloadOverlay"></div>')

      preloadOverlay.css({
        width: '100%',
        height: '400px'
      })
      $('#filterResultContainer').append(preloadOverlay)
      $('.ProductFilter__pagination').hide()
      $('.productResultContainer').hide()

      if (typeof window.selectedEntryLanguage != 'undefined') {
        var ids = []
        var types = []
        var openIds = []

        if (typeof selectedEntryId1 != 'undefined' && typeof selectedEntry1Type != 'undefined') {
          ids.push(selectedEntryId1)
          types.push(selectedEntry1Type)
        }
        if (typeof selectedEntryId2 != 'undefined' && typeof selectedEntry2Type != 'undefined') {
          ids.push(selectedEntryId2)
          types.push(selectedEntry2Type)
        }
        if (typeof selectedEntryId3 != 'undefined' && typeof selectedEntry3Type != 'undefined') {
          ids.push(selectedEntryId3)
          types.push(selectedEntry3Type)
        }
        if (typeof selectedEntryId4 != 'undefined' && typeof selectedEntry4Type != 'undefined') {
          ids.push(selectedEntryId4)
          types.push(selectedEntry4Type)
        }
        if (typeof selectedEntryId5 != 'undefined' && typeof selectedEntry5Type != 'undefined') {
          ids.push(selectedEntryId5)
          types.push(selectedEntry5Type)
        }
        if (typeof selectedEntryId6 != 'undefined' && typeof selectedEntry6Type != 'undefined') {
          ids.push(selectedEntryId6)
          types.push(selectedEntry6Type)
        }

        var newSelectorControl = false
        // New dynamic product list selector
        if (typeof selectedEntryIds != 'undefined' && typeof selectedEntryTypes != 'undefined') {
          ids = selectedEntryIds
          types = selectedEntryTypes
          openIds = selectedEntryOpenIds
          newSelectorControl = true
        }

        // For WIKA Customer Portal we will manipulate the results and remove all links, prices from it
        var getCPResult = '0'
        if (typeof channelCP != 'undefined' && channelCP.length > 0) {
          getCPResult = 'CP'
        }

        var parameter = {
          Group: groupid,
          Ids: ids,
          Types: types,
          CurrentLanguage: window.currentLanguage,
          Language: window.selectedEntryLanguage,
          Instance: window.instance,
          Productlistselector: $('.productlistselector').length > 0 ? '1' : '1',
          OldSelector: false,
          Channel: getCPResult
        }

        if (newSelectorControl) {
          var parameter2 = { IsOpen: openIds }
          $.extend(parameter, parameter2)
        }

        /*
         * for the new knockout based product selector the JSON data is stored in a hidden field
         * and gets picked up later in productOverview.js
         */
        var data = JSON.stringify(window.productListOptions)
        if (!parameter.OldSelector) {
          $('<input>')
            .attr({
              type: 'hidden',
              id: 'selectorCategoryData',
              value: data
            })
            .appendTo('body')
        } else {
          $('.filterSelection').html(data)
        }

        if (newSelectorControl) {
          initNewProductSelectorControl()
        }

        //We will use this to initiate the filter with existing filter settings on loading
        $('.filterSelection').parent().css('height', 'auto')
        if (typeof selectSessionKeys != 'undefined' && typeof selectSessionVals != 'undefined') {
          if (selectSessionVals != null && selectSessionKeys != null) {
            $('.surfaceFilter').accordion('activate', 0)
            var callbackRequired = false
            var preselectLastItem = false
            for (var i = 0; i < selectSessionKeys.length; i++) {
              if (!newSelectorControl) {
                if ($('select#' + selectSessionKeys[i]).val() == '') {
                  $('select#' + selectSessionKeys[i]).val(selectSessionVals[i])
                  if (i == selectSessionKeys.length - 1) {
                    callbackRequired = true
                    preselectLastItem = true
                  }
                  productListGetResults(
                    groupid,
                    $('select#' + selectSessionKeys[i]).val(),
                    selectSessionKeys[i],
                    $('select#' + selectSessionKeys[i]).attr('class'),
                    callbackRequired,
                    preselectLastItem,
                    -1
                  )
                }
              } else {
                var openCharacteristic = false
                var showMore = false
                var $characteristicContainer = $('h3#' + selectSessionKeys[i])
                if (window.currentLanguage == 'ru') {
                  $characteristicContainer = $('span#' + selectSessionKeys[i])
                }
                var $checkboxContainer = $characteristicContainer.next('[data-checkboxes]')
                if ($checkboxContainer.length > 0) {
                  var values = selectSessionVals[i].split('|')
                  for (var j = 0; j < values.length; j++) {
                    var $checkbox = $checkboxContainer.find(
                      'input:checkbox[value=' + values[j] + ']'
                    )
                    $checkbox.attr('checked', true)
                    openCharacteristic = true
                    showMore = $checkbox.parent().index() >= 5
                    $checkboxContainer.find('.checkbox-trigger.remove').show()
                  }

                  if (openCharacteristic) {
                    $characteristicContainer.addClass('toggled')
                    $checkboxContainer.show()

                    if (showMore) {
                      $checkboxContainer.find('.checkbox-trigger.show').click()
                    }
                  }
                }
                if (i == selectSessionKeys.length - 1) {
                  callbackRequired = true
                  preselectLastItem = true
                }

                productListGetResults(
                  groupid,
                  $('select#' + selectSessionKeys[i]).val(),
                  selectSessionKeys[i],
                  $('select#' + selectSessionKeys[i]).attr('class'),
                  callbackRequired,
                  preselectLastItem,
                  -1
                )
              }
            }

            selectSessionKeys = null
            selectSessionVals = null
          } else {
            productListGetResults(groupid, -1, -1, '-1', true, false, -1)
          }
        } else {
          productListGetResults(groupid, -1, -1, '-1', true, false, -1)
        }
      } else {
        productListGetResults(groupid, -1, -1, '-1', true, false, -1)
      }
    }

    function productListGetResults(
      groupid,
      selectedAttId,
      selectedEntryId,
      selectedType,
      callbackRequired,
      preselectLastItem,
      selectorGroupId
    ) {
      $('.ProductFilter__pagination').hide()
      $('.productResultContainer').hide()
      $('.filterResult h3').hide()
      var selectValues = []
      var selectIds = []
      var selectType = []
      var selectedOptionsFilter = []
      var productResults = window.productResults || []

      if ($('.filterSelection').parent().is(':visible')) {
        $('.filterSelection select').each(function () {
          selectValues.push($(this).val())
          selectIds.push($(this).attr('id'))
          selectType.push($(this).attr('class'))
          if ($(this).val() != '') {
            selectedOptionsFilter.push($(this).val() + '-' + $(this).attr('id'))
          }
          $('#s2id_' + $(this).attr('id')).removeClass('selectDisabled')
        })

        // this part only happens on new productlistselector controls
        if ($('.filterSelection input[type=checkbox]').length > 0) {
          if (window.currentLanguage == 'ru') {
            $('.filterSelection span.markup5').each(function () {
              if (
                $(this).siblings().find('input[type=checkbox]:checked').length > 0 &&
                $.inArray(
                  $(this).siblings().find('input[type=checkbox]:checked').val(),
                  selectValues
                ) === -1
              ) {
                selectValues.push(
                  $(this)
                    .siblings()
                    .find('input[type=checkbox]:checked')
                    .map(function () {
                      return this.value
                    })
                    .get()
                    .join('|')
                )
              } else {
                selectValues.push('')
              }

              selectIds.push($(this).attr('id'))
              selectType.push($(this).attr('data-class'))
            })
          } else {
            $('.filterSelection h3').each(function () {
              if (
                $(this).siblings().find('input[type=checkbox]:checked').length > 0 &&
                $.inArray(
                  $(this).siblings().find('input[type=checkbox]:checked').val(),
                  selectValues
                ) === -1
              ) {
                selectValues.push(
                  $(this)
                    .siblings()
                    .find('input[type=checkbox]:checked')
                    .map(function () {
                      return this.value
                    })
                    .get()
                    .join('|')
                )
              } else {
                selectValues.push('')
              }

              selectIds.push($(this).attr('id'))
              selectType.push($(this).attr('data-class'))
            })
          }
        }
      } else if ($('.productSelectorFilter').parent().is(':visible')) {
        $('.filterSelection select').each(function () {
          selectValues.push($(this).val())
          selectIds.push($(this).attr('id'))
          selectType.push($(this).attr('class'))
          if ($(this).val() != '') {
            selectedOptionsFilter.push($(this).val() + '-' + $(this).attr('id'))
          }
          $('#s2id_' + $(this).attr('id')).removeClass('selectDisabled')
        })
      }

      if (selectedAttId > -1 && selectedEntryId > -1 && selectedType != '-1') {
        if ($('.productlistselector').length <= 0) {
          if (selectedOptionsFilter.length > 0) {
            updateCharacteristicsDropDownCount()
          } else {
            productDistributorResetFilter()
          }
        }
      }
      $('#filterResultContainer .tipBox').remove()

      var corrLang
      if (
        typeof window.selectedEntryLanguage != 'undefined' &&
        window.selectedEntryLanguage.length > 0
      ) {
        corrLang = window.selectedEntryLanguage
      } else {
        corrLang = window.currentLanguage
      }

      var getCPResult = '0'
      if (typeof channelCP != 'undefined' && channelCP.length > 0) {
        getCPResult = 'CP'
      }

      var parameter
      if (getUrlVar('itemSize') != '') {
        parameter = {
          Group: groupid,
          SelectValues: selectValues,
          SelectIds: selectIds,
          SelectTypes: selectType,
          CurrentLanguage: corrLang,
          CurrentSite: window.currentSite,
          CurrentPageID: currentPageID,
          ItemSize: getUrlVar('itemSize'),
          Instance: instance,
          OldSelector: false,
          Channel: getCPResult,
          taxValue: taxValue
        }
      } else {
        parameter = {
          Group: groupid,
          SelectValues: selectValues,
          SelectIds: selectIds,
          SelectTypes: selectType,
          CurrentLanguage: corrLang,
          CurrentSite: window.currentSite,
          CurrentPageID: currentPageID,
          Instance: instance,
          OldSelector: false,
          Channel: getCPResult,
          taxValue: taxValue
        }
      }

      // add
      //This part is needed to invisible or ignore products from the list for the customer portal
      parameter['showIgnoredIds'] = 'false'
      if ('undefined' !== typeof wikaShowIgnoredIds) {
        parameter['showIgnoredIds'] = wikaShowIgnoredIds
        if (!wikaShowIgnoredIds) {
          // add productids that are going to be ignored in the result set
          if ('undefined' !== typeof wikaIgnoreIds) {
            parameter['ignoreIDs'] = wikaIgnoreIds.toString()
          } else {
            if (getUrlVar('ignoreIDs') !== '') {
              parameter['ignoreIDs'] = getUrlVar('ignoreIDs').toString()
            }
          }
        }
      } else {
        if (getUrlVar('showIgnoredIds') === 'true') {
          parameter['showIgnoredIds'] = 'true'
        } else {
          parameter['ignoreIDs'] = getUrlVar('ignoreIDs').toString()
        }
      }

      if ($('.preloadOverlay').length == 0) {
        var preloadOverlay = $('<div class="preloadOverlay"></div>'),
          top = $(window).scrollTop(),
          bh = $('body').height(),
          h = $('.grid-row').height(),
          percent = top / bh,
          corr = Math.floor(percent * 100) + 15
        if (corr > 98) {
          corr = 98
        }

        if (h < 1) {
          if ($('#productDistribution').length > 0) {
            h = $('#productDistribution').height()
          } else {
            h = $('#filterResultContainer').parent().height()
          }
        }

        preloadOverlay.css({
          backgroundPosition: 'center ' + corr + '%',
          height: h
        })
        $('#filterResultContainer').append(preloadOverlay)
      }
      $('.ProductFilter__pagination').hide()
      $('.productResultContainer').hide()

      if (callbackRequired) {
        setTimeout(function () {
          productResults = window.productResults || []

          if (!productResults) {
            console.warn('Global object "productResults" of product filter not available!')
            return
          }

          var data = JSON.stringify(productResults)
          /*
           * for the new knockout based product selector the JSON data is stored in a hidden field
           * and gets picked up later in productOverview.js
           */
          if (!parameter.OldSelector) {
            $('.ProductFilter__pagination').show()
            $('.productResultContainer').show()
            $('#filterResultContainer .preloadOverlay').detach()

            //We will do manually updating JSON content to setup inactiva products by JS because of to long query urls
            if (parameter['showIgnoredIds'] === 'true' || parameter['showIgnoredIds'] === true) {
              var item = JSON.parse(data)
              item.forEach(function (a) {
                if ('undefined' !== typeof wikaIgnoreIds) {
                  if (wikaIgnoreIds.indexOf(parseInt(a.productId)) > -1) {
                    a.showAsInactive = 1
                  }
                } else {
                  if (getUrlVar('ignoreIDs') !== '') {
                    var ignoreItems = getUrlVar('ignoreIDs').toString()
                    if (ignoreItems.indexOf(parseInt(a.productId)) > -1) {
                      a.showAsInactive = 1
                    }
                  }
                }
              })
              data = JSON.stringify(item)
            }

            $('<input>')
              .attr({
                type: 'hidden',
                id: 'selectorData',
                value: data
              })
              .appendTo('body')

            ko2.applyBindings(new ProductSelectorViewModel())

            return
          }
          var productContainerClass
          var productsPerRow
          if (instance == 'shop') {
            productContainerClass = '.webshopProductContainer'
            productsPerRow =
              $('.productlistselector .filterSelection.twoColumns').length > 0 ? 2 : 3
          } else {
            productContainerClass = '.productContainer'
            productsPerRow = 2
          }
          var products = $(data).filter(productContainerClass)
          if ($('.productlistselector .filterSelection.twoColumns').length > 0) {
            products.addClass('newSelector')
          }

          if (products.length == 0) {
            $('.ProductFilter__pagination').show()
            $('.productResultContainer').show()
            $('#filterResultContainer .preloadOverlay').detach()
          } else {
            $('.ProductFilter__pagination').show()
            $('.productResultContainer').show()
            $('.productCount').text(products.length)
            var filterResultContainer = $('#productResult').empty().height('auto')
            filterResultContainer.hide()
            for (var i = 0; i < products.length / productsPerRow; i++) {
              var productRow = $('<div class="columns is-mobile productRow"></div>')
              productRow.append(
                products.slice(i * productsPerRow, i * productsPerRow + productsPerRow)
              )
              filterResultContainer.append(productRow)
            }
            var productRows = filterResultContainer.find('.productRow')
            productRows.hide()
            var productsPerPage = $('#productResult').find(productContainerClass).length + 1
            if ($('select.selectProductNoSelector').prop('value') != '') {
              productsPerPage = parseInt($('select.selectProductNoSelector').prop('value'), 10)
            }
            $('#selectProductPageSelector').empty()
            var pages = products.length / productsPerPage
            if (pages <= 1) {
              $('#selectProductPageSelector').append('<option value="1">1</option>')
            } else {
              for (var p = 0; p < pages; p++) {
                $('#selectProductPageSelector').append(
                  '<option value="' + (p + 1) + '">' + (p + 1) + '</option>'
                )
              }
            }
            var of = $('.pagesof').html()
            $('.totalPageNr').text(of + ' ' + Math.ceil(products.length / productsPerPage))

            if (setProdOnce == 0 && getUrlVar('itemSize') != '') {
              if (getUrlVar('itemSize') == 'all') {
                $('#selectProductNoSelector').val('').trigger('change')
                setProdOnce++
              } else {
                $('#selectProductNoSelector')
                  .val(getUrlVar('itemSize'))
                  .attr('selected', true)
                  .trigger('change')
                setProdOnce++
              }
            }

            if (getUrlVar('tabPage').length <= 0) {
              $('#selectProductPageSelector').prop('value', 1)
            } else {
              $('#selectProductPageSelector').prop('value', getUrlVar('tabPage'))
            }
            $('#selectProductPageSelector').change()
            filterResultContainer.show()
            $('#filterResultContainer .preloadOverlay').detach()

            if (instance === 'shop' && $('input[id*=hfShippingUrl]').length > 0) {
              filterResultContainer
                .find('.shop-price > a')
                .attr('href', $('input[id*=hfShippingUrl]').val())
            }
          }

          setCheckboxProductCountAndState(selectorGroupId)

          $('.filterSelection.twoColumns').removeClass('disabledSelector')
        }, 0)
      }

      if ($('.filterSelection.twoColumns').find('input[type=checkbox]:checked').length > 0) {
        $('.filterSelection.twoColumns').find('.checkbox-trigger-global.remove').show()
      } else {
        $('.filterSelection.twoColumns').find('.checkbox-trigger-global.remove').hide()
      }

      return false
    }

    // BAYOONET EDIT: GET REQUEST PARAMETER BY KEY
    function getUrlVar(key) {
      var result = new RegExp(key + '=([^&]*)', 'i').exec(window.location.search)
      return (result && result[1]) || ''
    }

    function initNewProductSelectorControl() {
      $('.filterSelection h3[data-toggle]').on('click', function () {
        $(this).toggleClass('toggled')
        $(this).parent().toggleClass('checkboxes-hidden')
      })

      if (window.currentLanguage == 'ru') {
        $('.filterSelection span[data-toggle]').on('click', function () {
          $(this).toggleClass('toggled')
          $(this).parent().toggleClass('checkboxes-hidden')
        })
      }

      $('.filterSelection .checkbox-trigger.show').on('click', function (e) {
        e.preventDefault()
        $(this).parent().find('label:gt(4)').toggleClass('checkbox-hidden')
        $(this).hide()
        $(this).parent().find('.checkbox-trigger.hide').show()
      })

      $('.filterSelection .checkbox-trigger.hide').on('click', function (e) {
        e.preventDefault()
        $(this).parent().find('label:gt(4)').toggleClass('checkbox-hidden')
        $(this).hide()
        $(this).parent().find('.checkbox-trigger.show').show()
      })

      $('.filterSelection .checkboxes input[type=checkbox]').on('change', function (e) {
        e.preventDefault()

        if ($(this).closest('div').find('input[type=checkbox]:checked').length > 0) {
          $(this).closest('div').find('.checkbox-trigger.remove').show()
        } else {
          $(this).closest('div').find('.checkbox-trigger.remove').hide()
        }
      })

      $('.filterSelection .checkboxes .checkbox-trigger.remove').on('click', function (e) {
        e.preventDefault()

        $(this).parent().find('input[type=checkbox]:checked').attr('checked', false)
        productListGetResults(currentGroup, -1, -1, '-1', true, false, -1)

        $(this).hide()

        if ($('.filterSelection.twoColumns').find('input[type=checkbox]:checked').length > 0) {
          $('.filterSelection.twoColumns').find('.checkbox-trigger-global.remove').show()
        } else {
          $('.filterSelection.twoColumns').find('.checkbox-trigger-global.remove').hide()
        }
      })

      $('.filterSelection .bottom-panel .checkbox-trigger-global.remove').on('click', function (e) {
        e.preventDefault()
        // reset checkboxes
        $('.filterSelection.twoColumns')
          .find('input[type=checkbox]:checked')
          .each(function (ic, input) {
            $(input).removeProp('checked')
          })

        // hide all "reset selectors" buttons
        $('.filterSelection.twoColumns').find('.checkbox-trigger.remove').hide()

        // reset product list
        productListGetResults(currentGroup, -1, -1, '-1', true, false, -1)

        // reenable checkboxes
        $('span[id*=attOption]').each(function () {
          var id = $(this).attr('id').split('-')[0].replace('attOption', '')

          if ($('input[value=' + id + ']')[0].hasAttribute('data-oldCount')) {
            $('input[value=' + id + ']')
              .parent()
              .find('.productCountText')
              .text(
                $('input[value=' + id + ']')
                  .parent()
                  .find('.productCountText')
                  .text()
                  .replace('(0)', '(' + $('input[value=' + id + ']').attr('data-oldCount') + ')')
              )
          }

          if ($('input[value=' + id + ']').attr('data-oldCount') > 0) {
            $('input[value=' + id + ']')
              .removeAttr('disabled')
              .removeAttr('data-oldCount')
          }

          $('input[value=' + id + ']').removeAttr('data-oldCount')
        })
      })

      // prevent click event on inactive products
      $('#filterResultContainer').on('click', '.productContainer.inactiveProduct a', function (e) {
        e.preventDefault()
        return false
      })
    }

    $(document).ready(function () {
      getOptions(window.currentGroup)
    })

    $(document).ready(function () {
      if (isFilterHeadEnabled === '1') {
        $('.filterHead').css('cursor', 'pointer')
        $('.filterHead a').css('display', 'inline')
        $('span.ui-icon-triangle-1-e').css('display', 'inline')
      } else {
        $('.filterHead').css('cursor', 'default')
        $('.filterHead a').css('display', 'none')
        $('span.ui-icon-triangle-1-e').css('display', 'none')
      }
    })
  })
}

const initInterval = window.setInterval(() => {
  if (window.$ !== undefined) {
    init()

    window.clearInterval(initInterval)
  }
}, 100)

export default {
  name: 'ProductFilter'
}
</script>

<style module lang="scss">
@import './ProductFilter.scss';
</style>
