<template>
  <div :class="$style.container">
    <div :class="$style.contentArea">
      <MapFilterApp
        :config="config.mapConfig"
        :state="state"
        @display-details="getContentFromUrl"
      />
    </div>
    <template v-if="jsonLoaded">
      <h2 v-if="json.title" :class="$style.title">
        {{ json.title }}
      </h2>
      <div :class="$style.contentArea">
        <section v-if="json.content.simpleMap" :class="$style.contentSection">
          <ZoomableMapBox :config="json.content.simpleMap" />
        </section>
        <section v-if="json.content.generalInfo" :class="$style.contentSection">
          <TextMediaElement
            :media-right="json.content.generalInfo.mediaRight"
            :text="json.content.generalInfo.text"
            :title="json.content.generalInfo.title"
            :slides="json.content.generalInfo.slides"
            :video="json.content.generalInfo.video"
            :image="json.content.generalInfo.image"
            :youtube="json.content.generalInfo.youtube"
          />
        </section>
        <section v-if="json.content.benefits" :class="[$style.contentSection, $style.benefits]">
          <IconsList :config="json.content.benefits" />
        </section>
        <section v-if="json.content.hero" :class="$style.contentSection">
          <h2>{{ json.content.hero.title }}</h2>
          <div
            v-if="json.content.hero.text"
            :class="$style.contentText"
            v-html="json.content.hero.text"
          />
          <HeroSlider :slides="json.content.hero.slides" />
        </section>
        <section v-if="json.content.wayToUs" :class="$style.contentSection">
          <TextMediaElement
            :media-right="json.content.wayToUs.mediaRight"
            :title="json.content.wayToUs.title"
            :text="json.content.wayToUs.text"
            :slides="json.content.wayToUs.slides"
            :video="json.content.wayToUs.video"
            :image="json.content.wayToUs.image"
          />
        </section>
        <section v-if="json.content.carousel" :class="$style.contentSection">
          <h2>{{ json.content.carousel.title }}</h2>
          <div
            v-if="json.content.carousel.text"
            :class="$style.contentText"
            v-html="json.content.carousel.text"
          />
          <RotatingCarousel
            uid="locationCarousel"
            :slides="json.content.carousel.slides"
            :autoplay="true"
            :bg-img-url="json.content.carousel.bgImgUrl"
          />
        </section>
      </div>
    </template>
  </div>
</template>

<script setup>
import MapFilterApp from '../MapFilterApp/MapFilterApp.vue'
import ZoomableMapBox from '../ZoomableMapBox/ZoomableMapBox.vue'
import IconsList from '../IconsList/IconsList.vue'
import HeroSlider from '../HeroSlider/HeroSlider.vue'
import TextMediaElement from '../TextMediaElement/TextMediaElement.vue'
import RotatingCarousel from '../RotatingCarousel/RotatingCarousel.vue'
import axios from 'axios'
import { ref } from 'vue'

/*
 * * props
 */

defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  state: {
    type: Object,
    default: () => {}
  }
})

/*
 * * refs
 */

const json = ref(null)
const jsonLoaded = ref(false)

const getContentFromUrl = (url) => {
  axios
    .get(url)
    .then((response) => ((json.value = response.data), (jsonLoaded.value = true)))
    .catch((error) => (console.error(error), (json.value = null), (jsonLoaded.value = false)))
}
</script>

<style module lang="scss">
@import './MapLocations.scss';
</style>
