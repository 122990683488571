<template>
  <nav :class="$style.mainMenu">
    <!-- Secondary links for all sites -->
    <ul v-if="secondaryNav.length > 0" :class="$style.secondaryNav">
      <li
        v-for="elm in secondaryNav"
        :id="'secondary-' + elm.id"
        :key="elm.id"
        :class="elm.subNav && elm.subNav.length > 0 ? 'hasSubNav' : ''"
      >
        <a
          :class="{ MainMenu__active: activeNodes[0] == elm.id }"
          :href="elm.url"
          :target="elm.newWindow === 'true' ? '_blank' : undefined"
          @click="preventDefaultIfNeeded($event, elm.url)"
          >{{ elm.title }}</a
        >
      </li>
    </ul>
    <!-- Primary links for Main website, Shop or Microsite -->
    <ul
      :class="$style.primaryNav"
      :style="secondaryNav.length == 0 ? { 'margin-top': '2rem' } : {}"
    >
      <li
        v-for="elm in primaryNav"
        :id="'primary-' + elm.id"
        ref="primarySubMenuLinksRefs"
        :key="elm.id"
        :class="elm.subNav && elm.subNav.length > 0 ? 'hasSubNav' : ''"
      >
        <a
          :class="{ MainMenu__active: activeNodes[0] == elm.id }"
          :href="elm.url"
          :target="elm.newWindow === 'true' ? '_blank' : undefined"
          @click="preventDefaultIfNeeded($event, elm.url)"
          >{{ elm.title }}</a
        >
      </li>
    </ul>
    <!-- Tippy template for Secondary links for all sites -->
    <template v-for="elm in secondaryNav" :key="elm.title + elm.id">
      <tippy
        v-if="Object.keys(elm).includes('subNav') && elm.subNav.length > 0"
        :content-class="$style.popupContent"
        :hide-on-click="false"
        :interactive="true"
        :offset="[0, 0]"
        :to="'#secondary-' + elm.id"
        :trigger="isTouch ? 'click' : 'mouseenter focus'"
        :z-index="10000"
        content-tag="div"
        placement="bottom"
        theme="wika light"
      >
        <template #content>
          <ul>
            <li v-for="link in elm.subNav" :key="link.title + link.id">
              <a
                :class="{ MainMenu__active: activeNodes[1] == link.id }"
                :href="link.url"
                :target="link.newWindow === 'true' ? '_blank' : undefined"
                v-html="link.title"
              />
            </li>
          </ul>
        </template>
      </tippy>
    </template>
    <!-- Tippy template for Primary links for Main site, not Shop or Microsite -->
    <template v-if="!isShop && !isMicrosite">
      <template v-for="(elm, index) in primaryNav" :key="elm.title + elm.id">
        <tippy
          v-if="elm.subNav && elm.subNav.length"
          :content-class="$style.popupContent"
          :hide-on-click="false"
          :interactive="true"
          :to="'#primary-' + elm.id"
          :trigger="isTouch ? 'click' : 'mouseenter focus'"
          :append-to="parentRef"
          :offset="[findOffset(index), 10]"
          content-tag="div"
          max-width="none"
          placement="bottom-start"
          theme="wika light big"
        >
          <template #content>
            <div style="width: 1024px">
              <Tabs
                :tab-count="elm.subNav.length"
                padding="1rem"
                :divider-before-tab="elm.dividerBeforeTab"
                :tab-titles="elm.subNav"
                is-vertical
                navigate-on-click
              >
                <template
                  v-for="(subElm, subElmIndex) in elm.subNav"
                  #[tabTitleSlot(subElmIndex)]
                  :key="subElm.title + subElm.id + '-titleslot'"
                >
                  {{ subElm.title }}
                </template>
                <template
                  v-for="(subElm, subElmIndex) in elm.subNav"
                  #[tabContentSlot(subElmIndex)]
                  :key="subElm.title + subElm.id + '-contentslot'"
                >
                  <div>
                    <MenuTeaserGrid :grid-data="subElm.subNav" />
                    <div v-if="subElm.ctas" :class="$style.ctas">
                      <div :class="['columns', 'is-multiline', 'is-variable', 'is-1']">
                        <div
                          v-for="(cta, ctaIndex) in subElm.ctas"
                          :key="ctaIndex"
                          :class="['column', `is-${cta.mode}`]"
                        >
                          <a
                            :href="cta.url"
                            :target="cta.target"
                            :class="$style.cta"
                            :style="{
                              backgroundColor: cta.colorCode,
                              '--color-hover': cta.colorCodeHover
                            }"
                            >{{ cta.linkText }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </Tabs>
            </div>
          </template>
        </tippy>
      </template>
    </template>
    <!-- Tippy template for Primary links for Shop or Microsite -->
    <template v-if="isShop || isMicrosite">
      <template v-for="elm in primaryNav" :key="elm.title + elm.id">
        <tippy
          v-if="elm.subNav && elm.subNav.length"
          :content-class="$style.popupContent"
          :hide-on-click="false"
          :interactive="true"
          :to="'#primary-' + elm.id"
          :trigger="isTouch ? 'click' : 'mouseenter focus'"
          content-tag="div"
          placement="bottom"
          theme="wika light big"
        >
          <template #content>
            <ul>
              <li v-for="link in elm.subNav" :key="link.title + link.id">
                <a
                  :class="{ MainMenu__active: activeNodes[1] == link.id }"
                  :target="link.newWindow === 'true' ? '_blank' : undefined"
                  :href="link.url"
                  @click="preventDefaultIfNeeded($event, link.url)"
                  v-html="link.title"
                />
              </li>
            </ul>
          </template>
        </tippy>
      </template>
    </template>
  </nav>
</template>

<script setup>
import Tabs from '../Tabs/Tabs.vue'
import MenuTeaserGrid from '../MenuTeaserGrid/MenuTeaserGrid.vue'
import { ref, toRef } from 'vue'

const props = defineProps({
  isForcedOpen: {
    type: Boolean,
    default: false
  },
  isMicrosite: {
    type: Boolean,
    default: false
  },
  isMultiPage: {
    type: Boolean,
    default: false
  },
  isShop: {
    type: Boolean,
    default: false
  },
  primaryNav: {
    type: Array,
    default: () => []
  },
  secondaryNav: {
    type: Array,
    default: () => []
  },
  parentNode: {
    type: Object,
    default: undefined
  },
  activeNodes: {
    type: Array,
    default: () => []
  }
})

const isTouch = 'ontouchstart' in document.documentElement
const parentRef = toRef(props, 'parentNode')
const primarySubMenuLinksRefs = ref([])

function scrollTo(url) {
  const id = url.split('#')[1]
  const element = document.getElementById(id)
  element.style.scrollMargin = '10px'
  element.scrollIntoView({ behavior: 'smooth' })
}

function preventDefaultIfNeeded(event, url) {
  if (props.isMicrosite) {
    const currentTarget = event.currentTarget
    const listElements = currentTarget.parentElement.parentElement.querySelectorAll('li')
    for (const navItem of listElements) {
      navItem.firstChild.classList.remove('MainMenu__active')
    }
    currentTarget.classList.add('MainMenu__active')
    if (!props.isMultiPage) {
      if (url.startsWith('#')) {
        event.preventDefault()
        scrollTo(url)
        return false
      }
    }
    return true
  }
  if (
    event.currentTarget.parentElement.classList.contains('hasSubNav') &&
    event.currentTarget.parentElement.getAttribute('aria-expanded') === 'false'
  ) {
    event.preventDefault()
    return false
  }
  return true
}

function tabTitleSlot(index) {
  return `${index + 1}-title`
}

function tabContentSlot(index) {
  return `${index + 1}-content`
}
function findOffset(elementIndex) {
  const allParents = primarySubMenuLinksRefs.value
  const parentPosition = allParents[elementIndex]?.getBoundingClientRect().left || 0
  const containerPosition =
    document.querySelector('.PageHeader__container')?.getBoundingClientRect().left || 0
  return Math.ceil(containerPosition) - Math.ceil(parentPosition)
}
</script>
<style module lang="scss">
@import './MainMenu.scss';
</style>
