<template>
  <div>
    <div v-if="!consentGiven" :class="$style.consent">
      <h2>{{ dictionary.consentHeading }}</h2>
      <div v-html="vendorConsentText" />
      <Button
        is-close
        :class="$style.consentButton"
        :text="consentButtonLabel"
        @close="handleContentButton"
      />
    </div>
    <template v-if="consentGiven">
      <form :class="$style.container">
        <h1>{{ config.intro.title }}</h1>
        <p>{{ config.intro.text }}</p>
        <div class="columns">
          <div class="column">
            <FormInput
              v-model.trim="v$.formData.name.$model"
              :label="config.labels.name.label"
              :bold-label="true"
              :error-message="config.labels.name.errorMessage"
              :error="v$.formData.name.$error && v$.$dirty && isSubmitted"
              :original-value="previousData.name && previousData.name !== '' ? previousData.name : null"
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
          <div class="column">
            <FormInput
              v-model.trim="v$.formData.company.$model"
              :label="config.labels.company.label"
              :bold-label="true"
              :error-message="config.labels.company.errorMessage"
              :error="v$.formData.company.$error && v$.$dirty && isSubmitted"
              :original-value="
                previousData.company && previousData.company !== '' ? previousData.company : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <FormInput
              v-model="formData.customer"
              :label="config.labels.customer.label"
              :optional-label="config.labels.customer.optionLabel"
              :error="false"
              :original-value="
                previousData.customer && previousData.customer !== '' ? previousData.customer : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
          <div class="column">
            <FormInput
              v-model="formData.street"
              :label="config.labels.street.label"
              :optional-label="config.labels.street.optionLabel"
              :error="false"
              :original-value="
                previousData.street && previousData.street !== '' ? previousData.street : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <FormInput
              v-model.trim="v$.formData.zipCity.$model"
              :label="config.labels.zipCity.label"
              :bold-label="true"
              :error-message="config.labels.zipCity.errorMessage"
              :error="v$.formData.zipCity.$error && v$.$dirty && isSubmitted"
              :original-value="
                previousData.zipCity && previousData.zipCity !== '' ? previousData.zipCity : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
          <div class="column">
            <FormSelect
              v-model.trim="v$.formData.country.$model"
              :label="config.labels.country.label"
              :error-message="config.labels.country.errorMessage"
              :error="v$.formData.country.$error && v$.$dirty && isSubmitted"
              :options="config.countryOptions"
              :initial-option="config.initialCountryOption"
              :value="config.initialCountryOption"
              colour="white"
              :required="true"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <FormInput
              v-model="formData.phone"
              :label="config.labels.phone.label"
              :optional-label="config.labels.phone.optionLabel"
              :error="false"
              :original-value="
                previousData.phone && previousData.phone !== '' ? previousData.phone : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
          <div class="column">
            <FormInput
              v-model.trim="v$.formData.email.$model"
              :label="config.labels.email.label"
              :bold-label="true"
              :error-message="config.labels.email.errorMessage"
              :error="v$.formData.email.$error && v$.$dirty && isSubmitted"
              :original-value="
                previousData.email && previousData.email !== '' ? previousData.email : null
              "
              :form-submit-switch="formSubmitSwitch"
            />
          </div>
        </div>
        <div :class="$style.articleDataText">
          <b>{{ config.overview.title }}</b>
          <p>{{ config.overview.text }}</p>
        </div>
        <div v-if="formData.articleData && formData.articleData.length !== 0">
          <div
            v-for="(item, outerIndex) in formData.articleData"
            :key="outerIndex"
            :class="$style.articleData"
          >
            <b>{{ item.productTypes }}</b>
            <div v-for="(article, index) in item.articles" :key="index" class="columns is-mobile">
              <div class="column is-half">
                {{ config.labels.articleData.articleNumber }}: {{ article.number }}
                <tippy
                  ref="tippy"
                  :animate-fill="false"
                  :arrow="true"
                  :class="$style.infoPopper"
                  :distance="7"
                  :duration="[200, 600]"
                  :interactive="true"
                  :trigger="isMobile ? 'click' : 'mouseenter focus'"
                  :z-index="10000"
                  animation="fade"
                  placement="bottom"
                  theme="wika light tooltip"
                  @init="initTippy"
                >
                  <i class="fas fa-file-alt" />

                  <template #content>
                    <div
                      :style="{
                        fontSize: '10px',
                        padding: '10px 10px 0'
                      }"
                      v-html="article.detailText"
                    />
                  </template>
                </tippy>

                <a
                  :class="$style.trash"
                  :title="config.trashTitle"
                  @click.prevent.stop="removeItem(index, outerIndex)"
                >
                  <i class="fas fa-trash-alt" />
                </a>
              </div>
              <div class="column is-half">
                <FormInput
                  v-model="article.total"
                  type="number"
                  maxlength="4"
                  min="1"
                  :label="config.labels.articleData.articleTotal + ':'"
                />
              </div>
            </div>
          </div>
        </div>
        <p v-else :class="$style.warning">
          <span>{{ config.warning.title }}</span
          >{{ config.warning.text }}
        </p>
        <div class="columns">
          <div class="column">
            <FormTextarea
              v-model="formData.message"
              :label="config.labels.message.label"
              :optional-label="config.labels.message.optionLabel"
              :error="false"
              :rows="5"
              :columns="20"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column has-text-right-tablet">
            <Button colour="blue" text="Senden" type="submit" :is-form="true" @close="handleSubmit" />
          </div>
        </div>
        <div v-if="serverError" :class="$style.serverError">
          {{ config.serverErrorMessage }}
        </div>
        <ReCaptcha :execute-switch="executeSwitch" :submit="submit" />
      </form>
    </template>
  </div>
</template>


<script setup>
import { required, email } from '@vuelidate/validators'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'
import FormInput from '../FormInput/FormInput.vue'
import FormSelect from '../FormSelect/FormSelect.vue'
import FormTextarea from '../FormTextarea/FormTextarea.vue'
import Button from '../Button/Button.vue'
import * as Storage from '../../shared/Storage'
import { isMobile } from 'mobile-device-detect'
import StorageKeys from '../../globals/constants/storageKeys'
import submitRFQForm from '../../xhr/submitRFQForm'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, onUpdated, ref } from 'vue'
import { getMarketingConsent } from '../../globals/cookiebot'
import { getVendorConsent, registerConsentListeners } from '../../globals/consentmanager'
import cmpVendorIDs from '../../globals/constants/cmpVendorIDs'

const noPlaceholderValue = (value) =>
  value.label ? value.label.indexOf('...') === -1 : value.indexOf('...') === -1

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})


const vendor = cmpVendorIDs.GRECAPTCHA
const consentGiven = ref(window.Cookiebot ? getMarketingConsent() : getVendorConsent(vendor.id))

const dictionary = window.dictionary
const vendorConsentText = dictionary.consentText.replaceAll("###VENDOR_NAME###", vendor.name)
const consentButtonLabel = dictionary.consentButtonLabel.replaceAll(/&apos;/g, "'")

const previousData = ref({})
const isSubmitted = ref(false)
const executeSwitch = ref(false)
const formSubmitSwitch = ref(false)
const serverError = ref(false)

const formData = ref({
  name: '',
  company: '',
  customer: '',
  street: '',
  zipCity: '',
  country: '',
  phone: '',
  email: '',
  articleData: null,
  message: '',
  domain: '',
  pageUrl: '',
  language: '',
  token: ''
})

const rules = {
  formData: {
    name: {
      required
    },
    company: {
      required
    },
    zipCity: {
      required
    },
    country: {
      required,
      noPlaceholderValue
    },
    email: {
      required,
      email
    }
  }
}

const v$ = useVuelidate(rules, { formData })

const checkVendorConsent = () => {
  consentGiven.value = getVendorConsent(vendor.id)
  checkConsent()
}

const handleContentButton = () => {
  if (window.cmpConsentType === 'consentmanager') {
    window.__cmp('setVendorConsent', [vendor.id, 1], () => {
      consentGiven.value = getVendorConsent(vendor.id)
    })
  } else {
    if (window.Cookiebot) {
      window.Cookiebot.onaccept = () => {
        consentGiven.value = getMarketingConsent()
      }
      window.Cookiebot.show()
    }
    checkConsent()
  }
}

const checkConsent = () => {
  if (consentGiven.value) {
    initTippy()
  }
}

const registerConsentListenersWithDelay = (checkVendorConsent) => {
  const intervalId = setInterval(() => {
    if (typeof window.__cmp === 'function') {
      registerConsentListeners(checkVendorConsent);
      clearInterval(intervalId);
    }
  }, 100);
  setTimeout(() => clearInterval(intervalId), 5000);
};


onMounted(() => {
  if (window.Cookiebot) {
    consentGiven.value = getMarketingConsent()
  } else {
    consentGiven.value = getVendorConsent(vendor.id)
  }

  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListenersWithDelay(checkVendorConsent);
    setTimeout(() => {
      checkVendorConsent();
    }, 1000);
  } else {
    checkConsent()
  }

  checkLocalStorage()
  if (props.config.initialCountryOption) {
    formData.value.country = props.config.initialCountryOption
  }
})

onUpdated(() => {
  if (window.cmpConsentType === 'consentmanager') {
    registerConsentListeners(checkVendorConsent)
    checkVendorConsent()
  } else {
    checkConsent()
  }

  if (window.Cookiebot) {
    consentGiven.value = getMarketingConsent()
  } else {
    consentGiven.value = getVendorConsent(vendor.id)
  }
})

const checkLocalStorage = () => {
  formData.value.articleData = Storage.getLocalStorage(StorageKeys.ARTICLE_DATA, true)
}

const initTippy = (tippy) => {
  tippy.popper.id = 'infoPopper'
}

const removeItem = (index, outerIndex) => {
  if (formData.value.articleData[outerIndex].articles.length > 1) {
    formData.value.articleData[outerIndex].articles.splice(index, 1)
  } else {
    formData.value.articleData.splice(outerIndex, 1)
  }
  Storage.setLocalStorage(StorageKeys.ARTICLE_DATA, formData.value.articleData, true)
}

const handleSubmit = () => {
  serverError.value = false
  v$.value.$touch()
  isSubmitted.value = true
  formSubmitSwitch.value = !formSubmitSwitch.value
  if (!v$.value.$invalid) {
    executeSwitch.value = !executeSwitch.value
    // submit()
  } else {
    previousData.value = JSON.parse(JSON.stringify(formData.value))
  }
}

const submit = (token) => {
  const formData = {
    ...formData.value,
    ...props.config.mailData,
    formId: props.config.formId,
    'mwf-formElement-sequence':
      'name,company,customer,street,zipCity,country,phone,email,articleData,message',
    'mwf-formLabel-name': 'Name',
    'mwf-formLabel-company': 'Company',
    'mwf-formLabel-customer': 'Customer',
    'mwf-formLabel-street': 'Street',
    'mwf-formLabel-zipCity': 'ZIP code / City',
    'mwf-formLabel-country': 'Country',
    'mwf-formLabel-phone': 'Phone',
    'mwf-formLabel-email': 'E-Mail',
    'mwf-formLabel-articleData': 'Article data',
    'mwf-formLabel-message': 'Message',
    domain: window.location.origin,
    pageUrl: window.location.pathname,
    language: window.currentLanguage
  }

  // Process articleData
  if (formData.articleData) {
    formData.articleData = formData.articleData
      .map((x) =>
        x.articles.map((article) => `${article.number} - ${x.productTypes} - ${article.total}`)
      )
      .flatMap((x) => x)
      .join('\n')
  }
  // Add empty attachments if missing
  if (!formData.attachments) {
    formData.attachments = []
  }

  // Process country
  if (formData.country && formData.country.value) {
    formData.country = formData.country.value
  }

  formData.token = token
  submitRFQForm(props.config.serviceUrl, formData)
    .then((response) => {
      if (response.status === 200) {
        Storage.removeLocalStorage(StorageKeys.ARTICLE_DATA)
        window.open(props.config.thankyouUrl, '_self')
      } else {
        serverError.value = true
      }
    })
    .catch((error) => {
      serverError.value = true
      console.error(error)
    })
}
</script>

<style module lang="scss">
@import './RFQForm.scss';
</style>
