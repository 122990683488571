<template>
  <div :class="$style.container">
    <VueAgile
      :dots="checkSlidesCount"
      :nav-buttons="false"
      :fade="true"
      :speed="500"
      :autoplay="true"
      :autoplay-speed="7000"
    >
      <figure
        v-for="(slide, index) in slides"
        :key="index"
        :class="[$style.slide, { [$style.slideStandalone]: slides.length === 1 }]"
      >
        <template v-if="slide.extendedSlider">
          <span
            :class="{
              [$style.videoLink]: slide.video,
              [$style.extended]: slide.extendedSlider
            }"
          >
            <div :class="{ [$style.productimage]: slide.isProduct }">
              <a :href="slide.link">
                <i v-if="slide.video" class="fab fa-youtube" />
                <img v-if="slide.video" :src="slide.image" :alt="slide.alt" />
                <ImageMagnifier
                  v-else
                  :alt="slide.alt"
                  :src="slide.image"
                  :zoom-src="slide.imageZoom || slide.image"
                  width="100%"
                  height="auto"
                  mask-bg-color="#434140"
                  mask-height="70"
                  mask-width="70"
                  :scale="5"
                  :orientation="zoomOrientation"
                />
              </a>
            </div>
            <figcaption :class="$style.caption">
              <a v-if="slide.category" :href="slide.category.link" :class="$style.category">
                {{ slide.category.title }}
              </a>
              <a :href="slide.link" :class="{ [$style.single]: checkSlidesCount === false }">
                <div :class="$style.title">{{ slide.title }}</div>
                <p v-if="slide.text">{{ slide.text }}</p>
                <p v-if="slide.location">
                  <Flag :flag="slide.location.flag" size="xs" :class="$style.flag" />
                  <span>{{ slide.location.city }}</span>
                </p>
              </a>
            </figcaption>
          </span>
        </template>
        <template v-else>
          <a :class="{ [$style.videoLink]: slide.video }" @click="openModal(slide)">
            <div :class="{ [$style.productimage]: slide.isProduct }">
              <i v-if="slide.video" class="fab fa-youtube" />
              <img v-if="slide.video" :src="slide.image" :alt="slide.alt" />
              <ImageMagnifier
                v-else
                :alt="slide.alt"
                :src="slide.image"
                :zoom-src="slide.imageZoom || slide.image"
                mask-bg-color="#fff"
                width="100%"
                height="auto"
                mask-height="70"
                mask-width="70"
                :scale="5"
                :orientation="zoomOrientation"
              />
            </div>
            <figcaption v-if="slide.title || slide.text" :class="$style.caption">
              <div :class="$style.title">
                {{ slide.title }}
              </div>
              <p v-if="slide.text">{{ slide.text }}</p>
            </figcaption>
          </a>
        </template>
      </figure>
    </VueAgile>
    <Modal
      :is-modal-open="isModalOpen"
      :is-video="isVideo"
      :is-product="isProduct"
      @close-modal="closeModal"
    >
      <iframe
        v-if="isVideo"
        :data-src="currentSlideSrc"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      />
      <img v-else :src="currentSlideSrc" alt="" />
    </Modal>
  </div>
</template>

<script setup>
import { VueAgile } from 'vue-agile'
import Modal from '../Modal/Modal.vue'
import ImageMagnifier from '../ImageMagnifier/ImageMagnifier.vue'
import Flag from '../Flag/Flag.vue'
import { mergeSwitchWtParams } from '../../utils/tracking'
import { ref, computed } from 'vue'

const props = defineProps({
  slides: {
    type: Array,
    default: () => []
  },
  zoomOrientation: {
    type: String,
    default: () => 'left'
  },
  wtParams: Object
})

const isModalOpen = ref(false)
const isVideo = ref(false)
const isProduct = ref(false)
const currentSlideSrc = ref(null)
const wtTrack = ref(window.wtTrack)
const checkSlidesCount = computed(() => props.slides.length > 1)

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) =>
  mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)

const openModal = (slide) => {
  isProduct.value = slide.isProduct || false
  if (slide.video) {
    isVideo.value = true
    currentSlideSrc.value = slide.video
    slide.wtParams &&
      wtTrack.value(
        { cat: slide.wtParams.doctype },
        mergeWtParams(
          props.wtParams,
          {
            ...slide.wtParams,
            filename: slide.video
          },
          {}
        )
      )
  } else {
    currentSlideSrc.value = slide.imageZoom || slide.image
  }
  isModalOpen.value = true
}

const closeModal = () => {
  isModalOpen.value = false
  currentSlideSrc.value = null
  isVideo.value = false
}
</script>

<style module lang="scss">
@import './SidebarSlider.scss';
</style>
