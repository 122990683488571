const StoryConfig = {
  type: 'website',
  wishList: {
    serviceUrl: '/rest-services/wishlist/send',
    ftlUrl: window.baseUrl + '/de-de/merkzettel-mail-template.ftl',
    title: 'Merkzettel',
    emailPlaceholder: 'E-Mail',
    buttonText: 'Merkzettel',
    emailErrorMessage: 'Die E-Mail-Adresse hat ein falsches Format.',
    formErrorMessage: 'Zur Zeit befinden sich keine Dokumente auf Ihrem Merkzettel.',
    technicalErrorMessage: 'Es ist ein technischer Fehler aufgetreten.',
    successMessage: 'Die E-Mail wurde erfolgreich versendet.',
    counterText: 'Dokumente',
    emptyText:
      'Zur Zeit befinden sich keine Dokumente auf Ihrem Merkzettel. Sie können alle Dokumente aus dem Bereich Download zu Ihrem Merkzettel hinzufügen. Wählen Sie hierzu die gewünschte Sprache über das Flaggensymbol.',
    formText: 'Um Ihren Downloadlink per E-Mail zu erhalten, tragen Sie hier Ihre Adresse ein:',
    type: 'Typ',
    types: 'Typen'
  },
  copyright: '<span>© 2023 WIKA Alexander Wiegand SE & Co. KG</span>',
  socialLinks: [
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-youtube',
      color: '#e62117'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-linkedin-in',
      color: '#0072b1'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-xing',
      color: '#007e7e'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-facebook-f',
      color: '#3c5598'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-instagram',
      color: '#DD2A7B'
    }
  ],
  columns: [
    {
      genericLinks: [
        {
          content: 'Impressum',
          href: '#',
          target: '_blank'
        },
        {
          content: 'Datenschutzhinweise',
          href: '#',
          target: '_blank'
        },
        {
          content: 'AGB',
          href: '#',
          target: '_self'
        },
        {
          content: 'Bankverbindung',
          href: '#',
          target: '_blank'
        }
      ]
    },
    {
      class: 'Footer__links',
      content:
        '<ul class="parse-json-test-class">' +
        '<li><a href="#" target="_blank">Stellenmarkt</a></li>' +
        '<li><a href="#" target="_blank">Häufige Fragen</a></li>' +
        '<li><a href="#" target="_blank">Einheiten-Umrechner</a></li>' +
        '<li><a href="#" target="_blank">Blog</a></li>' +
        '</ul>'
    },
    {
      newsletter: {
        title: 'WIKA-Newsletter',
        text: 'Erhalten Sie aktuelle Informationen und Neuigkeiten von WIKA.',
        errorMessage: 'Bitte tragen Sie eine valide E-Mail-Adresse ein.',
        placeholder: 'E-Mail-Adresse',
        newsletterFormUrl: 'newsletter-form.html'
      }
    },
    {
      class: 'Footer__contact',
      content:
        '<div class="title">Kontakt</div>\n' +
        '<p>WIKA Alexander Wiegand SE &amp; Co. KG</p>\n' +
        '<p>Alexander-Wiegand-Str. 30 <br />63911 Klingenberg - Deutschland</p>\n' +
        '<p>Tel.: +49 9372 132-0</p>\n' +
        '<p>E-Mail: <a href="mailto:info@wika.de">info@wika.de</a></p>'
    }
  ]
}

export default StoryConfig

export const StoryShopConfig = {
  type: 'shop',
  badges: [
    {
      image: {
        class: 'ftrlFooter_imageShipping',
        src: 'https://www.wika.com/media/Images/System/ShopBadges/ordering.png'
      },
      headline: 'Kostenfreier Versand',
      text: 'Ab 100 Euro Warenwert'
    },
    {
      image: {
        class: 'ftrlFooter_imagePayment',
        src: 'https://www.wika.com/media/Images/System/ShopBadges/payment.png'
      },
      headline: 'Einfache Bezahlung',
      text: 'Alle gängigen Zahlungsmittel'
    },
    {
      image: {
        class: 'ftrlFooter_imageShipping',
        src: 'https://www.wika.com/media/Images/System/ShopBadges/original.png'
      },
      headline: '100% Original',
      text: 'Direkt vom Hersteller'
    },
    {
      image: {
        class: 'ftrlFooter_imageDelivery',
        src: 'https://www.wika.com/media/Images/System/ShopBadges/delivery.png'
      },
      headline: 'Schnelle Lieferung',
      text: '1.000.000 Lagergeräte'
    },
    {
      image: {
        class: 'ftrlFooter_imageSsl',
        src: 'https://www.wika.com/media/Images/System/ShopBadges/secure.png'
      },
      headline: 'Sicheres Einkaufen',
      text: 'SSL-/TLS-Verschlüsselung'
    }
  ],
  copyright:
    '<div>© 2019 WIKA Alexander Wiegand SE &amp; Co. KG</div>' +
    '<div>Kein Verkauf an Privatkunden i.S.d. § 13 BGB - Liefergebiet: Deutschland, Österreich.</div>',
  socialLinks: [
    {
      url: 'http://blog.wika.de/',
      newWindow: 'true',
      icon: 'fas fa-comment-alt',
      color: '#3480cf'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-youtube',
      color: '#e62117'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-linkedin-in',
      color: '#0072b1'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-xing',
      color: '#007e7e'
    },
    {
      url: '#',
      newWindow: 'true',
      icon: 'fab fa-facebook-f',
      color: '#3c5598'
    }
  ],
  columns: [
    {
      genericLinks: [
        {
          content: 'Impressum',
          href: '#',
          newWindow: 'false'
        },
        {
          content: 'Unternehmen',
          href: '#',
          newWindow: 'false'
        },
        {
          content: 'Datenschutzhinweise',
          href: '#',
          newWindow: 'false'
        },
        {
          content: 'AGB',
          href: '#',
          newWindow: 'true'
        },
        {
          content: 'Kontakt',
          href: '#',
          newWindow: 'false'
        }
      ]
    },
    {
      class: 'Footer__contact',
      content:
        '<div class="title">Kontakt</div>\n' +
        '<p>WIKA Alexander Wiegand SE &amp; Co. KG</p>\n' +
        '<p>Alexander-Wiegand-Str. 30 <br />63911 Klingenberg - Deutschland</p>\n' +
        '<p>Tel.: +49 9372 132-0</p>\n' +
        '<p>E-Mail: <a href="mailto:info@wika.de">info@wika.de</a></p>'
    },
    {
      class: 'Footer__text',
      content:
        '<strong class="title">Fragen?</strong>' +
        '<p>Kontaktieren Sie uns gerne kostenfrei über folgende Nummer:</p>' +
        '<p>' +
        '<strong class="phone">0800 1810390</strong><br />' +
        'Mo. - Do. 08:00 - 16:30 Uhr<br />' +
        'Fr. 08:00 - 15:00 Uhr' +
        '</p>'
    },
    {
      class: 'Footer__text',
      content:
        '<div class="columns is-multiline is-mobile">' +
        '<div class="column is-one-third">' +
        '<img src="https://www.wika.com/media/Images/System/ShopPayments/mastercard_en_co.png" alt="Master Card" />' +
        '</div>' +
        '<div class="column is-one-third">' +
        '<img src="https://www.wika.com/media/Images/System/ShopPayments/visa_en_co.png" alt="Visa" />' +
        '</div>' +
        '<div class="column is-one-third">' +
        '<img src="https://www.wika.com/media/Images/System/ShopPayments/maestro_en_co.png" alt="Maestro" />' +
        '</div>' +
        '<div class="column is-one-third">' +
        '<img src="https://www.wika.com/media/Images/System/ShopPayments/paypal_en_co.png" alt="PayPal" />' +
        '</div>' +
        '<div class="column is-one-third">' +
        '<img src="https://www.wika.com/media/Images/System/ShopPayments/rechnung_de_de.png" alt="Rechnung" />' +
        '</div>' +
        '</div>'
    }
  ]
}
