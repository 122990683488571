<template>
  <div :class="[$style.container, 'column', 'is-half-tablet', 'is-one-quarter-desktop']">
    <div class="Footer__title">
      {{ config.title }}
    </div>
    <p>{{ config.text }}</p>
    <div :class="$style.newsletterForm">
      <input
        id="newsletter-email"
        v-model="newsletterEmailAddress"
        name="newsletter-email"
        data-origval=""
        class="origVal"
        :placeholder="config.placeholder"
        type="email"
        :disabled="checkIsNewsletterUrlMissing"
        :class="$style.emailInput"
        aria-label="newsletter-email"
      />
      <div :class="$style.submitContainer">
        <button
          id="navigateToNewsletterFormBtn"
          value=""
          :class="$style.submitButton"
          :disabled="checkIsNewsletterUrlMissing"
          aria-label="submit newsletter-email"
          @click="navigateToNewsletterForm"
        />
        <i class="fas fa-caret-right" />
      </div>
      <div v-if="newsletterEmailAddressInvalid" :class="$style.errorMessage">
        {{ config.errorMessage }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { Email } from '../../globals/constants/validation'
import { computed, ref } from 'vue'

const newsletterEmailAddress = ref('')
const newsletterEmailAddressInvalid = ref(false)

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const navigateToNewsletterForm = () => {
  newsletterEmailAddressInvalid.value = !checkIsEmailValid()
  if (!newsletterEmailAddressInvalid.value) {
    window.location = `${props.config.newsletterFormUrl}?email=${newsletterEmailAddress.value}`
  }
}

const checkIsEmailValid = () => {
  return newsletterEmailAddress.value && Email.REGEX.test(newsletterEmailAddress.value)
}

const checkIsNewsletterUrlMissing = computed(() => {
  return !props.config.newsletterFormUrl
})
</script>

<style module lang="scss">
@import './NewsletterQuickSubscription.scss';
</style>
