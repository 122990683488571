<template>
  <form :class="$style.container">
    <div class="columns is-mobile">
      <div :class="[$style.itemsPerPage, 'column is-half']">
        <FormSelect
          v-model="initialSelectOption"
          :has-filter="false"
          :options="resultsPerPageSelectOptions"
          :initial-option="initialSelectOption"
          :disabled="props.results === 0 ? true : false"
          @update="updateResults($event)"
        />
      </div>
      <div :class="[$style.pages, 'column is-half']">
        <span :class="$style.text">{{ config.texts.page }}</span>
        <FormSelect
          v-model="currentPageNumber"
          :has-filter="false"
          :external-value="currentPageNumber"
          :options="pagesSelectOptions"
          :initial-option="'1'"
          :disabled="props.results === 0 || pages === 1"
          @update="updateCurrentPage"
        />
        <span :class="$style.text">{{ config.texts.of }} {{ pages }}</span>
        <input
          type="button"
          :disabled="currentPage === 1"
          :class="$style.previousButton"
          aria-label="previous"
          @click="currentPage--"
        />
        <input
          type="button"
          :disabled="props.results === 0 || currentPage === pages"
          :class="$style.nextButton"
          aria-label="next"
          @click="currentPage++"
        />
      </div>
    </div>
  </form>
</template>

<script setup>
import FormSelect from '../FormSelect/FormSelect.vue'
import { onBeforeMount, onMounted, ref, watch } from 'vue'

const emit = defineEmits(['update'])

const props = defineProps({
  results: {
    type: Number,
    default: null
  },
  config: {
    type: Object,
    default: () => {}
  }
})
const pages = ref(null)
const currentPage = ref(1)
const currentPageNumber = ref(null)
const resultsPerPage = ref(props.config.resultsPerPage)
const initialPagesSelectOption = ref('1')
const pagesSelectOptions = ref([])
const resultsPerPageSelectOptions = ref([])
const displayRange = ref(0, props.config.resultsPerPage - 1)
const initialSelectOption = ref([])

const updatePages = () => {
  currentPage.value = 1
  initialPagesSelectOption.value = currentPage.value.toString()
  const updatedPageCount = Math.ceil(props.results / resultsPerPage.value)
  pages.value = updatedPageCount
  pagesSelectOptions.value = []
  for (let i = 1; i < pages.value + 1; i++) {
    pagesSelectOptions.value.push(i.toString())
  }

  setDisplayRange()
}

const setDisplayRange = () => {
  displayRange.value = [
    currentPage.value * resultsPerPage.value - resultsPerPage.value,
    currentPage.value * resultsPerPage.value - 1
  ]
  emit('update', displayRange.value)
}

const setDisplayRangeFromPageChange = () => {
  displayRange.value = [
    currentPage.value * resultsPerPage.value - resultsPerPage.value,
    currentPage.value * resultsPerPage.value - 1
  ]
  emit('update', displayRange.value)
}

const updateResults = (results) => {
  const index = resultsPerPageSelectOptions.value
    .map((resultsPerPage) => resultsPerPage)
    .indexOf(results[0])
  if (index === 0) {
    resultsPerPage.value = props.config.resultsPerPage
  } else if (index === 1) {
    resultsPerPage.value = props.config.resultsPerPage * 2
  } else if (index === 2) {
    resultsPerPage.value = 999999
  }

  updatePages()
}

const updateCurrentPage = (page) => {
  currentPage.value = +page
  initialPagesSelectOption.value = currentPage.value.toString()
  setDisplayRangeFromPageChange()
}

watch(
  () => props.results,
  (newResults, oldResults) => {
    if (newResults !== oldResults) {
      updatePages()
    }
  }
)

watch(currentPage, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    currentPageNumber.value = newValue.toString()
  }
})

onBeforeMount(() => {
  initialSelectOption.value = props.config.resultsSelect.initialSelectOption
})

onMounted(() => {
  if (typeof props.config.resultsSelect.selectOptions === 'object') {
    props.config.resultsSelect.selectOptions.forEach((selectOption) => {
      resultsPerPageSelectOptions.value.push(selectOption)
    })
  } else {
    resultsPerPageSelectOptions.value = props.config.resultsSelect.selectOptions
  }

  updatePages()
})
</script>

<style module lang="scss">
@import './Pagination.scss';
</style>
