<template>
  <div :class="$style.item">
    <a :class="$style.trash" @click.prevent.stop="removeItem(index)">
      <i class="fas fa-trash-alt" />
    </a>
    <div v-if="item.types && item.types.length" :class="$style.itemTypes">
      {{ `${productTypeLabel} ${item.types.join(', ')}` }}
    </div>
    <div v-if="item.title" :class="$style.itemTitle">
      <span v-html="item.title" />
      <div v-if="item.subTitle" v-html="item.subTitle" />
    </div>
    <div v-if="item.downloads && countries && countries.length" :class="$style.flags">
      <CountryDownloadBox
        :countries="countries"
        :section="item.section"
        :title="item.title"
        :types="item.types"
        :labels="{ ebook: 'Ebook', pdf: 'PDF', wishList: 'Wishlist' }"
        :context="countryDownloadBoxContext"
      />
    </div>
    <div v-if="item.section" :class="$style.section">
      {{ item.section }}
    </div>
  </div>
</template>

<script setup>
import CountryDownloadBox from '../CountryDownloadBox/CountryDownloadBox.vue'
import { CountryDownloadBoxContext } from '../../globals/constants/download'

defineProps({
  countries: {
    type: Array,
    default: () => []
  },
  item: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: () => null
  },
  productTypeLabel: {
    type: String,
    default: () => ''
  }
})

const emit = defineEmits(['removeItem'])
const removeItem = (index) => emit('removeItem', index)

const countryDownloadBoxContext = CountryDownloadBoxContext.WISHLIST
</script>

<style module lang="scss">
@import './WishListItem.scss';
</style>
