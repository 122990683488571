<template>
  <div :class="$style.container">
    <div :class="$style.image">
      <a :href="resultLink">
        <span v-if="result.isNew" :class="$style.newProduct" />
        <img :src="result.image" :alt="result.alt" />
      </a>
    </div>
    <div :class="$style.content">
      <a :href="resultLink">
        <div>{{ result.type }}</div>
        <div :class="$style.title" v-html="result.title" />
        <div v-html="result.text" />
      </a>
    </div>
    <div :class="$style.properties">
      <div :class="$style.propertiesLeft" />
      <div :class="$style.propertiesRight">
        <a
          v-if="result.datasheetLink"
          :class="$style.data"
          :href="result.datasheetLink"
          target="_blank"
          @click="
            result.wtParams &&
              wtTrack(
                { cat: 'PRODUCTRESULTSHANDLER' },
                {
                  ...result.wtParams,
                  filename: result.datasheetLink
                }
              )
          "
        >
          <span>{{ config.productDatasheet }}</span>
        </a>
        <a :class="$style.button" :href="resultLink">
          <span>{{ config.productDetail }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  result: {
    type: Object,
    default: () => {}
  },
  config: {
    type: Object,
    default: () => {}
  },
  additionalUrlParam: {
    type: String,
    default: ''
  }
})

const wtTrack = window.wtTrack

const resultLink = computed(() => {
  if (props.additionalUrlParam.length === 0) {
    return props.result.link
  }

  const paramDelimiter = props.result.link.indexOf('?') !== -1 ? '&' : '?'
  return props.result.link + paramDelimiter + props.additionalUrlParam
})
</script>

<style module lang="scss">
@import './ProductTeaser.scss';
</style>
