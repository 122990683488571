<template>
  <div
    :class="[
      $style.container,
      { FormInput__reset: reset },
      { FormInput__file: props.type === 'file' },
      { FormInput__error: error },
      { FormInput__original: showOriginalValueState }
    ]"
  >
    <label :class="[$style.label, { FormInput__bold: boldLabel }]" :for="formInputId">
      {{ label }}
      <span v-if="optionalLabel" :class="$style.optional">
        ({{ optionalLabel
        }}<span v-if="type === 'file'"
          >, max. {{ maxFiles }} x {{ maxFileSizeMB }} MB: {{ accept }}</span
        >)
      </span>
    </label>
    <!-- begin: special input file behavior -->
    <div v-if="type === 'file'" :class="$style.selectedFiles">
      <span
        v-if="noFilesLabel && (!selectedFiles || !selectedFiles.length)"
        :class="$style.optional"
        >{{ noFilesLabel }}</span
      >
      <div v-for="(selectedFile, index) in selectedFiles" v-else :id="`file_${index}`" :key="index">
        <Button
          class="FormInput__filesButton"
          text="X"
          colour="white"
          :is-form="false"
          is-close
          @close="deleteFile(index)"
        />
        <img v-if="selectedFile.isImage" :src="selectedFile.url" alt="" />
        {{ selectedFile.fullName }}, {{ selectedFile.size }} MB,
        {{ selectedFile.fileExtension }}
      </div>
      <span v-if="maxFilesReachedLabel && maxFilesReached" :class="$style.optional"
        >{{ maxFilesReachedLabel }} {{ selectedFiles.length }}</span
      >
    </div>
    <!-- end: special input file behavior -->
    <input
      :id="formInputId"
      :type="type"
      :value="modelValue"
      :maxlength="maxlength"
      :min="min"
      :placeholder="placeholder"
      :aria-label="ariaLabel"
      :multiple="multiple"
      @input="handleInput"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
      @keyup="handleKeyUp"
      @change="handleChange"
    />
    <Button
      v-if="(type === 'file' && !multiple) || reset"
      text="X"
      colour="white"
      :is-form="false"
      is-close
      @close="resetValue"
    />
    <div v-if="error" :class="$style.errorMessage">
      {{ errorMessage }}
    </div>
    <div v-if="type === 'file' && invalidFiles.length > 0" :class="$style.errorMessage">
      <div>{{ errorMessage }}</div>
      <div v-for="(invalidFile, index) in invalidFiles" :id="`invalidFile_${index}`" :key="index">
        {{ invalidFile.fullName }} ({{ invalidFile.size }} MB | {{ invalidFile.fileExtension }})
      </div>
      <div>(max. {{ maxFiles }} | {{ maxFileSizeMB }} MB | {{ accept }})</div>
    </div>
  </div>
</template>

<script setup>
import Button from '../Button/Button.vue'
import { nanoid } from 'nanoid'
import {
  ACCEPTED_DOCS,
  MAX_FILES,
  MAX_FILE_SIZE_MB,
  attachmentDefinition,
  attachedFile,
  isFileValid,
  isImageFile,
  isMaxFilesReached,
  getFileSizeMB,
  getFileExtension,
  getFileInitialData
} from './helpers'
import { onMounted, ref, nextTick, watch } from 'vue'

const props = defineProps({
  type: {
    type: String
  },
  modelValue: {
    type: [String, Array, FileList]
  },
  label: {
    type: String,
    default: ''
  },
  boldLabel: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  optionalLabel: {
    type: String
  },
  errorMessage: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  reset: {
    type: Boolean,
    default: false
  },
  originalValue: {
    type: [String, Array]
  },
  formSubmitSwitch: {
    type: Boolean,
    default: false
  },
  placeholder: {
    type: String
  },
  maxlength: {
    type: String
  },
  min: {
    type: String
  },
  ariaLabel: {
    type: String,
    default: undefined
  },
  noFilesLabel: {
    type: String
  },
  maxFilesReachedLabel: {
    type: String
  }
})

const emit = defineEmits(['keyup', 'input', 'onFileListChanged', 'update:modelValue'])

const currentValue = ref('')
const showOriginalValueState = ref(false)
const formInputId = ref(nanoid())
const maxFilesReached = ref(false)
const selectedFiles = ref([])
const invalidFiles = ref([])
const base64Urls = ref([])
const attachedFiles = ref([])
const attachment = ref({})
const file = ref({})
const accept = ref(ACCEPTED_DOCS.join(', '))
const maxFileSizeMB = ref(MAX_FILE_SIZE_MB)
const maxFiles = ref(MAX_FILES)

onMounted(() => {
  attachment.value = attachmentDefinition
  file.value = attachedFile
})

const handleKeyUp = ($event) => {
  window.dispatchEvent(
    new CustomEvent('formInput:keyup', { detail: { props, originalEvent: $event } })
  )

  emit('keyup', $event)
}

const handleInput = ($event) => {
  if ($event.target.type !== 'file') {
    currentValue.value = $event.target.value

    window.dispatchEvent(
      new CustomEvent('formInput:input', { detail: { props, originalEvent: $event } })
    )

    emit('input', $event.target.value)
    emit('update:modelValue', $event.target.value)
  }
}

const handleChange = ($event) => {
  if ($event.target.type === 'file' && $event.target.files && $event.target.files[0]) {
    invalidFiles.value = []
    loadFiles($event.target.files)
  }
}

const handleInputFocus = () => {
  if (
    props.originalValue &&
    (props.modelValue === '' || props.modelValue === props.originalValue)
  ) {
    showOriginalValueState.value = false
    if (props.type !== 'file') {
      window.dispatchEvent(new CustomEvent('formInput:input', { detail: { props } }))

      emit('input', '')
    }
  }
}

const handleInputBlur = () => {
  if (props.originalValue && props.modelValue === '') {
    showOriginalValueState.value = true

    window.dispatchEvent(new CustomEvent('formInput:input', { detail: { props } }))

    emit('input', props.originalValue)
  }
}

const resetValue = () => {
  window.dispatchEvent(new CustomEvent('formInput:input', { detail: { props } }))

  emit('input', props.originalValue)
}

const deleteFile = (index) => {
  selectedFiles.value.splice(index, 1)
  base64Urls.value.splice(index, 1)
  attachedFiles.value.splice(index, 1)
  maxFilesReached.value = isMaxFilesReached(selectedFiles.value)
  invalidFiles.value = []

  // update parent ContactForm FormData
  window.dispatchEvent(
    new CustomEvent('formInput:onFileListChanged', {
      detail: { props, attachedFiles: attachedFiles.value }
    })
  )

  emit('onFileListChanged', attachedFiles.value)
}

const resetFile = () => {
  nextTick(() => {
    file.value = getFileInitialData()
  })
}

const loadFiles = (fileList) => {
  let fileCount = selectedFiles.value.length
  for (const file of fileList) {
    const fileSize = getFileSizeMB(file),
      fileExtension = getFileExtension(file),
      fileFullName = file.name,
      fileName = file.name.split('.').shift(),
      isImage = isImageFile(file)
    if (fileCount < MAX_FILES && isFileValid(file)) {
      // Upload file if valid
      fileCount++
      // Load base64url through FileReader API
      const reader = new FileReader()
      reader.onload = (event) => {
        // Upload file data
        file.value = {
          fullName: fileFullName,
          name: fileName,
          size: fileSize,
          type: file.type,
          fileExtension: fileExtension,
          isImage: isImage,
          url: event.target.result,
          isUploaded: true
        }
        selectedFiles.value.push(file.value)
        base64Urls.value.push(file.value.url)
        attachment.value = {
          filename: file.value.fullName,
          base64content: file.value.url.split(',').pop()
        }
        attachedFiles.value.push(attachment.value)
        maxFilesReached.value = isMaxFilesReached(selectedFiles.value)

        // update parent ContactForm FormData
        window.dispatchEvent(
          new CustomEvent('formInput:onFileListChanged', {
            detail: { props, attachedFiles: attachedFiles.value }
          })
        )

        emit('onFileListChanged', attachedFiles.value)
      }
      reader.readAsDataURL(file)
      // reset file.value values
      resetFile()
    } else {
      // Add file info to error list
      file.value = {
        fullName: fileFullName,
        name: fileName,
        size: fileSize,
        type: file.type,
        fileExtension: fileExtension,
        isImage: isImage
      }
      invalidFiles.value.push(file.value)
    }
  }
}

watch(
  () => props.formSubmitSwitch,
  (newValue, oldValue) => {
    if (newValue !== oldValue && props.originalValue) {
      showOriginalValueState.value = true
    }
  }
)
</script>

<style module lang="scss">
@import './FormInput.scss';
</style>
