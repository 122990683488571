// CSS Modules - component class name prefix: ProductAreaControlledCarousel__

<template>
  <div v-if="filteredProductLinks != null && filteredProductLinks.length > 0">
    <nav :class="$style.container">
      <ul :class="[$style.menu, 'columns']" @mouseleave="mouseLeave">
        <li
          v-for="(link, index) in filteredProductLinks"
          :key="link.id"
          :class="[
            $style.menuItem,
            'column',
            { [$style.activeItem]: lastClickedProductLinkIndex === index }
          ]"
          @mouseenter="mouseEnter(index)"
        >
          <button @click.prevent="setLastClicked(link, index)">
            {{ link.title }}
          </button>
        </li>
      </ul>
    </nav>

    <RotatingCarousel
      :key="'hrCarousel_' + currentProductLinkIndex"
      :uid="'hrCarousel_' + currentProductLinkIndex"
      :slides="currentSlides"
      :autoplay="currentAutoplay"
      :bg-img-url="currentBgImgUrl"
      :hide-pagination="currentHidePagination"
    />
  </div>
</template>

<script setup>
import RotatingCarousel from '../RotatingCarousel/RotatingCarousel.vue'
import { computed, ref } from 'vue'

const props = defineProps({
  productLinks: {
    type: Array,
    default: () => []
  }
})

const currentProductLinkIndex = ref(0)
const lastClickedProductLinkIndex = ref(0)

const setLastClicked = (link, index) => {
  if (filteredProductLinks.value[lastClickedProductLinkIndex.value].id === link.id) {
    if (link.url) {
      window.location.href = link.url
    }

    return
  }

  lastClickedProductLinkIndex.value = index
}

const mouseEnter = (index) => {
  currentProductLinkIndex.value = index
}

const mouseLeave = () => {
  currentProductLinkIndex.value = lastClickedProductLinkIndex.value
}

const filteredProductLinks = computed(() =>
  props.productLinks === null
    ? []
    : props.productLinks.filter((link) => link.slides != null && link.slides.length > 0)
)

const currentSlides = computed(() =>
  filteredProductLinks.value.length === 0
    ? []
    : filteredProductLinks.value[currentProductLinkIndex.value].slides
)

const currentAutoplay = computed(
  () => filteredProductLinks.value[currentProductLinkIndex.value].autoplay
)

const currentBgImgUrl = computed(
  () => filteredProductLinks.value[currentProductLinkIndex.value].bgImgUrl
)

const currentHidePagination = computed(() =>
  Boolean(filteredProductLinks.value[currentProductLinkIndex.value].hidePagination)
)
</script>

<style module lang="scss">
@import './ProductAreaControlledCarousel.scss';
</style>
