<template>
  <div :class="$style.container">
    <div v-if="!isMobile" :class="$style.tabs">
      <a
        v-for="(tab, index) in config.length"
        :key="`${index}-aKey`"
        :class="[
          $style.tab,
          { ContentTabs__active: activeTab === tab },
          { ContentTabs__disabled: config[index].disabled }
        ]"
        @click="(e) => updateTabs(e, tab)"
        v-html="config[index].title"
      />
      <div
        v-for="(tab, index) in config.length"
        :key="`${index}-bKey`"
        :class="[$style.content, { ContentTabs__activeContent: activeTab === tab }]"
      >
        <div v-if="contentLoaded === false" class="preloadFilter">
          <Loading />
        </div>
        <div v-if="activeTab === tab && config[index].sliders && config[index].sliders.length > 0">
          <SidebarSlider :slides="config[index].sliders" />
        </div>
        <div v-else-if="activeTab === tab && config[index].FAQListConfig">
          <div v-for="(child, i) in htmlChildren" :key="`${i}-childKey`">
            <template
              v-if="
                child.outerHTML.includes('FAQList__container') ||
                child.outerHTML.includes('faqList')
              "
            >
              <FAQList :config="config[index].FAQListConfig" />
            </template>
            <template v-else>
              <div v-html="child.outerHTML" />
            </template>
          </div>
        </div>
        <div v-else-if="activeTab === tab" v-html="content" />
      </div>
    </div>
    <div v-if="isMobile" :class="[$style.tabs, $style.mobile]">
      <template v-for="(tab, index) in config.length" :key="`${index}-cKey`">
        <a
          v-if="!config[index].disabled"
          :class="[$style.tab, { ContentTabs__active: activeTab === tab && mobileHidden !== tab }]"
          @click="(e) => updateTabs(e, tab)"
          v-html="config[index].title"
        />
        <div
          :class="[
            $style.content,
            { ContentTabs__activeContent: activeTab === tab && !isMobile },
            {
              ContentTabs__activeContent: activeTab === tab && isMobile && mobileHidden !== tab
            }
          ]"
        >
          <div v-if="contentLoaded === false" class="preloadFilter">
            <Loading />
          </div>
          <div
            v-if="activeTab === tab && config[index].sliders && config[index].sliders.length > 0"
          >
            <SidebarSlider :slides="config[index].sliders" />
          </div>
          <div v-else-if="activeTab === tab && config[index].FAQListConfig">
            <div v-for="(child, i) in htmlChildren" :key="`${i}-childKey`">
              <template
                v-if="
                  child.outerHTML.includes('FAQList__container') ||
                  child.outerHTML.includes('faqList')
                "
              >
                <FAQList :config="config[index].FAQListConfig" />
              </template>
              <template v-else>
                <div v-html="child.outerHTML" />
              </template>
            </div>
          </div>
          <div v-else-if="activeTab === tab" v-html="content" />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import SidebarSlider from '../SidebarSlider/SidebarSlider.vue'
import FAQList from '../FAQList/FAQList.vue'
import Loading from '../Loading/Loading.vue'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { checkIsMobile } from '../../utils/media'

const props = defineProps({
  config: {
    type: Array,
    default: () => []
  }
})

const isMobile = ref(false)
const activeTab = ref(null)
const mobileHidden = ref(null)
const content = ref(null)
const contentLoaded = ref(false)
const activeTabPreselected = ref(false)
const html = ref(null)

const htmlChildren = computed(() => {
  return html.value !== null ? html.value.children : []
})

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const getContentFromId = (id) => {
  html.value = document.getElementById(id)

  if (html.value) {
    content.value = html.value.innerHTML
    contentLoaded.value = true

    if (window.initGoogleMapsInContentTabs) {
      window.requestAnimationFrame(window.initGoogleMapsInContentTabs)
    }

    if (window.initFlipCardInContentTabs) {
      window.requestAnimationFrame(window.initFlipCardInContentTabs)
    }

    if (window.initRotatingCarouselInContentTabs) {
      window.requestAnimationFrame(window.initRotatingCarouselInContentTabs)
    }
  } else {
    content.value = null
    contentLoaded.value = false
  }
}

const getContentFromUrl = (url) => {
  axios
    .get(url)
    .then((response) => ((content.value = response.data), (contentLoaded.value = true)))
    .catch((error) => (console.log(error), (content.value = null), (contentLoaded.value = false)))
}

const updateTabs = (event, tab) => {
  const elm = event.currentTarget
  if (!elm.classList.contains('ContentTabs__disabled')) {
    contentLoaded.value = false
    if (props.config[tab - 1].url) {
      getContentFromUrl(props.config[tab - 1].url)
      content.value = ''
    } else if (props.config[tab - 1].sliders) {
      content.value = null
      contentLoaded.value = true
    } else if (props.config[tab - 1].id) {
      getContentFromId(props.config[tab - 1].id)
    } else {
      content.value = null
    }
    if (props.config[tab - 1].FAQListConfig) {
      contentLoaded.value = true
    }
    if (isMobile.value && activeTab.value === tab && activeTab.value !== mobileHidden.value) {
      mobileHidden.value = tab
    } else if (
      isMobile.value &&
      activeTab.value === tab &&
      activeTab.value === mobileHidden.value
    ) {
      mobileHidden.value = null
    } else if (isMobile.value && activeTab.value !== tab) {
      mobileHidden.value = null
      activeTab.value = tab
    } else {
      activeTab.value = tab
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  checkScreenSize()
  const queries = window.location.search.substring(1).split('&')
  let queryStringTabId = null
  if (queries) {
    for (const query of queries) {
      if (query.includes('tab')) {
        queryStringTabId = query.split('=')[1]
      }
    }
  }

  for (const [index, tab] of props.config.entries()) {
    if (
      (queryStringTabId && queryStringTabId == index + 1 && !tab.disabled) ||
      (tab.active && !tab.disabled)
    ) {
      activeTab.value = index + 1
      activeTabPreselected.value = true
      if (tab.url) {
        getContentFromUrl(tab.url)
      } else if (tab.sliders) {
        content.value = null
        contentLoaded.value = true
      } else if (tab.id) {
        getContentFromId(tab.id)
      }
    }
  }
  if (!activeTabPreselected.value) {
    activeTab.value = 1
    if (props.config[0].url) {
      getContentFromUrl(props.config[0].url)
    } else if (props.config[0].sliders) {
      content.value = null
      contentLoaded.value = true
    } else if (props.config[0].id) {
      getContentFromId(props.config[0].id)
    }
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style module lang="scss">
@import './ContentTabs.scss';
</style>
