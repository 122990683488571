<template>
  <span
    :class="`fflag ff-${size}${flagId ? ` fflag-${flagId.toUpperCase()}` : ''}`"
    :style="background ? background : ''"
    @click="handleClick"
  />
</template>

<script setup>
import { computed, ref } from 'vue'

const emit = defineEmits(['click'])

const props = defineProps({
  flag: {
    type: String
  },
  size: {
    type: String,
    default: 'xs'
  }
})

const handleClick = ($event) => {
  window.dispatchEvent(new CustomEvent('flag:click', { detail: { props, $event } }))

  emit('click')
}

const background = ref(undefined)

const setBackground = () => {
  background.value = `background: url(${props.flag}) no-repeat 50% 50% / cover`
}

const flagId = computed(() => {
  if (!props.flag) {
    return undefined
  }

  const fileName = props.flag.split('/').pop().split('.')[0]

  const langOnly = new RegExp(/^([a-z]{2})$/)
  const startsWithLang = new RegExp(/^([a-z]{2})(-|_)\w*$/)

  const lang =
    fileName.toLowerCase().match(langOnly) || fileName.toLowerCase().match(startsWithLang)

  if (lang) {
    return lang[1]
  }

  setBackground()
  return undefined
})
</script>
