<template>
  <div>
    <FormFilter
      :config="{ ...config, listCategory: getListCategory() }"
      :show-multi-selects="true"
      :allow-reset="true"
      @filtered="displayResults"
      @has-results="displayPagination"
    />
    <div class="columns is-multiline">
      <div v-for="(result, index) in displayedResults" :key="index" class="column is-full">
        <JobResultTeaser :result="result" />
      </div>
    </div>
    <Pagination
      v-show="config.loadInitialData || hasResults"
      :config="config.pagination"
      :results="results.length"
      @update="displayPaginatedResults"
    />
  </div>
</template>

<script setup>
import FormFilter from '../FormFilter/FormFilter.vue'
import JobResultTeaser from '../JobResultTeaser/JobResultTeaser.vue'
import Pagination from '../Pagination/Pagination.vue'
import { ListCategory } from '../../globals/constants/formFilter'
import { ref, computed } from 'vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const results = ref([])
const displayRange = ref([0, 11])
const displayedResults = ref([])
const hasResults = ref(false)

const displayPagination = computed((filterHasResults) => {
  return filterHasResults && hasResults.value === true
})

const displayResults = (newResults) => {
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < newResults.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }
}

const displayPaginatedResults = (newDisplayRange) => {
  displayRange.value = newDisplayRange
  displayResults(results.value)
}

const getListCategory = () => {
  return ListCategory.JOBS
}
</script>
