<template>
  <a
    :href="link.url"
    :target="link.newWindow ? '_blank' : '_self'"
    :title="link.title"
    @mouseenter="noHover = false"
    @mouseleave="noHover = true"
  >
    <i :class="[link.icon, { noHover: noHover }]" :style="{ background: link.color }" />
    <span v-if="showLabel" v-text="link.text" />
  </a>
</template>

<script setup>
import { ref } from 'vue'
defineProps({
  showLabel: {
    type: Boolean,
    default: false
  },
  link: {
    type: Object,
    default: () => {}
  }
})

const noHover = ref(true)
</script>
