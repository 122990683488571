<template>
  <nav :class="$style.container">
    <tippy
      v-tippy="{
        arrow: false,
        distance: '0',
        interactive: true,
        placement: 'top',
        theme: 'wika light word-cloud',
        trigger: 'mouseenter focus'
      }"
      :content-class="$style.popupContent"
    >
      <ul
        id="productAreaCloud"
        :class="[$style.menu, 'columns']"
        @hide="(currentLink = undefined), (isCloudLoading = true)"
      >
        <li
          v-for="link in productLinks"
          :key="link.id"
          :class="[$style.menuItem, 'column']"
          @mouseenter="(currentLink = link), (isCloudLoading = true)"
        >
          <a :href="link.url" @click.prevent="setLastClicked(link)">
            <span>{{ link.title }}</span>
          </a>
        </li>
      </ul>
      <template #content>
        <div v-if="currentLink">
          <h2>{{ currentLink.title }}</h2>
          <div class="columns is-variable is-8">
            <div class="column is-one-third">
              <p v-html="currentLink.text" />
            </div>
            <div :class="[$style.cloud, 'column is-two-thirds']">
              <div v-if="currentLink.words && currentLink.words.length && isCloudLoading">
                <Loading :size="'30'" />
              </div>
              <VueWordCloud
                :words="currentLink.words || [[]]"
                font-family="Helvetica, Arial"
                :animation-duration="0"
                :spacing="0.5"
                :font-size-ratio="3"
                @update:progress="handleCloudUpdateProgress"
              >
                <template #default="slotProps">
                  <a
                    :href="slotProps.word[2]"
                    :class="$style.cloudTag"
                    :target="getLinkTarget(slotProps.word)"
                  >
                    {{ slotProps.text }}
                  </a>
                </template>
              </VueWordCloud>
            </div>
          </div>
        </div>
      </template>
    </tippy>
  </nav>
</template>

<script setup>
import VueWordCloud from 'vuewordcloud'
import Loading from '../Loading/Loading.vue'
import { ref } from 'vue'

defineProps({
  productLinks: {
    type: Array,
    default: () => []
  }
})

const isTouch = 'ontouchstart' in document.documentElement
const isCloudLoading = ref(true)
const currentLink = ref(undefined)
const lastClickedId = ref(undefined)
const lastCloudEvent = ref(undefined)

const handleCloudUpdateProgress = (event) => {
  /* 
  The event's completedWords property is initially 0, but will be updated later
  So we keep the original object and check it later,
  when the second event fires, which will be null
   */
  if (event === null) {
    /* 
    if event is null, check the ref to the last event's object,
    which should be updated now
     */
    if (
      lastCloudEvent.value &&
      lastCloudEvent.value.completedWords === lastCloudEvent.value.totalWords
    ) {
      isCloudLoading.value = false
    }
  } else {
    // keep current event's object
    lastCloudEvent.value = event
  }
}

const setLastClicked = (link) => {
  if (!isTouch || lastClickedId.value === link.id) {
    window.location.href = link.url
    return
  }

  lastClickedId.value = link.id
}

const getLinkTarget = (propsArr) => {
  return propsArr[3] ? '_blank' : '_self'
}
</script>

<style module lang="scss">
@import './ProductArea.scss';
</style>
