import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

export default function init() {
  const element = document.getElementById('shopLogin')

  if (element) {
    const app = createApp(App, { ...element.dataset })

    app.use(VueTippy)
    app.mount('#shopLogin')
  }
}
