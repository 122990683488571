import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  const element = document.getElementById('contactList')

  if (element) {
    const app = createApp(App, {
      ...element.dataset
    })
    app.mount('#contactList')
  }
}
