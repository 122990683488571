import axios from 'axios'
import getQuoteIdFromUrl from './getQuoteIdFromUrl'

const updateQuantityToUrl = (url, requestData) => {
  return new Promise((resolve, reject) => {
    getQuoteIdFromUrl(window.getQuoteIdUrl)
      .then((quoteId) => {
        axios({
          url: url,
          method: 'post',
          data: { ...requestData, quoteid: quoteId }
        })
          .then((response) => {
            if (!response.data.success) {
              reject(`Response after call updateItemQuantityUrl (${url}): ${response.message}`)
            } else {
              resolve(response)
            }
          })
          .catch((error) => reject(error))
      })
      .catch((error) => reject(error))
  })
}

export default updateQuantityToUrl
