<template>
  <figure :class="[{ TextMediaElement__mediaRight: mediaRight }, $style.container]">
    <div :class="$style.mediaWrapper">
      <HeroSlider v-if="slides" :slides="slides" />
      <a v-if="video || youtube" :class="[$style.mediaLink, $style.videoLink]" @click="openModal">
        <i class="fab fa-youtube" />
        <img :src="image" :alt="title" />
      </a>
      <div v-else :class="[$style.mediaLink]">
        <img :src="image" :alt="title" />
      </div>
    </div>
    <figcaption>
      <h2>{{ title }}</h2>
      <div v-html="text" />
    </figcaption>
    <Modal
      v-if="video || youtube"
      :is-modal-open="isModalOpen"
      :is-video="true"
      :is-not-you-tube="!!video"
      @close-modal="closeModal"
    >
      <video v-if="video" ref="videoRef" controls>
        <source v-if="isMp4" :src="video" type="video/mp4" />
        <source v-else :src="video" type="video/webm" />
      </video>
      <iframe
        v-else-if="youtube"
        :title="`Youtube video: ${title}`"
        :src="youtube"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </Modal>
  </figure>
</template>

<script setup>
import HeroSlider from '../HeroSlider/HeroSlider.vue'
import Modal from '../Modal/Modal.vue'
import { ref } from 'vue'

defineProps({
  mediaRight: {
    type: Boolean,
    default: false
  },
  isMp4: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  text: {
    type: String,
    default: ''
  },
  slides: {
    type: Array,
    default: () => []
  },
  video: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  },
  youtube: {
    type: String,
    default: ''
  }
})

const isModalOpen = ref(false)
const videoRef = ref(null)

const openModal = () => {
  isModalOpen.value = true

  if (videoRef.value) {
    videoRef.value.load()
  }
}
const closeModal = () => {
  if (videoRef.value) {
    videoRef.value.pause()
  }

  isModalOpen.value = false
}
</script>

<style module lang="scss">
@import './TextMediaElement.scss';
</style>
