const groupCollectionBy = (collection, property) => {
  return collection.reduce((accumulator, currentObject) => {
    const key = currentObject[property]
    if (!accumulator[key]) {
      accumulator[key] = []
    }
    accumulator[key].push(currentObject)
    return accumulator
  }, {})
}

export { groupCollectionBy }
