import { createApp } from 'vue'
import { checkIsMobile } from '../../utils/media'

export default function init() {
  const element = document.getElementById('text-teaser')

  if (element) {
    const app = createApp({
      data: function () {
        return {
          isMobile: false,
          isInnerContainerSmaller: true,
          isContainerFullWidth: false,
          tout: null,
          refs: {
            container: null,
            text: null,
            overflow: null,
            textInner: null
          },
          initialTeaserTextHeight: 0
        }
      },
      mounted() {
        this.refs.container = document.getElementById('textTeaserContainer_')
        this.refs.text = document.getElementById('textTeaserText_')
        this.refs.overflow = document.getElementById('textTeaserOverflow_')
        this.refs.textInner = document.getElementById('textTeaserTextInner_')
        window.addEventListener('resize', this.resizeHandler, false)
        this.checkScreenSize()
        this.setOverflowElements()
        this.initialTeaserTextHeight = this.getOverflowHeight()
      },
      updated() {
        if (!this.tout) {
          this.resizeHandler()
        }
      },
      unmounted() {
        window.removeEventListener('resize', this.resizeHandler, false)
      },
      methods: {
        getOverflowHeight() {
          const lineHeight = 19
          const lines = parseInt(this.refs.text.offsetHeight / lineHeight)
          const height = lines * lineHeight
          return height
        },
        checkScreenSize() {
          this.isMobile = checkIsMobile()
        },
        resizeHandler() {
          clearTimeout(this.tout)
          this.initialTeaserTextHeight = 0

          this.tout = setTimeout(() => {
            this.checkScreenSize()
            this.setOverflowElements()
            if (this.isContainerFullWidth) {
              this.setContainerSize()
            }
            this.initialTeaserTextHeight = this.getOverflowHeight()
          }, 100)
        },
        setOverflowElements() {
          if (this.refs.text && this.refs.textInner) {
            this.isInnerContainerSmaller =
              this.refs.textInner.clientHeight < this.refs.text.clientHeight
          }
        },
        findColumnsElement() {
          let $containerParent = this.refs.container.parentElement
          for (let i = 0; i < 5; i++) {
            if ($containerParent.className.indexOf('columns') >= 0) {
              return $containerParent
            } else {
              $containerParent = $containerParent.parentElement
            }
          }
          return null
        },
        setContainerFullWidth() {
          this.isContainerFullWidth = !this.isContainerFullWidth
          this.setContainerSize()
        },
        setContainerSize() {
          const columnsContainer = this.findColumnsElement()
          if (columnsContainer && this.isContainerFullWidth) {
            if (!this.isMobile) {
              // take columnsContainer.clientWidth + 1 because on some devices columns clientWidth is not the full length
              this.refs.container.style.width = `${columnsContainer.offsetWidth + 1}px`
            }
            if (
              this.refs.text &&
              this.refs.textInner &&
              this.refs.textInner.clientHeight > this.refs.text.clientHeight
            ) {
              this.refs.text.style.maxHeight = 'none'
              if (!this.isMobile) {
                this.refs.container.style.height = `${
                  this.refs.textInner.clientHeight +
                  (this.refs.container.clientHeight - this.refs.text.clientHeight)
                }px`
              } else {
                this.refs.container.style.height = `${
                  this.refs.textInner.clientHeight +
                  (this.refs.container.clientHeight - this.refs.text.clientHeight + 30)
                }px`
              }
            }
          } else {
            this.refs.container.style.width = null
            this.refs.container.style.height = null
            this.refs.text.style.maxHeight = null
          }
        }
      }
    })

    app.mount('#text-teaser')
  }
}
