<template>
  <div
    :class="[
      $style.container,
      { FormSelect__blue: colour === 'blue' },
      { FormSelect__error: hasError },
      { FormSelect__active: isActive }
    ]"
    :aria-label="ariaLabel"
  >
    <label v-if="label" :class="$style.label">
      {{ label }}
      <span v-if="optionalLabel" :class="$style.optional"> ({{ optionalLabel }}) </span>
    </label>
    <template v-if="isMultiSelect">
      <Select
        v-if="filteredOptions"
        :colour="colour"
        :select-options="filteredOptions"
        :select-multi-value="
          Array.isArray(initialOption) || initialOption !== placeholder ? initialOption : undefined
        "
        :is-multi="true"
        :placeholder="placeholder"
        :active-select="activeSelect"
        :category="category"
        :reset-multi-selects="resetMultiSelectsRef"
        :is-invalid="false"
        :disabled="props.disabled"
        @multi-changed="handleOptionChange"
      />
    </template>
    <template v-else>
      <Select
        v-if="filteredOptions"
        :colour="colour"
        :select-options="filteredOptions"
        :select-value="optionLabel || externalValue || initialOption"
        :placeholder="placeholder"
        :active-select="activeSelect"
        :category="category"
        :searchable="hasFilter"
        :reset-multi-selects="resetMultiSelectsRef"
        :allow-empty="allowEmpty"
        :disabled="props.disabled"
        @on-change="handleOptionChange"
        @is-empty="handleEmpty"
      />
    </template>
    <div v-if="hasError" :class="$style.errorMessage">
      {{ props.errorMessage }}
    </div>
  </div>
</template>

<script setup>
import Select from '../Select/Select.vue'
import { ref, computed, onMounted, watch } from 'vue'

const emit = defineEmits(['update', 'externalReset'])

const props = defineProps({
  activeSelect: {
    type: String,
    default: undefined
  },
  ariaLabel: {
    type: String,
    default: undefined
  },
  category: {
    type: String,
    default: undefined
  },
  colour: {
    type: String,
    default: 'white'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: ''
  },
  hasFilter: {
    type: Boolean,
    default: false
  },
  initialOption: {
    type: [String, Number, Object, Array],
    default: ''
  },
  isMultiSelect: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
    default: ''
  },
  optionalLabel: String,
  options: {
    type: Array,
    default: () => []
  },
  placeholder: {
    type: String,
    default: undefined
  },
  value: {
    type: [String, Number, Object, Array]
  },
  resetMultiSelects: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  },
  externalValue: {
    type: String,
    defaut: undefined
  },
  isEmpty: {
    type: Boolean,
    default: false
  }
})

const handleUpdate = (options) => {
  window.dispatchEvent(
    new CustomEvent('formSelect:update', {
      detail: { props, value: options }
    })
  )

  return emit('update', options)
}

const isActive = ref(false)
const filter = ref('')
const selectedIndex = ref(-1)
const hasError = ref(props.error)

const resetMultiSelectsRef = ref(false)

const optionLabel = computed(() => {
  return props.value?.label ? [...props.value.label].join('') : props.value
})

const allowEmpty = computed(() => {
  return !props.required
})

const filteredOptions = computed(() => {
  if (props.options.length === 0) {
    return
  }

  const filteredOptionsFiltered = props.options.filter((option) => {
    return (
      filter.value === '' ||
      (option.label
        ? option.label.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1
        : option.toLowerCase().indexOf(filter.value.toLowerCase()) !== -1)
    )
  })

  filteredOptionsFiltered.map((option) => {
    const optionText = option.label ? option.label : option
    const optionLength = optionText.length
    const filterLength = filter.value.length
    const startPosition = optionText.toLowerCase().indexOf(filter.value.toLowerCase())
    if (filter.value === '') {
      if (option.label) {
        return {
          value: option.value,
          label: [option.label, '', '']
        }
      }

      return [option, '', '']
    } else if (startPosition === 0 && filterLength < optionLength) {
      if (option.label) {
        return {
          value: option.value,
          label: [option.label, '', '']
        }
      }
      return [
        '',
        option.substr(startPosition, filterLength),
        option.substring(filterLength, optionLength)
      ]
    } else if (startPosition === 0 && filterLength === optionLength) {
      if (option.label) {
        return {
          value: option.value,
          label: ['', option.label, '']
        }
      }
      return ['', option, '']
    } else if (startPosition > 0 && filterLength + startPosition < optionLength) {
      if (option.label) {
        return {
          value: option.value,
          label: [
            option.label.substring(0, startPosition),
            option.label.substr(startPosition, filterLength),
            option.label.substring(filterLength + startPosition, optionLength)
          ]
        }
      }
      return [
        option.substring(0, startPosition),
        option.substr(startPosition, filterLength),
        option.substring(filterLength + startPosition, optionLength)
      ]
    } else {
      if (option.label) {
        return {
          value: option.value,
          label: [
            option.label.substring(0, startPosition),
            option.label.substring(startPosition, optionLength),
            ''
          ]
        }
      }
      return [option.substring(0, startPosition), option.substring(startPosition, optionLength), '']
    }
  })

  if (props.isMultiSelect) {
    let multiDataOptions = []
    for (let option of filteredOptionsFiltered) {
      multiDataOptions.push(option)
    }
    return multiDataOptions
  } else {
    return filteredOptionsFiltered
      .map((options) => {
        return options.label ? options.label : options
      })
      .flat()
  }
})

const handleEmpty = (value) => {
  if (props.required) {
    return (hasError.value = value ? true : false)
  }
}

const handleOptionChange = (option) => {
  const optionEntries = Object.entries(props.options)
  isActive.value = !isActive.value
  if (!option) {
    if (!props.isMultiSelect) {
      if (props.initialOption) {
        return initInitialOption()
      }
    }
  }

  if (props.isMultiSelect) {
    if (option.length > 0) {
      if (typeof option === 'string') {
        return handleUpdate([option])
      }
      handleUpdate([...option])
    } else {
      handleUpdate([option.join('')])
    }
    emit('externalReset', false)
  } else {
    if (typeof option === 'string') {
      const prepOption = new Array(option)
      if (option.length > 0) {
        handleUpdate(prepOption)
      }
    } else if (typeof option === 'object') {
      const prepOption = option.label ? option.label : [...option][0]
      if (option.length > 0) {
        handleUpdate(prepOption)
      }
    } else if (Array.isArray(option)) {
      if (option.length > 0) {
        handleUpdate([option[0]])
      }
    }
    emit('externalReset', false)
  }

  if (props.isMultiSelect) {
    optionEntries.filter((propOption, index) => {
      if (option.value ? option.value === propOption.value : option.join('') === propOption) {
        return (selectedIndex.value = index)
      }
    })
  } else {
    optionEntries.filter((propOption, index) => {
      if (option ? option === propOption.value : propOption) {
        return (selectedIndex.value = index)
      }
    })
  }

  isActive.value = false
  filter.value = ''
}

const initInitialOption = (optionRaw) => {
  if (typeof optionRaw === 'string') {
    return handleUpdate([optionRaw])
  }

  if (Array.isArray(optionRaw)) {
    if (!props.isMultiSelect) {
      return handleUpdate(optionRaw)
    } else {
      let preselectedOptions = []
      preselectedOptions = [...optionRaw]
      for (let option of optionRaw) {
        if (typeof option === 'string') {
          if (!preselectedOptions.includes(option)) {
            preselectedOptions.push(option)
          }
        } else {
          for (let initOption of option) {
            if (initOption === option) {
              return preselectedOptions.indexOf(option) === -1 && preselectedOptions.push(option)
            }
          }
        }
      }
      return preselectedOptions.length > 0 && handleUpdate(preselectedOptions)
    }
  }
}

watch(
  () => props.resetMultiSelects,
  (newValue) => {
    resetMultiSelectsRef.value = newValue
  }
)

onMounted(() => {
  if (props.initialOption) {
    initInitialOption(props.initialOption)
  }
})
</script>

<style module lang="scss">
@import './FormSelect.scss';
</style>
