<template>
  <div>
    <PanelGroup v-for="group in groups" :key="group.id" :heading="group.label">
      <PanelGroupCheckbox
        v-for="item in group.items"
        :key="item.id"
        :active="item.active"
        @change="(active) => emit('change', group.id, item.id, active)"
      >
        <span v-html="item.label" />
      </PanelGroupCheckbox>
    </PanelGroup>
  </div>
</template>

<script setup>
import PanelGroup from '../PanelGroup/PanelGroup.vue'
import PanelGroupCheckbox from '../PanelGroupCheckbox/PanelGroupCheckbox.vue'

const emit = defineEmits(['change'])

defineProps({
  groups: {
    type: Array,
    default: () => []
  }
})
</script>
