<template>
  <a
    :href="slide.product.url"
    :target="slide.newWindow ? '_blank' : '_self'"
    @click="slide.isShopLink && wtTrack({ cat: 'TOONLINESHOP' })"
  >
    <div
      v-if="heightFirst"
      :class="$style.bgImage"
      :style="{ backgroundImage: `url(${slide.image})` }"
    >
      <img :src="slide.image" :alt="slide.title || ''" />
    </div>
    <img
      v-else
      :src="slide.image"
      :alt="slide.title || ''"
      :width="slide.imageWidth"
      :height="slide.imageHeight"
    />
    <div
      :class="[
        { HeroSlider__caption__top: slide.captionTop },
        { HeroSlider__caption__left: slide.captionLeft },
        $style.caption
      ]"
    >
      <figcaption :class="$style.figCaption">
        <h1 v-if="index === 0" v-html="slide.title" />
        <h3 v-else v-html="slide.title" />
        <div :class="$style.captionText">
          <ul>
            <li
              v-for="keyfact in slide.product.keyfacts"
              :key="keyfact"
              class="bullet"
              v-html="keyfact"
            />
          </ul>
        </div>
      </figcaption>
      <div :class="$style.product">
        <span
          v-if="slide.product.pricePreFix"
          :class="$style.pricePreFix"
          v-text="slide.product.pricePreFix"
        />
        <span v-if="slide.product.price" :class="$style.price">
          <IndividualPrice
            :value="Number(slide.product.price)"
            :btype-list="slide.product.btypeList"
            :product-type="slide.product.productType"
          />
        </span>
        <span
          v-if="slide.product.pricePostFix"
          :class="$style.pricePostFix"
          v-text="slide.product.pricePostFix"
        />
      </div>
    </div>
    <div
      :class="[
        'Button__blue',
        'Button__button',
        { HeroSlider__toProduct__left: !slide.captionLeft },
        $style.toProduct
      ]"
    >
      <div class="buttonLink">{{ slide.product.linkText }}</div>
    </div>
  </a>
</template>

<script setup>
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const wtTrack = window.wtTrack

defineProps({
  slide: {
    type: Object,
    default: () => {}
  },
  heightFirst: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 0
  }
})
</script>

<style module lang="scss">
@import './HeroSlider.scss';
</style>
