<template>
  <div :class="isFlyout === true ? [$style.isFlyout, { isActive: isActive }] : $style.noFlyout">
    <div
      v-if="isFlyout"
      :class="$style.button"
      @mouseenter="isActive = true"
      @mouseleave="isActive = false"
      @click="isActive = !isActive"
    >
      <i :class="socialSharingIcon" />
    </div>
    <ul
      :class="[$style.container, isFlyout ? '' : 'column']"
      @mouseenter="isActive = true"
      @mouseleave="isActive = false"
    >
      <li
        v-for="(link, index) in socialSharingLinks"
        :key="index"
        :class="link.showDelimiter === true ? $style.prevDelimiter : ''"
      >
        <SocialSharingLink :link="link" :show-label="isFlyout" />
      </li>
    </ul>
  </div>
</template>

<script setup>
import SocialSharingLink from './SocialSharingLink.vue'
import { ref } from 'vue'

defineProps({
  isFlyout: {
    type: Boolean,
    default: false
  },
  socialSharingIcon: {
    type: String,
    default: 'fas fa-share-alt'
  },
  socialSharingLinks: {
    type: Array,
    default: () => []
  }
})

const isActive = ref(false)
</script>

<style module lang="scss">
@import './SocialSharing.scss';
</style>
