<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <BreadCrumbs :crumbs="crumbs" />
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <div class="Button__button Button__blue">
            <a href="#" target="_self">Zurück zur Übersicht</a>
          </div>
        </div>
        <SocialSharing :social-sharing-links="socialSharingLinks" />
      </div>
      <div class="columns is-gapless">
        <div class="column is-three-quarters">
          <img
            src="https://picsum.photos/840/257"
            style="display: block; object-fit: cover; height: 100%"
          />
        </div>
        <div class="column is-one-quarter">
          <BlueTextTeaser
            icon="fas fa-user-tie"
            title="Ihr Job – ein besonderer Teil Ihres Lebens!"
            copy="Arbeiten bei WIKA bedeutet neue Herausforderungen meistern und sich stets weiterzuentwickeln. Hierfür bieten wir Ihnen internationale Standorte und ein breites Produktportfolio sowie spannende Perspektiven."
            link-url="#"
            :new-window="true"
          />
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <div class="columns">
        <div class="column is-three-quarters SubPage__contentArea CareerDetail__container">
          <p>In unserem Bereich Industrial Instrumentation, Entwicklung bieten wir ab 01.01.2020</p>
          <h1>Bachelor- oder Masterarbeiten sowie Praxissemester</h1>
          <h2>Ihre Herausforderung</h2>
          <ul>
            <li>Unterstützung bei der Entwicklung und Analyse neuartiger Dünnschichtprozesse</li>
            <li>
              Neu- und Weiterentwicklung bestehender Fertigungsprozesse aus dem Bereich der
              Dünnschichtabscheidung und Strukturierung
            </li>
            <li>Selbstständiges Arbeiten in einem innovativen Umfeld</li>
          </ul>
          <h2>Ihr Profil</h2>
          <ul>
            <li>
              Technisches Studium in den Bereichen Physik, Chemie, Materialwissenschaften,
              Mechatronik, Elektrotechnik oder vergleichbar
            </li>
            <li>Kenntnisse&nbsp;in den Bereichen CVD, PE-CVD, ICP-CVD von Vorteil</li>
            <li>
              Erfahrung in PVD, reaktives und nicht reaktives Sputtern sowie Lithographie
              wünschenswert
            </li>
            <li>Gute Englischkenntnisse</li>
            <li>Strukturierte, teamorientierte und sorgfältige Arbeitsweise</li>
            <li>Selbstständiges Arbeiten</li>
            <li>
              Für die Bachelor- / Masterarbeit oder das Praxissemester steht Ihnen ein Zeitraum von
              min.&nbsp;6 Monaten zur Verfügung
            </li>
          </ul>
          <h2>Ihre Vorteile</h2>
          <p>
            Wir bieten Ihnen einen umfassenden Einblick in&nbsp;den Bereich der elektronischen
            Druckmesstechnik eines weltweit aufgestellten Industrieunternehmens. Während Ihrer
            Praktikumsphase werden Sie vom Fachbereich intensiv betreut und erhalten eine adäquate
            Vergütung.
          </p>
          <hr />
          <h2>Bitte bewerben Sie sich online auf www.wika.de/karriere</h2>
          <div class="Button__button Button__blue Button__apply">
            <a href="#" target="_self">Jetzt bewerben</a>
          </div>
        </div>
        <div class="column is-three-quarters SubPage__contentArea CareerIFrame__container inactive">
          <CareerIFrame
            :config="{
              src: 'https://wika-portal.rexx-recruitment.com/stellenangebot-formular.html?yid=1052#http://de-de.wika.de:5678/publish/joboffergeneric.aspx?AxID=2462||http://de-de.wika.de:5678/publish/joboffergeneric.aspx?AxID=2462'
            }"
          />
        </div>
        <div class="column is-one-quarter SubPage__sideBar">
          <div class="column">
            <ContentBox
              title="Unser Unternehmen"
              text="WIKA Alexander Wiegand SE & Co. KG ist anerkannter Partner und kompetenter Spezialist für Druck-, Temperatur-, Füllstands-, Kraft- und Durchflussmesstechnik.<br><br>Als international aufgestelltes Familienunternehmen mit einem Jahresumsatz von 1 Milliarde Euro vertreten wir unsere Spitzenposition im Weltmarkt. Hinter unserem Erfolg stehen heute weltweit mehr als 10.000 hoch qualifizierte und motivierte Mitarbeiter, die die Basis für unseren Erfolg bilden."
            />
          </div>
          <div class="column">
            <DownloadBox :download-options="downloadOptions" :labels="labels" />
          </div>
          <div class="column">
            <ContactBox
              image="https://picsum.photos/110/180"
              head="Ihr Kontakt"
              description="Leitung Ausbildung & Studium"
              name="Yvonne Busch"
              phone="+49 1234567890"
              vcard="#"
              additional-text="Sie möchten sich bewerben, oder haben noch Fragen zum Stellenangebot?"
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
  </div>
</template>

<script setup>
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import DownloadBox from '../DownloadBox/DownloadBox.vue'
import Footer from '../Footer/Footer.vue'
import PageHeader from '../PageHeader/PageHeader.vue'
import ContactBox from '../ContactBox/ContactBox.vue'
import ContentBox from '../ContentBox/ContentBox.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import SocialSharing from '../SocialSharing/SocialSharing.vue'
import BlueTextTeaser from '../BlueTextTeaser/BlueTextTeaser.vue'
import CareerIFrame from '../CareerIFrame/CareerIFrame.vue'

const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  },
  button: {
    text: 'Zustimmen',
    colour: 'blue'
  }
}

const crumbs = [
  {
    title: 'Startseite',
    url: '#'
  },
  {
    title: 'Karriere',
    url: '#'
  },
  {
    title: 'Arbeitgeber',
    url: '#'
  },
  {
    title: 'Stellenmarkt',
    url: '#'
  }
]

const labels = {
  pdf: 'PDF',
  ebook: 'E-Book',
  wishlist: 'Merkzettel'
}

const downloadOptions = [
  {
    title: 'Download',
    noWishList: true,
    downloadList: [
      {
        title: 'Stellenanzeige',
        pdf: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
      }
    ]
  }
]
const socialSharingLinks = [
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-facebook-f',
    color: '#3c5598'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-twitter',
    color: '#1da1f2'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-xing',
    color: '#007e7e'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-linkedin-in',
    color: '#0072b1'
  },
  {
    url: 'mailto:?subject=Ihr angeforderter Link von der WIKA-Internetseite&body=Guten Tag,%0D%0A%0D%0ASie haben auf unserer Internetseite einen Link angefordert:%0D%0A%0D%0Ahttps://de-de.wika.de/products_de_de.WIKA%0D%0A%0D%0AVielen Dank für Ihr Interesse.%0D%0AIhr WIKA-Team',
    title: 'Seite zusenden',
    newWindow: 'true',
    icon: 'fas fa-envelope',
    color: '#3480cf'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-instagram',
    color: '#DD2A7B'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-pinterest',
    color: '#DD2A7B'
  }
]
</script>
