<template>
  <div :class="[$style.container, { WishList__open: wishListOpen }]">
    <div :class="$style.wishList">
      <div :class="$style.tab" @click="handleTabClick">
        <div :class="$style.text">
          {{ config.buttonText }}
        </div>
        <i class="fas fa-list" />
      </div>
      <div id="wishListBadge" :class="$style.badge">
        {{ counter }}
      </div>
      <div :class="$style.title">{{ config.title }} - {{ config.counterText }} ({{ counter }})</div>
      <div :class="$style.list">
        <p v-if="counter === 0" :class="$style.emptyText">
          {{ config.emptyText }}
        </p>
        <template v-if="wishList">
          <template v-for="(item, index) in wishList">
            <WishListItem
              v-if="checkHasLangAndFlag(getCountries(item))"
              :key="`key_${index}_${JSON.stringify(item)}`"
              :item="item"
              :index="index"
              :countries="getCountries(item)"
              :product-type-label="item.types.length > 1 ? config.types : config.type"
              @remove-item="removeItem"
            />
            <a
              v-if="!checkHasLangAndFlag(getCountries(item))"
              :key="`key_${index}_${JSON.stringify(item)}`"
              :class="$style.downLoadWrapper"
              :href="item.downloads && item.downloads[0].url"
              target="_blank"
              download="download"
            >
              <WishListItem
                :item="item"
                :index="index"
                :countries="getCountries(item)"
                :product-type-label="item.types.length > 1 ? config.types : config.type"
                @remove-item="removeItem"
              />
            </a>
          </template>
        </template>
      </div>
      <form :class="$style.form">
        <template v-if="!formSubmitted">
          <FormInput
            v-model="email"
            :label="config.formText"
            :error-message="errorMessage"
            :error="formError"
            :placeholder="config.emailPlaceholder"
            @input="formError = false"
          />
          <div :class="$style.label">
            <Button
              colour="blue"
              type="submit"
              :is-form="true"
              aria-label="submit e-mail"
              @close="handleSubmit"
            />
            <i class="fas fa-caret-right" />
          </div>
        </template>
        <template v-else>
          <div>{{ config.successMessage }}</div>
        </template>
      </form>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import * as Storage from '../../shared/Storage'
import FormInput from '../FormInput/FormInput.vue'
import Button from '../Button/Button.vue'
import WishListItem from './WishListItem.vue'
import { ref, onMounted, onUnmounted, watch } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const wishList = ref(null)
const counter = ref(0)
const customEvent = ref(null)
const wishListOpen = ref(false)
const badge = ref(null)
const email = ref(null)
const formError = ref(false)
const formSubmitted = ref(false)
const errorMessage = ref('')
const isEmail = ref()

const formData = ref({
  ftlUrl: '',
  email: '',
  userLang: ''
})

const wtTrack = window.wtTrack

const validateEmail = (emailRaw) => {
  isEmail.value = new RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(emailRaw)
}

const checkLocalStorage = () => {
  wishList.value = Storage.getLocalStorage('wishList', true)
  if (wishList.value) {
    counter.value = wishList.value.length
  } else {
    counter.value = 0
  }
}

const checkHasLangAndFlag = (countries) => {
  const urlsWithLanguage = countries.filter((country) => !!country.language && !!country.flag) || []
  return urlsWithLanguage.length
}

const showBadge = () => {
  if (counter.value !== 0) {
    badge.value.style.transition = 'opacity 200ms ease 300ms'
    badge.value.style.opacity = 1
  }
}

const getCountries = (docItem) => {
  const countries = []

  if (docItem.downloads) {
    for (let i = 0; i < docItem.downloads.length; i++) {
      if (docItem.downloads[i].languageCodes[0] || docItem.downloads[i].flag) {
        countries.push({
          flag: docItem.downloads[i].flag,
          language: docItem.downloads[i].languageCodes[0] || null,
          url: docItem.downloads[i].url
        })
      }
    }
  }

  return countries
}

const hideBadge = () => {
  badge.value.style.transition = 'none'
  badge.value.style.opacity = 0
}

const updateAllBrowserTabs = () => checkLocalStorage()

const removeItem = (index) => {
  if (wishList.value.length === 1) {
    removeAllItems()
    return
  }
  checkLocalStorage()
  wishList.value.splice(index, 1)
  counter.value = wishList.value.length
  Storage.setLocalStorage('wishList', wishList.value, true)
  window.dispatchEvent(customEvent.value)
}

const removeAllItems = () => {
  checkLocalStorage()
  wishList.value = []
  counter.value = 0
  Storage.setLocalStorage('wishList', wishList.value, true)
  window.dispatchEvent(customEvent.value)
}

const handleSubmit = () => {
  if (isEmail.value && counter.value !== 0) {
    wtTrack({ cat: 'WISHLIST' })
    formError.value = false
    formData.value.ftlUrl = props.config.ftlUrl
    formData.value.email = email.value
    formData.value.wishListItems = wishList.value.map((listItem) => {
      const allTypes = listItem.types && !!listItem.types.length ? listItem.types.join(', ') : ''
      let allTypesWithLabel = allTypes
        ? `${listItem.types.length > 1 ? props.config.types : props.config.type} ${allTypes}`
        : ''
      return {
        section: listItem.section,
        types: allTypesWithLabel,
        title: listItem.title,
        downloads: listItem.downloads.map((downloadItem) => {
          if (downloadItem.url.indexOf('://') === -1) {
            downloadItem.url =
              window.top.location.protocol + '://' + window.top.location.hostname + downloadItem.url
          }
          return {
            url: downloadItem.url,
            languageCodes: downloadItem.languageCodes
          }
        })
      }
    })
    formData.value.userLang = window.currentLanguage || 'en'
    axios({
      url: props.config.serviceUrl,
      method: 'post',
      data: formData.value,
      responseType: 'json'
    })
      .then((response) => {
        if (!response.data.success) {
          console.error(
            `Response after submit (${props.config.serviceUrl}): ${response.data.message} - ${response.data.errormessage}`
          )
          errorMessage.value = props.config.technicalErrorMessage
          formError.value = true
        } else {
          removeAllItems()
          formSubmitted.value = true
          email.value = null
        }
      })
      .catch((error) => {
        errorMessage.value = props.config.technicalErrorMessage
        formError.value = true
        console.error(`Response after submit (${props.config.serviceUrl}): ${error}`)
      })
  } else if (isEmail.value && counter.value === 0) {
    errorMessage.value = props.config.formErrorMessage
    formError.value = true
  } else if (!isEmail.value) {
    errorMessage.value = props.config.emailErrorMessage
    formError.value = true
  }
}

const handleTabClick = () => {
  if (wishListOpen.value) {
    wishListOpen.value = false
    checkLocalStorage()
    showBadge()
  } else {
    formSubmitted.value = false
    checkLocalStorage()
    wishListOpen.value = true
    hideBadge()
  }
}

watch(email, (newValue, oldValue) => {
  if (newValue && newValue !== oldValue) {
    validateEmail(newValue)
  }
})

onMounted(() => {
  window.addEventListener('storage', updateAllBrowserTabs)
  window.addEventListener('addToWishList', checkLocalStorage)
  badge.value = document.getElementById('wishListBadge')
  customEvent.value = new CustomEvent('removeFromWishList')
  checkLocalStorage()
  showBadge()
})

onUnmounted(() => {
  window.removeEventListener('storage', updateAllBrowserTabs)
  window.removeEventListener('addToWishList', checkLocalStorage)
})
</script>

<style module lang="scss">
@import './WishList.scss';
</style>
