<template>
  <form :class="$style.container">
    <p :class="$style.text">
      {{ config.text }}
    </p>
    <div :class="[$style.checkboxes, 'columns is-mobile is-multiline']">
      <div
        v-for="(checkbox, index) in config.newsletters"
        :key="`checkbox-${index}`"
        class="column is-half"
      >
        <input
          :id="`checkbox-${index}`"
          v-model="newsletterIds"
          type="checkbox"
          :name="`checkbox-${index}`"
          :value="checkbox.newsletterId"
        />
        <label :for="`checkbox-${index}`">{{ checkbox.label }}</label>
      </div>
    </div>
    <div v-if="checkboxError" :class="$style.serverError">
      {{ config.checkboxErrorMessage }}
    </div>
    <div class="columns">
      <div class="column is-half-tablet">
        <FormInput
          v-model.trim="v$.formData.email.$model"
          :label="config.labels.email.label"
          :bold-label="true"
          :error-message="config.labels.email.errorMessage"
          :error="v$.formData.email.$errors.length && v$.$dirty && isSubmitted"
          :original-value="
            previousData.email && previousData.email !== '' ? previousData.email : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <p :class="$style.title">
      {{ config.optionalSectionTitle }}
    </p>
    <p :class="$style.text">
      {{ config.optionalSectionText }}
    </p>
    <div class="columns">
      <div class="column">
        <FormSelect
          v-model="formData.anrede"
          :label="config.labels.title.label"
          :optional-label="config.labels.title.optionLabel"
          :error="false"
          :options="config.titleSelectOptions"
          :initial-option="config.titleInitialSelectOption"
        />
      </div>
      <div class="column">
        <FormSelect
          v-model="formData.land"
          :label="config.labels.country.label"
          :optional-label="config.labels.country.optionLabel"
          :error="false"
          :options="config.selectOptions"
          :initial-option="config.initialSelectOption"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <FormInput
          v-model="formData.vorname"
          :label="config.labels.firstName.label"
          :bold-label="true"
          :optional-label="config.labels.firstName.optionLabel"
          :error="false"
          :original-value="
            previousData.firstName && previousData.firstName !== '' ? previousData.firstName : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
      <div class="column">
        <FormInput
          v-model="formData.name"
          :label="config.labels.lastName.label"
          :bold-label="true"
          :optional-label="config.labels.lastName.optionLabel"
          :error="false"
          :original-value="
            previousData.lastName && previousData.lastName !== '' ? previousData.lastName : null
          "
          :form-submit-switch="formSubmitSwitch"
        />
      </div>
    </div>
    <div :class="['columns', $style.dataSecurityColumn]">
      <div :class="['column', $style.dataSecurityChecked]">
        <input
          :id="`checkbox-dataSecurity`"
          v-model.trim="formData.dataSecurity"
          type="checkbox"
          :name="`checkbox-dataSecurity`"
          :class="v$.formData.dataSecurity.$errors.length && v$.$dirty && isSubmitted && 'error'"
        />
        <label :for="`checkbox-dataSecurity`" v-html="config.dataSecurityText" />
        <div
          v-if="v$.formData.dataSecurity.$errors.length && v$.$dirty && isSubmitted"
          :class="$style.dataSecurityError"
          v-html="config.dataSecurityErrorMessage"
        />
      </div>
    </div>
    <div v-if="serverError" :class="$style.serverError">
      {{ config.serverErrorMessage }}
    </div>
    <div v-if="serverSuccess" :class="$style.serverSuccess">
      <p>{{ config.subscribeSuccess }}</p>
      <p>{{ config.confirmationHint }}</p>
      <p>{{ config.spamFilterHint }}</p>
    </div>
    <div class="columns">
      <div class="column has-text-right-tablet">
        <Button
          colour="blue"
          :text="config.buttonText"
          type="submit"
          :is-form="true"
          @close="handleSubmit"
        />
      </div>
    </div>
    <ReCaptcha :execute-switch="executeSwitch" :submit="submit" />
  </form>
</template>

<script setup>
import { required, email as emailRule } from '@vuelidate/validators'
import ReCaptcha from '../ReCaptcha/ReCaptcha.vue'
import FormInput from '../FormInput/FormInput.vue'
import FormSelect from '../FormSelect/FormSelect.vue'
import Button from '../Button/Button.vue'
import axios from 'axios'
import { useVuelidate } from '@vuelidate/core'
import { onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

// form fields
const formData = ref({
  newsletter: '',
  vorname: '',
  name: '',
  anrede: '',
  land: '',
  email: 'test',
  domain: '',
  pageUrl: '',
  token: '',
  dataSecurity: false
})

// regular refs
const newsletterIds = ref([])
const previousData = ref({ ...formData.value })
const isSubmitted = ref(false)
const formSubmitSwitch = ref(false)
const executeSwitch = ref(false)
const serverError = ref(false)
const serverSuccess = ref(false)
const checkboxError = ref(false)

const shouldBeTrue = (val) => {
  return val === true
}

const rules = {
  formData: {
    email: {
      required,
      emailRule
    },
    dataSecurity: {
      shouldBeTrue
    }
  }
}
const v$ = useVuelidate(rules, { formData })

onMounted(() => {
  if (props.config.initialSelectOption) {
    formData.value.land = props.config.initialSelectOption
  }
  if (props.config.titleInitialSelectOption) {
    formData.value.anrede = props.config.titleInitialSelectOption
  }
  const queries = window.location.search.substring(1).split('&')
  if (queries) {
    for (const query of queries) {
      if (query.includes('email')) {
        formData.value.email = query.split('=')[1]
      }
    }
  }
})

const handleSubmit = () => {
  serverError.value = false
  serverSuccess.value = false
  checkboxError.value = false
  v$.value.formData.email.$touch()
  v$.value.formData.dataSecurity.$touch()
  isSubmitted.value = true
  formSubmitSwitch.value = !formSubmitSwitch.value
  if (!v$.$invalid) {
    if (newsletterIds.value.length !== 0) {
      executeSwitch.value = !executeSwitch.value
    } else {
      checkboxError.value = true
    }
  } else {
    previousData.value = { ...formData.value }
  }
}

const submit = (newToken) => {
  formData.value.token = newToken
  formData.value.domain = window.location.origin
  formData.value.pageUrl = window.location.pathname
  let combinedRequests = []
  for (const id of newsletterIds.value) {
    formData.value.newsletter = id
    combinedRequests.push(
      axios({
        url: window.newsletterSubscribeUrl,
        method: 'post',
        data: { ...formData.value },
        responseType: 'json'
      })
    )
  }
  axios
    .all(combinedRequests)
    .then(
      axios.spread((...responses) => {
        if (responses.every((response) => response.status === 200)) {
          serverSuccess.value = true
        } else {
          serverSuccess.value = false
          serverError.value = true
        }
      })
    )
    .catch((errors) => {
      serverSuccess.value = false
      serverError.value = true
      console.error(errors)
    })
}
</script>

<style module lang="scss">
@import './NewsletterForm.scss';
</style>
