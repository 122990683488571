<template>
  <div :class="$style.container">
    <div :class="[{ DownloadListTeaser__withImage: result.image }, $style.content]">
      <div
        v-if="result.subHeading"
        ref="expandableSubHeading"
        :class="[$style.subHeading, $style.expandable]"
      >
        <template
          v-if="
            Array.isArray(result.subHeading) ||
            Array.isArray(result.subHeading[Object.keys(result.subHeading).shift()])
          "
        >
          <span v-for="(elm, key) in result.subHeading" :key="key">
            {{ key }}
            <template v-if="Array.isArray(elm)">
              <template v-for="(type, index) in elm">
                <template v-if="index !== 0">, </template>
                {{ type }}
              </template>
            </template>
            <template v-else>{{ elm }}</template>
          </span>
        </template>
        <template v-else>
          <span>{{ result.subHeading }}</span>
        </template>
        <a
          v-if="hasMoreSubHeading"
          :class="[{ DownloadListTeaser__open: expandedSubHeading }, $style.more]"
          @click="toggleExpandableSubHeading"
        />
      </div>
      <h5 v-if="result.title" :class="$style.title" v-html="result.title" />
      <div v-if="result.description" :class="$style.description" v-html="result.description" />
      <dl class="columns is-multiline is-mobile is-gapless">
        <template v-for="(elm, key) in result.text" :key="key">
          <template v-if="Array.isArray(elm)">
            <dt class="column is-5">{{ key }}:</dt>
            <dd class="column is-7">
              <span v-for="(type, index) in elm" :key="index" :class="$style.text"
                >{{ type }}
              </span>
            </dd>
          </template>
          <template v-else>
            <dt class="column is-5">{{ key }}:</dt>
            <dd class="column is-7">
              <span :class="$style.text">{{ elm }}</span>
            </dd>
          </template>
        </template>
      </dl>
      <CountryDownloadBox
        v-if="result.files"
        :countries="result.files"
        :section="section"
        :title="result.title || result.subHeading || result.description"
        :types="result.productTypes"
        :labels="labels"
        :context="getContext()"
        :wt-params="mergeWtParams(wtParams, result.wtParams, result)"
        :hide-tooltip="hideTooltip"
      />
      <div v-if="result.software" :class="$style.software">
        <DownloadButton
          v-if="result.software.file"
          :labels="labels"
          :config="
            createConfigFromSoftwareResult(
              result.software.file,
              result.productTypes,
              result.description,
              false
            )
          "
          :section="section"
          :wt-params="{
            ...wtParams,
            ...result.software.file.wtParams
          }"
        />
        <DownloadButton
          v-if="result.software.manual"
          :labels="labels"
          colour="white"
          :config="
            createConfigFromSoftwareResult(
              result.software.manual,
              result.productTypes,
              result.description,
              true
            )
          "
          :section="section"
        />
      </div>
      <div v-if="result.link" :class="$style.data3d">
        <DownloadButton
          :labels="labels"
          :config="createConfigFrom3dDataResult(result)"
          :section="section"
          :wt-params="mergeWtParams(wtParams, result.wtParams, result)"
        />
      </div>
    </div>
    <div
      v-if="result.image"
      :class="$style.image"
      :style="{ backgroundImage: `url(${result.image})` }"
    >
      <img :src="result.image" alt="" />
    </div>
  </div>
</template>

<script setup>
import CountryDownloadBox from '../CountryDownloadBox/CountryDownloadBox.vue'
import { CountryDownloadBoxContext } from '../../globals/constants/download'
import DownloadButton from '../DownloadButton/DownloadButton.vue'
import { mergeSwitchWtParams } from '../../utils/tracking'
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'

const props = defineProps({
  result: {
    type: Object,
    default: () => {}
  },
  labels: {
    type: Object,
    default: () => {}
  },
  wtParams: {
    type: Object,
    default: () => {}
  },
  hideTooltip: {
    type: Boolean,
    default: false
  }
})

const hasMoreTypes = ref(false)
const hasMoreSubHeading = ref(false)
const expandedSubHeading = ref(false)
const section = ref('')
const expandableTypes = ref(null)
const expandableSubHeading = ref(null)

const mergeWtParams = (globalWtParams, itemWtParams, itemParams) => {
  return mergeSwitchWtParams(globalWtParams, itemWtParams, itemParams)
}

const checkExpandable = () => {
  const isIE11 = !!window.MSInputMethodContext && !!document.documentMode
  const maxScrollheight = isIE11 ? 31 : 25
  hasMoreTypes.value = expandableTypes.value && expandableTypes.value.scrollHeight > maxScrollheight
  hasMoreSubHeading.value =
    expandableSubHeading.value && expandableSubHeading.value.scrollHeight > maxScrollheight
}

const toggleExpandableSubHeading = () => {
  expandableSubHeading.value.style.height = expandedSubHeading.value ? '' : 'auto'
  expandedSubHeading.value = !expandedSubHeading.value
}

const getContext = () => {
  return CountryDownloadBoxContext.DOWNLOAD_LIST_TEASER
}

const createConfigFromSoftwareResult = (downloadable, productTypes, description, isManual) => {
  return {
    labels: {
      wishlist: downloadable.text
    },
    metadata: {
      fileType: '',
      fileSize: ''
    },
    title: downloadable.text,
    wishlistTitle: isManual ? `${downloadable.text}: ${description}` : description,
    types: productTypes || [],
    urls: {
      file: '',
      language: '',
      url: downloadable.url
    },
    hideTooltip: props.hideTooltip
  }
}

const createConfigFrom3dDataResult = (result) => {
  return {
    labels: {
      wishlist: result.subHeading
    },
    metadata: {
      fileType: '',
      fileSize: ''
    },
    text: result.text,
    title: result.fileInfo,
    wishlistTitle: result.description,
    types: result.productTypes || [],
    urls: {
      file: '',
      language: '',
      url: result.link
    },
    hideTooltip: props.hideTooltip
  }
}

onMounted(() => {
  if (expandableTypes.value || expandableSubHeading.value) {
    window.addEventListener('resize', checkExpandable)
    checkExpandable()
  }
  if (window.downloadCategory) {
    section.value = window.downloadCategory
  }
})

onUpdated(() => {
  if (expandableTypes.value || expandableSubHeading.value) {
    checkExpandable()
  }
})

onUnmounted(() => {
  if (expandableTypes.value || expandableSubHeading.value) {
    window.removeEventListener('resize', checkExpandable.value)
  }
})
</script>

<style module lang="scss">
@import './DownloadListTeaser.scss';
</style>
