<template>
  <div :class="[$style.container, { FormTextarea__error: error }]">
    <label
      :class="[
        $style.label,
        {
          FormTextarea__optional: optionalLabel
        }
      ]"
      :for="formTextareaId"
    >
      {{ label }}
      <span v-if="optionalLabel">({{ optionalLabel }})</span>
    </label>
    <textarea
      :id="formTextareaId"
      :rows="rows"
      :columns="columns"
      :value="modelValue"
      :aria-label="ariaLabel"
      @input="handleInput"
    />
    <div v-if="error" :class="$style.errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script setup>
import { nanoid } from 'nanoid'

const emit = defineEmits(['input', 'update:modelValue'])

const props = defineProps({
  modelValue: {
    type: String
  },
  label: {
    type: String,
    default: ''
  },
  errorMessage: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Number,
    default: 1
  },
  columns: {
    type: Number,
    default: 1
  },
  optionalLabel: {
    type: String
  },
  ariaLabel: {
    type: String,
    default: undefined
  }
})

const formTextareaId = nanoid()

const handleInput = ($event) => {
  window.dispatchEvent(
    new CustomEvent('formTextarea:input', { detail: { props, originalEvent: $event } })
  )

  emit('input', $event.target.value)
  emit('update:modelValue', $event.target.value)
}
</script>

<style module lang="scss">
@import './FormTextarea.scss';
</style>
