<template>
  <span :class="$style.container" :data-props="JSON.stringify(props)">
    <span v-if="isLoading || !formattedPrice" :class="$style.loadingPrice" data-loading="__,__">{{
      '__,__'
    }}</span>
    <span v-else-if="formattedPrice" v-html="formattedPrice"></span>
  </span>
</template>

<script setup>
import { formatMoney as accountingFormatMoney, toFixed } from 'accounting-js'
import { ref, onBeforeUnmount, watch, onUpdated, onBeforeMount, onMounted } from 'vue'
import * as Storage from '../../shared/Storage'
import StorageKeys from '../../globals/constants/storageKeys'

const emit = defineEmits(['onChange'])

const props = defineProps({
  value: {
    type: Number,
    default: undefined
  },
  productType: {
    string: '',
    default: undefined
  },
  quantity: {
    type: Number,
    default: 1
  },
  btypeList: {
    type: Array,
    default: () => []
  }
})

const discount = ref(undefined)
const priceRaw = ref(undefined)
const productTypeRaw = ref(undefined)
const typesExcludedDiscountRaw = ref(undefined)
const quantityRaw = ref(1)
const btypeListRaw = ref(undefined)

const typesExcludedDiscount = ref([])
const priceWithDiscount = ref(undefined)
const calculatedPrice = ref(undefined)
const formattedPrice = ref(undefined)
const minPrice = ref(undefined)
const comparedPrice = ref(undefined)
const totalPrice = ref(undefined)

const isLoading = ref(true)

const calcTotalPrice = () => {
  isLoading.value = true
  totalPrice.value = quantityRaw.value * comparedPrice.value
}

const calcDiscount = () => (100 - discount.value) / 100

const comparePrices = () => {
  isLoading.value = true
  let minimalPrice
  if (minPrice.value && minPrice.value !== 0 && !isNaN(calcDiscount())) {
    minimalPrice = Math.max(calcDiscount() * minPrice.value, calculatedPrice.value)
    comparedPrice.value = Number(toFixed(minimalPrice, 2))
  } else {
    comparedPrice.value = Number(toFixed(calculatedPrice.value, 2))
  }
}

const calcMinimalPrice = () => {
  isLoading.value = true
  let removeTypesExcludedDiscount = []

  if (btypeListRaw.value?.length && typesExcludedDiscount.value?.length) {
    const btypeList = JSON.parse(JSON.stringify(btypeListRaw.value))

    removeTypesExcludedDiscount =
      btypeList &&
      [...btypeList].filter((type) => ![...typesExcludedDiscount.value].includes(type.btype))
  }

  const allMinimalPrices =
    removeTypesExcludedDiscount &&
    removeTypesExcludedDiscount?.map((listItem) => listItem.minimalPrice)

  minPrice.value = allMinimalPrices.length && Math.min(...allMinimalPrices)
}

const handleDiscount = () => {
  isLoading.value = true

  if (isNaN(priceRaw.value) || priceRaw.value === 0 || !priceRaw.value) {
    return null
  }

  if (productTypeRaw.value && [...typesExcludedDiscount.value].includes(productTypeRaw.value)) {
    priceWithDiscount.value = priceRaw.value
  } else {
    if (discount.value) {
      priceWithDiscount.value = calcDiscount() * priceRaw.value
    } else {
      priceWithDiscount.value = priceRaw.value
      discount.value = Number(0)
    }
  }

  calculatedPrice.value =
    priceWithDiscount.value !== 0 && !isNaN(priceWithDiscount.value)
      ? priceWithDiscount.value
      : priceRaw.value
}

const handleLoading = () => {
  isLoading.value = window?.logInLoading
}

const handleLogIn = () => {
  const { logIn } = window

  if (logIn) {
    discount.value = Number(logIn.discount)
    typesExcludedDiscountRaw.value = logIn.typesExcludedDiscount
  }
}

watch(btypeListRaw, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    calcMinimalPrice()
  }
})

watch(calculatedPrice, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    comparePrices()
  }
})

watch(comparedPrice, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    calcTotalPrice()
  }
})

watch(quantityRaw, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    if (discount.value !== window.logIn?.discount) {
      handleDiscount()
    }
    calcTotalPrice()
  }
})

watch(priceRaw, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    handleDiscount()
  }
})

watch(discount, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    handleDiscount()
  }
})

watch(typesExcludedDiscountRaw, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    typesExcludedDiscount.value = newValue.split('|')
  }
})

watch(totalPrice, (newValue, oldValue) => {
  if (newValue !== oldValue && newValue) {
    formattedPrice.value = newValue
      ? accountingFormatMoney(newValue, {
          symbol: window.currency,
          format: window.currencyPosition,
          decimal: window.decimalSep,
          thousand: window.thousandSep,
          precision: window.precisionValue
        })
      : undefined
    emit('onChange', newValue)
    isLoading.value = false
  }
})

const initIndividualPrice = (btypeList, value, quantity, productType) => {
  isLoading.value = true

  btypeListRaw.value = btypeList
  priceRaw.value = Number(value)
  calculatedPrice.value = Number(value)
  productTypeRaw.value = productType

  if (Number(quantity) !== 0) {
    quantityRaw.value = Number(quantity)
  }
}

onBeforeMount(() => {
  if (isNaN(props.value) || props.value === 0 || !props.value) {
    return
  }

  const logInDiscountFromStorage = Storage.getSessionStorage(StorageKeys.LOGINDISCOUNT, true)

  initIndividualPrice(props.btypeList, props.value, props.quantity, props.productType)

  if (!!logInDiscountFromStorage && !!Object.keys(logInDiscountFromStorage).length) {
    if (logInDiscountFromStorage.discount) {
      discount.value = Number(logInDiscountFromStorage.discount)
    }

    if (logInDiscountFromStorage.typesExcludedDiscount) {
      typesExcludedDiscount.value = logInDiscountFromStorage.typesExcludedDiscount.split('|')
    }
  }
})

onMounted(() => {
  window.addEventListener('logInLoading', handleLoading)
  window.addEventListener('logIn', handleLogIn)
})

onUpdated(() => {
  if (isNaN(props.value) || props.value === 0 || !props.value) {
    return
  }

  if (
    props.quantity !== quantityRaw.value ||
    props.value !== priceRaw.value ||
    !formattedPrice.value
  ) {
    initIndividualPrice(btypeListRaw.value, props.value, props.quantity, productTypeRaw.value)
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('logInLoading', handleLoading)
  window.removeEventListener('logIn', handleLogIn)
})
</script>

<style module lang="scss">
@import './IndividualPrice.scss';
</style>
