<template>
  <div v-show="!loading" :class="$style.container">
    <a id="cartPopperTrigger" :href="config.shopCartUrl" :class="$style.cartPopper">
      <i class="fas fa-shopping-cart" />
      <div :class="$style.badge">{{ cartData.quantity }}</div>
      <span v-html="priceSum" />
    </a>
    <tippy
      v-if="cartHasItems"
      ref="tippyRef"
      :append-to="() => body"
      theme="wika light tooltip"
      :interactive="true"
      placement="bottom"
      animation="fade"
      :duration="[200, 600]"
      :animate-fill="false"
      :arrow="true"
      :distance="11"
      :z-index="10001"
      to="#cartPopperTrigger"
      :trigger="isMobile ? 'click' : 'mouseenter'"
      :hide-on-click="false"
    >
      <template #content>
        <div id="cartPopperContent" :class="$style.popperContent" @mouseleave="hideCartPopper">
          <div v-if="isUpdating">
            <Loading background-color="#ffffff90" size="48" />
          </div>
          <div v-if="cartData" class="columns is-mobile is-multiline">
            <div
              v-for="(item, itemIndex) in cartData.items"
              :key="itemIndex"
              :class="[$style.cartItem, 'column is-full']"
              @mouseenter="addActiveClass"
              @mouseleave="removeActiveClass"
            >
              <div
                :ref="(el) => (infoPopperRefs[itemIndex] = el)"
                :class="$style.infoPopperContent"
              >
                <i class="fas fa-info-circle" />
                {{ item.longtext }}
              </div>
              <div class="columns is-mobile">
                <div class="column is-one-third">
                  <img :src="item.thumbnail" :alt="item.title" />
                </div>
                <div :class="[$style.priceBox, 'column is-two-thirds']">
                  <a
                    :class="$style.trash"
                    aria-label="delete button"
                    @click.prevent="removeItem(item, itemIndex)"
                  >
                    <i class="fas fa-trash-alt" />
                  </a>
                  <strong :class="$style.title">{{ item.title }}</strong>
                  <div>{{ item.name }}</div>
                  <p :class="$style.heading">
                    {{ config.itemNumber }}:
                    {{
                      typeof item !== 'undefined' &&
                      typeof item.id !== 'undefined' &&
                      !item.id.includes('none_')
                        ? item.id
                        : config.noArtNoAvailableText
                    }}
                    <a>
                      <i
                        :class="[$style.infoPopper, 'fas fa-info-circle']"
                        @mouseenter="showInfoPopper(itemIndex)"
                        @mouseleave="hideInfoPopper(itemIndex)"
                      />
                    </a>
                  </p>
                  <div>
                    {{ config.pricePerItem }}:
                    <IndividualPrice :value="item.pricesingle" :product-type="item.productType" />
                  </div>
                  <div :class="$style.countSum">
                    <div :class="$style.label">{{ config.quantity }}:</div>
                    <Select
                      :select-options="selectOptions"
                      :select-value="item.quantity"
                      colour="white"
                      @on-change="(quantity) => updateQuantity(quantity, item)"
                    />
                    <div :class="$style.label">
                      <IndividualPrice
                        :value="item.pricesingle"
                        :quantity="item.quantity"
                        :product-type="item.productType"
                        @on-change="(value) => priceChange(value, itemIndex)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="[$style.cartTotal, 'column is-offset-one-third is-two-thirds']">
              <div :class="$style.sum">{{ config.totalPrice }}: <span v-html="priceSum" /></div>
              <div :class="$style.tax">
                {{ config.tax }} &amp;
                <a :href="config.shippingUrl">
                  {{ config.shippingText }}
                </a>
              </div>
              <Button
                colour="blue"
                :text="config.buttonText"
                :is-link="true"
                :href="config.shopCartUrl"
              />
            </div>
          </div>
        </div>
      </template>
    </tippy>
    <ShopCartPopper :config="config" :item="newItem" :is-popper-open="newItemIsSet" />
  </div>
</template>

<script setup>
import Button from '../Button/Button.vue'
import Select from '../Select/Select.vue'
import ShopCartPopper from '../ShopCart/ShopCartPopper.vue'
import CustomEvents from '../../globals/constants/customEvents'
import removeItemToUrl from '../../xhr/shopCart/removeItemToUrl'
import updateQuantityToUrl from '../../xhr/shopCart/updateQuantityToUrl'
import getCartFromUrl from '../../xhr/shopCart/getCartFromUrl'
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import { formatMoney } from 'accounting-js'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'
import Loading from '../Loading/Loading.vue'

const { body } = window.document

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const loading = ref(true)
const isUpdating = ref(false)
const tippyRef = ref(null)
const infoPopperRefs = ref({})
const cartData = ref({
  quantity: 0,
  items: []
})
const newItem = ref({})
const newItemIsSet = ref(false)
const isInitialized = ref(false)

const selectOptions = computed(() => {
  const range = (start, end) => [...Array(end - start + 1)].map((_, i) => start + i).map(String)
  return range(1, props.config.selectOptionMax)
})

const formatWithCurrency = (value) =>
  formatMoney(value, {
    symbol: props.config.currency,
    format: props.config.currencyPosition,
    decimal: props.config.decimalSep,
    thousand: props.config.thousandSep,
    precision: props.config.precisionValue
  })

const priceSum = ref(formatWithCurrency(0))
const priceSumItems = ref([])

const calcSum = () => {
  const sumRaw = priceSumItems.value.reduce((previousValue, currentValue) => {
    return previousValue + currentValue
  }, 0)

  priceSum.value = formatWithCurrency(sumRaw)
}

const priceChange = (itemPrice, index) => {
  priceSumItems.value[index] = itemPrice

  calcSum()
}

const cartHasItems = computed(() => {
  return cartData.value.quantity !== 0
})

const hideCartPopper = () => tippyRef.value.hide()

const showInfoPopper = (index) => infoPopperRefs.value[index].classList.add('ShopCart__infoVisible')
const hideInfoPopper = (index) =>
  infoPopperRefs.value[index].classList.remove('ShopCart__infoVisible')

const openAddToCartPopper = (data) => {
  newItem.value = data.items[0]
  newItemIsSet.value = true

  getCart()

  setTimeout(() => {
    newItemIsSet.value = false
  }, 3000)
}

const addActiveClass = (event) => {
  event.currentTarget.classList.add('active')
}

const removeActiveClass = (event) => {
  event.currentTarget.classList.remove('active')
}

const getCart = () => {
  getCartFromUrl(window.getCartUrl, {
    country: window.currentCountry,
    language: window.currentLanguage
  })
    .then((response) => {
      cartData.value = response
    })
    .catch((error) => console.error(error))
    .finally(() => {
      loading.value = false
      isUpdating.value = false
    })
}

const addItem = (event) => openAddToCartPopper(event.detail)

const updateQuantity = (quantity, item) => {
  if (isInitialized.value) {
    isUpdating.value = true

    updateQuantityToUrl(window.updateItemQuantityUrl, {
      itemid: item.id,
      quantity,
      country: window.currentCountry,
      language: window.currentLanguage
    })
      .then(() => {
        getCart()
      })
      .catch((error) => console.error(error))
  }

  isInitialized.value = true
}

const removeItem = (item, index) => {
  priceSumItems.value.splice(index, 1)

  calcSum()

  isUpdating.value = true

  removeItemToUrl(window.removeItemUrl, {
    itemid: item.id,
    country: window.currentCountry,
    language: window.currentLanguage
  })
    .then(() => {
      getCart()
    })
    .catch((error) => console.error(error))
}

onBeforeMount(() => {
  getCart()
})

onMounted(() => {
  window.addEventListener(CustomEvents.ADD_TO_CART, addItem, false)
  window.addEventListener(CustomEvents.UPDATE_SHOP_CART, getCart, false)
})

onUnmounted(() => {
  window.removeEventListener(CustomEvents.ADD_TO_CART, addItem, false)
  window.removeEventListener(CustomEvents.UPDATE_SHOP_CART, getCart, false)
})
</script>

<style module lang="scss">
@import './ShopCart.scss';
</style>
