<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <Button :text="backBtnLabel" colour="blue" @on-simple-click="handleBackClick" />
        </div>
        <SocialSharing :social-sharing-links="socialSharingLinks" />
      </div>
      <div class="columns is-gapless">
        <div class="column is-three-quarters">
          <HeaderBox
            type="Typen 111.10, 111.12"
            title="Rohrfedermanometer, Kupferlegierung"
            text="Standardausführung"
            subtitle="Ausgewählte Dokumente"
            :links="['Dokument1', 'Dokument2', 'Dokument3']"
          />
        </div>
        <div class="column">
          <SalesContainer :config="salesContainerConfig" />
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <div class="columns">
        <div class="column is-three-quarters SubPage__contentArea">
          <div id="detailSection" style="display: none">
            <div class="ProductDetailSection__container">
              <section>
                <h2>Anwendungen</h2>
                <ul>
                  <li>
                    Für Messstellen mit erhöhter Differenzdrucküberlast und/oder hohen
                    Betriebsdrücken (stat. Drücken), auch in aggressiver Umgebung
                  </li>
                  <li>
                    Für gasförmige, flüssige, verunreinigte, viskose und aggressive Messstoffe
                  </li>
                  <li>Pumpenüberwachung und -steuerung</li>
                  <li>Filterüberwachung</li>
                  <li>Füllstandsmessung an geschlossenen Behältern</li>
                </ul>
              </section>
              <section>
                <h2>Leistungsmerkmale</h2>
                <ul>
                  <li>Differenzdruckmessbereiche ab 0 … 60 mbar</li>
                  <li>
                    Hoher Betriebsdruck (statischer Druck) und hohe Überlastsicherheit wahlweise bis
                    40, 100, 250 oder 400 bar
                  </li>
                  <li>Messzellenflüssigkeitsdämpfung gegen hohe Druckänderungsgeschwindigkeiten</li>
                  <li>Kompatibel mit Schaltkontakten</li>
                  <li>Typ 762.14: Monelausführung</li>
                </ul>
              </section>
              <section>
                <h2>Beschreibung</h2>
                <div class="columns">
                  <div class="column is-half">
                    <p>
                      Diese Differenzdruckmanometer zeichnen sich durch die hochkorrosionsbeständige
                      CrNi-Stahl-Ausführung aus. Die hohe Überlastbarkeit wird durch eine
                      vollflächige metallische Anlage des Plattenfeder-Messelementes erreicht.
                    </p>
                    <ul>
                      <li>Die Verwendung hochwertiger CrNi-Stahl-Werkstoffe</li>
                      <li>verfahrenstechnischen Prozessen und ist für flüssige</li>
                    </ul>
                  </div>
                  <div class="column is-half">
                    <p>
                      Die messstoffberührten Bauteile sind für diese Geräte auch aus
                      Sonderwerkstoffen wie Monel oder Hastelloy verfügbar.
                    </p>
                    <p>
                      Anzeigebereiche von 0 ... 60 mbar bis 0 ... 40 bar stellen die in
                      verschiedensten Applikationen geforderten Messbereiche sicher.
                    </p>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <ProductDetailTabs :config="tabsConfig" />
        </div>
        <div class="column is-one-quarter SubPage__sideBar">
          <div class="column">
            <SidebarSlider :slides="slides" />
          </div>
        </div>
      </div>
    </div>
    <Footer :config="StoryConfig" />
    <CookieDisclaimer :config="cookieDisclaimerConfig" />
    <ProductDetailSection />
  </div>
</template>

<script setup>
import Button from '../Button/Button.vue'
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer.vue'
import Footer from '../Footer/Footer.vue'
import HeaderBox from '../HeaderBox/HeaderBox.vue'
import PageHeader from '../PageHeader/PageHeader.vue'
import SalesContainer from '../SalesContainer/SalesContainer.vue'
import SidebarSlider from '../SidebarSlider/SidebarSlider.vue'
import SocialSharing from '../SocialSharing/SocialSharing.vue'
import ProductDetailSection from '../ProductDetailSection/ProductDetailSection.vue'
import { onBeforeMount } from 'vue'
import StoryConfig from '../Footer/FooterStoryDefaults'

import ProductDetailTabs from '../../components/ProductDetailTabs/ProductDetailTabs.vue'

//setPublicPath()

const tabsConfig = [
  {
    title: 'Details',
    id: 'detailSection',
    sliders: [
      {
        title: 'Zubehör',
        products: [
          {
            image: 'https://picsum.photos/200/200?random=1',
            type: 'Typ 910.14',
            title: 'Dichtungen für Druckmessgeräte',
            text: 'Für die Prozessindustrie, hochüberlastsicher bis 40, 100, 250 oder 400 bar',
            primaryLink: '#'
          }
        ]
      },
      {
        title: 'Ähnliche Produkte2',
        products: [
          {
            image: 'https://picsum.photos/200/200?random=4',
            type: 'Typ 910.14',
            title: 'Dichtungen für Druckmessgeräte',
            text: 'Für die Prozessindustrie, hochüberlastsicher bis 40, 100, 250 oder 400 bar',
            primaryLink: '#'
          },
          {
            image: 'https://picsum.photos/200/200?random=5',
            type: 'Typ 910.14',
            title: 'Dichtungen für Druckmessgeräte',
            text: 'Für die Prozessindustrie, hochüberlastsicher bis 40, 100, 250 oder 400 bar',
            primaryLink: '#'
          },
          {
            image: 'https://picsum.photos/200/200?random=6',
            type: 'Typ 910.14',
            title: 'Dichtungen für Druckmessgeräte',
            text: 'Für die Prozessindustrie, hochüberlastsicher bis 40, 100, 250 oder 400 bar',
            primaryLink: '#'
          }
        ]
      }
    ]
  },
  {
    title: 'Übersicht',
    id: 'detailSection'
  },
  {
    title: 'Downloads',
    url: [
      'productcode_downloads_35135.json',
      'approvaldownloads.json?title=S-20,S-20,S-20',
      'productdocumentsdownloads.json?title=S-20,S-20,S-20',
      'declarationsdownloads.json?title=S-20,S-20,S-20'
    ],
    isDownload: true,
    initialSelectOption: 'Bitte wählen...',
    selectAllOption: 'Alle',
    productTypes: ['732.14', '762.14'],
    labels: {
      pdf: 'PDF',
      ebook: 'E-Book',
      wishlist: 'Merkzettel',
      declarations: 'Herstellerinformationen und Bescheinigungen',
      projectdocuments: 'Projektdokumente',
      approvals: 'Zulassungen',
      certificationBody: 'Zulassungsstelle',
      typeOfApproval: 'Zulassungsart',
      economicArea: 'Land / Wirtschaftsraum'
    },
    mappingUrl: 'keyword_translation.json'
  },
  {
    tabID: 'standardarticle',
    title: 'Standardartikel',
    id: 'orderdata',
    url: ['standardartikel_tabelle_18428.WIKA.json'],
    isTable: true,
    hideTooltip: true,
    scrollWarning: 'Wischen Sie, um die Tabelle nach links und rechts zu scrollen.',
    detailLinkText: 'Details',
    RFQText: 'Anfragen',
    RFQUrl: '/iframe.html?id=6-forms-rfq-form--rfq-form',
    productTypes: 'Typen 111.10, 111.12',
    shopLinkText: 'Zum Online-Shop',
    shopUrl: 'https://shop.wika.de/732_51_shop_de_de.WIKA?itemId='
  },
  {
    tabID: 'configurator',
    title: 'Konfigurator',
    disabled: true
  }
]
const cookieDisclaimerConfig = {
  text: 'Unsere Webseite verwendet Cookies. Durch die weitere Nutzung stimmen Sie der Verwendung zu.',
  link: {
    text: 'Weitere Informationen',
    url: '#'
  },
  button: {
    text: 'Zustimmen',
    colour: 'blue'
  }
}

const salesContainerConfig = {
  title: 'Vertriebs&shy;kontakt',
  icon: 'fas fa-user-tie',
  input: {
    placeholderText: 'Postleitzahl eingeben',
    submitUrl: 'contact_de_at.WIKA?cnType=1&cnEntry='
  }
}

const slides = [
  {
    image: 'https://picsum.photos/300/160?random=1',
    video: 'https://www.youtube.com/embed/QbPEuTQvaiw?enablejsapi=1',
    text: 'Play-Taste mit Default Cursor und Modal-Overlay.',
    title: 'Eine Video slide',
    alt: 'Eine Video slide'
  },
  {
    image: 'https://picsum.photos/300/160?random=2',
    video: '',
    text: 'Keine Play-Taste mit Zoom-In-Cursor und Modal-Overlay. Keine Play-Taste mit Zoom-In-Cursor und Modal-Overlay.',
    title: 'Eine Bild slide',
    alt: 'Eine Bild slide'
  },
  {
    image: 'https://picsum.photos/300/160?random=3',
    video: '',
    text: 'Keine Play-Taste mit Zoom-In-Cursor und Modal-Overlay.',
    title: 'Noch eine Bild slide',
    alt: 'Noch eine Bild slide'
  }
]

const socialSharingLinks = [
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-facebook-f',
    color: '#3c5598'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-twitter',
    color: '#1da1f2'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-xing',
    color: '#007e7e'
  },
  {
    url: '#',
    title: 'Teilen',
    newWindow: 'true',
    icon: 'fab fa-linkedin-in',
    color: '#0072b1'
  },
  {
    url: 'mailto:?subject=Ihr angeforderter Link von der WIKA-Internetseite&body=Guten Tag,%0D%0A%0D%0ASie haben auf unserer Internetseite einen Link angefordert:%0D%0A%0D%0Ahttps://de-de.wika.de/products_de_de.WIKA%0D%0A%0D%0AVielen Dank für Ihr Interesse.%0D%0AIhr WIKA-Team',
    title: 'Seite zusenden',
    newWindow: 'true',
    icon: 'fas fa-envelope',
    color: '#3480cf'
  }
]

const backBtnLabel = 'Zurück zur Übersicht'

const handleBackClick = () => {
  window.history.back()
}

onBeforeMount(() => {
  window.instance = 'website'
})
</script>
