<template>
  <div :class="$style.container">
    <hr :class="$style.line" />
    <h2 v-if="config.title" :class="$style.title">
      {{ config.title }}
    </h2>
    <div :class="$style.slider">
      <VueAgile :key="`agileSlider_${config.products.length}`" :options="sliderOptions">
        <figure
          v-for="(product, index) in config.products"
          :key="index"
          :class="[!isMobile && $style.notMobile, $style.slideFigure]"
        >
          <a
            :class="$style.mediaLink"
            :href="product.primaryLink"
            :navigationID="product.navigationID"
            :navigationType="product.navigationType"
            target="_self"
          >
            <img :src="product.image" :alt="product.title" />
          </a>
          <figcaption>
            <a
              :class="$style.primaryLink"
              :href="product.primaryLink"
              :navigationID="product.navigationID"
              :navigationType="product.navigationType"
              target="_self"
            >
              <div v-if="!config.isShop">{{ product.type }}</div>
              <p v-if="!config.isShop" :class="$style.productTitle" v-html="product.title" />
              <p v-if="config.isShop" :class="$style.productTitle" v-html="product.title" />
              <div v-if="config.isShop">{{ product.type }}</div>
              <div v-if="product.text" v-html="product.text" />
              <div v-if="config.isShop" :class="$style.priceTag">
                <strong>
                  <IndividualPrice
                    :value="Number(product.price)"
                    :btype-list="product.btypeList"
                    :product-type="product.productType"
                  />
                </strong>
                <span>
                  {{ product.tax }} &amp;
                  <a :href="product.shippingUrl" target="_blank">
                    {{ product.shippingText }}
                  </a>
                </span>
              </div>
            </a>
          </figcaption>
        </figure>
      </VueAgile>
    </div>
  </div>
</template>

<script setup>
import { VueAgile } from 'vue-agile'
import { onBeforeMount, onUnmounted, ref } from 'vue'
import { checkIsMobile } from '../../utils/media'
import IndividualPrice from '../IndividualPrice/IndividualPrice.vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const isMobile = ref(false)
const sliderOptions = ref({
  navButtons: props.config.products.length > 1,
  speed: 500,
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToMove: props.config.products.length > 1 ? 1 : 0,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        navButtons: props.config.products.length > 2,
        slidesToShow: 2,
        slidesToMove: props.config.products.length > 2 ? 2 : 0
      }
    }
  ]
})

const checkScreenSize = () => (isMobile.value = checkIsMobile())

onBeforeMount(() => {
  checkScreenSize()
  window.addEventListener('resize', checkScreenSize)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>

<style module lang="scss">
@import './ProductSlider.scss';
</style>
