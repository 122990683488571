import axios from 'axios'

const getSearchResultFromUrl = (url, payload) => {
  return new Promise((resolve, reject) => {
    const country =
      window.searchCountry !== undefined ? window.searchCountry : window.currentCountry
    return axios({
      url: url,
      method: 'post',
      data: {
        ...payload,
        language: window.currentLanguage.replace('EN-US', 'EN'),
        country: country
      }
    })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error))
  })
}

export default getSearchResultFromUrl
