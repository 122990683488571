<template>
  <div class="SubPage__container">
    <PageHeader />
    <div class="SubPage__contentGrid SubPage__firstGrid">
      <BreadCrumbs :crumbs="crumbs" />
      <div class="SubPage__header columns is-mobile">
        <div class="column">
          <h1 class="SubPage__title">Branchen Detailseite</h1>
        </div>
      </div>
      <div class="columns is-gapless">
        <div class="column is-three-quarters">
          <HeroSlider :slides="slides" :height-first="true" />
        </div>
        <div class="column is-one-quarter">
          <SalesContainer :config="salesContainerConfig" />
        </div>
      </div>
    </div>
    <div class="SubPage__contentGrid">
      <div class="columns">
        <div class="column is-three-quarters SubPage__contentArea">
          <div id="tab-content-0" style="display: none">
            <h3>Führend in SF<sub>6</sub> Lifecycle Lösungen</h3>
            <p>
              Um Anlagen mit <strong>SF</strong><strong><sub>6</sub></strong
              ><span
                ><strong>-Gas</strong> sachgerecht betreiben zu können, wird eine Vielzahl an
                speziellen Geräten sowie fachspezifisches Know-How benötigt. WEgrid Solutions ist
                ein Expertenteam von WIKA-Mitarbeitern, das sich auf die besonderen Anforderungen
                der Energieübertragungsbranche spezialisiert hat. Als Einziger am Markt bietet
                WEgrid Solutions ein vollständiges Produktportfolio sowie individuelle
                Komplettlösungen für SF<sub>6</sub>-gefüllte Anlagen an.</span
              >
            </p>
            <hr />
            <h3>Unsere Stärken für Ihre SF<sub>6</sub> Anlagen</h3>
            <p>
              Weltweit befinden sich über 1.000.000 WIKA-Gasdichteüberwachungsgeräte im Einsatz.
              Diese Zahl verdanken wir der hohen Qualität unserer Produkte, die komplett&nbsp;auf
              dem 5-Sigma-Qualitätsniveau hergestellt und geprüft werden, sowie der daraus
              resultierenden Kundenzufriedenheit. Unsere Messgeräte sind hermetisch dicht und
              temperaturkompensiert, dadurch werden selbst schwierigsten Einsatzbedingungen
              zuverlässige Messergebnisse erreicht.
            </p>
            <p>
              Die Anlagensicherheit von SF<sub>6</sub>-gasisolierten Schaltanlagen wird bereits
              durch geringe Konzentrationen von Zersetzungsprodukten stark herabgesetzt. Diese
              Zersetzungsprodukte entstehen während des Betriebs durch Lichtbögen in feuchtem bzw.
              unreinem SF<sub>6</sub>-Gas. Um selbst kleinste Leckagen aufzuspüren oder um vor
              Gasemissionen zu warnen, haben wir hochwertige Gasdetektoren und
              Emissionsschutzwächter im Angebot. WIKA bietet für die Gasanalytik eine vollständige
              Produktlinie inklusive Zubehör.
            </p>
            <p>
              Selbstschließende Ventile und dauerhaft gasdichte Anschlussteile sorgen dafür, dass
              kein umweltgefährdendes SF<sub>6</sub>
              -Gas entweicht, sondern sicher in den dafür vorgesehenen Behältern bleibt.
            </p>
            <p>
              Die Servicevorgänge wie Evakuieren und Füllen von SF<sub>6</sub>
              -Tanks bzw. das Aufbereiten von SF<sub>6</sub>-Gas muss mit geeigneten Betriebsmitteln
              durchgeführt werden. Das WIKA-Produktportfolio der Füll- und Aufbereitungsgeräte deckt
              die Bereiche des Handlings von SF<sub>6</sub>-Gas in der T&amp;D Industrie umfassend
              ab.
            </p>
            <hr />
            <h3>Applikationsbeispiele</h3>
            <ul>
              <li>Gasisolierte Schaltanlagen und Leitungen</li>
              <li>Radiologiegeräte</li>
              <li>Teilchenbeschleuniger</li>
              <li>Anlagen für die Halbleiterherstellung</li>
            </ul>
          </div>
          <div id="tab-content-1" style="display: none">
            <h3>Vielfältiges Serviceangebot</h3>
            <p>
              Neben dem umfangreichen SF<sub>6</sub>-Gas Produktportfolio wir Ihnen eine große
              Auswahl an ergänzenden Dienstleistungen an. In unseren Schulungen geben Ihnen
              zertifizierte Experten rund um das SF<sub>6</sub>-Lifecycle-Mangement einen Einblick
              in bewährte Vorgehensweisen und aktuelle Trends. Sie erhalten eine Vorführung der
              Geräte direkt vom Hersteller:&nbsp;
            </p>
            <p>Unser Dienstleistungsangebot umfasst:</p>
            <ul>
              <li>
                <a href="/service_sf6_services_de_de.WIKA" target="_self">
                  Schulungen zur Erlangung des SF<sub>6</sub>-Sachkundenachweises
                </a>
              </li>
              <li>Gasanalyse</li>
              <li>Reparatur- und Wartung</li>
              <li>
                <a href="/service_calibration_service_sf6_de_de.WIKA" target="_self">
                  Kalibrierung, Qualitätsanalyse und Lecksuche
                </a>
              </li>
              <li>Beratung</li>
            </ul>
            <p>
              Unser speziell geschultes Personal steht Ihnen gerne in allen Fragen rund um das Thema
              SF<sub>6</sub> mit Rat und Tat zur Seite. Viele unserer Dienstleistungen und
              allgemeine Produktschulungen können auch direkt bei Ihnen Vor-Ort angeboten werden.
              Selbstverständlich sind alle Angebote in deutscher sowie in englischer Sprache
              verfügbar.
            </p>
            <p>
              Benötigen Sie weitere Informationen oder haben Sie eine spezielle Anfrage?
              Kontaktieren Sie uns einfach unter
              <a href="mailto:SF6-sales@wika.com">SF6-sales@wika.com</a>.
            </p>
          </div>
          <BranchApp />
          <BranchDetailTabs :config="tabsConfig" />
        </div>
        <div class="column is-one-quarter SubPage__sideBar">
          <div class="column">
            <ContentBox
              image="https://de-de.wika.de/upload/PIC_CR_WegridSF6_de_de_95002.jpg"
              title="WEgrid Solutions"
            />
          </div>
          <div class="column">
            <DownloadBox :download-options="downloadOptions" :labels="labels" />
          </div>
          <div class="column">
            <ContentBox
              primary-link="#"
              title="SF<sub>6</sub>-Services"
              text="Vertrauen Sie bei Kalibrierung und Wartung Ihrer Messgeräte und Schulungen von Fachkräften einem kompetenten Partner."
            />
          </div>
          <div class="column">
            <ContentBox
              primary-link="#"
              image="https://de-de.wika.de/upload/PIC_CR_BasicMaterialsAluminium_de_de_101304.jpg"
              title="SF<sub>6</sub>-Schulungen"
              text="Möchten Sie Ihr Know-how vertiefen und auf den neuesten Stand bringen? Informieren Sie sich über unsere Angebote."
            />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script setup>
import PageHeader from '../PageHeader/PageHeader.vue'
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs.vue'
import HeroSlider from '../HeroSlider/HeroSlider.vue'
import SalesContainer from '../SalesContainer/SalesContainer.vue'
import BranchDetailTabs from '../BranchDetailTabs/BranchDetailTabs.vue'
import BranchApp from '../BranchApp/BranchApp.vue'
import ContentBox from '../ContentBox/ContentBox.vue'
import DownloadBox from '../DownloadBox/DownloadBox.vue'
import Footer from '../Footer/Footer.vue'

const crumbs = [
  {
    title: 'Startseite',
    url: '#'
  },
  {
    title: 'Branchen',
    url: '#'
  },
  {
    title: 'Energieübertragung und -verteilung (SF<sub>6</sub>)',
    url: '#'
  }
]

const slides = [
  {
    image: 'https://picsum.photos/662/257?random=1',
    newWindow: false,
    url: '#'
  },
  {
    image: 'https://picsum.photos/662/257?random=2',
    newWindow: false,
    url: '#'
  },
  {
    image: 'https://picsum.photos/662/257?random=3',
    newWindow: false,
    url: '#'
  }
]

const salesContainerConfig = {
  title: 'Vertriebs&shy;kontakt',
  icon: 'fas fa-user-tie',
  input: {
    placeholderText: 'Postleitzahl eingeben',
    submitUrl: 'contact_de_at.WIKA?cnType=1&cnEntry='
  }
}

const labels = {
  pdf: 'PDF',
  ebook: 'E-Book',
  wishlist: 'Merkzettel'
}

const downloadOptions = [
  {
    noWishList: true,
    image: '//placehold.it/250x134&text=Placeholder',
    title: 'Segmentbroschüre',
    categoryLink: 'http://www.google.com',
    downloadList: [
      {
        title: 'Innovative SF6-Lösungen',
        pdf: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
      }
    ]
  }
]

const tabsConfig = [
  { title: 'Übersicht', id: 'tab-content-0' },
  { title: 'Dienstleistungen', id: 'tab-content-1' },
  {
    title: 'Produkte',
    active: true,
    withFilter: true,
    listConfig: {
      listTitle: 'Branchen-Produktliste',
      hasSearch: false,
      withTeasers: false,
      texts: {
        resultText: 'Anzahl der gefundenen Einträge:',
        resetText: '',
        helpText: 'Bitte wählen Sie eine Filteroption.',
        pdf: 'PDF',
        ebook: 'E-Book',
        wishlist: 'Merkzettel',
        productDetail: 'Details',
        productDatasheet: 'Datenblatt'
      },
      resetSelectOption: 'Alle',
      loadInitialData: true,
      // url: window.baseUrl + '/de-de/branchen-json-87626.json',
      url: 'branchen_json.json',
      withExternalData: false,
      mappingUrl: '',
      filters: [
        {
          hasFilter: false,
          colour: 'blue',
          label: '',
          key: '',
          id: '',
          sort: 'asc',
          initialSelectOption: 'Bitte wählen...',
          placeholder: 'Bitte wählen...'
        },
        {
          hasFilter: false,
          colour: 'blue',
          label: 'Produktlinie',
          key: 'productline',
          id: 'productline',
          sort: 'asc',
          initialSelectOption: 'Bitte wählen...',
          placeholder: 'Bitte wählen...'
        }
      ],
      pagination: {
        texts: { page: 'Seite', of: 'von' },
        resultsPerPage: 12,
        resultsSelect: {
          initialSelectOption: '12 Einträge pro Seite',
          selectOptions: ['12 Einträge pro Seite', '24 Einträge pro Seite', 'Alle']
        }
      }
    }
  }
]
</script>
