import axios from 'axios'
import {
  getQuoteIdFromSession,
  removeQuoteIdFromSession,
  setQuoteIdToSession
} from '../../utils/quoteId'

const getQuoteIdFromUrl = (getQuoteIdUrl) => {
  const quoteIdFromSession = getQuoteIdFromSession()
  return new Promise((resolve, reject) => {
    axios.defaults.withCredentials = true
    axios({
      url: getQuoteIdUrl,
      method: 'get',
      responseType: 'json',
      params: {
        createQuoteIfNotExist: quoteIdFromSession ? 0 : 1,
        _: Date.now()
      }
    })
      .then((response) => {
        const quoteId = response.data
        if (quoteId === '') {
          removeQuoteIdFromSession()
          getQuoteIdFromUrl(getQuoteIdUrl)
            .then((responseData) => resolve(responseData))
            .catch((error) => reject(error))
        } else if (quoteId && typeof quoteId === 'string') {
          if (!quoteIdFromSession || quoteIdFromSession !== quoteId) {
            setQuoteIdToSession(quoteId)
          }

          resolve(quoteId)
        } else if (!quoteId || typeof quoteId !== 'string') {
          reject(`Could not get quoteId or response is not a quoteId string: ${quoteId}`)
        }
      })
      .catch((error) => reject(error))
  })
}

export default getQuoteIdFromUrl
