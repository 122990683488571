<template>
  <div style="position: relative">
    <div v-if="!iframeLoaded && iframeConfig.src" class="preloadFilter">
      <Loading />
    </div>
    <IFrameResizer
      v-if="iframeConfig.src"
      v-show="iframeLoaded"
      :config="iframeConfig"
      class="careerIFrame"
      @loaded="iframeLoaded = true"
    />
  </div>
</template>

<script setup>
import IFrameResizer from '../IFrameResizer/IFrameResizer.vue'
import Loading from '../Loading/Loading.vue'
import { onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const iframeTrigger = ref(null)
const careerDetailContainer = ref(null)
const careerIframeContainer = ref(null)
const iframeLoaded = ref(null)
const iframeConfig = ref({
  id: 'erecruitframe',
  width: '100%',
  height: '1025px',
  src: null
})

const eRecruitdomainHttp = 'http://wika-portal.rexx-recruitment.com'
const eRecruitdomainHttps = 'https://wika-portal.rexx-recruitment.com'

const receiveMessage = (event) => {
  if (event.origin !== eRecruitdomainHttp || event.origin !== eRecruitdomainHttps) {
    return
  }
  if (event.data === 'close') {
    careerDetailContainer.value.classList.remove('inactive')
    careerIframeContainer.value.classList.add('inactive')
  }
}

onMounted(() => {
  window.addEventListener('message', receiveMessage, false)
  iframeTrigger.value = document.querySelector('.Button__apply')
  careerIframeContainer.value = document.querySelector('.CareerIFrame__container')
  careerDetailContainer.value = document.querySelector('.CareerDetail__container')
  iframeTrigger.value.addEventListener('click', (event) => {
    event.preventDefault()
    careerDetailContainer.value.classList.add('inactive')
    careerIframeContainer.value.classList.remove('inactive')
  })
  iframeConfig.value.src = props.config.src
})
</script>

<style module lang="scss">
@import './CareerIFrame.scss';
</style>
