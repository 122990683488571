<template>
  <div
    v-show="!loading"
    ref="shopCountrySwitcherRef"
    :class="[{ ShopCountrySwitcher__container__active: countrySwitcherActive }, $style.container]"
  >
    <a id="countrySwitcherTrigger" :class="$style.toggle">
      <img :src="config.flag" :alt="config.currentCountry" />
      <span>{{ config.currentCountry }}</span>
    </a>
    <span :class="$style.caret" />
    <tippy
      ref="tippyRef"
      :content-class="`${$style.layer} countrySwitcherPopper`"
      :interactive="true"
      :to="'#countrySwitcherTrigger'"
      :z-index="10000"
      :arrow="true"
      :on-show="
        () => {
          countrySwitcherActive = true
        }
      "
      :on-hide="
        () => {
          countrySwitcherActive = false
        }
      "
      max-width="none"
      content-tag="div"
      placement="bottom-start"
      theme="wika light tooltip"
      trigger="click"
      :offset="[findOffset(), 0]"
    >
      <template #content>
        <div id="countrySwitcher" :class="$style.layer">
          <Button :class="$style.close" is-close colour="white" @close="closeCountrySwitcher" />
          <div :class="$style.content">
            <Tabs :tab-count="1">
              <template #1-title>
                {{ config.title }}
              </template>
              <template #1-content>
                <div :class="$style.websites">
                  <div :class="[$style.websitesControls, 'columns']">
                    <div
                      class="column is-half-mobile is-one-third-tablet is-one-quarter-desktop is-one-fifth-widescreen"
                    >
                      <VueSelect
                        :key="`sitesKey-${componentKey}`"
                        :class="$style.regionSelect"
                        colour="blue"
                        :select-options="siteSelectOptions"
                        :select-value="siteSelectValue"
                        @on-change="updateCountryButtons"
                      />
                    </div>
                    <div
                    class="column is-offset-one-third-tablet is-offset-half-desktop is-offset-three-fifths-widescreen"
                    >
                      <Button
                        :class="$style.corporate"
                        is-link
                        colour="orange"
                        :text="config.corporateTitle"
                        :href="config.corporateShopUrl"
                      />
                    </div>
                  </div>
                  <div :class="$style.countries">
                    <div v-for="(country, index) in countryButtons" :key="index">
                      <CountryButton
                        :label="countryButtonLabel"
                        :country="country.name"
                        :flag="country.flag"
                        :languages="country.languages"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </Tabs>
          </div>
        </div>
      </template>
    </tippy>
  </div>
</template>

<script setup>
import Button from '../Button/Button.vue'
import CountryButton from '../CountryButton/CountryButton.vue'
import VueSelect from '../Select/Select.vue'
import Tabs from '../Tabs/Tabs.vue'
import { regionDataUrl } from '../../shared/globals'
import getShopRegionsFromUrl from '../../xhr/shopHeader/getShopRegionsFromUrl'
import * as Storage from '../../shared/Storage'
import { onMounted, ref, computed } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const tippyRef = ref(null)
const loading = ref(true)
const componentKey = ref(0)
const apiData = ref(null)
const availableSites = ref([])
const siteSelectOptions = ref([])
const siteSelectValue = ref('')
const countryButtons = ref([])
const countrySelectOptions = ref([])
const countrySelectValue = ref('')
const countrySwitcherActive = ref(false)
const cookieName = 'cookieCountry'
const shopCountrySwitcherRef = ref(null)

const countryButtonLabel = computed(() => {
  return props.config.languageTitle || window.dictionary?.countrySwitcher?.language
})

const forceRerender = () => {
  componentKey.value += 1
}

const closeCountrySwitcher = () => {
  tippyRef.value.hide()
  countrySwitcherActive.value = false
}
const setSiteSelectOptions = () => {
  availableSites.value = apiData.value.regions.filter((region) => {
    return (
      region.countries.findIndex((country) => country.languages && country.languages.length) > -1
    )
  })
  siteSelectOptions.value = availableSites.value.map((site) => site.name)
}
const setCountrySelectOptions = () => {
  let foundRegion = siteSelectOptions.value[0]
  countryButtons.value = []

  if (props.config.currentRegion) {
    foundRegion =
      availableSites.value.find((region) => region.name === siteSelectValue.value) || foundRegion
  }

  countrySelectOptions.value = foundRegion.countries.map((country) => {
    setCountryButtons(country)
    return country.name
  })
}
const setCountryButtons = (country) => {
  if (country.flag && country.languages && country.languages.length !== 0) {
    countryButtons.value.push(country)
  }
}
const initApiData = (data) => {
  apiData.value = data
  siteSelectValue.value = props.config.currentRegion
  countrySelectValue.value = props.config.currentCountry
  setSiteSelectOptions()
  setCountrySelectOptions()
  forceRerender()
}
const updateCountryButtons = (value) => {
  siteSelectValue.value = value
  setCountrySelectOptions()
}

onMounted(() => {
  if (window.currentCountry) {
    Storage.setSessionCookie(cookieName, window.currentCountry)
  }
  let dataUrl = ''
  window.shopRegionDataUrl ? (dataUrl = window.shopRegionDataUrl) : (dataUrl = regionDataUrl)

  if (dataUrl) {
    getShopRegionsFromUrl(dataUrl)
      .then((responseData) => initApiData(responseData))
      .catch((error) => console.error(error))
      .finally(() => (loading.value = false))
  } else {
    console.error('Error: Region data url is missing.')
  }
})

function findOffset() {
  const parentPosition = shopCountrySwitcherRef.value?.getBoundingClientRect().left || 0
  const containerPosition =
    document.querySelector('.ShopPageHeader__container')?.getBoundingClientRect().left || 0
  return Math.round(containerPosition) - Math.round(parentPosition)
}
</script>

<style module lang="scss">
@import '../CountrySwitcher/CountrySwitcher.scss';
</style>
