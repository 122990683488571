<template>
  <div class="ContentTabs__container">
    <h2 class="SubPage__subHeading">
      {{ config.title }}
    </h2>
    <div v-if="!isMobile" class="ContentTabs__tabs">
      <a
        v-for="(tab, index) in config.tabs.length"
        :key="`${index}-aKey`"
        :class="[
          'ContentTabs__tab',
          { ContentTabs__active: activeTab === tab },
          { ContentTabs__disabled: config.tabs[index].disabled }
        ]"
        @click="(e) => updateTabs(e, tab)"
      >
        {{ config.tabs[index].title }}
      </a>
      <div
        v-for="(tab, index) in config.tabs.length"
        :key="`${index}-bKey`"
        :class="['ContentTabs__content', { ContentTabs__activeContent: activeTab === tab }]"
      >
        <template v-if="activeTab === tab">
          <NewsletterSubscribe v-if="tab === 1" :config="config.tabs[index].formConfig" />
          <NewsletterUnsubscribe v-if="tab === 2" :config="config.tabs[index].formConfig" />
        </template>
      </div>
    </div>
    <div v-if="isMobile" class="ContentTabs__tabs ContentTabs__mobile">
      <div v-for="(tab, index) in config.tabs.length" :key="`${index}-cKey`">
        <a
          v-if="!config.tabs[index].disabled"
          :class="[
            'ContentTabs__tab',
            { ContentTabs__active: activeTab === tab && mobileHidden !== tab }
          ]"
          @click="(e) => updateTabs(e, tab)"
        >
          {{ config.tabs[index].title }}
        </a>
        <div
          :class="[
            'ContentTabs__content',
            { ContentTabs__activeContent: activeTab === tab && !isMobile },
            {
              ContentTabs__activeContent: activeTab === tab && isMobile && mobileHidden !== tab
            }
          ]"
        >
          <template v-if="activeTab === tab">
            <NewsletterSubscribe v-if="tab === 1" :config="config.tabs[index].formConfig" />
            <NewsletterUnsubscribe v-if="tab === 2" :config="config.tabs[index].formConfig" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { checkIsMobile } from '../../utils/media'
import NewsletterSubscribe from './NewsletterSubscribe.vue'
import NewsletterUnsubscribe from './NewsletterUnsubscribe.vue'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const isMobile = ref(false)
const activeTab = ref(null)
const mobileHidden = ref(null)
const activeTabPreselected = ref(false)

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const initTabs = () => {
  for (const [index, tab] of props.config.tabs.entries()) {
    if (tab.active && !tab.disabled) {
      activeTab.value = index + 1
      activeTabPreselected.value = true
    }
  }
  if (!activeTabPreselected.value) {
    activeTab.value = 1
  }
}

const updateTabs = (e, tab) => {
  const elm = e.currentTarget
  if (!elm.classList.contains('ContentTabs__disabled')) {
    if (isMobile.value && activeTab.value === tab && activeTab.value !== mobileHidden.value) {
      mobileHidden.value = tab
    } else if (
      isMobile.value &&
      activeTab.value === tab &&
      activeTab.value === mobileHidden.value
    ) {
      mobileHidden.value = null
    } else if (isMobile.value && activeTab.value !== tab) {
      mobileHidden.value = null
      activeTab.value = tab
    } else {
      activeTab.value = tab
    }
  }
}

onMounted(() => {
  window.addEventListener('resize', checkScreenSize)
  checkScreenSize()
  initTabs()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkScreenSize)
})
</script>
