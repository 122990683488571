<template>
  <div
    v-if="reactiveConfig.newConfigurator"
    :class="[$style.container, $style.newConfigurator]"
    @click="handleInitWithWikaProdConfNew()"
  />
  <div v-else :class="$style.container">
    <div :class="$style.hint">
      <strong>{{ reactiveConfig.hintLabel }}</strong>
      <span>{{ reactiveConfig.hintText }}</span>
    </div>
    <div
      v-if="reactiveConfig.modelcodes.length > 1"
      :class="[$style.radios, 'columns', 'is-multiline']"
    >
      <div :class="['column', 'is-full']">
        <strong>{{ reactiveConfig.pleaseChooseText }}</strong>
      </div>
      <div
        v-for="(code, index) in reactiveConfig.modelcodes"
        :key="code"
        :class="['column', 'is-half']"
      >
        <div :class="$style.radio">
          <span>
            <input
              :id="'modelcode' + index"
              v-model="reactiveConfig.modeloritemid"
              type="radio"
              :class="$style.orderDataItem"
              :value="code"
              name="modelcode"
              @change="init(reactiveConfig)"
            />
            <label :for="'modelcode' + index">
              {{ reactiveConfig.additionalTexts[index] }}
            </label>
          </span>
        </div>
      </div>
    </div>
    <div :class="$style.app" />
  </div>
</template>

<script setup>
import CustomEvents from '../../globals/constants/customEvents'
import addItemToUrl from '../../xhr/shopCart/addItemToUrl'
import getQuoteIdFromUrl from '../../xhr/shopCart/getQuoteIdFromUrl'

import { onMounted, ref } from 'vue'

const wtTrack = window.wtTrack

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  shopConfig: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['closed'])

const delay = ref(1000)
const reactiveConfig = ref(JSON.parse(JSON.stringify(props.config)))

const initNew = (localConfig) => {
  window.onWebConfiguratorClosedOkToShopNew = (data, close, closeAll) => {
    let urlParams = ''
    let articleID = ''
    let buildID = ''

    if (!data.ItemInformation.Item.IsOrmigNumber) {
      articleID = data.ItemInformation.Item.ItemId
      urlParams = '?itemid=' + articleID
    } else {
      //getBuildID
      buildID = data.BuildId
      urlParams = '?buildid=' + buildID
    }
    // add delay to prevent NS_BINDING_ABORTED in firefox
    window.setTimeout(() => {
      window.location.href =
        localConfig.shopUrl +
        (typeof urlParams !== 'undefined' && urlParams !== '' ? urlParams : '')
    }, delay.value)
    close.close()
    closeAll()
  }

  // Finished configuration
  window.onWebConfiguratorClosedOkNew = (data, close, closeAll) => {
    const deliveryTime = parseInt(data.ItemInformation.DeliveryInformation.DeliveryTime, 10)
    const externalTransport = parseInt(
      data.ItemInformation.DeliveryInformation.ExternalTransport,
      10
    )
    const deliveryTimeSum =
      (isNaN(deliveryTime) ? 0 : deliveryTime) + (isNaN(externalTransport) ? 0 : externalTransport)

    getQuoteIdFromUrl(window.getQuoteIdUrl)
      .then((quoteId) => {
        addItemToUrl(window.addItemUrl, {
          itemid: data.ItemInformation.Item.ItemId,
          quantity: Number(data.Quantity || 1),
          country: window.currentCountry,
          language: window.currentLanguage,
          quoteid: quoteId,
          headline: props.shopConfig.headline,
          subheadline: props.shopConfig.subheadline,
          pictureurl: props.shopConfig.pictureurl,
          thumbnailurl: props.shopConfig.thumbnailurl,
          producturl: props.shopConfig.producturl,
          btype: data.ItemInformation.ModelcodeObj.map(
            (codeObjItem) => codeObjItem.TypeObject.CodeType
          ),
          codestring: data.ItemInformation.ModelcodeObj.map(
            (codeObjItem) => codeObjItem.TypeObject.CodeString
          ),
          price: data.ItemInformation.Pricing.NetPrice,
          priceSum: data.ItemInformation.Pricing.PositionPrice,
          priceInstanceSum: data.ItemInformation.Pricing.PositionPrice,
          grossPrice: data.ItemInformation.Pricing.GrossPrice,
          grossPriceUnit: data.ItemInformation.Pricing.GrossPriceUnit,
          deliveryTime: deliveryTimeSum,
          deliveryCategory: data.ItemInformation.DeliveryInformation.DeliveryCategoryInt,
          // ? individualPrice extra
          productType: props.shopConfig.productType || data.ItemInformation.Item.ProductType,
          isLoggedIn: Boolean(window.logIn),
          discount: window.logIn?.discount,
          typesExcludedDiscount: window.logIn?.typesExcludedDiscount
        })
          .then((response) => {
            const event = new CustomEvent(CustomEvents.ADD_TO_CART, {
              detail: response.data
            })
            window.dispatchEvent(event)
            emit('closed')
            //Close configurator at the moment
            close.close()
            closeAll()
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
        console.error(error)
      })
  }
  // Find similar products
  window.onWebConfiguratorClosedOkToWebformNew = function (data, close, closeAll) {
    let codeStrings = ''
    let codeNames = ''
    let articleID = ''
    let descText = ''
    if (data.CodeComplete) {
      let codes = data.ItemInformation.ModelcodeObj
      let c = 0
      for (c = 0; c < codes.length; c++) {
        if (c != codes.length - 1) {
          codeStrings += codes[c].TypeObject.CodeString + ','
          codeNames += codes[c].TypeObject.CodeType + ','
        } else {
          codeStrings += codes[c].TypeObject.CodeString
          codeNames += codes[c].TypeObject.CodeType
        }
      }
      if (!data.ItemInformation.Item.IsOrmigNumber) {
        articleID = data.ItemInformation.Item.ItemId
      }
      descText = data.ItemInformation.Description.LongText
    } else {
      let codes = data.CurrentCoding
      let c = 0
      for (c = 0; c < codes.length; c++) {
        codeStrings += codes[c].ContentString
        codeNames += codes[c].CodeTypeId
      }
      descText = data.CurrentDescriptionLong
    }
    let codeQueryString = '?codeNames=' + codeNames + '&codes=' + codeStrings

    if (articleID == '' || articleID == 'undefined') {
      articleID = codeStrings
    }
    const event = new CustomEvent(CustomEvents.ADD_TO_LOCAL_STORAGE, {
      detail: {
        id: articleID,
        detailText: descText
      }
    })
    window.dispatchEvent(event)
    // add delay to prevent NS_BINDING_ABORTED in firefox
    window.setTimeout(() => {
      window.location.href = localConfig.orderFormUrl + codeQueryString + '&article=' + articleID
    }, delay.value)
    close.close()
    closeAll()
  }

  const ibt_btn_config = {
    label: localConfig.button_datasheet_label,
    class: 'blue',
    icon: 'download',
    callbackInternal: 'getDatasheet',
    disabledWhileIncomplete: false
  }
  const toShop_btn_config = {
    label: localConfig.button_enquiry_label,
    class: 'blue',
    icon: 'quit',
    callbackExternal: 'onWebConfiguratorClosedOkToWebformNew',
    pregenerateBuildId: true,
    disabledWhileIncomplete: false,
    overwrites: {
      conditions: {
        isComplete: true,
        hasValidTermination: true,
        deliveryCategories: [1, 2, 3, 4]
      },
      values: {
        label: localConfig.button_to_shop_label,
        icon: 'quit',
        class: '',
        callbackExternal: 'onWebConfiguratorClosedOkToShopNew',
        disabledWhileIncomplete: true,
        pregenerateBuildId: false
      }
    }
  }

  const toWebform_btn_config = {
    label: localConfig.button_enquiry_label,
    class: 'blue',
    icon: 'quit',
    callbackExternal: 'onWebConfiguratorClosedOkToWebformNew',
    disabledWhileIncomplete: false
  }

  const reset_btn_config = {
    label: localConfig.button_reset_label,
    class: 'red',
    icon: 'reset',
    disabledWhileIncomplete: false,
    callbackInternal: 'resetConfiguration'
  }
  const help_btn_config = {
    label: localConfig.button_help_label,
    class: 'blue',
    icon: 'help',
    callbackInternal: 'helpModel',
    showSeparatorAbove: true
  }
  const addBasket_btn_config = {
    label: localConfig.primaryButton_label,
    icon: 'cart',
    class: '',
    callbackExternal: 'onWebConfiguratorClosedOkNew',
    disabledWhileIncomplete: true
  }
  let primaryBtn = {}
  if (window.instance === 'shop') {
    primaryBtn = addBasket_btn_config
  } else {
    primaryBtn = toShop_btn_config
  }

  const more_btn_config = []
  if (localConfig.display_ibd_btn) {
    more_btn_config.push(ibt_btn_config)
  }
  if (localConfig.display_enquiry_btn && window.instance === 'website') {
    more_btn_config.push(toWebform_btn_config)
  }
  more_btn_config.push(help_btn_config)
  more_btn_config.push(reset_btn_config)

  let idvalue = ''
  let model_variants = localConfig.model_variants
  if (localConfig.model_variants.length == 1) {
    idvalue = localConfig.model_variants[0].model
    model_variants = []
  }
  if (localConfig?.modelcodeobj?.length > 0) {
    model_variants = []
    idvalue = localConfig.modelcodeobj.find(Boolean).TypeObject.CodeType
  }
  let isPricingAllowed = window.instance === 'shop' ? true : false

  const configValue = {
    lng: window.currentLanguage.toLowerCase(),
    langAPI: localConfig.language.toLowerCase(),
    config: {
      LiveEnvPath: localConfig.webservice_url,
      buildIdPath: localConfig.buildidpath,
      getBuildIdValues: localConfig.getbuildidvalues,
      optionGraphicSettings: localConfig.publicShareBaseUrl,
      mailSupport: localConfig.mailSupport,
      hotlinePhone: localConfig.hotlinePhone,
      qrURI: localConfig.qrURI,
      qrIdURI: localConfig.qrIdURI,
      company: localConfig.copyright,
      welcomeMessage: '',
      cadenasBaseUrl: localConfig.cadenasBaseUrl
    },
    'data-config': {
      hideAssemblies: true,
      Id: idvalue,
      modelVariants: model_variants,
      modelCodeObj: localConfig.modelcodeobj,
      maxQty: localConfig.maxRangeQuantity,
      minQty: 0,
      //buildId: "XXXXXXX",
      /*
      modelCodeObj:[
              {
                "TypeObject":
                  {
                    "CodeType":"100.XX",
                    "CodeString":"100.01D-BG310   -A  U-AJZZ -A1ZZZZZZZZZ-",
                    "FreeTexts":[]
                  }
              },
              {
                "TypeObject":
                {
                  "CodeType":"LABEL",
                  "CodeString":"LABEL-E01DE-ZZZZZ",
                  "FreeTexts":[]
                }
              }
          ],
      */
      displayDeviceController: localConfig.displayDeviceController,
      web3dViewerUrl: localConfig.web3dViewerUrl,
      langPath: localConfig.langPath,
      contentStringType: localConfig.contentStringType,
      showAdministratorWidget: localConfig.showadminwidget,
      autoUpdateDrawings: localConfig.autoupdatedrawings,
      displayDispatchInformation: localConfig.display_despatch_information,
      isDeliveryDateExp: localConfig.is_delivery_date_exp,
      stockOnHandVisible: localConfig.stockOnHandVisible,
      shippingDetailUrl: window.shippingURL,
      customerNumber: localConfig.customer_number,
      loginName: localConfig.loginname,
      isLoggedIn: window.configuratorLoggedIn,
      websessionId: localConfig.websessionid,
      environment: localConfig.environment,
      //tenant: localConfig.erp_tenant,
      //region: localConfig.region,
      //qrcode: { mode: 'url', size: 400 },
      zIndex: parseInt(localConfig.zIndex),
      isPriceDetailsVisible: localConfig.display_price_details,
      displayViewSwitch: localConfig.display_view_switch,
      supportEmail: localConfig.support_email,
      supportPhone: localConfig.support_phone,
      supportChat: localConfig.support_chat,
      showPricing: isPricingAllowed,
      showOptionId: localConfig.showOptionId
    },
    'data-action-buttons': {
      primaryButton: primaryBtn,
      moreOptionButtonActions: more_btn_config
    }
  }
  window.Configurator.open(configValue)
}

const init = (localConfig) => {
  // Online-Shop
  window.onWebConfiguratorClosedOkToShop = (data) => {
    let articleID = ''
    if (!data.ExtendedInformation.ItemInformation.Item.IsOrmigNumber) {
      articleID = data.ExtendedInformation.ItemInformation.Item.ItemId
    }
    // add delay to prevent NS_BINDING_ABORTED in firefox
    window.setTimeout(() => {
      window.location.href =
        localConfig.shopUrl +
        (typeof articleID !== 'undefined' && articleID !== '' ? '?itemid=' + articleID : '')
    }, delay.value)
  }
  // Finished configuration
  window.onWebConfiguratorClosedOk = (data) => {
    const deliveryTime = parseInt(data.ItemInformation.DeliveryInformation.DeliveryTime, 10)
    const externalTransport = parseInt(
      data.ItemInformation.DeliveryInformation.ExternalTransport,
      10
    )
    const deliveryTimeSum =
      (isNaN(deliveryTime) ? 0 : deliveryTime) + (isNaN(externalTransport) ? 0 : externalTransport)

    getQuoteIdFromUrl(window.getQuoteIdUrl)
      .then((quoteId) => {
        addItemToUrl(window.addItemUrl, {
          itemid: data.ItemInformation.Item.ItemId,
          quantity: Number(data.Quantity || 1),
          country: window.currentCountry,
          language: window.currentLanguage,
          quoteid: quoteId,
          headline: props.shopConfig.headline,
          subheadline: props.shopConfig.subheadline,
          pictureurl: props.shopConfig.pictureurl,
          thumbnailurl: props.shopConfig.thumbnailurl,
          producturl: props.shopConfig.producturl,
          btype: data.ItemInformation.ModelcodeObj.map(
            (codeObjItem) => codeObjItem.TypeObject.CodeType
          ),
          codestring: data.ItemInformation.ModelcodeObj.map(
            (codeObjItem) => codeObjItem.TypeObject.CodeString
          ),
          price: data.ItemInformation.Pricing.NetPrice,
          priceSum: data.ItemInformation.Pricing.PositionPrice,
          priceInstanceSum: data.ItemInformation.Pricing.PositionPrice,
          grossPrice: data.ItemInformation.Pricing.GrossPrice,
          grossPriceUnit: data.ItemInformation.Pricing.GrossPriceUnit,
          deliveryTime: deliveryTimeSum,
          deliveryCategory: data.ItemInformation.DeliveryInformation.DeliveryCategoryInt,
          // ? individualPrice extra
          productType: props.shopConfig.productType || data.ItemInformation.Item.ProductType,
          isLoggedIn: Boolean(window.logIn),
          discount: window.logIn?.discount,
          typesExcludedDiscount: window.logIn?.typesExcludedDiscount
        })
          .then((response) => {
            const event = new CustomEvent(CustomEvents.ADD_TO_CART, {
              detail: response.data
            })
            window.dispatchEvent(event)
            emit('closed')
          })
          .catch((error) => {
            console.error(error)
          })
      })
      .catch((error) => {
        console.error(error)
      })
  }
  // Find similar products
  window.onWebConfiguratorClosedOkToWebform = function (data) {
    let codes = data.CurrentState.CurrentCoding
    let codeStrings = ''
    let codeNames = ''
    let c = 0
    for (c = 0; c < codes.length; c++) {
      if (c != codes.length - 1) {
        codeStrings += codes[c].ContentString + ','
        codeNames += codes[c].CodeTypeName + ','
      } else {
        codeStrings += codes[c].ContentString
        codeNames += codes[c].CodeTypeName
      }
    }
    let codeQueryString = '?codeNames=' + codeNames + '&codes=' + codeStrings
    let articleID = ''
    if (!data.ExtendedInformation.ItemInformation.Item.IsOrmigNumber) {
      articleID = data.ExtendedInformation.ItemInformation.Item.ItemId
    }
    if (articleID == '' || articleID == 'undefined') {
      articleID = codeStrings
    }
    const event = new CustomEvent(CustomEvents.ADD_TO_LOCAL_STORAGE, {
      detail: {
        id: articleID,
        detailText: data.CurrentState.CurrentDescriptionLong
      }
    })
    window.dispatchEvent(event)
    // add delay to prevent NS_BINDING_ABORTED in firefox
    window.setTimeout(() => {
      window.location.href = localConfig.orderFormUrl + codeQueryString + '&article=' + articleID
    }, delay.value)
  }
  // Initialize configurator
  window.WikaProdConf.initProduktConfigurator(
    {
      containerselector: '.Configurator__app',
      baseUrl: localConfig.baseUrl,
      optionGraphicSettings: {
        enabled: true,
        publicShareBaseUrl: localConfig.publicShareBaseUrl
      },
      webservice_url: localConfig.webservice_url,
      language: localConfig.language,
      loginname: localConfig.loginname,
      websessionid: localConfig.websessionid,
      plannumber: localConfig.plannumber,
      maxRangeQuantity: localConfig.maxRangeQuantity,

      showtypecode: localConfig.showtypecode,
      showtexts: localConfig.showtexts,
      showadminwidget: localConfig.showadminwidget,

      visualmode: localConfig.visualmode,
      showIbDLink: localConfig.showIbDLink,
      disableExtBtn1: !window.shopAvailable || window.instance === 'shop',
      allowincomplete: localConfig.allowincomplete,
      hastagnumbers: localConfig.hastagnumbers,
      allowcodechange: localConfig.allowcodechange,
      allowgroupmode: localConfig.allowgroupmode,
      modeloritemid: localConfig.modeloritemid,
      modelcodeobj: localConfig.modelcodeobj,
      button_definitions: [
        {
          text: 'resume',
          className: 'primary',
          disabledWhileIncomplete: true,
          onClick: function (app) {
            const res = app.getResults()
            res
              .then(function (data) {
                window.onWebConfiguratorClosedOk(data)
              })
              .fail((error, data) => console.error(error, data))
          }
        }
      ]
    },
    true
  )
}

const getParameter = (param) => {
  const params = window.location.search.substr(1).split('&')

  for (const element of params) {
    const p = element.split('=')

    if (p[0] == param) {
      return decodeURIComponent(p[1])
    }
  }
  return false
}

//Old Configurator
const handleInitWithWikaProdConf = () => {
  if (window.WikaProdConf) {
    init(reactiveConfig.value)
  } else {
    const timeoutForWikaProdConf = setTimeout(() => {
      handleInitWithWikaProdConf()
      clearTimeout(timeoutForWikaProdConf)
    }, 500)
  }
}

//New Configurator with new JS Libary
const handleInitWithWikaProdConfNew = () => {
  if (window.Configurator) {
    // Check if a code/configuratin is provided by url
    let initByCode = false
    let codeString = getParameter('codestring')
    if (codeString) {
      initByCode = true
      let codeType = getParameter('codetype')
      reactiveConfig.value.modelcodeobj = [
        {
          TypeObject: {
            CodeType: codeType || '',
            CodeString: codeString
          }
        }
      ]
    }
    // Check if multiple modelcodes were provided - if not initialize directly
    if (reactiveConfig.value.model_variants.length == 1 || initByCode) {
      reactiveConfig.value.Id = initByCode ? '' : reactiveConfig.value.model_variants[0].model
    }
    initNew(reactiveConfig.value)
  } else {
    const timeoutForWikaProdConf = setTimeout(() => {
      handleInitWithWikaProdConfNew()
      clearTimeout(timeoutForWikaProdConf)
    }, 500)
  }
}

onMounted(() => {
  // Check if a code/configuratin is provided by url
  let initByCode = false
  let codeString = getParameter('codestring')
  if (codeString) {
    initByCode = true
    let codeType = getParameter('codetype')
    reactiveConfig.value.modelcodeobj = [
      {
        TypeObject: {
          CodeType: codeType || '',
          CodeString: codeString
        }
      }
    ]
  }

  // Check if multiple modelcodes were provided - if not initialize directly
  if (reactiveConfig.value.modelcodes.length == 1 || initByCode) {
    reactiveConfig.value.modeloritemid = initByCode ? '' : reactiveConfig.value.modelcodes[0]
    if (!reactiveConfig.value.newConfigurator) {
      handleInitWithWikaProdConf()
    }
  }

  if (reactiveConfig.value.newConfigurator) {
    const scripts = ['/shared/configurator/configurator.js']
    scripts.forEach((script) => {
      let tag = document.head.querySelector(`[src="${script}"`)
      if (!tag) {
        tag = document.createElement('script')
        tag.setAttribute('defer', 'defer')
        tag.setAttribute('src', script)
        tag.setAttribute('type', 'text/javascript')
        document.head.appendChild(tag)
      }
    })

    window.addEventListener('buttonClickStartChat', function (event) {
      if (event.detail !== undefined) {
        if (window?.Microsoft?.Omnichannel?.LiveChatWidget?.SDK?.startChat) {
          setTimeout(() => {
            window.Microsoft.Omnichannel.LiveChatWidget.SDK.startChat()
            wtTrack({ cat: 'LIVECHATBUTTONCLICK' })
            event.detail.close()
            return false
          }, 100)
        }
      }
    })

    let tabParam = getParameter('tab')
    if (tabParam && tabParam.includes('configurator')) {
      handleInitWithWikaProdConfNew()
    }
  }
})
</script>

<style module lang="scss">
@import './Configurator.scss';
</style>
