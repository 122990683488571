<template>
  <div
    :class="[
      $style.button,
      {
        [$style.buttonStandAlone]: languages.length === 1
      }
    ]"
    @click="languages.length === 1 && openUrl(languages[0].url)"
  >
    <span :class="$style.inactive">
      <Flag :flag="flag" size="md" :class="$style.flag" />
      {{ country }}
    </span>
    <span :class="$style.active"
      >{{ label }}
      <a
        v-for="(language, index) in languages"
        :key="index"
        :href="language.url"
        :title="language.title"
      >
        <Flag :flag="language.flag" size="xs" :class="$style.flag" />
      </a>
      {{ country }}
    </span>
  </div>
</template>

<script setup>
import Flag from '../Flag/Flag.vue'

defineProps({
  languages: {
    type: Array,
    default: () => []
  },
  country: {
    type: String,
    default: ''
  },
  flag: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  }
})

const openUrl = (url) => {
  window.location.href = url
}
</script>

<style module lang="scss">
@import './CountryButton.scss';
</style>
