<template>
  <div :id="recaptchaId" data-size="invisible" />
</template>

<script setup>
import { nanoid } from 'nanoid'
import { onMounted, ref, watch } from 'vue'

const props = defineProps({
  badge: {
    type: String,
    default: 'bottomleft'
  },
  submit: {
    type: Function,
    required: true
  },
  executeSwitch: {
    type: Boolean,
    required: true
  }
})

const widgetId = ref('')

const recaptchaKey = '6Le-pKIUAAAAAHbqlNrc3ZOYf2ilCoAnLvLZ0SGY'
const recaptchaId = `invisible-recaptcha-${nanoid()}`

onMounted(() => {
  if (typeof window.grecaptcha === 'undefined') {
    const script = document.createElement('script')
    script.src = 'https://www.google.com/recaptcha/api.js'
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    script.onload = callRender
    document.head.appendChild(script)
  } else {
    callRender()
  }
})

const render = () => {
  widgetId.value = window.grecaptcha.render(recaptchaId, {
    sitekey: recaptchaKey,
    size: 'invisible',
    badge: props.badge,
    callback: (token) => {
      props.submit(token)
      window.grecaptcha.reset(widgetId.value)
    }
  })
}

const callRender = () => {
  const tout = setTimeout(() => {
    if (typeof window.grecaptcha !== 'undefined' && window.grecaptcha.render) {
      render()
    } else {
      callRender()
    }
    clearTimeout(tout)
  }, 200)
}

const execute = () => {
  const tout = setTimeout(() => {
    if (
      typeof window.grecaptcha !== 'undefined' &&
      window.grecaptcha.execute &&
      (widgetId.value || widgetId.value === 0)
    ) {
      window.grecaptcha.execute(widgetId.value)
    } else {
      execute()
    }
    clearTimeout(tout)
  }, 200)
}

watch(
  () => props.executeSwitch,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      execute()
    }
  }
)
</script>
