<template>
  <figure :class="$style.container" @click="emit('clicked')">
    <a v-if="primaryLink" :class="$style.mediaLink" :href="primaryLink">
      <img :src="image" alt="" />
    </a>
    <a v-else :class="$style.mediaLink">
      <img :src="image" alt="" />
    </a>
    <figcaption>
      <a v-if="primaryLink" :href="primaryLink">
        <span>{{ title }}</span>
        <div v-if="copy">
          {{ copy }}
        </div>
      </a>
      <a v-else>
        <span>{{ title }}</span>
        <div v-if="copy">
          {{ copy }}
        </div>
      </a>
    </figcaption>
  </figure>
</template>

<script setup>
const emit = defineEmits(['clicked'])
defineProps({
  image: {
    type: String,
    default: ''
  },
  title: {
    type: String,
    default: ''
  },
  copy: {
    type: String,
    required: false,
    default: ''
  },
  primaryLink: {
    type: String,
    default: ''
  }
})
</script>

<style module lang="scss">
@import './ContentOverviewTeaser.scss';
</style>
