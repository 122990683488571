<template>
  <div
    id="textTeaserContainer_"
    ref="textTeaserContainer"
    :class="[$style.container, { TextTeaser__static: isStatic }]"
  >
    <h2 :class="$style.head">
      {{ head }}
    </h2>
    <div
      id="textTeaserText_"
      ref="textTeaserText"
      :class="[
        $style.text,
        { TextTeaser__shadow: !isInnerContainerSmaller },
        { TextTeaser__hideShadow: isContainerFullWidth }
      ]"
    >
      <div
        id="textTeaserOverflow_"
        :class="$style.textOverflow"
        :style="
          isContainerFullWidth || initialTeaserTextHeight === 0
            ? ''
            : `overflow: hidden; height:${initialTeaserTextHeight}px`
        "
      >
        <div id="textTeaserTextInner_" ref="textTeaserTextInner">
          {{ text }}
        </div>
        <div
          v-if="!isInnerContainerSmaller || isContainerFullWidth"
          :class="$style.more"
          @click="setContainerFullWidth"
        >
          <div>
            <span v-text="isContainerFullWidth ? ' weniger' : 'mehr'" />
            <i v-if="isContainerFullWidth" class="fas fa-chevron-up" />
            <i v-else class="fas fa-chevron-down" />
          </div>
        </div>
      </div>
    </div>
    <div :class="$style.docLinkContainer">
      <a v-for="(link, index) in links" :key="index" :class="$style.docLink" href="#"
        >{{ link }}
      </a>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { checkIsMobile } from '../../utils/media'

defineProps({
  head: String,
  text: String,
  links: {
    type: Array,
    default: () => []
  },
  isStatic: Boolean
})

const isMobile = ref(false)
const isInnerContainerSmaller = ref(true)
const isContainerFullWidth = ref(false)
const tout = ref(null)
const textTeaserContainer = ref(null)
const textTeaserTextInner = ref(null)
// unused const textTeaserOverflow = ref(null)
const textInner = ref(null)
const initialTeaserTextHeight = ref(0)

onMounted(() => {
  window.addEventListener('resize', resizeHandler, false)
  checkScreenSize()
  setOverflowElements()
  initialTeaserTextHeight.value = getOverflowHeight()
})

onUpdated(() => {
  if (tout.value === null) {
    resizeHandler()
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler, false)
})

const getOverflowHeight = () => {
  const lineHeight = 19
  const lines = parseInt(textTeaserTextInner.value.offsetHeight / lineHeight)
  const height = lines * lineHeight
  return height
}

const checkScreenSize = () => (isMobile.value = checkIsMobile())

const resizeHandler = () => {
  clearTimeout(tout.value)
  initialTeaserTextHeight.value = 0

  tout.value = setTimeout(() => {
    checkScreenSize()
    setOverflowElements()
    if (isContainerFullWidth.value) {
      setContainerSize()
    }
    initialTeaserTextHeight.value = getOverflowHeight()
  }, 100)
}

const setOverflowElements = () => {
  if (textTeaserTextInner.value && textInner.value) {
    isInnerContainerSmaller.value =
      textInner.value.clientHeight < textTeaserTextInner.value.clientHeight
  }
}
const findColumnsElement = () => {
  let $containerParent = textTeaserContainer.value.parentElement
  for (let i = 0; i < 5; i++) {
    if ($containerParent.className.indexOf('columns') >= 0) {
      return $containerParent
    } else {
      $containerParent = $containerParent.parentElement
    }
  }
  return null
}

const setContainerFullWidth = () => {
  isContainerFullWidth.value = !isContainerFullWidth.value
  setContainerSize()
}

const setContainerSize = () => {
  const columnsContainer = findColumnsElement()
  if (columnsContainer && isContainerFullWidth.value) {
    if (!isMobile.value) {
      // take columnsContainer.clientWidth + 1 because on some devices columns clientWidth is not the full length
      textTeaserContainer.value.style.width = `${columnsContainer.offsetWidth + 1}px`
    }
    if (
      textTeaserTextInner.value &&
      textInner.value &&
      textInner.value.clientHeight > textTeaserTextInner.value.clientHeight
    ) {
      textTeaserTextInner.value.style.maxHeight = 'none'
      if (!isMobile.value) {
        textTeaserContainer.value.style.height = `${
          textInner.value.clientHeight +
          (textTeaserContainer.value.clientHeight - textTeaserTextInner.value.clientHeight)
        }px`
      } else {
        textTeaserContainer.value.style.height = `${
          textInner.value.clientHeight +
          (textTeaserContainer.value.clientHeight - textTeaserTextInner.value.clientHeight + 30)
        }px`
      }
    }
  } else {
    textTeaserContainer.value.style.width = null
    textTeaserContainer.value.style.height = null
    textTeaserTextInner.value.style.maxHeight = null
  }
}
</script>

<style module lang="scss">
@import './TextTeaser.scss';
</style>
