<template>
  <figure :class="$style.container">
    <a
      v-if="image"
      :class="$style.mediaLink"
      :href="secondaryLink"
      :target="secondaryLinkNewWindow ? '_blank' : '_self'"
    >
      <img :src="image" :alt="category" />
    </a>
    <figcaption>
      <div class="columns is-multiline">
        <a
          :class="[$style.secondaryLink, 'column']"
          :href="secondaryLink"
          :target="secondaryLinkNewWindow ? '_blank' : '_self'"
          >{{ category }}</a
        >
        <section
          v-for="(faq, index) in faqs"
          :key="index"
          :class="['column', { FAQTeaser__isRow: faqs.length === 3 }]"
        >
          <h3>{{ faq.title }}</h3>
          <div>
            {{ faq.text }}
            <a
              v-if="faq.moreLink"
              :class="$style.more"
              :href="faq.primaryLink"
              :target="faq.primaryLinkNewWindow ? '_blank' : '_self'"
            >
              {{ dictionaryMore }}
              <i class="fas fa-chevron-right" />
            </a>
          </div>
        </section>
      </div>
    </figcaption>
  </figure>
</template>

<script setup>
defineProps({
  faqs: {
    type: Array,
    default: () => []
  },
  secondaryLink: {
    type: String,
    default: ''
  },
  secondaryLinkNewWindow: {
    type: Boolean,
    default: true
  },
  category: {
    type: String,
    default: ''
  },
  image: {
    type: String,
    default: ''
  }
})

const dictionaryMore = window.dictionary.more
</script>

<style module lang="scss">
@import './FAQTeaser.scss';
</style>
