<template>
  <InfoWindow
    :options="{
      position
    }"
    @closeclick="emit('closeclick')"
  >
    <div v-if="details" :class="$style.container">
      <img v-if="details.image" :src="details.image" :class="$style.image" />
      <div :class="$style.detail">
        <strong
          v-if="details.firstname"
          :class="$style.space"
          v-html="details.firstname + '&nbsp'"
        />
        <strong v-if="details.lastname" :class="$style.space" v-text="details.lastname" />
        <div v-if="details.position" :class="$style.space" v-text="details.position" />
        <strong v-if="details.company" :class="$style.space" v-text="details.company" />
        <div
          v-if="details.address"
          :class="$style.space"
          v-html="details.address.replace(/\n/g, '<br />')"
        />
        <a v-if="details.email" :href="`mailto:${details.email}`">
          {{ details.email }}
        </a>
        <a v-if="details.phone" :href="`tel:${details.phone}`">
          {{ details.phone }}
        </a>
        <a v-if="details.detailsLink" @click="displayDetails">
          {{ details.detailsLink }}
        </a>
        <a
          v-if="details.jobOffersLink && isJobOfferAvailable"
          :href="details.jobOffersUrl"
          target="_blank"
        >
          {{ details.jobOffersLink }}
        </a>
        <a v-if="useForm && details.formEmail" :href="prepareContactFormUrl(details)">
          {{ dynamicFormLabel }}
        </a>
      </div>
    </div>
  </InfoWindow>
</template>

<script setup>
import axios from 'axios'
import { InfoWindow } from 'vue3-google-map'
import { computed, ref } from 'vue'
import { prepareContactFormUrl } from '../../utils/form'

const props = defineProps({
  position: {
    type: Object,
    default: () => undefined
  },
  opened: {
    type: Boolean,
    default: false
  },
  details: {
    type: Object,
    default: () => {}
  },
  options: {
    type: Object,
    default: () => {}
  },
  useForm: {
    type: Boolean,
    default: false
  },
  jobOfferJSONURL: {
    type: String,
    default: ''
  },
  filterKeyValue: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['displayDetails', 'closeclick'])

const dynamicFormLabel = window.dictionary.dynamicForm
const jobOfferAvailable = ref(false)
const jobData = ref(null)

const displayDetails = () => {
  emit('displayDetails', props.details.detailsUrl)
}

const jobOffersAvailable = (url, filterKey, itemValue) => {
  if (itemValue) {
    axios
      .get(url)
      .then((response) => {
        jobData.value = response.data.items.filter(
          (item) => item.filterCategories[filterKey] === itemValue
        )
        jobOfferAvailable.value = false
        if (jobData.value != null && jobData.value.length > 0) {
          jobOfferAvailable.value = true
        }
      })
      .catch((error) => {
        console.error(error)
        return false
      })
  } else {
    jobOfferAvailable.value = true
  }
}

const isJobOfferAvailable = computed(() => {
  jobOffersAvailable(props.jobOfferJSONURL, props.filterKeyValue, props.details.jobMappingValue)
  return jobOfferAvailable.value
})
</script>

<style module lang="scss">
@import './MapInfoWindow.scss';
</style>
