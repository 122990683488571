import { createApp } from 'vue'
import App from './App.vue'
import VueTippy from 'vue-tippy'

export default function init() {
  const elements = document.querySelectorAll('.downloadBox')

  if (elements.length) {
    elements.forEach((element) => {
      const app = createApp(App, { ...element.dataset })

      app.use(VueTippy)
      app.mount(`#${element.id}`)
    })
  }
}
