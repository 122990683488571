import { createApp } from 'vue'
import App from './App.vue'

export default function init() {
  const elements = document.querySelectorAll('.wikaBtn')

  if (elements.length) {
    elements.forEach((element) => {
      const app = createApp(App, {
        ...element.dataset
      })

      app.mount(`#${element.id}`)
      /*
      //We will open configurator directly if no detail view is available?
      if (JSON.parse(document.getElementById('productDetailTabs')?.dataset?.config).findIndex((tab) => !tab.disabled) <= 0
          && document.getElementsByClassName('Configurator__container').length > 0)
      {
        document.getElementsByClassName('Configurator__container')[0].click()
      }
      */
    })
  }
}
