const Category = {
  DOWNLOAD: 'download',
  SHOP: 'shop',
  VIDEOS: 'videos',
  PRODUCTS: 'products',
  JOBS: 'joboffers'
}

const DocType = {
  BLOG: 'blog'
}

export { Category, DocType }
