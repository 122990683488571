<template>
  <a
    :class="[slide.url ? null : 'noLink']"
    :href="slide.url"
    :target="slide.newWindow ? '_blank' : '_self'"
    @click="slide.isShopLink && wtTrack({ cat: 'TOONLINESHOP' })"
  >
    <div
      v-if="heightFirst"
      :class="$style.bgImage"
      :style="{ backgroundImage: `url(${slide.image})` }"
    >
      <video v-if="slide.type === 'video'" muted autoplay loop>
        <source :src="slide.image" type="video/mp4" />
      </video>
      <img v-else :src="slide.image" :alt="slide.title || ''" />
    </div>
    <template v-else>
      <video v-if="slide.type === 'video'" muted autoplay loop>
        <source :src="slide.image" type="video/mp4" />
      </video>
      <img
        v-else
        :src="slide.image"
        :alt="slide.title || ''"
        :width="slide.imageWidth"
        :height="slide.imageHeight"
      />
    </template>
    <div
      v-if="slide.title || slide.text"
      :class="[
        { HeroSlider__caption__top: slide.captionTop },
        { HeroSlider__caption__left: slide.captionLeft },
        $style.caption
      ]"
    >
      <figcaption :class="$style.figCaption">
        <h1 v-if="index === 0" v-html="slide.title" />
        <h3 v-else v-html="slide.title" />
        <div :class="$style.captionText" v-html="slide.text" />
      </figcaption>
    </div>
  </a>
</template>

<script setup>
defineProps({
  slide: {
    type: Object,
    default: () => {}
  },
  heightFirst: {
    type: Boolean,
    default: false
  },
  index: {
    type: Number,
    default: 0
  }
})

const wtTrack = window.wtTrack
</script>

<style module lang="scss">
@import './HeroSlider.scss';
</style>
