<template>
  <div>
    <GoogleMap
      ref="map"
      api-key="AIzaSyCdM1m57HfyyDYNneMFZ_e8spr1PeSOpjM"
      :center="mapCenter"
      :zoom="zoom"
      :style="mapStyle"
      :options="mapOptions"
    >
      <GmapMarker
        v-if="currentPosition"
        :options="{
          position: currentPosition,
          clickable: false,
          icon: {
            url: getMarkerIcon(markerColour),
            scaledSize: { width: 24, height: 32 }
          }
        }"
      />
      <GmapMarker
        v-if="currentPosition"
        :options="{
          position: currentPosition,
          clickable: false,
          icon: {
            url: getPositionIcon(),
            scaledSize: { width: 32, height: 32 }
          }
        }"
      />

      <template #visible>
        <div v-if="!initializing" :class="$style.positionControl">
          <button
            ref="positionBtn"
            :disabled="loadingPosition"
            :class="{
              [$style.disabled]: !currentPositionAvailable
            }"
            @click="handlePositionIconClick"
          >
            <Loading
              v-if="loadingPosition"
              :class="$style.spinner"
              :size="20"
              background-color="transparent"
            />
            <PositionIcon v-else />
          </button>
        </div>
      </template>
    </GoogleMap>
  </div>
</template>

<script setup>
import { GoogleMap, Marker as GmapMarker } from 'vue3-google-map'
import { getMarkerIcon, getPositionIcon } from './helpers'
import PositionIcon from '../../assets/svg/Position.vue'
import Loading from '../Loading/Loading.vue'

import { computed, ref, watch } from 'vue'

const props = defineProps({
  minHeight: {
    type: String,
    default: '350px'
  },
  height: {
    type: String,
    default: '100%'
  },
  width: {
    type: String,
    default: '100%'
  },
  marker: {
    type: Object,
    default: () => {}
  },
  mapTypeId: {
    type: String,
    default: 'roadmap'
  },
  mapZoom: {
    type: Number,
    default: 10
  },
  geolocation: {
    type: Object,
    default: () => {}
  },
  markerColour: {
    type: String,
    default: ''
  }
})

const map = ref(null)
const initializing = ref(true)
const loadingPosition = ref(false)
const dirty = ref(false)
const mapCenter = ref(
  props.geolocation || {
    lat: -1,
    lng: -1
  }
)
const mapOptions = ref({
  disableDefaultUI: true,
  zoomControl: true
})

const currentPosition = ref(props.geolocation || undefined)
const zoom = ref(props.mapZoom)
const boundsChangedListener = ref(undefined)
const currentPositionAvailable = ref(true)

const mapStyle = computed(
  () => `height: ${props.height};width: ${props.width};min-height: ${props.minHeight};`
)

const initDragListener = () => {
  const gmap = map.value.map
  gmap.addListener('dragstart', () => {
    dirty.value = true
  })
}

const initZoomChangeListener = () => {
  const gmap = map.value.map
  gmap.addListener('zoom_changed', () => {
    zoom.value = gmap.getZoom()
  })
}

watch(
  () => props.geolocation,
  (newGeolocation) => (mapCenter.value = newGeolocation)
)

watch(
  () => map.value?.ready,
  (ready) => {
    if (ready) {
      const gmap = map.value.map
      initDragListener()
      initZoomChangeListener()
      boundsChangedListener.value = gmap.addListener('bounds_changed', () => {
        map.value.api.event.removeListener(boundsChangedListener.value)
        boundsChangedListener.value = null
      })
    }
  }
)
</script>
