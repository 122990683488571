<template>
  <div :class="$style.container">
    <div :class="$style.anchor" />
    <FormFilter :config="config" @filtered="displayResults" @has-results="displayPagination" />
    <div :class="[$style.results, 'columns is-multiline']">
      <div
        v-for="(result, index) in displayedResults"
        :key="index"
        :class="[
          'column',
          'is-half-tablet',
          'is-half-desktop',
          { [$style.highlightResults]: highlightResults && index < 2 }
        ]"
      >
        <ProductTeaser
          :result="result"
          :config="config.texts"
          :additional-url-param="additionalUrlParam"
        />
      </div>
    </div>
    <Pagination
      v-show="config.loadInitialData || hasResultsRef"
      :config="config.pagination"
      :results="results.length"
      @update="displayPaginatedResults"
    />
  </div>
</template>

<script setup>
import FormFilter from '../FormFilter/FormFilter.vue'
import ProductTeaser from '../ProductTeaser/ProductTeaser.vue'
import Pagination from '../Pagination/Pagination.vue'

import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const results = ref([])
const displayRange = ref([0, props.config.pagination.resultsPerPage - 1])
const displayedResults = ref([])
const hasResultsRef = ref(false)
const highlightResults = ref(false)
const productId = ref(null)

const additionalUrlParam = computed(() => {
  if (window.wtParamCollection && window.wtParamCollection['WT.i_overview']) {
    return `WT.cg_s=${window.wtParamCollection['WT.i_overview']}`
  }
  return ''
})

const displayPagination = (hasResults) => {
  hasResultsRef.value = hasResults
}

const displayResults = (newResults) => {
  results.value = newResults
  displayedResults.value = []
  for (let i = 0; i < results.value.length; i++) {
    if (i >= displayRange.value[0] && i <= displayRange.value[1]) {
      displayedResults.value.push(results.value[i])
    } else if (i > displayRange.value[1]) {
      break
    }
  }

  return displayedResults.value
}

const displayPaginatedResults = (displayRangeRaw) => {
  displayRange.value = displayRangeRaw
  displayResults(results.value)
}

const updateFromLink = (filter) => {
  if (filter.id === 'all') {
    highlightResults.value = false
  } else if (filter.id) {
    highlightResults.value = true
  }
}

watch(productId, (newValue, oldValue) => {
  if (newValue !== oldValue) {
    updateFromLink(newValue)
  }
})

onMounted(() => {
  window.addEventListener('updateFilter', (event) => {
    productId.value = event.detail
  })
})

onUnmounted(() => {
  window.removeEventListener('updateFilter', (event) => {
    productId.value = event.detail
  })
})
</script>

<style module lang="scss">
@import './BranchList.scss';
</style>
