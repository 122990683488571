<template>
  <HeroSlider
    :slides="parseExternalProps(props.slides)"
    :height-first="parseExternalProps(props.heightFirst)"
  />
</template>

<script setup>
import HeroSlider from '../../components/HeroSlider/HeroSlider.vue'
import { parseExternalProps } from '../../utils/parseExternalProps'

const props = defineProps({
  slides: {
    type: String,
    default: undefined
  },
  heightFirst: {
    type: String,
    default: undefined
  }
})
</script>
