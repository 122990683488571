<template>
  <ul :class="[$style.container, $style[context]]">
    <li v-for="(country, index) in countriesRef" :key="`${index}-${country.flag}`">
      <tippy
        v-if="elementLinks(country).length > 1"
        v-tippy="{
          theme: 'wika light tooltip',
          interactive: true,
          placement: 'top',
          animation: 'fade',
          duration: [200, 600],
          animateFill: false,
          arrow: true,
          zIndex: 10002,
          trigger: isMobileDevice ? 'click' : 'mouseenter focus'
        }"
      >
        <a :href="country.url || '#'" target="_blank" :class="$style.flagContainer">
          <Flag :flag="country.flag" :title="country.language" :class="$style.flag" />
        </a>

        <template v-if="elementLinks(country).length > 1 && !hideTooltip" #content>
          <ul :class="$style.downloadLinks">
            <li v-if="country.url && hasWishlistLink()">
              <WishListLink
                :config="{
                  text: labels.wishlist,
                  item: {
                    types: types,
                    section: section,
                    title: title,
                    subTitle: subTitle,
                    downloads: groupedDownloadsByUrlRef[country.url]
                  }
                }"
              />
            </li>
            <li v-if="country.ebook">
              <a
                :href="country.ebook"
                target="_blank"
                @click="
                  wtParams &&
                    wtTrack(
                      { cat: 'E_BOOK' },
                      {
                        ...wtParams,
                        doctype: 'E_BOOK',
                        description:
                          country.wtParams && country.wtParams.description
                            ? country.wtParams.description
                            : wtParams.description || country.title || title,
                        filename: country.ebook
                      }
                    )
                "
              >
                <i class="fas fa-book" />{{ labels.ebook }}
              </a>
            </li>
            <li v-if="country.url">
              <a
                :href="country.url"
                target="_blank"
                @click="
                  wtParams &&
                    wtTrack(
                      { cat: wtParams.doctype },
                      {
                        ...wtParams,
                        description:
                          country.wtParams && country.wtParams.description
                            ? country.wtParams.description
                            : wtParams.description || country.title || title,
                        filename: country.url
                      }
                    )
                "
              >
                <i class="fas fa-file-pdf" />{{ labels.pdf }}
              </a>
            </li>
          </ul>
        </template>
      </tippy>
      <a v-else :href="country.url || '#'" target="_blank" :class="$style.flagContainer">
        <Flag :flag="country.flag" :title="country.language" :class="$style.flag" />
      </a>
    </li>
  </ul>
</template>

<script setup>
import { isMobile } from 'mobile-device-detect'
import WishListLink from '../WishListLink/WishListLink.vue'
import Flag from '../Flag/Flag.vue'
import { CountryDownloadBoxContext } from '../../globals/constants/download'
import { groupCollectionBy } from '../../utils/group'
import { sortCollectionAccordingDOWNLOADMATRIX_LANGUAGES } from '../../utils/sort'
import { onBeforeMount, ref } from 'vue'

const props = defineProps({
  types: {
    type: Array,
    default: () => []
  },
  title: {
    type: String
  },
  subTitle: {
    type: String,
    default: ''
  },
  section: {
    type: String
  },
  countries: {
    type: Array,
    default: () => []
  },
  labels: {
    type: Object,
    default: () => {}
  },
  context: {
    type: String
  },
  wtParams: {
    type: Object
  },
  hideTooltip: {
    type: Boolean,
    default: false
  }
})

const groupedDownloadsByUrlRef = ref({})

const isMobileDevice = isMobile
const wtTrack = window.wtTrack

const countriesRef = ref([...props.countries])

const elementLinks = (country) => {
  return [country.url, country.ebook, country.url && hasWishlistLink()].filter(Boolean)
}

const hasWishlistLink = () => {
  return props.context !== CountryDownloadBoxContext.WISHLIST
}

const sortCountries = () => {
  const buffer = sortCollectionAccordingDOWNLOADMATRIX_LANGUAGES(
    countriesRef.value,
    'language',
    'asc'
  )
  const idxCurrentLanguage = buffer.findIndex(
    (elem) => elem.language && elem.language.toUpperCase() === window.currentLanguage
  )

  // Set current language at the beginning of the array
  if (idxCurrentLanguage > -1) {
    buffer.splice(0, 0, buffer.splice(idxCurrentLanguage, 1)[0])
  }

  countriesRef.value = buffer
}

const groupDownloadsByUrl = () => {
  groupedDownloadsByUrlRef.value = groupCollectionBy(countriesRef.value, 'url')
}

onBeforeMount(() => {
  sortCountries()
  groupDownloadsByUrl()
})
</script>

<style module lang="scss">
@import './CountryDownloadBox.scss';
</style>
