<template>
  <div :class="$style.container">
    <div :class="$style.selects">
      <div class="columns is-multiline">
        <div class="column is-half-tablet is-one-quarter-desktop">
          <FormSelect
            v-model="regionSelectValue"
            :has-filter="false"
            :label="config.regionLabel"
            :options="regionSelectOptions"
            :initial-option="regionSelectValue"
            @update="updateCountries"
          />
        </div>
        <div class="column is-half-tablet is-one-quarter-desktop">
          <FormSelect
            :key="`countryKey-${regionSelectValue}`"
            v-model="countrySelectValue"
            :has-filter="false"
            :label="config.countryLabel"
            :options="countrySelectOptions"
            :initial-option="countrySelectValue"
            @update="updateContacts"
          />
        </div>
      </div>
    </div>
    <div class="columns is-multiline">
      <div
        v-for="(result, index) in contacts"
        :key="index"
        class="column is-half-tablet is-one-third-desktop"
      >
        <ListTeaser :result="result" :use-form="useForm" />
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import FormSelect from '../FormSelect/FormSelect.vue'
import ListTeaser from '../ListTeaser/ListTeaser.vue'
import { regionDataUrl } from '../../shared/globals'
import { onMounted, ref } from 'vue'

const props = defineProps({
  config: {
    type: Object,
    default: () => {}
  },
  useForm: {
    type: Boolean,
    default: false
  }
})

const apiData = ref(null)
const contacts = ref([])
const regionSelectOptions = ref([])
const regionSelectValue = ref('')
const countrySelectOptions = ref([])
const countrySelectValue = ref('')

const wtTrack = window.wtTrack

const getContacts = (url) => {
  return axios
    .get(url)
    .then((response) => (contacts.value = response.data.contacts))
    .catch((error) => console.error(error))
}

const updateContacts = (valueRaw) => {
  const value = Array.isArray(valueRaw) ? valueRaw[0] : valueRaw
  wtTrack({ cat: 'SALESBOXCLICK' }, { inputIdent: `${regionSelectValue.value} - ${value}` })
  countrySelectValue.value = value

  for (let region of apiData.value.regions) {
    if (region.name === regionSelectValue.value) {
      for (let country of region.countries) {
        if (country.name === value) {
          getContacts(country.contacts)
        }
      }
    }
  }
}

const initApiData = (data) => {
  apiData.value = data
  regionSelectValue.value = props.config.currentRegion
  countrySelectValue.value = props.config.currentCountry

  for (let region of apiData.value.regions) {
    regionSelectOptions.value.push(region.name)

    if (region.name === props.config.currentRegion) {
      for (let country of region.countries) {
        countrySelectOptions.value.push(country.name)

        if (country.name === props.config.currentCountry) {
          getContacts(country.contacts)
        }
      }
    }
  }
}

const updateCountries = (valueRaw) => {
  const value = Array.isArray(valueRaw) ? valueRaw[0] : valueRaw
  regionSelectValue.value = value

  for (let region of apiData.value.regions) {
    if (region.name === value) {
      countrySelectValue.value = region.countries.some(
        (country) => country.name === props.config.currentCountry
      )
        ? props.config.currentCountry
        : region.countries[0].name
      countrySelectOptions.value.length = 0

      const selectedCountryIndex = region.countries.findIndex(
        (country) => country.name === countrySelectValue.value
      )

      for (let country of region.countries) {
        countrySelectOptions.value.push(country.name)
      }
      getContacts(
        selectedCountryIndex
          ? region.countries[selectedCountryIndex]?.contacts
          : region.countries[0].contacts
      )
    }
  }
}

onMounted(() => {
  let dataUrl = ''
  window.regionDataUrl ? (dataUrl = window.regionDataUrl) : (dataUrl = regionDataUrl)
  axios
    .get(dataUrl)
    .then((response) => initApiData(response.data))
    .catch((error) => console.error(error))
})
</script>

<style module lang="scss">
@import './WorldWideList.scss';
</style>
