import { createApp } from 'vue'
import App from './App.vue'
import { customAlphabet } from 'nanoid'

const selector = '.individualPrice:not([data-initialized])'

export default function init() {
  function mountApp(element) {
    const app = createApp(App, { ...element.dataset })

    const nanoid = customAlphabet('abcdefghijklmnopqrstuvwxyt', 10)
    element.id = nanoid()

    app.mount(`#${element.id}`)
  }

  const elements = document.querySelectorAll(selector)

  elements.forEach((element) => {
    element.setAttribute('data-initialized', true)
    mountApp(element)
  })

  window.initIndividualPrice = () => {
    const lazyElements = document.querySelectorAll(selector)

    lazyElements.forEach((element) => {
      element.setAttribute('data-initialized', true)
      mountApp(element)
    })
  }
}
