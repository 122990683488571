const ShopPostMessages = {
  CHECK_LOGIN_STATE: 'checkLoginState',
  GET_CART: 'GETCART',
  REDIRECT_TO_BASE_URL: 'REDIRECTTOBASEURL',
  RESIZE_IFRAME: 'RESIZEIFRAME',
  SCROLL_TOP: 'SCROLLTOP',
  SHOW_CANCEL_PAYMENT_MESSAGE: 'showCancelPaymentMessage',
  WEB_SHOP_FRAME: 'WEBSHOPFRAME'
}

export default ShopPostMessages

const ShopQueryActionType = {
  ACCOUNT: 'account',
  ACCOUNT_EDIT: 'accountEdit',
  ACTION_TYPE: 'actionType',
  ADDRESS: 'address',
  CANCEL_PAYMENT: 'cancelPayment',
  CREATE_ACCOUNT_CONFIRM: 'createaccountconfirm',
  CREATE_PASSWORD: 'createpassword',
  FORGOT_PASSWORD: 'forgotpassword',
  RESEND_ACTIVIATION_LINK: 'resendactivationlink',
  HISTORY: 'history',
  REGISTER: 'register',
  SHOW_CART: 'showcart',
  SUCCESS_PAYMENT: 'successpayment',
  SUCCESS_PAYMENT_CW: 'successpaymentcw'
}

export { ShopQueryActionType }
