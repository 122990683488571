<template>
  <div :class="[{ MicrositePageHeader__sticky__margin: sticky }, $style.container]">
    <div :class="$style.navMeta">
      <div class="columns is-gapless is-mobile is-vcentered">
        <div
          :class="[
            $style.metaNavLink,
            'column is-one-quarter-mobile is-one-quarter-tablet is-one-fifth-desktop'
          ]"
        >
          <a :href="config.metaNavLink">{{ config.metaNavLinkText }}</a>
        </div>
        <div
          :class="[
            $style.navMetaWithSearch,
            'column',
            'is-three-quarters-mobile',
            'is-three-quarters-tablet',
            'is-four-fifths-desktop'
          ]"
        >
          <LanguageSwitcher :options="config.langSwitcherOptions" />
          <ElasticSearch :config="searchConfig" />
          <QuickLinks :quick-links="config.quickLinks" />
        </div>
      </div>
    </div>
    <div :class="[{ MicrositePageHeader__sticky: sticky }, $style.navMain]">
      <div class="columns is-mobile is-multiline is-vcentered">
        <div :class="[$style.homeLink, 'column']">
          <a title="WIKA" :href="config.homeLink">
            <img :class="$style.logoBlue" src="../../assets/logo_wika.svg" alt="WIKA" />
            <img :class="$style.logoWhite" src="../../assets/logo_wika_white.svg" alt="WIKA" />
          </a>
        </div>
        <div :class="[$style.desktopNav, 'column']">
          <MainMenu :is-microsite="true" :primary-nav="config.primaryNav" />
        </div>
        <div v-if="config.isMicrositeHR" :class="[$style.jobPortalButton, 'column']">
          <Button colour="orange" text="ZUM JOBPORTAL" no-border is-link href="#" />
        </div>
        <div :class="[$style.mobileNav, 'column']">
          <MobileMenu :is-microsite="true" :primary-nav="config.primaryNav" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher.vue'
import MainMenu from '../MainMenu/MainMenu.vue'
import MobileMenu from '../MobileMenu/MobileMenu.vue'
import QuickLinks from '../QuickLinks/QuickLinks.vue'
import Button from '../Button/Button.vue'
import ElasticSearch from '../ElasticSearch/ElasticSearch.vue'
import { onBeforeMount, onUnmounted, ref } from 'vue'

defineProps({
  config: {
    type: Object,
    default: () => {}
  }
})

const sticky = ref(false)
const stickyPosition = ref(40)
const searchConfig = {
  headerSearch: false,
  isShop: false,
  submitUrl: '',
  placeholder: 'Landsuche'
}

const initScroll = () => {
  window.addEventListener('scroll', handleScroll)
}
const handleScroll = () => {
  sticky.value = window.scrollY > stickyPosition.value
}

onBeforeMount(() => {
  initScroll()
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<style module lang="scss">
@import './MicrositePageHeader.scss';
</style>
